// Import webpack libraries
import Rails                              from "@rails/ujs";

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { confirmModal }                   from '@video_lib/helpers/js_modals'

import I18n                               from '@video_lib/i18n-js/init.js.erb'
import { Constant }                       from '@video_lib/constant.js.erb'

export default class extends SimpleAjaxForm {
  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    let _this = this

    alertify.success(I18n.t('alertify.success'))
    _this.editPickupServicesController.successSubmit(event)
    _this.editPickupEventController.successSubmit(event)
  }

  failedSubmit(event) {
    alertify.error(I18n.t('alertify.error'))
  }

  ////
  // GETTER CONTROLLER METHODS
  ////

  get editPickupServicesController() {
    return this.getController('owner--pickup--edit-pickup-services')
  }

  get editPickupEventController() {
    return this.getController('owner--pickup--edit-pickup-event')
  }

  ////
  // ADTIONAL METHODS
  ////

  submitForm() {
    Rails.fire(this.element, 'submit')
  }
}
