import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['toggler', 'collapseMenu']

  connect() {
    super.connect()
  }

  toggle(e) {
    if (e.target.getAttribute('aria-expanded') == 'false') {
      $(this.collapseMenuTarget).addClass('animate')
      $(this.collapseMenuTarget).addClass('show')
    } else {
      $(this.collapseMenuTarget).removeClass('animate')
      $(this.collapseMenuTarget).removeClass('show')
    }
  }
}
