// Import libraries from webpacker libraries
import Rails from '@rails/ujs';
import { serialize } from 'object-to-formdata';
import { el } from 'redom';
import { v4 as uuidv4 } from 'uuid';

// Import custom libraries
import ApplicationController from '@controllers/application_controller';
import I18n from '@lib/i18n-js/init.js.erb';
import { showLoaderFor, selectFirstMessage } from '@lib/helpers/view_helpers';
import _ from 'lodash';
import UploadAndPreviewImage from "@lib/helpers/upload_and_preview_logo_helper";
import pdfPreview from '@lib/helpers/pdf_preview_helper';
import FileSizeLessThan from '@lib/helpers/check_file_size_less_than_helper';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends ApplicationController {
  static values = {
    needSurvey:            Boolean,
    id:                    String,
    downloadableType:      String,
    downloadableId:        String,
    eventSlug:             String,
    acceptedFileTypes:     Array,
    eventServiceId:        String,
    serviceId:             String,
    assignedDocuments:     Array,
    allDocuments:          Array,
    something:             Object,
    exhibitorMaxDocs:      Number,
    eventServiceDocuments: Array
  }

  static targets = [
    'documentItem',
    'documentList',
    'checkboxInput',
    'hiddenDownloadLink',
    'downloadBtn',
    'tooltipWrapper',
    'serviceBannerInput',
    'serviceBannerPreview',
    'logoInput',
    'logoPreview',
    'addDocumentBtn',
    'dropdownOpener',
    'uploadedDocumentsList',
    'documentsDropdown',
    'dropdownDocumentItem',
    'submitBtn',
    'cancelBtn',
    'placeholderDocumentInput',
    'emptyDocumentList'
  ]

  connect() {
    $(window).on('beforeunload', function(){
      if($('#cancel-btn').first().attr('data-confirm_to_close')) {
        return 'Are you sure you want to leave?';
      }
    });

    $("#service-detail-modal").on("hide.bs.modal", function () {
      if($('#cancel-btn').first().attr('data-confirm_to_close')) {
        confirmModal({
          title: '',
          message: I18n.t('organizer.event_service.confirm_modals.close_modal'),
          labels: {
            ok_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.confirm'),
            cancel_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.cancel')
          },
          onOk: () => {
            $("#service-detail-modal").modal('hide')
          },
          onCancel: () => {
            $("#service-detail-modal").modal('show')
          }
        })
      }
    });

    super.connect()
    this.initTooltip()
    this._setupOnClickOutsideDocumentsDropdown()
    this._setupDiscardDocumentTitleChangeOnOutside()

    // Documents data for submit
    // { id, title, file, deleteMark, key }
    this.documentsParams = []

    // documents to be created, then assign to event_service
    this.assignedDocumentsParams = []
    this.allDocuments            = []

    this.allDocumentsValue.forEach(document => {
      this._addAllDocument(document)
    })

    this.assignedDocumentsValue.forEach(document => {
      this._addAssignedDocumentParams(document)
    })

    if ($(this.element).hasClass('edit')) {
      $(this.element).parents('.modal-wrapper').find('.close-btn').addClass('d-none')
    }
    else {
      $(this.element).parents('.modal-wrapper').find('.close-btn').removeClass('d-none')
    }

    setTimeout(() => {
      this._setupDocumentsLabels()
    }, 150)
  }

  ////
  // OVERRIDE METHODS
  ////

  ////
  // NEW METHODS
  ////

  // Wrapper controller of chat screens
  get wrapperController() {
    let controllers = this.application.controllers.filter(controller => {
      return controller.context.identifier == 'direct-chat--wrapper';
    });

    return controllers[0];
  }

  get chatModalController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'direct-chat--chat-modal';
    });
  }

  get _previewSources() {
    return {
      pdfPreview: 'pdfPreview',
      imageUrl: 'imageUrl'
    }
  }

  initTooltip() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  inputChange() {
    this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')
  }

  // DELETE EVENT_SERVICE
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.event_service.confirm_modals.delete_event_service.title'),
      message: I18n.t('organizer.event_service.confirm_modals.delete_event_service.content'),
      labels: {
        ok_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }

  openServiceChatScreen(e) {
    // $('.modal').modal('hide')
    // this.chatModalController.chatBubbleTarget.classList.add('d-none')
    this.wrapperController.screensWrapperTarget.classList.remove('d-none')
    this.loadDirectChatScreen(e.currentTarget)
  }

  loadDirectChatScreen(target){
    // Show loader
    showLoaderFor(this.wrapperController.screensWrapperTarget)
    // Load direct chat screen
    this.stimulate(
      'DirectChat::ScreensReflex#create_and_load_direct_chat_screen',
      target,
      {
        'room_info_type': 'ServiceRoom',
        'owner_id': target.dataset.owner_id,
        'event_id': target.dataset.event_id,
        'service_id': target.dataset.service_id
      }
    )
  }

  _setupOnClickOutsideDocumentsDropdown() {
    $(document).on('click', (evt) => {
      if (!$(evt.target).closest('.documents-dropdown').length) {
        $('.documents-dropdown').removeClass('show')
      }
    })
  }

  _setupDiscardDocumentTitleChangeOnOutside() {
    $(document).on('click', (evt) => {
      if (!$(evt.target).closest('.dropdown-document-item.edit').length) {
        let $item = $('.dropdown-document-item.edit')
        if ($item.length) {
          $item.find('.input-draft-document-title').val($item[0].dataset.title)
          $item.removeClass('edit')
        }
      }
    })
  }

  getDocumentData(documentTarget) {
    let data = JSON.parse(documentTarget.dataset.document)
    let { id, title, key, url, file, deleteMark } = data

    return {  id, title, key, url, file, deleteMark  }
  }

  setDocumentData(documentTarget, { id, title, key, url, file, deleteMark }) {
    let data = JSON.parse(documentTarget.dataset.document)

    if (id)         data.id = id
    if (title)      data.title = title
    if (key)        data.key = key
    if (url)        data.url = url
    if (file)       data.file = file
    if (deleteMark) data.deleteMark = deleteMark

    documentTarget.dataset.document = JSON.stringify(data)
  }

  // アップロード済み資料リスト
  openDocumentsDropdown(evt) {
    evt.stopPropagation();

    if (!this.uploadedDocumentsListTarget.innerHTML) {
      this.allDocumentsValue.forEach(document => {
        $(this.uploadedDocumentsListTarget).append(this._buildDropdownDocumentItem(document))
      })
    }

    this.documentsDropdownTarget.classList.add('show')

    $('[data-toggle="tooltip"]').tooltip();
  }

  _previewPdfDocument(documentItem, file) {
    // Work for both dropdown document and document
    let preview =
      $(documentItem).find('canvas.draft-pdf-viewer')[0] ||
      $(documentItem).find('canvas.pdf-viewer')[0]

    if ($(preview).hasClass('pdf-viewer')) {
      $(preview).parents('.canvas-wrap').addClass('has-preview')
    }

    pdfPreview(file, preview, 1, 1.5)
  }

  editDocument(evt) {
    let item = this._findDropdownDocumentItem(evt.currentTarget.dataset.key)
    item.classList.add('edit')
  }

  deleteDocument(event) {
    const document_id = event.currentTarget.dataset.id;

    confirmModal({
      title: '資料の削除',
      message: 'BOXIL EVENT CLOUD上から資料を削除します。',
      labels: {
        ok_btn: 'OK',
        cancel_btn: 'キャンセル'
      },
      onOk: () => {
        this.stimulate(
          'Events::EventServiceReflex#delete_document',
          event.target,
          {
            document_id: document_id,
            event_service_id: this.eventServiceIdValue
          }
        ).then(
          () => {
            document.querySelector('#dropdown-document-item-' + document_id).remove()
            alertify.success('資料の削除が完了しました。')
          }
        )
      }
    })
  }

  cancelEditDocument(evt) {
    let key = evt.currentTarget.dataset.key

    let item = this._findDropdownDocumentItem(key)
    item.classList.remove('edit')

    $(item).find('.input-draft-document-title').val(this.getDocumentData(item).title)
  }

  applyEditDocument(evt) {
    let key = evt.currentTarget.dataset.key
    let item = this._findDropdownDocumentItem(key)
    let $item = $(item)
    let $titleInput = $item.find('.input-draft-document-title')
    let newTitle = $item.find('.input-draft-document-title').val()

    if (!newTitle) {
      $titleInput.addClass('error')
      return
    }
    else {
      $titleInput.removeClass('error')
    }

    this.setDocumentData(item, { title: newTitle })
    $(item).find('.document-title').text(newTitle)

    let { id, title } = this.getDocumentData(item)
    let docIndex = this.documentsParams.findIndex(doc => doc.key == key)

    if (docIndex != -1) {
      this.documentsParams[docIndex].title = title
    }
    else {
      this.documentsParams.push({ id, title, key })
    }

    item.classList.remove('edit')

    this.stimulate(
      'Service::DocumentReflex#update_document',
      evt.currentTarget,
      {
        document_id: id,
        title: newTitle
      }
    )
  }

  _findDocumentItem(identifier) {
    if (!identifier) return

    return this.documentItemTargets.find(item => {
      return this.getDocumentData(item).id == identifier ||
             this.getDocumentData(item).key == identifier
    })
  }

  _findDocumentItemDropdown(documentData) {
    return this.dropdownDocumentItemTargets.find(item => item.dataset.key == documentData.key || item.dataset.documentId == documentData.id.toString())
  }

  _findDropdownDocumentItem(key) {
    return this.dropdownDocumentItemTargets.find(item => this.getDocumentData(item).key == key)
  }

  // 新規ファイルアップロード
  newDocument(evt) {
    let key = uuidv4()
    let document = { key }

    $(this.uploadedDocumentsListTarget).prepend(this._buildDropdownDocumentItem(document, 'view', false))

    setTimeout(() => {
      let item = this._findDropdownDocumentItem(key)

      $(item).find('.input-draft-document-file')[0].click()
    }, 250)
  }

  _buildDocumentItem(document, previewSource) {
    let { key, id, title, file, url } = document

    let ctrlr = 'services--detail-modal'
    let jsonDocument = JSON.stringify(document)
    let documentItemAttrs = {
      [`data-${ctrlr}-target`]: 'documentItem',
      'data-document': jsonDocument
    }

    let canvasClass = ''
    let imageClass = ''
    if (previewSource == this._previewSources.pdfPreview) {
      canvasClass = ''
      imageClass = 'd-none'
    }
    else {
      canvasClass = 'd-none'
      imageClass = ''
    }

    return el('.col-sm-6.mb-24.document-item-wrap',
      el('.document-item', [
        el('.preview-wrap.flex-vh-centered', [
          el('.document-label.cursor-pointer', { 'data-toggle': 'tooltip', 'data-placement': 'top', 'data-action': `click->${ctrlr}#markDocumentAsPrimary` }),
          el(`.canvas-wrap`, [
            el('canvas.pdf-viewer'),
            el('span.remove-document-btn.material-icons.icons', 'close', {
              'data-action': `click->${ctrlr}#removeDocument`,
              'data-id': id,
              'data-key': key
            })
          ]),
          el(`.image-wrap.position-relative`, [
            el('img.document-item__preview', { src: url || '' },
              el('.document-preview-overlay')
            ),
            el('span.remove-document-btn.material-icons.icons.clickable', 'close', {
              'data-action': `click->${ctrlr}#removeDocument`,
              'data-id': id,
              'data-key': key,
              'data-toggle': 'tooltip',
              'data-placement': 'top',
              'title': 'このイベントから非表示にする'
            })
          ]),
        ]),
        el(`.caption-2.mt-9.document-item__title.no-pointer-events.text-center.document_item_title_${id}`, title)
      ],
      documentItemAttrs)
    )
  }

  // ファイルアップロード
  uploadDocumentFile(evt) {
    this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')

    let key = evt.target.dataset.key
    let item = this._findDropdownDocumentItem(key)
    let file = evt.target.files[0]

    // ファイルサイズ：25MB超過バリデーション
    if (!FileSizeLessThan(file, 25 * 1024 * 1024)) {
      alertify.error('資料のファイルサイズが規定のサイズを超えているため失敗しました。ファイルサイズを25MB以内にしてください。')
      $(item).remove()
      return
    }

    let firstUpload = !this.getDocumentData(item).title

    if (firstUpload) {
      let fileName = file.name.substring(0, file.name.lastIndexOf('.'))

      // ファイル名：50文字超過バリデーション
      if (fileName.length > 50) {
        alertify.error('資料のタイトルが規定の文字数を超えているため失敗しました。タイトルを50文字以内に設定してください。')
        $(item).remove()
        return
      }

      let formData = new FormData();

      formData.append('event_service_id', this.eventServiceIdValue);
      formData.append('file', file);

      Rails.ajax({
        url: Routes.upload_documents_path(),
        type: 'POST',
        data: formData,
        success: (response) => {
          if (response['result'] == 'success') {
            // ファイルリスト
            item.setAttribute('id', 'dropdown-document-item-' + response['document_id'])

            // ダウンロードリンク
            document.querySelector('#document-download-link-' + key).setAttribute('href', Routes.download_document_path(response['document_id']))

            // 削除ボタン
            document.querySelector('#download-delete-button-' + key).setAttribute('data-id', response['document_id'])
          }
        }
      })

      this.setDocumentData(item, { title: fileName })
      this.documentsParams.push({ key, title: fileName, file })
      this.allDocuments.push({ key, isAssigned: false })

      let $item = $(item)
      $item.removeClass('d-none')

      // Preview pdf file
      this._previewPdfDocument(item, file)

      // Set fileName as title
      $item.find('.input-draft-document-title').val(fileName)
      $item.find('.document-title').text(fileName)
    }
    else {
      let { id } = evt.target.dataset
      let file = evt.target.files[0]
      let docItem = this._findDocumentItem(id || key)

      this._previewPdfDocument(item, file)

      if (docItem) {
        this._previewPdfDocument(docItem, file)
      }

      let docIndex = this.documentsParams.findIndex(doc => doc.key == key)
      if (docIndex != -1) {
        this.documentsParams[docIndex].file = file
      }
      else {
        this.documentsParams.push({ id, file, key })
      }
    }
  }

  _setupDocumentsLabels() {
    // Remove primary class from all document item
    $('.document-item-wrap:not(.d-none)').each(function (_ind, elm) {
      $(elm).find('.document-item .document-label').removeClass('primary').tooltip('dispose')
    })

    // Add primary class for first document item
    let primary = $('.document-item-wrap:not(.d-none)').first().find('.document-item .document-label')[0]
    if (primary) {
      $(primary).addClass('primary').text(I18n.t('shared.services.detail_modal.labels.primary_doc'))
    }

    // Setup text and tooltip for non-primary
    $('.document-item-wrap:not(.d-none)').each(function (_ind, elm) {
      let label = $(elm).find('.document-item .document-label:not(.primary)')[0]
      if (!label) return

      label.classList.add('cursor-pointer')
      label.innerText = I18n.t('shared.services.detail_modal.labels.sub_doc')
      label.title = I18n.t('shared.services.detail_modal.tooltips.sub_documen_label')
      $(label).tooltip()
    })
  }

  markDocumentAsPrimary(evt) {
    if (evt.target.classList.contains('primary')) return

    let $primaryDoc = $('.document-item-wrap:not(.d-none)').first()
    let $currentDoc = $(evt.target).parents('.document-item-wrap:not(.d-none)')

    // Use d-none to handle issue with tooltip
    $currentDoc.addClass('d-none')

    $primaryDoc.clone().insertAfter($currentDoc)
    $currentDoc.insertBefore($primaryDoc)
    $primaryDoc.remove()
    $currentDoc.removeClass('d-none')
    $currentDoc.find('.cursor-pointer').removeClass('cursor-pointer')

    this._markPrimaryDocumentParams(JSON.parse($currentDoc.find('.document-item')[0].dataset.document))

    setTimeout(() => this._setupDocumentsLabels(), 100)
  }

  assignDocument(evt) {
    let shouldSkip =
      evt.target.classList.contains('menu-icon') ||
      evt.target.classList.contains('input-draft-document-file') ||
      evt.target.classList.contains('cancel-edit-btn') ||
      evt.target.classList.contains('apply-edit-btn') ||
      $(evt.target).parents('.more-menu').length ||
      $(evt.target).parents('.preview-wrap').length ||
      evt.currentTarget.classList.contains('edit')

    if (shouldSkip) return

    // Check exist
    let documentData = this.getDocumentData(evt.currentTarget)
    let exist = $('.document-item-wrap:not(.d-none) .document-item').toArray().some((elm) => {
      let docData = JSON.parse(elm.dataset.document)
      return (documentData.id && docData.id == documentData.id) ||
        documentData.key == docData.key
    })

    if (exist) return

    // Check max documents
    let maxReached = $('.document-item-wrap:not(.d-none)').length >= this.exhibitorMaxDocsValue

    if (maxReached) return

    // Check show confirm to close
    this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')

    // Check hidden
    let isHidden = $('.document-item-wrap.d-none .document-item').toArray().some((elm) => {
      let docData = JSON.parse(elm.dataset.document)

      return (documentData.id && docData.id == documentData.id) ||
        documentData.key == docData.key
    })

    if (isHidden) {
      let docElm = $('.document-item').toArray().find(elm => {
        let docData = JSON.parse(elm.dataset.document)

        return (documentData.id && docData.id == documentData.id) ||
                documentData.key == docData.key
      })

      // Check if this is the first assigned document
      let isFirstAssigned = $('.document-item-wrap:not(.d-none)').length == 0

      if (isFirstAssigned) {
        this.emptyDocumentListTarget.classList.add('d-none')
        this.documentListTarget.classList.remove('d-none')
      }

      let $docWrap =  $(docElm).parents('.document-item-wrap')

      $docWrap.appendTo($(this.documentListTarget).find('.row'))
      $docWrap.removeClass('d-none')

      // Handle selection
      this._addAllDocument(documentData)
      this._setItemDocumentDropdownSelection(documentData)
      this._addAssignedDocumentParams(documentData)

      this._setupDocumentsLabels()
      return
    }

    this._addAssignedDocumentParams(documentData)
    this._addAllDocument(documentData)

    // Check if this is the first assigned document
    let isFirstAssigned = $('.document-item-wrap:not(.d-none)').length == 0

    if (isFirstAssigned) {
      this.emptyDocumentListTarget.classList.add('d-none')
      this.documentListTarget.classList.remove('d-none')
    }

    let isPrimary = isFirstAssigned

    if (isPrimary) this._markPrimaryDocumentParams(documentData)

    let previewSource = documentData.url ? this._previewSources.imageUrl : this._previewSources.pdfPreview
    let itemDom       = this._buildDocumentItem(documentData, previewSource)

    $(this.documentListTarget).find('.row').append(itemDom)
    this.initTooltip()

    // Handle selection
    this._setItemDocumentDropdownSelection(documentData)

    this._setupDocumentsLabels()

    let file = $(evt.currentTarget).find('.input-draft-document-file')[0].files[0]

    if (!file) return

    setTimeout(() => {
      let documentItem = this._findDocumentItem(documentData.key)
      this._previewPdfDocument(documentItem, file)
    }, 250)
  }

  triggerSelectItemFile(evt) {
    $(evt.currentTarget).find('.input-draft-document-file')[0].click()
  }

  // 未使用？(grepで使用箇所が検出されない)
  uploadItemFile(evt) {
    let { id, key } = evt.target.dataset
    let file = evt.target.files[0]
    let dropItem = this._findDropdownDocumentItem(key)
    let docItem = this._findDocumentItem(id || key)

    this._previewPdfDocument(dropItem, file)
    this._previewPdfDocument(docItem, file)

    let docIndex = this.documentsParams.findIndex(doc => doc.key == key)
    if (docIndex != -1) {
      this.documentsParams[docIndex].file = file
    }
    else {
      this.documentsParams.push({ id, file, key })
    }
  }

  setZIndexTargetDropdownMenu(event) {
    const zIndexControlTargetClass = event.target.dataset.zIndexControlTargetClass;

    document.querySelectorAll('.z-index-control').forEach((element) => {
      element.classList.remove('z-index-10');
    });

    document.querySelector('.' + zIndexControlTargetClass).classList.add('z-index-10');
  }

  _buildDropdownDocumentItem = (document, mode = 'view', visible = true) => {
    const existEventServiceDocument = this.eventServiceDocumentsValue.find((doc) => doc.document_id == document.id )

    let { id, key, title, url } = document
    let ctrlr                   = 'services--detail-modal'
    let jsonDocument            = JSON.stringify(document)
    let editDataAttrs           = {
      'data-action': `click->${ctrlr}#editDocument`,
      'data-id': document.id,
      'data-key': key,
      'data-document': jsonDocument
    }
    let deleteAttrs           = {
      'id': 'download-delete-button-' + key,
      'title': existEventServiceDocument ? '公開中のイベントに表示されている資料のため、削除できません。' : '',
      'data-toggle': 'tooltip',
      'data-action': existEventServiceDocument ? '' : `click->${ctrlr}#deleteDocument`,
      'data-id': document.id
    }

    let maxReached            = $('.document-item-wrap:not(.d-none)').length >= this.exhibitorMaxDocsValue
    let isNotAssignedDocument = this.allDocuments.find((e) => e.id == document.id && !e.isAssigned )
    let isAssignedDocument    = this.assignedDocumentsParams.find((e) => (e.id == document.id || e.key == document) && !e.deleteMark )
    let rootClass             = visible ? '' : 'd-none'
    let assignedClass         = isAssignedDocument ? '' : '.d-none'
    let selectionBackground   = isNotAssignedDocument && maxReached ? '.selected' : ''

    const zIndexControlTargetClass = `z-index-control-${document.key}`
    const downloadDocumentPath     = document.id ? Routes.download_document_path(document.id) : ''

    return el(`#dropdown-document-item-${document.id}.dropdown-document-item.z-index-control.${zIndexControlTargetClass}.${mode}.${rootClass}${selectionBackground}`, [
      el(`.check-icon${assignedClass}`,
        el('.i-Check.icon-md.color-white')
      ),
      el('.preview-wrap.position-relative', [
        el('.overlay-cover'),
        el('.overlay-select-file',
          el('i.i-Edit.icon-md.absolute-vh-centered.color-white')
        ),
        // ファイルアップロード
        el('input.d-none.input-draft-document-file', '', { type: 'file', accept: '.pdf', 'data-id': id, 'data-key': key, 'data-action': `change->${ctrlr}#uploadDocumentFile` }),
        el('canvas.draft-pdf-viewer'),
        el('img.dropdown-document__preview', { src: url || '' }),
      ], { 'data-key': key, 'data-id': id, 'data-action': `click->${ctrlr}#triggerSelectItemFile` }),
      el('.caption-2.document-title', title),
      el('.document-edit-form', [
        el('textarea.input-draft-document-title.caption-2', title),
        el('.btn-groups.d-flex.justify-content-end', [
          el('button.btn.btn-outline-default.btn-sm.bg-white.mr-8.cancel-edit-btn', I18n.t('shared.common.buttons.cancel'), {
            'data-action': `click->${ctrlr}#cancelEditDocument`,
            'data-key': key,
            'data-id': id
          }),
          el('button.btn.btn-primary.btn-sm.d-flex.align-items-center.apply-edit-btn', [
            el('i.i-Check.icon-sm.mr-4'),
            I18n.t('shared.common.buttons.submit')
          ], { 'data-action': `click->${ctrlr}#applyEditDocument`, 'data-key': key, 'data-id': id }),
        ])
      ]),
      el(`.actions-shortcut.dropdown.flex-vh-centered`, [
        el('span.material-icons.menu-icon.d-block', 'more_vert', { 'data-toggle': 'dropdown', 'data-action': `click->${ctrlr}#setZIndexTargetDropdownMenu`, 'data-z-index-control-target-class': zIndexControlTargetClass }),
        el('.dropdown-menu.dropdown-menu-right.shadow',
          el('section.more-menu',
            el('.list-item',
              el('.body-1.d-flex.align-items-center', [
                el('span.material-icons-outlined.menu-icon.mr-8', 'edit'),
                'タイトルを編集'
              ]),
              editDataAttrs
            ),
            el('.list-item',
              el('.body-1.d-flex.align-items-center', [
                el('span.material-icons-outlined.menu-icon.mr-8', 'file_download'),
                el('a', 'ダウンロード', { href: downloadDocumentPath, download: true, id: 'document-download-link-' + key })
              ]),
              { 'data-action': `click->${ctrlr}#clickDownloadLink`, 'data-download-link-element-id': 'document-download-link-' + key }
            ),
            el('.list-item',
              el('.body-1.d-flex.align-items-center', [
                el('span.material-icons-outlined.menu-icon.mr-8', 'clear'),
                '削除'
              ]),
              deleteAttrs
            )
          )
        )
      ])
    ], {
      'data-document': jsonDocument,
      'data-key': key,
      'data-document-id': document.id,
      [`data-${ctrlr}-target`]: 'dropdownDocumentItem',
      'data-action': `click->${ctrlr}#assignDocument`
    })
  }

  clickDownloadLink(event) {
    document.querySelector('#' + event.currentTarget.dataset.downloadLinkElementId).click();
  }

  subscribeContent(e) {
    let $bookmarker = $(e.currentTarget)
    let contentId = e.currentTarget.dataset.event_content_id

    Rails.ajax({
      url: Routes.subscribe_event_event_content_path(contentId, { event_slug: this.element.dataset.event_slug }),
      type: 'PUT',
      data: {},
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = selectFirstMessage(errors)
        alertify.error(error_message)
      },
      success: (e) => {
        let { status } = e.data

        $bookmarker.parents().find(`.text-${contentId}`).text(`${I18n.t('shared.timetables.index.buttons.subscribed')}`)
        $bookmarker.parents().find(`.btn-${contentId}`).removeClass('btn-outline-accent')
        $bookmarker.parents().find(`.btn-${contentId}`).addClass('btn-accent')
        $bookmarker.parents().find(`.icon-${contentId}`).removeClass('i-Save')
        $bookmarker.parents().find(`.icon-${contentId}`).addClass('i-CheckCircle')

        $bookmarker.parents().find(`.card-btn-${contentId}`).addClass('content-active')
        $bookmarker.parents().find(`.card-icon-${contentId}`).removeClass('d-none')
        $bookmarker.parents().find(`.card-text-${contentId}`).removeClass('text-accent')
        $bookmarker.parents().find(`.card-text-${contentId}`).text('予約中')

        // 視聴予約解除不可
        $bookmarker.removeAttr('data-action');
        $bookmarker.parents().find(`.card-btn-${contentId}`).attr('style', 'cursor: default;')

        alertify.success(I18n.t('messages.notify.general.edit.success'))
      }
    })
  }

  toggleDocumentCheck(evt) {
    let input = this.checkboxInputTargets.find((checkbox) => checkbox.dataset.documentId === evt.currentTarget.dataset.documentId)
    let documentItem = this.documentItemTargets.find((item) => item.dataset.documentId === evt.currentTarget.dataset.documentId)
    let hiddenLink = this.hiddenDownloadLinkTargets.find((link) => link.dataset.documentId == evt.currentTarget.dataset.documentId)

    if (input.checked) {
      input.checked = false
      documentItem.firstElementChild.classList.remove('is-checked')
    }
    else {
      input.checked = true
      documentItem.firstElementChild.classList.add('is-checked')
    }
    this.updateDownloadBtnStatus()
  }

  downloadDocuments(evt) {
    if (this.needSurveyValue) {
      // 名刺情報取得モーダル
      this.downloadSurveyModalCtrlr.showModal(
        this.idValue,
        this.downloadableTypeValue,
        this.downloadableIdValue,
        this.eventSlugValue,
        this.selectedDocumentIds
      )
    }
    else {
      if(this.checkboxInputTargets.length > 0) {
        let links = this.hiddenDownloadLinkTargets.filter((link) => {
          return this.checkboxInputTargets.some((input) => {
            return input.dataset.documentId == link.dataset.documentId && input.checked
          })
        })

        links.forEach((link, i) => {
          if(i == 1) {
            setTimeout(() => link.click(), 5000)
          } else {
            setTimeout(() => link.click(), 2000)
          }
        })
      } else {
        this.hiddenDownloadLinkTarget.click()
      }
    }
  }

  saveChanges(evt) {
    evt.currentTarget.disabled = true

    let $this               = $(this.element)
    let banner_file         = this.serviceBannerInputTarget.files[0]
    let logo_file           = this.logoInputTarget.files[0]
    let service_name        = $this.find('.service-name-input').val()
    let service_url         = $this.find('.service-url-input').val()
    let service_description = $this.find('.service-description-input').val()
    let reach_user_number   = $this.find('#service-reach-user-number-input').val()

    let params = {
      event_services: {
        banner_file,
        logo_file,
        service_name,
        service_url,
        service_description,
        reach_user_number,
        documents: this.documentsParams.map(doc => ({
          id:    doc.id,
          key:   doc.key,
          title: doc.title,
          file:  doc.file,
        })),
        assigned_documents: this.assignedDocumentsParams.map(doc => ({
          id:          doc.id,
          key:         doc.key,
          delete_mark: doc.deleteMark,
          is_primary:  doc.isPrimary
        }))
      }
    }

    Rails.ajax({
      url:      Routes.event_exhibitors_event_service_path(this.eventServiceIdValue, { event_slug: this.eventSlugValue }),
      type:     'PUT',
      data:     serialize(params),
      dataType: 'json',
      success: (resp) => {
        this.cancelBtnTarget.dataset.confirm_to_close = ''
        this.switchToView()

        let $serviceCard = $(`.service-card-wrapper[data-event-service-id="${this.eventServiceIdValue}"]`)

        $serviceCard.find('.service-card__name').text(service_name)
        $serviceCard.find('.service-card__description').text(service_description)

        this.stimulate('Events::EventServiceReflex#reload_event_service', evt.target)

        alertify.success(I18n.t('messages.notify.general.success'))
      },
      error: (err) => {
        alertify.error(I18n.t('messages.notify.general.error'))
      }
    })

    if ($(this.serviceBannerPreviewTarget).attr('src') == '/images/event_services/placeholder.png') {
      Rails.ajax({
        url: Routes.delete_banner_event_exhibitors_event_service_path(this.eventServiceIdValue, { event_slug: this.eventSlugValue, banner: true }),
        type: 'DELETE',
        success: (resp) => {
        },
        error: (err) => {
        }
      })
    }

    if ($(this.logoPreviewTarget).attr('src') == '/images/placeholder.svg') {
      Rails.ajax({
        url: Routes.delete_banner_event_exhibitors_event_service_path(this.eventServiceIdValue, { event_slug: this.eventSlugValue, logo: true }),
        type: 'DELETE',
        success: (resp) => {
        },
        error: (err) => {
        }
      })
    }
  }

  validateServiceName(evt) {
    if (evt.target.value) {
      evt.target.classList.remove('error')
      this.submitBtnTarget.classList.remove('disabled')
    }
    else {
      evt.target.classList.add('error')
      this.submitBtnTarget.classList.add('disabled')
    }
  }

  switchToEdit(evt) {
    Rails.ajax({
      url: Routes.service_detail_event_exhibition_hall_path(this.serviceIdValue, { event_slug: this.eventSlugValue, mode: 'edit' }),
      type: 'GET',
      success: (resp) => {
        $(this.element).replaceWith(resp.data.html)
      }
    })
  }

  switchToView(evt) {
    if(this.cancelBtnTarget.dataset.confirm_to_close) {
      confirmModal({
        title: '',
        message: this.cancelBtnTarget.dataset.confirm_to_close,
        labels: {
          ok_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.confirm'),
          cancel_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.cancel')
        },
        onOk: () => {
          Rails.ajax({
            url: Routes.service_detail_event_exhibition_hall_path(this.serviceIdValue, { event_slug: this.eventSlugValue, mode: 'view' }),
            type: 'GET',
            success: (resp) => {
              $(this.element).replaceWith(resp.data.html)
            }
          });
        }
      })
    } else {
      Rails.ajax({
        url: Routes.service_detail_event_exhibition_hall_path(this.serviceIdValue, { event_slug: this.eventSlugValue, mode: 'view' }),
        type: 'GET',
        success: (resp) => {
          $(this.element).replaceWith(resp.data.html)
        }
      })
    }
  }

  _removeAllDocument(documentData) {
    let { id, key } = documentData
    let allDocIndex = this.allDocuments.findIndex(doc => doc.id === documentData.id || doc.key == documentData.key)

    if(allDocIndex != -1) {
      this.allDocuments[allDocIndex].isAssigned = false
    } else {
      this.allDocuments.push({ id, key, isAssigned: false })
    }
  }

  _addAllDocument(documentData) {
    let { id, key, isAssigned } = documentData
    let allDocIndex = this.allDocuments.findIndex(doc => doc.id == documentData.id || doc.key == documentData.key)

    if(allDocIndex != -1) {
      this.allDocuments[allDocIndex].isAssigned = true
    } else {
      this.allDocuments.push({ id, key, isAssigned })
    }
  }

  _removeAssignedDocumentParams(documentData) {
    let { id, key } = documentData

    let docIndex = this.assignedDocumentsParams.findIndex(doc => (
      id && doc.id == id ||
      doc.key == key
    ))

    if (docIndex != -1) {
      this.assignedDocumentsParams[docIndex].deleteMark = true
      this.assignedDocumentsParams[docIndex].isPrimary = false
      this.assignedDocumentsParams[docIndex].isAssigned = false
    }
    else {
      this.assignedDocumentsParams.push({ id, key, deleteMark: true, isPrimary: false, isAssigned: false })
    }
  }

  _addAssignedDocumentParams(documentData) {
    let { id, key, isPrimary } = documentData

    let docIndex = this.assignedDocumentsParams.findIndex(doc => (
      id && doc.id == id || doc.key == key
    ))

    if (docIndex != -1) {
      this.assignedDocumentsParams[docIndex].deleteMark = false
      // Move item to last of array
      this.assignedDocumentsParams.push(this.assignedDocumentsParams.splice(docIndex, 1)[0])
    }
    else {
      this.assignedDocumentsParams.push({ id, key, isPrimary })
    }
  }

  _markPrimaryDocumentParams(documentData) {
    let { id, key } = documentData

    // Set other documents as non-primary
    this.assignedDocumentsParams.filter(doc => doc.key != key).forEach(doc => doc.isPrimary = false)

    let docIndex = this.assignedDocumentsParams.findIndex(doc => doc.key == documentData.key)

    // Set assigned icon
    if (docIndex != -1) {
      this.assignedDocumentsParams[docIndex].isPrimary = true
    }
    else {
      this.assignedDocumentsParams.push({ id, key, isPrimary: true })
    }
  }

  _setItemDocumentDropdownSelection(documentData, selected) {
    let itemDropdown = this._findDocumentItemDropdown(documentData)
    let _this = this
    let maxReached = $('.document-item-wrap:not(.d-none)').length >= this.exhibitorMaxDocsValue

    if(!itemDropdown) return

    itemDropdown.querySelector('.check-icon').classList.remove('d-none')
    itemDropdown.classList.remove('selected')

    if(maxReached) {
      _this.allDocuments.forEach((document) => {
        if(!document.isAssigned) {
          _this._findDocumentItemDropdown(document).classList.add('selected')
        }
      })
    }
  }

  _removeItemDocumentDropdownSelection(documentData) {
    let itemDropdown = this._findDocumentItemDropdown(documentData)
    let maxReached = $('.document-item-wrap:not(.d-none)').length >= this.exhibitorMaxDocsValue
    let _this = this

    if (itemDropdown) {
      itemDropdown.querySelector('.check-icon').classList.add('d-none')
      if(maxReached) {
        this.allDocuments.forEach((document) => _this._findDocumentItemDropdown(document).classList.remove('selected') )
      }
    }
  }

  removeDocument(evt) {
    confirmModal({
      title: '資料の非表示',
      message: 'このイベントで資料が表示されなくなります。<span class="text-danger">表示されていた資料に関するリード情報が削除されます。</span>',
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')

        let key = evt.target.dataset.key

        let item = this._findDocumentItem(key)
        this.setDocumentData(item, { deleteMark: true })

        // Check if this is the first document
        let isFirst = false
        let firstWrap = $('.document-item-wrap:not(.d-none)')[0]
        if (firstWrap) {
          isFirst = JSON.parse($(firstWrap).find('.document-item')[0].dataset.document).key == key
        }

        // Check current docs count
        let docsCount = $('.document-item-wrap:not(.d-none)').length
        if (docsCount <= 1) {
          this.emptyDocumentListTarget.classList.remove('d-none')
          this.documentListTarget.classList.add('d-none')
        }

        let documentData = this.getDocumentData(item)
        this._removeAllDocument(documentData)
        this._removeAssignedDocumentParams(documentData)
        this._removeItemDocumentDropdownSelection(documentData)
        // Check and mark second document as primary
        if (isFirst) {
          let secondWrap = $('.document-item-wrap:not(.d-none)')[1]
          if (secondWrap) {
            let secondData = JSON.parse($(secondWrap).find('.document-item')[0].dataset.document)

            $(secondWrap).find('.document-label').removeClass('cursor-pointer')
            this._markPrimaryDocumentParams(secondData)
          }
        }

        $(item).parent().addClass('d-none')

        setTimeout(() => {
          this._setupDocumentsLabels()
        }, 150)
      }
    })
  }

  selectLogo(evt) {
    this.logoInputTarget.click()
  }

  changeLogo(evt) {
    let message = UploadAndPreviewImage(evt, this.logoPreviewTarget, 5)
    this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')
    if (message) {
      alertify.error(message)
    }
  }

  selectBanner() {
    this.serviceBannerInputTarget.click()
  }

  changeServiceBanner(evt) {
    let message = UploadAndPreviewImage(evt, this.serviceBannerPreviewTarget, 10)
    this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')
    if (message) {
      alertify.error(message)
    }
  }

  updateDownloadBtnStatus() {
    let checkCount = this.checkboxInputTargets.filter((input) => input.checked).length

    let text = I18n.t('shared.services.detail_modal.buttons.download_documents', { count: checkCount })
    this.downloadBtnTarget.innerText = text

    this.downloadBtnTarget.disabled = (checkCount == 0)
    if(!this.downloadBtnTarget.disabled) {
      $(this.tooltipWrapperTarget).tooltip('dispose')
    } else {
      $(this.tooltipWrapperTarget).tooltip()
    }
  }

  deleteImage(el) {
    if (this.serviceBannerPreviewTarget) {
      $(this.serviceBannerPreviewTarget).attr('src', '/images/event_services/placeholder.png')
      this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')
    }
  }

  deleteImageLogo(el) {
    if (this.logoPreviewTarget) {
      $(this.logoPreviewTarget).attr('src', '/images/placeholder.svg')
      this.cancelBtnTarget.dataset.confirm_to_close = I18n.t('organizer.event_service.confirm_modals.cancel_btn')
    }
  }

  get selectedDocumentIds() {
    let ids = this.checkboxInputTargets.map((checkbox) => {
      if (checkbox.checked) {
        return checkbox.dataset.documentId
      }
    })
    return _.compact(ids)
  }

  get downloadDocumentsCtrlr() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'download-documents';
    });
  }

  get downloadSurveyModalCtrlr() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'download-survey-modal';
    });
  }
}
