// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';
import { confirmModal } from '../../lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static targets = [ 'form', 'submitBtn', 'required' ]

  static rules = {
    subject: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.email_notification.attributes.subject.blank')}`
      }
    }
  }
  connect() {
    super.connect()
  }

  validateAll(event) {
    // Skip validate all
  }


  displayError(el, errorMessages) {
    el.classList.add('error');
    this.errorMessageEl(el).innerHTML = errorMessages[0];
  }

  errorMessageEl(el) {
    return el.parentElement.nextElementSibling;
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    // Notify success message
    document.location.href = response.data.redirect_path
  }

  startDelete(event) {
    event.stopPropagation()

    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.email_notifications.confirm_modals.delete.title'),
      message: I18n.t('organizer.email_notifications.confirm_modals.delete.content'),
      labels: {
        ok_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
          }
        });
      }
    })
  }

  startSend(event) {
    let _this = this
    confirmModal({
      title: I18n.t('organizer.email_notifications.confirm_modals.send.title'),
      message: I18n.t('organizer.email_notifications.confirm_modals.send.content'),
      labels: {
        ok_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.cancel')
      },
      onOk: () => {
        $('#submit-btn').trigger('click');
      }
    })
  }
}
