import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['listSeeMore', 'containerSeeMore', 'btnSeeMore']

  seeMore(event) {
    let _this = this

    let nextPageUrl = _this.nextPageUrl
    _this.containerSeeMore.html(_this.templateLoadingSeeMore)

    $.ajax({
      url: nextPageUrl,
      method: 'GET',
      dataType: 'JSON',
      success: function(response) {
        let htmlListSeeMore = response.html_list_see_more
        let nextPageUrl = response.next_page_url

        _this.listSeeMore.append(htmlListSeeMore)

        if (nextPageUrl) {
          _this.containerSeeMore.html(_this.templateBtnSeeMore)
          _this.btnSeeMore.data('next_page_url', nextPageUrl)
        } else {
          _this.containerSeeMore.remove()
        }
      }
    })
  }

  get listSeeMore() {
    return $(this.listSeeMoreTarget)
  }

  get containerSeeMore() {
    return $(this.containerSeeMoreTarget)
  }

  get btnSeeMore() {
    return $(this.btnSeeMoreTarget)
  }

  get nextPageUrl() {
    return $(this.btnSeeMore).data('next_page_url')
  }

  get templateBtnSeeMore() {
    return $(this.element).data('template_btn_see_more')
  }

  get templateLoadingSeeMore() {
    return $(this.element).data('template_loading_see_more')
  }
}
