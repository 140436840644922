import { Controller } from 'stimulus';
import I18n from '../../../../lib/i18n-js/init.js.erb';
import { el } from 'redom';

export default class extends Controller {
  static values = { formType: String }
  static targets = ['root', 'dismissBtn', 'itemWrap', 'radio', 'choicesWrap', 'submitBtn']

  connect() {
    super.connect()
  }

  addItem({ documentId, documentTitle }) {
    $(this.choicesWrapTarget).append(this.buildItemDom({ documentId, documentTitle }))
    this.setupDismissBtnTooltip(documentId)
    this.refresh()
  }

  removeItem(documentId) {
    this.disposeDismissBtnTooltip(documentId)
    this.itemWrapTargets.find((input) => input.dataset.documentId === documentId).remove()
    this.refresh()
  }

  refresh() {
    this.rootTarget.classList.remove('d-none')
    let $radios = $(this.radioTargets)

    if ($radios.length == 0) {
      // Hide if no item
      this.rootTarget.classList.add('d-none')
    }
    else if ($radios.length == 1) {
      // Auto-check if there's only 1 item and it's not checked
      // Somehow, the click data-action of newly appended radio button does not get called,
      // use setTimeout as an workaround
      $radios[0].checked == false && setTimeout(() => $radios[0].click(), 75)
    }
    else {
      // Auto-check the first item if there's no checked item
      !$radios.filter(':checked').length && $radios[0].click()
    }
  }

  submitNestedDocForm(evt) {
    this.nestedDocsAttributesCtrlr.submitForm()
  }

  reset() {
    this.disposeAllDismissBtnTooltips()
    $(this.itemWrapTargets).remove()
    this.rootTarget.classList.add('d-none')
  }

  setupDismissBtnTooltip(documentId) {
    let tooltipInput = this.dismissBtnTargets.find((input) => input.dataset.documentId === documentId)
    $(tooltipInput).tooltip({ boundary: 'window' })
  }

  disposeDismissBtnTooltip(documentId) {
    let tooltipInput = this.dismissBtnTargets.find((input) => input.dataset.documentId === documentId)
    $(tooltipInput).tooltip('dispose')
  }

  changePrimaryDoc(evt) {
    let documentId = evt.target.value
    this.nestedDocsAttributesCtrlr.unPrimaryAll()

    // Get the hidden is_primary input for documentId, and set its value to true
    this.nestedDocsAttributesCtrlr.updateIsPrimary(documentId, true)

    if (this.formTypeValue == 'edit') {
      this.submitNestedDocForm()
    }
  }

  buildItemDom({ documentId, documentTitle }) {
    let dismissBtnAttrs = {
      'data-action': `click->${this.identifier}#dismissItem`,
      'data-document-id': documentId,
      'data-document-title': documentTitle,
      'data-toggle': 'tooltip',
      title: I18n.t('organizer.exhibitors.invitation_form.primary_document_select.dismiss_btn_tooltip')
    }
    dismissBtnAttrs[`data-${this.identifier}-target`] = 'dismissBtn'

    let itemWrapAttrs = {
      'data-document-id': documentId
    }
    itemWrapAttrs[`data-${this.identifier}-target`] = 'itemWrap'

    let radioAttrs = {
      name: 'primary_document_id',
      value: documentId,
      type: 'radio',
      'data-action': `click->${this.identifier}#changePrimaryDoc`
    }
    radioAttrs[`data-${this.identifier}-target`] = 'radio'

    return el('.primary-doc-item', itemWrapAttrs, [
      el('.content-block', [
        el('.radio-wrap', el(`input#primary_document_id_${documentId}`, radioAttrs)),
        el('label.ml-16.mb-0.choice-label', documentTitle, { for: `primary_document_id_${documentId}` })
      ]),
      el('button.material-icons.btn-icon.btn-icon-normal.btn-icon-md', 'close', dismissBtnAttrs)
    ])
  }

  dismissItem(evt) {
    let documentId = evt.target.dataset.documentId
    let documentTitle = evt.target.dataset.documentTitle

    // Find the hidden input set for documentId, then remove
    this.nestedDocsAttributesCtrlr.removeItem(documentId)

    // Put this document back into documents select
    this.docsSelectCtrlr.addItem({ documentId, documentTitle })

    // Remove this document from primary select
    this.removeItem(documentId)

    this.docsSelectCtrlr.updateStatus()
    this.submitNestedDocForm()
  }

  disposeAllDismissBtnTooltips() {
    $(this.dismissBtnTargets).tooltip('dispose')
  }

  disableSubmitBtn() {
    this.submitBtnTarget.disabled = true
  }

  enableSubmitBtn() {
    this.submitBtnTarget.disabled = false
  }

  disableSelections() {
    this.radioTargets.forEach((radio) => radio.disabled = true)
  }

  enableSelections() {
    this.radioTargets.forEach((radio) => radio.disabled = false)
  }

  get nestedDocsAttributesCtrlr() {
    let target_ctrlr = 'organizer--exhibitors--invitation-form--nested-docs-attributes'
    return this.application.controllers.find((ctrlr) => {
      return ctrlr.identifier == target_ctrlr && ctrlr.formTypeValue == this.formTypeValue
    })
  }

  get docsSelectCtrlr() {
    let target_ctrlr = 'organizer--exhibitors--invitation-form--documents-select'
    return this.application.controllers.find((ctrlr) => {
      return ctrlr.identifier == target_ctrlr && ctrlr.formTypeValue == this.formTypeValue
    })
  }
}
