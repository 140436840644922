import _ from 'lodash';
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  navigate(e) {
    this.stimulate('Organizers::Timetables::TimetableReflex#perform', e.target)
  }
}

