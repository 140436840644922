import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static values = { contentId: String, reactionType: String }
  static targets = ['reactionWrap']

  connect() {
    super.connect()

    // Hiding the applause function on the stage screen
    // !!this.contentIdValue && this.renderReactionComponent()
  }

  renderReactionComponent() {
    Rails.ajax({
      url: Routes.reaction_component_content_reactions_path({ content_id: this.contentIdValue, reaction_type: this.reactionTypeValue }),
      type: 'GET',
      success: (resp) => {
        $(this.reactionWrapTarget).html(resp.body.innerHTML)
      }
    })
  }
}
