import _ from 'lodash';
import I18n from 'i18n-js';
import ApplicationController from '@controllers/application_controller';
import { autoOpenServiceModal } from '@lib/helpers/auto_open_service_modal';
import { autoOpenContentModal } from '@lib/helpers/auto_open_content_modal';

export default class extends ApplicationController {
  static targets = ['reply', 'approach', 'percent', 'block', 'layer', 'thread', 'title', 'sideBar', 'miniForm']

  connect() {
    super.connect();

    $(window).on('scroll', function(e){
      let scroll = $(window).scrollTop();
      if(scroll >= 245){
        $('.left-sidebar').addClass('left-sidebar__fixed')
      } else {
        $('.left-sidebar').removeClass('left-sidebar__fixed')
      }
    })

    $('[data-toggle="tooltip"]').tooltip();

    this.scroll()
    this.calcPercentage()
    this.initializeTooltip()

    autoOpenServiceModal()
    autoOpenContentModal('')
  }

  openChatScreen() {
    $('.nav-item-chat').trigger("click")
  }

  initializeTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  scroll() {
    $(document).on('click', '.js-scroll-to', function (event) {
      event.preventDefault()
      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 70
      }, 100)
    })
  }

  calcPercentage() {
    let replyCount = this.replyTarget.dataset.value;
    let approachCount = this.approachTarget.dataset.value;

    if (_.toInteger(approachCount) && _.toInteger(replyCount)) {
      $(this.layerTarget).show()
      $(this.threadTarget).show()

      let percentage = (replyCount*100)/approachCount

      $(this.layerTarget).css('width', `${percentage}%`)

      this.titleTarget.dataset.title = I18n.t("shared.event_halls.tooltip.percent", { percent: percentage })
    } else {
      $(this.layerTarget).hide()
      $(this.threadTarget).hide()
    }

    $('.thread-data__thread-bg').on("mouseenter", function() { $('.thread-data__thread-bg__thread-layer__tooltip-holder').tooltip('show') })
    $('.thread-data__thread-bg').on("mouseleave", function() { $('.thread-data__thread-bg__thread-layer__tooltip-holder').tooltip('hide') })
  }

  hoverButton(e) {
    let width = $('.event-home-index').width() - 465
    let height = $('.event-home-index').height()
    let pos = $(e.currentTarget.children[0]).offset().left
    let posBottom = $(e.currentTarget.children[0]).offset().top + 260
    if(pos < width) {
      let h = $(e.currentTarget.children[0]).height() + 24
      $(e.currentTarget.children[0]).css('transform', `translate(23px,-${h/2}px)`)
      $(e.currentTarget.children[0]).css('top', `0px`)
    } else if(pos > width && posBottom > height) {
      $(e.currentTarget.children[0]).css('transform', `translate(-228px, -211px)`)
      $(e.currentTarget.children[0]).css('top', `0px`)
    } else {
      $(e.currentTarget.children[0]).css('transform', `translate(-228px, 28px)`)
      $(e.currentTarget.children[0]).css('top', `0px`)
    }
  }

  hoveroutButton(e) {
    $(e.currentTarget.children[0]).css('transform', `translate(-228px, -88px)`)
    $(e.currentTarget.children[0]).css('top', `0px`)
  }
}
