import _ from 'lodash';
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import I18n from '@video_lib/i18n-js/init.js.erb';
import { restrictedDomains } from "@lib/helpers/validation_helper"

export default class extends SimpleAjaxForm {

  static targets = ['form', 'required', 'submitBtn', 'email']
  static validators = { validEmail: { attributes: ['email'] } }

  static rules = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.email.blank')}`
      },
      email: {
        message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
      }
    }
  }

  connect() {
    super.connect();
  }

  validEmail({attr, value}) {
    let domain = value.split('@')[1]
    let isInvalid = restrictedDomains.includes(domain)

    if(isInvalid) {
      this.errors.add(attr, I18n.t('activerecord.errors.models.user.attributes.email.restrict_domain'))
    }
  }

  validForm() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.validForm()

    if (isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }
  }

  successSubmit(e) {
    window.location.href = Routes.admin_account_path({success: I18n.t('messages.notify.user.change_email.success')});
  }
}
