import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import { confirmModal } from '../../lib/helpers/js_modals';

export default class extends Controller {
  static targets = ['form', 'statusTxt', 'statusBtn', 'statusFld', 'eventslugFld']

  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error'),
      "publish_success": I18n.t('messages.notify.event.publish.success'),
      "unpublish_success": I18n.t('messages.notify.event.unpublish.success'),
    }

    return messages;
  }

  changeStatusTo(updatedStatus) {
    if (updatedStatus == 'published') {
      // Change value of status
      this.statusFldTarget.value = 'unpublished'

      // Change Text of label
      this.statusTxtTarget.innerText = I18n.t('shared.common.events.header.publish')
      this.statusTxtTarget.classList.add('organizer-event--publish')
      this.statusTxtTarget.classList.remove('organizer-event--unpublish')

      // Change Text of Button
      this.statusBtnTarget.innerText = I18n.t('shared.common.events.header.to_unpublish')
      this.statusBtnTarget.classList.add('btn-outline-default')
      this.statusBtnTarget.classList.remove('btn-outline-primary')

      this.eventFormController.updateSlugDisability('disabled')
    } else {
      // Change value of status
      this.statusFldTarget.value = 'published'

      // Change Text of Label
      this.statusTxtTarget.innerText = I18n.t('shared.common.events.header.unpublish')
      this.statusTxtTarget.classList.add('organizer-event--unpublish')
      this.statusTxtTarget.classList.remove('organizer-event--publish')

      // Change Text of Button
      this.statusBtnTarget.innerText = I18n.t('shared.common.events.header.to_publish')
      this.statusBtnTarget.classList.add('btn-outline-primary')
      this.statusBtnTarget.classList.remove('btn-outline-default')

      this.eventFormController.updateSlugDisability('enabled')
    }
  }

  // Handle submit
  startSubmit(event) {
    let newStatus = this.statusFldTarget.value
    // Switch status from 'published' to 'unpublished', show confirm, if yes then submit
    if (newStatus == 'unpublished') {
      confirmModal({
        title: I18n.t('organizer.modals.confirm_modals.publish_event.title'),
        message: I18n.t('organizer.modals.confirm_modals.publish_event.content'),
        labels: {
          ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
          cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
        },
        onOk: () => {
          Rails.fire(this.formTarget, 'submit')
        }
      })
    } else { // Switch to 'published', submit immediately
      Rails.fire(this.formTarget, 'submit')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    this.isSubmitting = false;

    let [response, _status, _xhr] = e.detail;

    if (this.statusFldTarget.value == 'published') {
      alertify.success(this.messages.publish_success)
      this.changeStatusTo('published')
    } else {
      alertify.success(this.messages.unpublish_success)
      this.changeStatusTo('unpublished')
    }
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    alertify.error(this.messages.error)
  }

  get eventFormController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === 'organizer--event-form'
    })

  }
}
