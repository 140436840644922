// Import custom libraries
import { VideoPlayer } from '@video_lib/helpers/video_player'

// Import libraries from webpacker libraries
import _ from 'lodash'
import Rails from "@rails/ujs"

export default class extends VideoPlayer {
  static targets = [
    'restrictAt', 'duration', 'targetPublic',
    'section', 'overlay', 'videoId'
  ]

  connect() {
    super.connect()

    if(!this.hasPlayerTarget) return

    this.setRestrictAt()
    this.initRestrict()
    this.initTextTrack()
  }

  initRestrict() {
    if(this.restrictAt != undefined) {
      this.player.duration = () => {
        return this.duration
      }
    }
  }

  initTextTrack() {
    let _this = this
    let video = _this.player.el_.firstChild
    let track = video.addTextTrack('chapters')

    _.each(_this.sectionTargets, (section, index) => {
      let $section = $(section)
      let start = parseInt($section.find('input.js-start-at').val())
      let end = _this.duration
      let title = $section.find('input.js-title').val()
      let nextSection = _this.sectionTargets[index + 1]

      if(nextSection != undefined) {
        end = parseInt($(nextSection).find('input.js-start-at').val())
      }
      let cue = new VTTCue(start, end, title)

      cue.onenter = () => {
        $section.addClass('active')
        if(_this.targetPublic == 'private' && _this.restrictAt <= start) {
          _this.overlayTarget.classList.remove('d-none')
          _this.player.pause()
          if(_this.player.isFullscreen()) {
            _this.player.exitFullscreen()
          }
        } else {
          _this.overlayTarget.classList.add('d-none')
        }
      }
      cue.onexit = () => {
        $section.removeClass('active')
      }
      if(_this.targetPublic == 'private' && _this.restrictAt <= end) cue.pauseOnExit = true

      track.addCue(cue)
    })
  }

  seekTo(event) {
    if(this.hasPlayerTarget) {
      let start_at = parseInt($(event.currentTarget).find('input.js-start-at').val())
      this.player.currentTime(start_at + 0.001)
      this.player.play()
    }
  }

  agreeTerm(e) {
    let videoId = e.currentTarget.dataset.videoId
    let url = Routes.agree_term_video_path({ id: videoId })

    Rails.ajax({
      url:  url,
      type: 'POST',
      data: {},
      success: () => {
        location.reload()
      },
      error: () => {
      }
    })

    e.preventDefault()
  }

  setRestrictAt() {
    let restrictAt = this.restrictAtTarget.dataset.restrict_at
    if( restrictAt == '') return

    this.restrictAt = parseInt(restrictAt)
  }

  get duration() {
    return parseInt(this.durationTarget.dataset.duration)
  }

  get targetPublic() {
    return this.targetPublicTarget.dataset.target_public
  }
}
