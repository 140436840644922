import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    super.connect()

    $('.owl-carousel').owlCarousel({
      loop: false,
      dots: false,
      nav: true,
      autoWidth: true,
      items: 3,
      navText: ['<span class="material-icons icons">navigate_before</span>', '<span class="material-icons icons">navigate_next</span>']
    })
  }

  disconnect() {
    $('.owl-carousel').trigger('destroy.owl.carousel')

    super.disconnect()
  }
}
