import ApplicationController from '@controllers/application_controller';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends ApplicationController {
  removeChat(e) {
    let messageId = e.target.dataset.message_id;
    let eventStageId = e.target.dataset.event_stage_id
    let executorRole = e.target.dataset.executor_role
    let confirmMessage = e.target.dataset.confirm_message

    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_chat_item.title'),
      message: confirmMessage,
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.stimulate(
          'EventHalls::ChatReflex#remove_message',
          messageId,
          eventStageId,
          executorRole
        )
          .catch(payload => {
            window.location.reload()
          })
      }
    })
  }
}
