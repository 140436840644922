// Import libraries from webpacker libraries
import Rails from '@rails/ujs';

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'
import { Constant }                       from '@video_lib/constant.js.erb'
import { confirmModal }                   from '@video_lib/helpers/js_modals'

export default class extends SimpleAjaxForm {
  static targets = [
    // modal edit
    'modal', 'formEdit', 'userName', 'companyName', 'email', 'invitationDatetime', 'registeredDatetime',
    'btnUpdate', 'btnOpenModalConfirmDeleteMember'
  ]

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'name', 'blank') }`
      },
      length: {
        maximum: Constant.settings.user.name.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'name', 'too_long', { count: Constant.settings.user.name.length.maximum }) }`
      }
    },
    company_name: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'company_name', 'blank') }`
      },
      length: {
        maximum: Constant.settings.user.company_name.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'company_name', 'too_long', { count: Constant.settings.user.company_name.length.maximum }) }`
      }
    }
  }

  initModal(e) {
    let userInfo = $(e.target).parent('.js-user-info')
    let userId = userInfo.data('id')
    let url = `/videostaff/users/${userId}`
    $(this.userNameTarget).val(userInfo.find('.list__name').text())
    $(this.companyNameTarget).val(userInfo.find('.list__company').text())
    $(this.emailTarget).text(userInfo.find('.list__email').text())
    $(this.invitationDatetimeTarget).text(userInfo.find('.list__upload-date').text())
    $(this.registeredDatetimeTarget).text(userInfo.find('.list__register-date').text())
    $(this.formEditTarget).attr('action', url)
    $(this.btnOpenModalConfirmDeleteMemberTarget).attr('data-ajax_url', url)
    $(this.modalTarget).modal('show')
    this.attributes.forEach(({ el }) => {
      el.classList.remove('error');
      this.errorMessageEl(el).innerHTML = '';
    })
    this.btnUpdateTarget.removeAttribute('disabled')
  }

  successSubmit(event) {
    let [response, _status, _xhr] = event.detail
    window.location.href = response.data.redirect_path
  }

  allowSubmit(isAllow) {
    let _this = this

    if(isAllow && !_this.errors.hasAny()) {
      this.btnUpdateTarget.removeAttribute('disabled')
    } else {
      this.btnUpdateTarget.setAttribute('disabled', '')
    }
  }

  openModalConfirmDeleteMember(event) {
    let i18nPath = 'javascripts.owner.users.modal_confirm_delete_member'
    let url = event.target.dataset.ajax_url

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        Rails.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
          },
          success: function(response) {
            window.location.href = response.data.redirect_path
          }
        })
      }
    })
  }
}
