// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../lib/helpers/simple_ajax_form';

export default class extends SimpleAjaxForm {
  static targets = [ 'form', 'submitBtn', 'required', 'selectTag', 'tagList', 'loadAllGenresBtn', 'term']

  connect() {
    super.connect()
    this.selectedTags.forEach(el => $(el).prop('checked', true))
    this.disableOptions()
    this.allowSubmit(this.inputAllRequireFields());
  }

  select(el) {
    this.disableOptions()
  }

  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  disableOptions() {
    this.uncheckTags.forEach(el => $(el).prop('disabled', this.reachLimitTag))
  }

  afterValidate({ el, attr }) {
    this.allowSubmit(this.inputAllRequireFields());
  }

  // Element to show error for an input element
  errorMessageEl(el) {
    return el.parentNode.querySelector('.error-message');
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.inputAllRequireFields();
    let checkbox = $(this.termTarget).is(":checked")

    if(isAllow && allRequiredInput && checkbox) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  isChecked(){
    this.allowSubmit(this.inputAllRequireFields)
  }

  setJobCategory(evt) {
    this.loadAllGenresBtnTarget.dataset.jobCategoryId = evt.target.value
  }

  get uncheckTags() {
    return this.selectTagTargets.filter(checkbox => !checkbox.checked)
  }

  get selectedOptions() {
    return this.tagListTarget.dataset.selected
  }

  get reachLimitTag() {
    return this.tagListTarget.dataset.limit == this.allSelected
  }

  get selectedTags() {
    return this.selectTagTargets.filter(checkbox => !_.isEmpty(checkbox.dataset.checked))
  }

  get allSelected() {
    return this.selectTagTargets.filter(checkbox => checkbox.checked).length
  }
}
