import ApplicationController from '@controllers/application_controller';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends ApplicationController {
  static targets = ['exportCsv']

  // Function: Preview Survey
  previewSurvey(e) {
    // Get attributes
    let overallAttrs = $(this.overallFormController.formTarget).serialize()
    let questionAttrs = $(this.questionFormController.formTarget).serialize()

    // Combine all data of form
    let combinedPreviewData = `${overallAttrs}&${questionAttrs}`

    this.stimulate('ContentSurveys::ContentReflex#preview', e.target, combinedPreviewData)
    this.drawerController.showPreview(e)

    e.preventDefault()
  }

  // Function: Copy link to clipboard
  copySurveyUrlToClipboard(e) {
    let url = e.target.dataset.url
    var aux = document.createElement("input");

    // Get the text from the element passed into the input
    aux.setAttribute("value", url);
    // Append the aux input to the body
    document.body.appendChild(aux);
    // Highlight the content
    aux.select();
    // Execute the copy command
    document.execCommand("copy");
    // Remove the input from the body
    document.body.removeChild(aux);

    alertify.success(I18n.t('messages.notify.general.success'))

    e.preventDefault()
  }

  // Function: Download survey
  downloadSurveyCsv(e) {
    this.stimulate('ContentSurveys::ContentReflex#export_csv', e.target)

    let loader = e.target.querySelector('.loader')
    let icon = e.target.querySelector('.material-icons')

    loader.classList.remove('d-none')
    icon.classList.add('d-none')

    e.stopPropagation()
  }

  readyDownload(e) {
    if (e.detail.clientId != this.exportCsvTarget.dataset.client_id) return

    let loader = this.exportCsvTarget.querySelector('.loader')
    let icon = this.exportCsvTarget.querySelector('.material-icons')

    loader.classList.add('d-none')
    icon.classList.remove('d-none')

    if (e.detail.fileUrl == '') return

    let link = document.createElement('a')
    link.href = e.detail.fileUrl

    link.click()
  }

  // Function: Copy survey
  duplicateSurvey(e) {
    this.stimulate('ContentSurveys::ContentReflex#copy', e.target)
  }

  copySuccess(e) {
    this.reload(e, true)
  }

  // Function: Unpublish survey
  unPublishSurvey(e) {
    confirmModal({
      message: '選択したアンケートを公開終了にします',
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.stimulate('ContentSurveys::ContentReflex#unpublish', e.target)
      }
    })
  }

  unpublishSuccess(e) {
    this.reload(e)
  }

  // Function: Publish survey
  publishSurvey(e) {
    this.stimulate('ContentSurveys::ContentReflex#publish', e.target)
  }

  publishSuccess(e) {
    this.reload(e)
  }

  // Function: Delete survey
  deleteSurvey(e) {
    let surveyTitle = e.target.dataset.surveyTitle
    let confirmMessage = "削除しますがよろしいですか？"

    if (surveyTitle) {
      confirmMessage = `${surveyTitle}を削除しますがよろしいですか？`
    }

    confirmModal({
      message: confirmMessage,
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.stimulate('ContentSurveys::ContentReflex#delete', e.target)
      }
    })

    e.preventDefault()
  }

  deleteSuccess(e) {
    this.reload(e, true)
  }

  // Reload survey list and survey drawer
  reload(e, closeDrawer = false) {
    if (!closeDrawer) {
      let activeTabId = this.drawerController.activeTab.dataset.tab_id
      let surveyId = e.dataset.surveyId || e.dataset.survey_id

      this.stimulate('ContentSurveys::DrawerReflex#load_survey', e, surveyId, 'view_survey', activeTabId)
    } else {
      this.drawerController.animateHideScreen(e)
    }

    this.stimulate('ContentSurveys::SurveyListReflex#reload_survey_list', e)
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }

  get overallFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--overall-form')
  }

  get questionFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--question-form')
  }
}
