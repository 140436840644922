// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../lib/helpers/simple_ajax_form';
import I18n from '../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = [ 'formWatcher', 'formWrap', 'form', 'submitBtn', 'required', 'agreement', 'term', 'termArea', 'placeTooltip', 'invalidInputsCountWrap', 'tooltipHolder', 'invalidInputsCountSpWrap', 'emailField', 'passwordField', 'submitBtnWrap']
  static validators = { validEmail: { attributes: ['email'] }, validPassword: { attributes: ['password'] } }

  ////
  // VALIDATION RULES
  ////
  static rules = {
    first_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.first_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.first_name.too_long', { count: 100 })}`
      }
    },
    last_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.last_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.last_name.too_long', { count: 100 })}`
      }
    },
    company_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.company_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.company_name.too_long', { count: 100 })}`
      }
    },
    department_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.department_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.department_name.too_long', { count: 100 })}`
      }
    },
    position: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.position.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.position.too_long', { count: 100 })}`
      }
    },
    telephone: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.blank')}`
      },
      length: {
        maximum: 11,
        minimum: 10,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.too_long', { count: 11 })}`,
        tooShort: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.too_short', { count: 10 })}`
      },
      format: {
        pattern: "^[0-9]+$",
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.invalid')}`
      }
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.blank')}`
      },
      email: {
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.invalid')}`
      }
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.blank')}`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_short', { count: 8 })}`,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_long', { count: 32 })}`
      }
    }
  }


  connect() {
    super.connect()

    this.inputErrors = {} // Keep track of which input has error
    this.requiredTargets.forEach(elm => this.inputErrors[elm.id] = _.isEmpty(elm.value))

    // Reset checkbox state on reload
    if (this.hasAgreementTarget) {
      this.agreementTarget.checked = false
      this.inputErrors[this.agreementTarget.id] = true
    }

    this._setupSubmitBtn()
    this._updateInvalidInputCount()

    // Workaround to avoid global affect on styling html and body element
    $('html, body, .dashboard').addClass('in-registration-form')
  }

  submitForm(evt) {
    Rails.fire(this.formTarget, 'submit')
  }

  _setupSubmitBtn() {
    if(this.allRequiredInputValid) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  _updateInvalidInputCount() {
    let errorsCount = Object.keys(this.inputErrors).filter(key => !!this.inputErrors[key]).length

    if (errorsCount == 0) {
      this.invalidInputsCountWrapTargets.forEach((el) => {
        $(el).empty().append(I18n.t('users.registrations.form_watcher.all_inputs_filled'))
      })
      $(this.invalidInputsCountSpWrapTarget).empty().append(I18n.t('users.registrations.form_watcher.all_inputs_filled'))
    }
    else {
      let $text = $(`<div>${I18n.t('users.registrations.form_watcher.input_missing')}</div>`)
      $text.find('.js-invalid-inputs-count').text(errorsCount)
      this.invalidInputsCountWrapTargets.forEach((el) => {
        $(el).empty().append($text.html())
      })
      $(this.invalidInputsCountSpWrapTarget).empty().append($text.html())
    }
  }

  toggleAgreement(evt) {
    this.inputErrors[evt.target.id] = !evt.target.checked
    this._updateInvalidInputCount()

    this.allowSubmit(evt.target.checked)
  }

  validate(evt) {
    super.validate(evt)

    // Take care of autofill on chrome
    if (this.hasPasswordFieldTarget && evt.target == this.passwordFieldTarget) {
      // Ignore if email field is in initialized state
      if(!this.hasEmailFieldTarget) return

      this.validateManually(this.passwordFieldTarget)
      return
    }

    if (this.hasEmailFieldTarget && evt.target == this.emailFieldTarget) {
      // Ignore if password field is in initialized state
      if (!this.passwordFieldTarget.classList.contains('error') && !this.passwordFieldTarget.value) return

      this.validateManually(this.emailFieldTarget)
    }
  }

  afterValidate({ el, attr }) {
    super.afterValidate({ el, attr })
    this.inputErrors[el.id] = !_.isEmpty(this.errorMessage(attr))
    this._updateInvalidInputCount()
  }

  checkTelephoneInput(e) {
    if (e.key == '-') e.preventDefault()
  }

  selectProvince(evt) {
    this.inputErrors[evt.target.id] = !evt.target.value
    this._updateInvalidInputCount()
    this.allowSubmit(this.allRequiredInputValid);
  }

  // Element to show error for an input element
  errorMessageEl(el) {
    if (el.classList.contains('selectpicker')) {
      return el.parentNode.parentNode.querySelector('.error-message');
    }
    else {
      return el.parentNode.querySelector('.error-message');
    }
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    if(isAllow && this.allRequiredInputValid) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  disableSubmitButton() {
    this.submitBtnTargets.forEach((el) => el.classList.add('disabled'))
    this.submitBtnWrapTargets.forEach((el) => $(el).tooltip())
  }

  enableSubmitButton() {
    this.submitBtnTargets.forEach((el) => el.classList.remove('disabled'))
    this.submitBtnWrapTargets.forEach((el) => $(el).tooltip('dispose'))
  }

  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    document.location.href = response.data.redirect_path
  }

  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
    this._updateInvalidInputCount()
  }

  get allRequiredInputValid() {
    let valid = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value) || el.classList.contains('error')) {
        valid = false;
        return false
      }
    })

    if (this.hasAgreementTarget) {
      valid = valid && this.agreementTarget.checked
    }

    return valid
  }
}
