export default function pdfPreview(file, target, pageNumber, scale){
  const pdfjsLib = window['pdfjs-dist/build/pdf'];

  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

  const fileReader = new FileReader();

  fileReader.onload = function() {
    const pdfData = new Uint8Array(this.result);
    const loadingTask = pdfjsLib.getDocument({data: pdfData});

    loadingTask.promise.then(function(pdf) {
      pdf.getPage(pageNumber).then(function(page) {
        const viewport = page.getViewport({scale: scale});
        let canvas;

        if (target instanceof HTMLCanvasElement) {
          canvas = target;
        } else {
          canvas = document.createElement('canvas');
        }

        canvas.classList.add('has-preview')
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };

        const renderTask = page.render(renderContext);

        renderTask.promise.then(function () {
          if (target instanceof HTMLImageElement) {
            target.setAttribute('src', canvas.toDataURL("image/png"))
          }
        });
      });
    }, function (reason) {});
  };

  fileReader.readAsArrayBuffer(file);
}
