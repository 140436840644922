// Import libraries from webpacker libraries
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['root']

  connect() {
    super.connect()
  }
}
