import ApplicationController from '@controllers/application_controller';
import I18n from '@lib/i18n-js/init.js.erb';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends ApplicationController {
  static targets = [];

  connect() {
    super.connect();

    $(window).on('click', function (e) {
      if ($(e.target).has('.show').length === 0) {
        $('.table-td-aside').css('z-index', '0');
      }
    });
  }

  submit(e) {
    let url = e.currentTarget.dataset.form_url;
    let token = e.currentTarget.dataset.token;
    let status = e.currentTarget.dataset.status;

    confirmModal({
      message: I18n.t(
        'components.events.event_notifications.home_component.confirm.messages'
      ),
      labels: {
        ok_btn: I18n.t(
          'components.events.event_notifications.home_component.confirm.button.ok'
        ),
        cancel_btn: I18n.t(
          'components.events.event_notifications.home_component.confirm.button.cancel'
        ),
      },
      onOk: () => {
        Rails.ajax({
          url: url,
          data: jQuery.param({ token: token, status: status }),
          type: 'POST',
          error: function () {
            alertify.error(I18n.t('messages.notify.general.error'));
          },
        });
      },
    });
  }
  // イベント招待のキャンセル
  cancelInvitation(e) {
    confirmModal({
      message: I18n.t(
        'components.events.event_notifications.home_component.confirm.cancel'
      ),
      labels: {
        ok_btn: I18n.t(
          'components.events.event_notifications.home_component.confirm.button.ok'
        ),
        cancel_btn: I18n.t(
          'components.events.event_notifications.home_component.confirm.button.cancel'
        ),
      },
      onOk: () => {
        this.stimulate(
          'Organizers::RegisteredUsers::UserLineReflex#cancel_invitation',
          e.target
        ).then((e) => {
          alertify.success(I18n.t('messages.notify.general.success'));
          new TableScroll('.table-aside-container');
        });
      },
    });
  }
  // イベント参加登録削除
  removeEventRegistration(e) {
    const email = e.currentTarget.dataset.email;
    confirmModal({
      message: I18n.t(
        'components.events.event_notifications.home_component.confirm.remove',
        { email: email }
      ),
      labels: {
        ok_btn: I18n.t(
          'components.events.event_notifications.home_component.confirm.button.ok'
        ),
        cancel_btn: I18n.t(
          'components.events.event_notifications.home_component.confirm.button.cancel'
        ),
      },
      onOk: () => {
        this.stimulate(
          'Organizers::RegisteredUsers::UserLineReflex#remove_event_registration',
          e.target
        ).then((e) => {
          alertify.success(I18n.t('messages.notify.general.success'));
          new TableScroll('.table-aside-container');
        });
      },
    });
  }

  clickMoreMenu(e) {
    let element = $(e.currentTarget).closest('.table-td-aside');

    $(element).css('z-index', '1');

    $(element).on('hide.bs.dropdown', function () {
      $(element).css('z-index', '0');
    });
  }
}
