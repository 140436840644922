// Import Rails JS libraries
import I18n                                     from '@video_lib/i18n-js/init.js.erb'

// Import Constant
import { Constant }                             from '@video_lib/constant.js.erb'

// Import custom libraries
import { ConvertIntegerToFileSizeStringHelper } from '@video_lib/helpers/convert_integer_to_file_size_string_helper'
import { IsFileSizeLessThanHelper }             from '@video_lib/helpers/is_file_size_less_than_helper'

export const UploadAndPreviewImageHelper = (inputTarget, previewTarget, maxSize) => {
  let file = inputTarget.files[0]
  if (!file) {
    $(previewTarget).removeAttr('src')
    return
  }

  // validation file type
  let imageTypes = Constant.settings.common.image_types
  let type = file.type
  if (imageTypes.indexOf(type) == -1) {
    $(previewTarget).removeAttr('src')

    return I18n.t('errors.messages.content_type_invalid', { valid_content_types: Constant.custom_settings.common.image_types_text })
  }

  // optional validation file size
  if (maxSize) {
    let size = file.size
    if (!IsFileSizeLessThanHelper(file, maxSize)) {
      $(previewTarget).removeAttr('src')

      return I18n.t('errors.messages.file_size_out_of_range', { file_size: ConvertIntegerToFileSizeStringHelper(maxSize) })
    }
  }

  // display image
  let reader = new FileReader();
  reader.onload = function(e) {
    $(previewTarget).attr('src', e.target.result)
  }
  reader.readAsDataURL(file)
}
