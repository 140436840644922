import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static values  = { needSurvey: Boolean, id: String, downloadableType: String, downloadableId: String, eventSlug: String }
  static targets = [ 'downloadBtn', 'hiddenDownloadBtn', 'openModalLink' ]

  downloadDocuments(_e) {
    // モーダル表示
    if (this.needSurveyValue) {
      this.downloadSurveyModalCtrlr.showModal(
        this.idValue,
        this.downloadableTypeValue,
        this.downloadableIdValue,
        this.eventSlugValue,
        this.selectedDocumentIds
      )
    } else {
      // 資料ダウンロード実行
      // app/views/shared/event_contents/_service.html.slim
      // link_to '', download_document_path(document, event_service_id: event_service.id)
      //   => documents#download
      // app/controllers/documents_controller.rb => download
      this.hiddenDownloadBtnTarget.click()
    }
  }

  openServiceDetailModal(evt) {
    // Skip if triggered from download button
    let shouldSkip =
      (this.hasDownloadBtnTarget && evt.target == this.downloadBtnTarget) ||
      (this.hasHiddenDownloadBtnTarget && evt.target == this.hiddenDownloadBtnTarget)
    if (shouldSkip) return

    this.openModalLinkTarget.click()
  }

  get selectedDocumentIds() {
    return [this.hiddenDownloadBtnTarget.dataset.documentId]
  }

  get downloadSurveyModalCtrlr() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'download-survey-modal';
    });
  }
}
