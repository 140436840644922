// Import custom libraries
import ApplicationController     from '../application_controller'
import { eventContentClickable } from '@lib/helpers/event_content_modal_clickable'
import { selectFirstMessage }    from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static values = { actionOrigin: String, isRegisterForm: String }

  connect() {
    super.connect()

    eventContentClickable('.content-card-items', this.actionOriginValue, this.isRegisterFormValue)

    this._scrollToHighlightContent()
  }

  // 視聴予約
  bookmark(e) {
    let $bookmarker    = e.currentTarget
    let eventContentId = $bookmarker.dataset.event_content_id

    Rails.ajax({
      url: Routes.subscribe_event_event_content_path(eventContentId, { event_slug: e.currentTarget.dataset.event_slug }),
      type: 'PUT',
      data: {},
      error: (e) => {
        let errors = e.errors;

        // Show first error message
        let error_message = selectFirstMessage(errors)

        alertify.error(error_message)
      },
      success: (e) => {
        let { status } = e.data

        $($bookmarker).parents().find(`.text-${eventContentId}`).text(`${I18n.t('shared.timetables.index.buttons.subscribed')}`)
        $($bookmarker).parents().find(`.btn-${eventContentId}`).removeClass('btn-outline-accent')
        $($bookmarker).parents().find(`.btn-${eventContentId}`).addClass('btn-accent')
        $($bookmarker).parents().find(`.icon-${eventContentId}`).removeClass('i-Save')
        $($bookmarker).parents().find(`.icon-${eventContentId}`).addClass('i-CheckCircle')

        $($bookmarker).parents().find(`.card-btn-${eventContentId}`).addClass('content-active')
        $($bookmarker).parents().find(`.card-icon-${eventContentId}`).removeClass('d-none')
        $($bookmarker).parents().find(`.card-text-${eventContentId}`).removeClass('text-accent')
        $($bookmarker).parents().find(`.card-text-${eventContentId}`).text('予約中')

        // 視聴予約解除不可
        $($bookmarker).removeAttr('data-action');
        $($bookmarker).attr('style', 'cursor: default;')

        this.reloadStageFilter()
      }
    })
  }

  reloadStageFilter() {
    var i = window.location.href.lastIndexOf('?');
    var j = window.location.href.lastIndexOf('&');
    var k = window.location.href.indexOf('&');
    var stage_ids = window.location.href.substr(i+17).replace(/stage_ids%5B%5D=/g,'').split('&');

    $(".filtered").each(function() {
      if ( stage_ids == "" ) {
        $(this).prop('checked', true)
      } else if (j == -1 || window.location.href.substr(j+1).includes(['event_content_id']) && j == k) {
        $(this).prop('checked', true)
      } else if (i == -1) {
        $(this).prop('checked', true)
      } else {
        $(this).prop('checked', false)
        if($.inArray(this.id, stage_ids) !== -1){
          $(this).prop('checked', true);
        }
      }
    })
  }

  _scrollToHighlightContent() {
    if ($('.content-card__badger-highlight').length == 0) return

    $('html, body').animate({
      scrollTop: $('.content-card__badger-highlight').offset().top - 200
    }, 200)
  }
}
