// event_videoからの移管ファイル

// Import stimulus libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import { el } from 'redom';

export default class extends SimpleAjaxForm {
  static targets = ['root', 'documentItem', 'destroyFlag', 'dismissBtn'];
  static values = { emServiceId: String };

  connect() {
    super.connect();
  }

  // サービス資料データの再読み込み
  populateServiceData(emService) {
    // Clear all current items
    this.documentItemTargets.forEach((item) => this._dismissItem(item));

    let doms = emService.documents.map(({ id, title }) =>
      this._prepareDocItemDom({ id, title })
    );
    $(this.rootTarget).append($(doms));

    setTimeout(() => {
      emService.documents.forEach(({ id }) => {
        let item = this._findDocItemDom(id);
        this._useItem(item);
      });

      this._refresh();
    }, 250);
  }

  clearItems() {
    this.documentItemTargets.forEach((item) => this._dismissItem(item));
    this._refresh();
  }

  dismissDocumentItem(evt) {
    let dataset = evt.currentTarget.dataset;
    let id = dataset.emDocumentId;
    let title = dataset.emDocumentTitle;

    // Add the option back to docsSelect
    this._docSelectCtrlr.addOption({ id, title });

    let docItem = this._findDocItemDom(id);
    this._dismissItem(docItem);

    this._refresh();
  }

  addItem({ id, title }) {
    let dom = this._prepareDocItemDom({ id, title });
    $(dom).appendTo($(this.rootTarget));

    // Get this item ready for use
    let docItem = this._findDocItemDom(id);
    this._useItem(docItem);

    // Refresh
    this._refresh();
  }

  // Private methods

  _dismissItem(item) {
    item.dataset.isDestroyed = 'destroyed';
    item.classList.add('d-none');

    let destroyFlag = this.destroyFlagTargets.find(
      (flag) => flag.dataset.emDocumentId == item.dataset.emDocumentId
    );
    destroyFlag.value = true;
  }

  _useItem(item) {
    item.dataset.isDestroyed = '';
    item.classList.remove('d-none');

    let destroyFlag = this.destroyFlagTargets.find(
      (flag) => flag.dataset.emDocumentId == item.dataset.emDocumentId
    );
    destroyFlag.value = false;
  }

  _prepareDocItemDom({ id, title }) {
    // Reuse if itemDom existed, otherwise create a new one.
    return this._findDocItemDom(id) || this._generateDocItemDom({ id, title });
  }

  _findDocItemDom(id) {
    return this.documentItemTargets.find(
      (item) => item.dataset.emDocumentId == id.toString()
    );
  }

  _generateDocItemDom({ id, title }) {
    let ctrlr = this.context.identifier;

    let itemAttrs = {};
    itemAttrs[`data-${ctrlr}-target`] = 'documentItem';
    itemAttrs['data-em-document-id'] = id;

    let btnAttrs = {
      type: 'button',
      'data-action': `click->${ctrlr}#dismissDocumentItem`,
      'data-toggle': 'tooltip',
      'data-placement': 'top',
      'data-em-document-id': id,
      'data-em-document-title': title,
    };
    btnAttrs[`data-${ctrlr}-target`] = 'dismissBtn';

    let hiddenPrefix =
      'video[video_services_attributes[]][video_service_documents_attributes[]]';
    let destroyFlagAttrs = {
      type: 'hidden',
      name: `${hiddenPrefix}[_destroy]`,
      'data-em-document-id': id,
    };
    destroyFlagAttrs[`data-${ctrlr}-target`] = 'destroyFlag';

    return el('.doc-item', itemAttrs, [
      el('span', title),
      el(
        'button.btn.close.btn--icon.btn--icon-small',
        btnAttrs,
        el('span.material-icons.icons', 'close')
      ),
      el('input', { type: 'hidden', name: `${hiddenPrefix}[id]` }),
      el('input', {
        type: 'hidden',
        name: `${hiddenPrefix}[em_document_id]`,
        value: id,
      }),
      el('input', destroyFlagAttrs, false),
    ]);
  }

  _refresh() {
    let validItems = this.documentItemTargets.filter(
      (item) => !item.dataset.isDestroyed
    );

    if (validItems.length >= 1) {
      this.rootTarget.classList.remove('d-none');
    } else {
      this.rootTarget.classList.add('d-none');
    }
  }

  _deleteItem(emDocumentId) {
    let item = this.documentItemTargets.find(
      (item) => item.emDocumentId == emDocumentId.toString()
    );
    item.classList.add('d-none');
    item.dataset.isDestroyed = 'destroyed';

    let destroyFlag = this.destroyFlagTargets.find(
      (flag) => flag.emDocumentId == emDocumentId.toString()
    );
    destroyFlag.value = true;
  }

  get _docSelectCtrlr() {
    return this.application.controllers.find((ctrlr) => {
      return (
        ctrlr.context.identifier ===
          'shared--videos--form--service-documents-select' &&
        ctrlr.emServiceIdValue == this.emServiceIdValue
      );
    });
  }
}
