import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { triggeringCtrlrId: String }

  static targets = ['root', 'form', 'hiddenDownloadableType', 'hiddenDownloadableId', 'requestExplainWrap', 'requestExplain', 'hiddenEmEventId']

  showModal({triggeringCtrlrId, downloadableType, downloadableId, emEventId, documentIds = []}) {
    this._resetModal()

    this.triggeringCtrlrIdValue = triggeringCtrlrId
    this.hiddenDownloadableTypeTarget.value = downloadableType
    this.hiddenDownloadableIdTarget.value = downloadableId
    this.hiddenEmEventIdTarget.value = emEventId
    this._appendSelectedDocuments(documentIds)

    $(this.rootTarget).modal('show')
  }

  submitSuccess(_evt) {
    this._triggeringCtrlr.needSurveyValue = false
    this._triggeringCtrlr.startDownload()
    this._closeModal()
  }

  ////
  // PRIVATE METHODS
  ////


  ////
  // Append/Remove selected documents
  ////
  _removeSelectedDocuments() {
    let inputs = $("[name='download_survey[document_ids][]']")
    if (inputs.length > 0) {
      inputs.remove()
    }
  }

  _appendSelectedDocuments(values) {
    values.forEach((value) => {
      $('<input>').attr({
        type: 'hidden',
        name: 'download_survey[document_ids][]',
        value: value
      }).appendTo(this.formTarget);
    })
  }

  _resetModal() {
    this.formTarget.reset()
    this._removeSelectedDocuments()
  }

  _closeModal() {
    $(this.rootTarget).modal('hide')
  }

  get _triggeringCtrlr() {
    return this.application.controllers.find(controller => {
      return controller.idValue === this.triggeringCtrlrIdValue
    });
  }
}
