// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../../lib/helpers/simple_ajax_form';
import I18n from '../../../lib/i18n-js/init.js.erb';
export default class extends SimpleAjaxForm {
  static targets = ['form', 'submitBtn']

  ////
  // VALIDATION RULES
  ////

  static i18n_error_base = 'activerecord.errors.models.user'

  static rules = {
    password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(`${this.i18n_error_base}.attributes.password.blank`)}`
      }
    },
    password_confirmation: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(`${this.i18n_error_base}.attributes.name.blank`)}`
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    if (el == this.logoInputTarget) {
      return this.errorLogoTarget;
    }
    return el.nextElementSibling;
  }

  // Trigger an action after validate an attribute

  ////
  // NEW METHODS
  ////
  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }
    return messages;
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    if (isAllow) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }
}
