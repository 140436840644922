import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { eventSlug: String, openOnInit: Boolean}
  static targets = ['root', 'listWrap', 'historyItem']

  connect() {
    super.connect()

    this._subscribeEventActivitiesChannel(this.eventSlugValue)

    if (!document.documentElement.hasAttribute('data-turbolinks-preview')) {
      if (this.openOnInitValue) this.showScreen({ animate: true })
    }
  }

  showScreen({ animate, eventServiceId }) {
    Rails.ajax({
      url: Routes.history_items_event_exhibitors_csv_exports_path({ event_slug: this.eventSlugValue, event_service_id: eventServiceId }),
      type: 'GET',
      success: (resp) => {
        this.listWrapTarget.innerHTML = resp.body.innerHTML

        if (animate) {
          this.rootTarget.classList.add('animate')
          this.rootTarget.classList.add('show')
        }
        else {
          this.rootTarget.classList.remove('animate')
          this.rootTarget.classList.add('show')
        }
      },
      error: () => {
        window.location.reload()
      }
    })
  }

  hideScreen(_evt) {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')
  }

  updateHistoryItem(evt) {
    let item = this.historyItemTargets.find(item => item.dataset.csv_export_id === evt.detail.csvExportId.toString())
    if (!item) return

    $(item).replaceWith(evt.detail.html)
  }

  _subscribeEventActivitiesChannel(eventSlug) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'Events::ActivitiesChannel', event_slug: eventSlug
    }, {
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  get historyScreen() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier === 'events--export-csv-screens--history-screen')
  }
}
