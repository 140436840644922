// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../../lib/helpers/simple_ajax_form';
import I18n from '../../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = [ 'form', 'submitBtn', 'required' ]

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.event.attributes.name.too_long', { count: 100 })}`
      }
    },
    email: {
      email: {
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.invalid')}`
      },
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.event.attributes.name.too_long', { count: 100 })}`
      }
    }
  }
  connect() {
    super.connect()
  }

  // Input all required field or not
  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })
    return allInputed;
  }

  allowSubmit(isAllow) {
    let allRequiredInput = this.inputAllRequireFields();
    if(isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    // Notify success message
    if (this.submitBtnTarget.value == 'create') {
      document.location.href = response.data.redirect_path
    } else {
      document.location.href = response.data.redirect_path
    }
  }
}
