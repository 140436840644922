import { Controller } from 'stimulus';
import { generateHTMLOptions } from '../../../../lib/helpers/view_helpers';

export default class extends Controller {
  static values = { maxDocs: Number, formType: String }
  static targets = ['selectTag']

  connect() {
    super.connect()
    $(this.selectTagTarget).selectpicker()
  }

  addItem({ documentId, documentTitle }) {
    let opt = generateHTMLOptions([{ value: documentId, label: documentTitle}])
    $(this.selectTagTarget).append(opt)
    $(this.selectTagTarget).selectpicker('refresh')
  }

  pickItem(evt) {
    let documentId = evt.target.value

    // Build and append hidden attributes for the selected document
    this.nestedDocsAttributesCtrlr.addItem(documentId)

    // Build and append new item for primary document select
    let selectedOpt = $(this.selectTagTarget).find('option:selected')
    this.primaryDocSelectCtrlr.addItem({ documentId: documentId, documentTitle: selectedOpt.text() })

    // Remove the selected option, then refresh selectpicker
    $(this.selectTagTarget).children(`option[value='${documentId}']`).remove()
    $(this.selectTagTarget).selectpicker('refresh')

    if (this.formTypeValue == 'new') {
      this.updateStatus()
    }
    else if (this.formTypeValue == 'edit') {
      this.nestedDocsAttributesCtrlr.submitForm()
    }
  }

  updateStatus() {
    if (this.nestedDocsAttributesCtrlr.countItems() >= this.maxDocsValue) {
      this.disable()
    }
    else {
      this.enable()
    }
  }

  reInit(data) {
    let opts = generateHTMLOptions(data.map((doc) => ({ value: doc.id, label: doc.title }) ))
    $(this.selectTagTarget).empty().append(opts);
    $(this.selectTagTarget).selectpicker('refresh');
  }

  enable() {
    this.selectTagTarget.disabled = false
    $(this.selectTagTarget).selectpicker('refresh')
  }

  disable() {
    this.selectTagTarget.disabled = true
    $(this.selectTagTarget).selectpicker('refresh')
  }

  get nestedDocsAttributesCtrlr() {
    let target_ctrlr = 'organizer--exhibitors--invitation-form--nested-docs-attributes'
    return this.application.controllers.find((ctrlr) => {
      return ctrlr.identifier == target_ctrlr && ctrlr.formTypeValue == this.formTypeValue
    })
  }

  get primaryDocSelectCtrlr() {
    let target_ctrlr = 'organizer--exhibitors--invitation-form--primary-doc-select'
    return this.application.controllers.find((ctrlr) => {
      return ctrlr.identifier == target_ctrlr && ctrlr.formTypeValue == this.formTypeValue
    })
  }
}
