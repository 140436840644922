// event_videoからの移管ファイル

import { v4 as uuidv4 }                   from 'uuid';

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { Constant }                       from '@video_lib/constant.js.erb'

// Import helper libraries
import { confirmModal }                   from '@video_lib/helpers/js_modals'
import { UploadAndPreviewImageHelper }    from '@video_lib/helpers/upload_and_preview_image_helper'
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'

export default class extends SimpleAjaxForm {
  ////
  // TARGETS
  ////

  static targets = [
    'hiddenContentSpeakersCount',
    'btnOpenModalConfirmDeleteContenSpeaker',
    'listContentSpeakers',
    'btnDisplayContentSpeakerForm'
  ]

  ////
  // RULES
  ////

  static rules = {
    content_speakers_count: {
      numericality: {
        greaterThanOrEqualTo: Constant.settings.video.content_speakers.length.minimum,
        notGreaterThanOrEqualTo: `^${ ActiveRecordErrorMessageHelper('video', 'content_speakers', 'too_short', { count: Constant.settings.video.content_speakers.length.maximum }) }`,
        lessThanOrEqualTo: Constant.settings.video.content_speakers.length.maximum,
        notLessThanOrEqualTo: `^${ ActiveRecordErrorMessageHelper('video', 'content_speakers', 'too_long', { count: Constant.settings.video.content_speakers.length.maximum }) }`
      }
    }
  }

  ////
  // INIT METHODS
  ////

  connect() {
    super.connect()

    this.initUniqIdForAvatar()
  }

  initUniqIdForAvatar() {
    let _this = this

    $('.js-content-speaker').each((index, item) => {
      _this.updateUniqId($(item))
    })
  }

  updateUniqId(contentSpeaker) {
    let label = contentSpeaker.find('.js-label-content-speaker-avatar')
    let input = contentSpeaker.find('.js-input-content-speaker-avatar')
    let id = `video_video_content_speakers_attributes__avatar_${uuidv4()}`

    label.attr('for', id)
    input.attr('id', id)
  }

  ////
  // VALIDATE METHODS
  ////

  validate(event) {
    const element = event.currentTarget
    this.validateElWithEvent(event, element)
  }

  validateElWithEvent(event, element) {
    let _this = this

    let input = $(element)
    if (input.hasClass('js-input-content-speaker-name')) {
      _this.validateName(event, element)
    }
  }

  validateEl(element) {
    super.validateEl(element)
  }

  validateAll(event) {
    super.validateAll(event)

    let _this = this

    _this.inputContentSpeakerNames.each((index, element) => {
      _this.validateName(event, element)
    })
  }

  validateName(event, element) {
    let _this = this

    var input = $(element)
    var name = input.val()
    let valid = true
    let message = ''

    if (name == undefined) {
      message = _this.blankNameErrorMessage
      valid = false
    } else if (name.length < _this.minimumNameLength) {
      message = _this.blankNameErrorMessage
      valid = false
    } else if (name.length > _this.maximumNameLength) {
      message = _this.tooLongNameErrorMessage
      valid = false
    }

    if (valid) {
      _this.clearError(element)
    } else {
      _this.displayError(element, [message])
      event.preventDefault()
    }
  }

  ////
  // EVENT METHODS
  ////

  openModalConfirmDeleteContenSpeaker(event) {
    let _this = this
    let i18nPath = 'javascripts.controllers.shared.videos.form.content_speakers.modal_confirm_delete_content_speaker'

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        _this.deleteContenSpeaker(event)
      }
    })
  }

  deleteContenSpeaker(event) {
    let _this = this

    let btn = $(event.target)
    let contentSpeaker = btn.closest('.js-content-speaker')
    _this.deleteContenSpeakerHTML(contentSpeaker)
  }

  deleteContenSpeakerHTML(contentSpeaker) {
    let _this = this

    let id = contentSpeaker.find('.js-content-speaker-id').val()

    let html = ''
    if (id) {
      html = `
        <div class="js-content-speaker-deleted">
          <input type="hidden" name="video[video_content_speakers_attributes][][id]" id="video_video_content_speakers_attributes__id" value="${id}">
          <input type="hidden" name="video[video_content_speakers_attributes][][_destroy]" id="video_video_content_speakers_attributes__destroy" value="true">
        </div>
      `
    }

    contentSpeaker.replaceWith(html)

    _this.updateContentSpeakersCount()
  }

  previewContentSpeakerAvatar(event) {
    let _this = this

    let input = event.target
    let contentSpeaker = $(input).closest('.js-content-speaker')
    let img = contentSpeaker.find('.js-img-content-speaker-avatar')[0]
    // don't valid video thumbnail size
    let size = ''

    let message = UploadAndPreviewImageHelper(input, img, size)
    if (message) {
      _this.displayError(input, [message])
      $(input).val('')
    } else {
      _this.clearError(input)
    }

    let avatarUrl = contentSpeaker.find('.js-hidden-content-speaker-avatar-url')
    avatarUrl.val('')
  }

  displayContentSpeakerForm(event) {
    let _this = this

    let list = _this.listContentSpeakers
    let template = _this.templateContentSpeaker
    list.append(template)

    _this.updateUniqId($('.js-content-speaker').last())
    _this.updateContentSpeakersCount()
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    $('.js-content-speaker-deleted').remove()

    let [response, _status, _xhr] = event.detail
    let ids = response.data.content_speaker_ids

    $('.js-content-speaker').each(function(index, item) {
      let contentSpeaker = $(item)
      contentSpeaker.find('.js-content-speaker-id').val(ids[index])
    })
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get hiddenContentSpeakersCount() {
    return $(this.hiddenContentSpeakersCountTarget)
  }

  get btnDisplayContentSpeakerForm() {
    return $(this.btnDisplayContentSpeakerFormTarget)
  }

  get listContentSpeakers() {
    return $(this.listContentSpeakersTarget)
  }

  get inputContentSpeakerNames() {
    return $('.js-input-content-speaker-name')
  }

  ////
  // GETTER VALUE METHODS
  ////

  get contentSpeakersCount() {
    return parseInt(this.hiddenContentSpeakersCount).val()
  }

  get maximumContentSpeakersCount() {
    return Constant.settings.video.content_speakers.length.maximum
  }

  get templateContentSpeaker() {
    return $(this.element).data('templateContentSpeaker')
  }

  get minimumNameLength() {
    return Constant.settings.content_speaker.name.length.minimum
  }

  get maximumNameLength() {
    return Constant.settings.content_speaker.name.length.maximum
  }

  get blankNameErrorMessage() {
    return ActiveRecordErrorMessageHelper('content_speaker', 'name', 'blank')
  }

  get tooLongNameErrorMessage() {
    return ActiveRecordErrorMessageHelper('content_speaker', 'name', 'too_long', { count: this.maximumNameLength })
  }

  ////
  // ADDTIONAL METHODS
  ////

  updateContentSpeakersCount() {
    let _this = this

    let count = $('.js-content-speaker').length
    $(_this.hiddenContentSpeakersCount).val(count)
    _this.validateEl(_this.hiddenContentSpeakersCountTarget)


    let btn = $(_this.btnDisplayContentSpeakerForm)
    if (count >= _this.maximumContentSpeakersCount) {
      btn.addClass('d-none')
    } else {
      btn.removeClass('d-none')
    }
  }
}
