import { Controller } from 'stimulus';
import { el } from 'redom';
import I18n from '../../../../lib/i18n-js/init.js.erb';

export default class extends Controller {
  static values = { formType: String }
  static targets = ['inputsSetWrap', 'docInputSet', 'isPrimaryInput', 'destroyFlag', 'form', 'esDocIdInput']

  addItem(documentId) {
    // Any hidden document input set for documentId?
    let find = this.destroyFlagTargets.find((input) => input.dataset.documentId === documentId)

    if (find) {
      // Reuse
      find.value = false
    }
    else {
      // Generate new DOM
      $(this.inputsSetWrapTarget).append(this.buildInputSetDom({ documentId }))
    }
  }

  removeItem(documentId) {
    // Find destroyFlag then change its value to true
    this.destroyFlagTargets.find((input) => input.dataset.documentId === documentId).value = true
  }

  updateIsPrimary(documentId, newValue) {
    this.isPrimaryInputTargets.find((input) => input.dataset.documentId === documentId).value = newValue
  }

  unPrimaryAll() {
    this.isPrimaryInputTargets.forEach((input) => input.value = false)
  }

  countItems() {
    return this.destroyFlagTargets.filter((input) => input.value === 'false').length
  }

  clearItems() {
    $(this.inputsSetWrapTarget).empty()
  }

  beforeSubmit(evt) {
    this.docsSelectCtrlr.disable()
    this.primaryDocSelectCtrlr.disableSelections()
    this.primaryDocSelectCtrlr.disableSubmitBtn()
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  successSubmit(evt) {
    this.docsSelectCtrlr.updateStatus()
    this.reInit(evt.detail[0].data.event_service_documents)
    this.primaryDocSelectCtrlr.enableSubmitBtn()
    this.primaryDocSelectCtrlr.enableSelections()
    alertify.success(I18n.t('messages.notify.general.success'))
  }

  reInit(data) {
    $(this.inputsSetWrapTarget).empty()
    let newInputsSetDom = data.map(({ id, document_id: documentId, is_primary: isPrimary }) => this.buildInputSetDom({ id, documentId, isPrimary }))
    $(this.inputsSetWrapTarget).append(newInputsSetDom)
  }

  buildInputSetDom({ id = '', documentId, isPrimary = false }) {
    let docInputSetAttrs = {}
    docInputSetAttrs[`data-${this.identifier}-target`] = 'docInputSet'
    docInputSetAttrs['data-document-id'] = documentId

    let esDocIdInputAttrs = {
      type: 'hidden',
      name: 'event_service[event_service_documents_attributes[]][id]',
      value: id
    }
    esDocIdInputAttrs[`data-${this.identifier}-target`] = 'esDocIdInput'

    let docIdInputAttrs = {
      type: 'hidden',
      name: 'event_service[event_service_documents_attributes[]][document_id]',
      value: documentId
    }

    let isPrimaryInputAttrs = {
      type: 'hidden',
      name: 'event_service[event_service_documents_attributes[]][is_primary]',
      value: isPrimary,
      'data-document-id': documentId
    }
    isPrimaryInputAttrs[`data-${this.identifier}-target`] = 'isPrimaryInput'

    let destroyFlagInputAttrs = {
      type: 'hidden',
      name: 'event_service[event_service_documents_attributes[]][_destroy]',
      value: false,
      'data-document-id': documentId
    }
    destroyFlagInputAttrs[`data-${this.identifier}-target`] = 'destroyFlag'

    return el('.document-attributes-inputs-wrap', docInputSetAttrs, [
      el('input', esDocIdInputAttrs),
      el('input', docIdInputAttrs),
      el('input', isPrimaryInputAttrs),
      el('input', destroyFlagInputAttrs)
    ])
  }

  get docsSelectCtrlr() {
    let target_ctrlr = 'organizer--exhibitors--invitation-form--documents-select'
    return this.application.controllers.find((ctrlr) => {
      return ctrlr.identifier == target_ctrlr && ctrlr.formTypeValue == this.formTypeValue
    })
  }

  get primaryDocSelectCtrlr() {
    let target_ctrlr = 'organizer--exhibitors--invitation-form--primary-doc-select'
    return this.application.controllers.find((ctrlr) => {
      return ctrlr.identifier == target_ctrlr && ctrlr.formTypeValue == this.formTypeValue
    })
  }
}
