import UploadAndPreviewImage from "../../../lib/helpers/upload_and_preview_logo_helper";

// Import custom libraries
import { SimpleAjaxForm } from '../../../lib/helpers/simple_ajax_form';
import I18n from '../../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static values = { maxDocs: Number}

  static targets = [
    'form', 'scroll', 'submitBtn', 'service', 'companyName',
    'firstName', 'lastName', 'email', 'bannerPreview',
    'bannerFileInput'
  ]
  ////
  // VALIDATION RULES
  ////
  static rules = {
    service_id: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.service.blank')}`
      },
    },
    reach_user_limit: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_service.attributes.reach_user_limit.blank')}`
      },
    },
    first_name: (value) => {
      if (_.isEmpty(value)) return;
      return {
        length: {
          maximum: 20,
          tooLong: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.first_name.too_long', { count: 20 })}`
        }
      }
    },
    last_name: (value) => {
      if (_.isEmpty(value)) return;
      return {
        length: {
          maximum: 20,
          tooLong: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.last_name.too_long', { count: 20 })}`
        }
      }
    },
    email: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.email.invalid')}`
        }
      }
    }
  }

  connect() {
    super.connect()
    $(this.serviceTarget).selectpicker({ liveSearch: true, noneResultsText: I18n.t('shared.select_picker.no_result_search') });

    this.initSelectPicker()
  }

  ////
  // OVERRIDE METHODS
  ////

  initSelectPicker() {
    let _this = this;

    // Disable scroll when dropdown open
    $('.selectpicker').on('shown.bs.select', function () {
      $(_this.scrollTarget).css('overflow-y', 'hidden')
    })

    // Enable scroll when dropdown open
    $('.selectpicker').on('hidden.bs.select', function () {
      $(_this.scrollTarget).css('overflow-y', 'auto')
    })
  }


  // Element to show error for an input element
  errorMessageEl(el) {
    let parent = el.parentNode;
    return parent.parentNode.querySelector('.error-message');
  }

  // Clear all inputs, highlights and error messages
  clearForm() {
    let _this = this;

    this.attributes.forEach(({ el }) => {
      el.classList.remove('error');
      el.value = ''
      _this.errorMessageEl(el).innerHTML = '';
    })
    this.setCompanyName("-");
    this.resetSelectPicker();
  }

  setCompanyName(name) {
    this.companyNameTarget.innerHTML = name;
  }

  resetSelectPicker() {
    $(this.serviceTarget).val('default');
    $(this.serviceTarget).selectpicker('refresh');
  }

  changeService(evt) {
    this.primaryDocSelectCtrlr.reset()
    this.nestedDocsAttributesCtrlr.clearItems()
    this.docsSelectCtrlr.updateStatus()

    if (evt.target.value) {
      Rails.ajax({
        url: Routes.organizer_service_path(evt.target.value),
        type: 'GET',
        success: (response) => {
          this.setCompanyName(response.data.company.name)
          this.docsSelectCtrlr.reInit(response.data.documents)
        }
      })
    } else {
      this.setCompanyName('-')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this
    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  openFileChooser(_evt) {
    this.bannerFileInputTarget.click()
  }

  uploadBanner(evt) {
    let message = UploadAndPreviewImage(evt, this.bannerPreviewTarget, 10)
    if (message) {
      this.displayError(evt.target, [message])
      this.clearElement(evt.target)
    } else {
      this.clearError(evt.target)
    }
  }

  get nestedDocsAttributesCtrlr() {
    let ctrlr = 'organizer--exhibitors--invitation-form--nested-docs-attributes'
    return this.application.controllers.find(({ identifier }) => identifier == ctrlr )
  }

  get primaryDocSelectCtrlr() {
    let ctrlr = 'organizer--exhibitors--invitation-form--primary-doc-select'
    return this.application.controllers.find(({ identifier }) => identifier == ctrlr )
  }

  get docsSelectCtrlr() {
    let ctrlr = 'organizer--exhibitors--invitation-form--documents-select'
    return this.application.controllers.find(({ identifier }) => identifier == ctrlr )
  }
}
