import { Controller } from "stimulus";
import CableReady from 'cable_ready';

export default class extends Controller {

  static values = {}
  static subscription = null

  connect() {
    super.connect()
    this.subscribeChannel(this.data.get('eventSlug'))
  }

  disconnect() {
    if (this.constructor.subscription) this.constructor.subscription.unsubscribe()
    super.disconnect()
  }

  subscribeChannel(eventSlug) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'EventHalls::StagesChatChannel', event_slug: eventSlug
    }, {
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  updateOrganizerMessagesInfo(evt) {
    let stageIds = evt.detail.stageIds

    let avatarSelectors = []
    let nameSelectors = []

    stageIds.forEach(stageId => {
      avatarSelectors.push(`.stage-${stageId}-organizer-chat-input-avatar`)
      avatarSelectors.push(`.stage-${stageId}-organizer-message-avatar`)

      nameSelectors.push(`.stage-${stageId}-organizer-chat-input-name`)
      nameSelectors.push(`.stage-${stageId}-organizer-message-name`)
    })

    $(avatarSelectors.join(', ')).attr('src', evt.detail.organizerAvatarUrl)
    $(nameSelectors.join(', ')).text(evt.detail.organizerName)
  }

  updateMessagesInfo(evt) {
    let stageIds = evt.detail.stageIds
    let authorId = evt.detail.authorId

    let avatarSelectors = []
    let nameSelectors = []

    stageIds.forEach(stageId => {
      avatarSelectors.push(`.stage-${stageId}-author-${authorId}-chat-input-avatar`)
      avatarSelectors.push(`.stage-${stageId}-author-${authorId}-message-avatar`)

      nameSelectors.push(`.stage-${stageId}-author-${authorId}-chat-input-name`)
      nameSelectors.push(`.stage-${stageId}-author-${authorId}-message-name`)
    })

    $(avatarSelectors.join(', ')).attr('src', evt.detail.avatarUrl)
    $(nameSelectors.join(', ')).text(evt.detail.name)
  }

  updateGuestComments(evt) {
    let { guestId, stageId, name } = evt.detail
    $(`.stage-${stageId}-author-${guestId}-message-name`).text(name)
  }
}
