import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect()
    this.initSelectPicker()
  }

  initSelectPicker() {
    $('.selectpicker').selectpicker()
  }
}
