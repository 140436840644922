// event_videoからの移管ファイル

import { v4 as uuidv4 }                   from 'uuid';

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import helper libraries
import { confirmModal }                   from '@video_lib/helpers/js_modals'
import { UploadAndPreviewDocumentHelper } from '@video_lib/helpers/upload_and_preview_document_helper'

export default class extends SimpleAjaxForm {
  ////
  // TARGETS
  ////

  static targets = [
    'listContentDocuments',
  ]

  connect() {
    super.connect()

    this.initUniqIdForDocument()
  }

  initUniqIdForDocument() {
    let _this = this

    _this.listContentDocuments.find('.js-content-document').each((index, item) => {
      _this.updateUniqId($(item))
    })
  }

  updateUniqId(contentDocument) {
    let label = contentDocument.find('.js-label-content-document')
    let input = contentDocument.find('.js-input-content-document')
    let id = `video_content_documents_attributes__document_${uuidv4()}`

    label.attr('for', id)
    input.attr('id', id)
  }

  ////
  // EVENTS METHODS
  ////

  openModalConfirmDeleteContenDocument(event) {
    let _this = this
    let i18nPath = 'javascripts.controllers.shared.videos.form.content_documents.modal_confirm_delete_content_document'

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        _this.deleteContenDocument(event)
      }
    })
  }

  deleteContenDocument(event) {
    let _this = this

    let btn = $(event.target)
    let contentDocument = btn.closest('.js-content-document')
    _this.deleteContenDocumentHTML(contentDocument)
  }

  deleteContenDocumentHTML(contentDocument) {
    let _this = this

    let id = contentDocument.find('.js-content-document-id').val()

    let html = ''
    if (id) {
      html = `
        <div class="js-content-document-deleted">
          <input type="hidden" name="video[content_documents_attributes][][id]" id="video_content_documents_attributes__id" value="${id}">
          <input type="hidden" name="video[content_documents_attributes][][_destroy]" id="video_content_documents_attributes__destroy" value="true">
        </div>
      `
    }

    contentDocument.replaceWith(html)
  }

  previewContentDocument(event) {
    let _this = this

    let input = event.target
    let contentDocument = $(input).closest('.js-content-document')
    let img = contentDocument.find('.js-img-content-document')[0]
    // don't valid video thumbnail size
    let size = ''

    let message = UploadAndPreviewDocumentHelper(input, img, size)
    if (message) {
      _this.displayError(input, [message])
      $(input).val('')
    } else {
      _this.clearError(input)
    }
  }

  displayContentDocumentForm(event) {
    let _this = this

    let list = _this.listContentDocuments
    let template = _this.templateContentDocument
    list.append(template)

    _this.updateUniqId($('.js-content-document').last())
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    $('.js-content-document-deleted').remove()

    let [response, _status, _xhr] = event.detail
    let ids = response.data.content_document_ids

    $('.js-content-document').each(function(index, item) {
      let contentDocument = $(item)
      contentDocument.find('.js-content-document-id').val(ids[index])
    })
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get listContentDocuments() {
    return $(this.listContentDocumentsTarget)
  }

  ////
  // GETTER VALUE METHODS
  ////

  get templateContentDocument() {
    return $(this.element).data('templateContentDocument')
  }

  ////
  // ADDTIONAL METHODS
  ////
}
