import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static values = { initialPercentage: Number }
  static targets = ['root', 'indicator']

  connect() {
    super.connect()

    this.updateProgress(this.initialPercentageValue)
  }

  updateProgress(percentage) {
    let totalWidth = $(this.rootTarget).width()

    let width = Math.floor(percentage * totalWidth)
    $(this.indicatorTarget).width(width)
  }
}
