import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['root', 'content', 'emailText']

  showScreen(email, privateType) {
    if (privateType == 'private_organizer') {
      this.contentTarget.classList.add('d-none')
    } else {
      this.emailTextTarget.innerText = email
    }

    this.rootTarget.classList.add('show')
  }

  animateHideScreen(_evt) {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')
  }

  toHistoryScreen(e) {
    let eventServiceId = e.target.dataset.event_service_id

    this._hideScreen()
    this.historyScreenCtrlr.showScreen({ animate: false, eventServiceId: eventServiceId })

  }

  _hideScreen() {
    this.rootTarget.classList.remove('animate')
    this.rootTarget.classList.remove('show')
  }

  get historyScreenCtrlr() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier === 'events--export-csv-screens--history-screen')
  }
}
