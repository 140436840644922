import CableReady from 'cable_ready';
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static subscription = null
  static values = { currentSurveyId: String, shouldShowSurvey: Boolean, drawerAction: String, drawerOrigin: String }

  connect() {
    super.connect()
    this._subscribeStageActivitiesChannel(this.data.get('eventStageId'))

    setTimeout(() => {
      this.reCalcRightPanel()
    }, 300)

  }

  disconnect() {
    if (!this.constructor.subscription) return

    this.constructor.subscription.unsubscribe()

    super.disconnect()
  }

  _subscribeStageActivitiesChannel(eventStageId) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'EventHalls::StageActivitiesChannel', event_stage_id: eventStageId
    }, {
      connected: () => {
        this._renderSurveyOnConnect()
      },
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  triggerReflexRender(e) {
    this.stimulate('EventHalls::StageReflex#render_target_component', e.detail)
  }

  triggerSurveyRender(e) {
    if(this.drawerController) {
      this.drawerController.closeSurvey(e)
      this.drawerController.loadSurvey(e)
    }
  }

  renderTargetComponentSuccess() {
    this.reCalcRightPanel()
  }

  renderContentPanel(e) {
    e.detail.forEach((el) => {
      $(el.target).html(el.html)
    })

    this.reCalcRightPanel()
    this.stimulate('EventHalls::StageReflex#render_resource_component')
  }

  renderResourceComponentSuccess() {
    this.reCalcRightPanel()
  }

  reCalcRightPanel() {
    let contentSectionHeight = $('.right-panel').find('.content-section-top').outerHeight()
    let notificationHeight = $('.notification_wrapper').outerHeight() || 0

    $('.chat-box-wrapper').css({ height: `calc(100vh - 60px - ${contentSectionHeight + notificationHeight}px)` })
  }

  _renderSurveyOnConnect() {
    if (!this.shouldShowSurveyValue) return

    let el = { detail: { surveyId: this.currentSurveyIdValue, drawerAction: this.drawerActionValue, drawerOrigin: this.drawerOriginValue } }
    this.drawerController.loadSurvey(el)
  }

  get rightPanelCtrlr() {
    this.application.controllers.find(ctrlr => ctrlr.identifier === 'event-halls--right-panel')
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }
}
