
export const confirmModal = ({ title = '', message, labels = {} , onOk = () => {}, onCancel = () => {} }) => {
  alertify.confirm(title, message, onOk, onCancel)
          .set('closable', false)
          .set('reverseButtons', true)
          .set('labels', {
            ok: labels.ok_btn,
            cancel: labels.cancel_btn
          })
}
