import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['headerButton', 'service', 'member']

  connect() {
    super.connect()

    this.loadSelectedTab()
  }

  loadSelectedTab() {
    let hash = window.location.hash;
    let anchor = $('a[href$="'+hash+'"]');

    if (anchor.length > 0){
      anchor.click();
      this.activeTab(anchor[0])
      this.activeHeaderBtn(anchor[0])
    }
  }

  displayScreen(e) {
    this.activeTab(e.target)
    this.activeHeaderBtn(e.target)
    window.location.hash = e.target.id
  }

  activeTab(e){
    let tabs = [
      this.serviceTarget, this.memberTarget
    ]

    _.forEach(tabs, (el)=> {
      if(e.dataset.tab === el.dataset.tab) {
        el.classList.add('show')
        el.classList.add('active')
      }else {
        el.classList.remove('show')
        el.classList.remove('active')
      }
    })
  }

  activeHeaderBtn(e) {
    this.headerButtonTargets.forEach((el) => {
      if(e.dataset.tab === el.dataset.tab) {
        el.classList.remove('d-none')
      } else {
        el.classList.add('d-none')
      }
    })
  }
}
