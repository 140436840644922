// Import custom libraries
import ApplicationController from './../application_controller';
import { scrollToBottom } from '../../lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = ['input', 'button', 'wrapper', 'companyMemberWrapper']

  connect() {
    super.connect()
    this.checkValid = _.debounce(this.checkValid, 400).bind(this)
  }

  checkValid(e) {
    this._clearCompanyMemberWrapper()

    this.stimulate('Events::ServiceMemberReflex#check_valid', e.target, this.inputTarget.value)
  }

  sendInvite(e) {
    let _this = this

    this.stimulate('Events::ServiceMemberReflex#invite_member', e.target, this.inputTarget.value)
      .then((e) => {
        _this._clearInvitationForm()
        scrollToBottom(_this.serviceInvitationFormController.listFormTarget)
      })

    alertify.success(I18n.t("messages.notify.company_member.invite_success"))
  }

  _clearInvitationForm() {
    $(this.buttonTargets).remove()
    this.inputTarget.value = ''
    this._clearCompanyMemberWrapper()
  }

  _clearCompanyMemberWrapper() {
    if($(this.companyMemberWrapperTargets).length > 0) {
      $(this.companyMemberWrapperTargets).remove()
    }
  }

  get serviceInvitationFormController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'service-invitation-form';
    });
  }
}
