// Import webpacker libraries
import CableReady from 'cable_ready'
const queryString = require('query-string')
const waitUntil = require('wait-until');

// Import custom libraries
import { getCookie } from '@lib/helpers/set_cookie_helper';
import ApplicationController from '@controllers/application_controller'
import { showLoaderFor } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static subscription = null
  static targets = ['chatBubble', 'screensWrapper', 'chatScreen', 'directChatScreen']

  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect()
    this._subscribeDirectChatChannel(this.element.dataset.event_slug)

    this.VIEW_CONTEXTS = {
      chatScreenOverview: 'chatscreen_overview',
      currentChatRoom: 'current_chat_room'
    }

    if (!document.documentElement.hasAttribute('data-turbolinks-preview')) {
      this._preopenDirectChatRoom()
    }
  }

  _subscribeDirectChatChannel(eventSlug) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'Events::DirectChatChannel', event_slug: eventSlug
    }, {
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }
  ////
  // NEW METHODS
  ////

  get directChatScreenController() {
    return this.getController('direct-chat--direct-chat-screen-reflex')
  }

  get chatScreenCtrlr() {
    return this.getController('direct-chat--chat-screen')
  }

  get chatModalCtrlr() {
    return this.getController('direct-chat--chat-modal')
  }

  requestBroadcastNotification(evt) {
    // Many users will trigger this event at the same time
    let delay = _.random(10, 500)

    setTimeout(() => {
      this.stimulate(
        'DirectChat::ScreensReflex#request_broadcast_chat_notification',
        this.element,
        {
          view_context: this._viewContext(evt.detail.chatRoomId),
          chat_room_id: evt.detail.chatRoomId
        }
      )
    }, delay)
  }

  updateChatTabs(evt) {
    let tabs = evt.detail.tabs
    this.chatScreenCtrlr.updateChatTabs(tabs)
  }

  updateChatRoomPreview(evt) {
    let targetChatRoomId = evt.detail.data.chatRoomId

    let isTargetChatRoomOpening = !!this.directChatScreenController && this.directChatScreenController.element.dataset.chat_room_id == targetChatRoomId.toString()

    if (!isTargetChatRoomOpening) {
      this.chatScreenCtrlr && this.chatScreenCtrlr.updateChatRoomPreview(evt.detail.data)
      this.informNewMessage()
    }
  }

  // Load chat screen with message preview
  loadChatScreen(opts = {}){
    showLoaderFor(this.screensWrapperTarget);

    this.stimulate(
      'DirectChat::ScreensReflex#load_home_screen',
      opts
    ).catch(payload => {
      window.location.reload()
    })
  }

  // Load direct chat screen of a conversation
  loadDirectChatScreen(roomPreviewTarget){
    showLoaderFor(this.directChatScreenTarget);

    this.stimulate(
      'DirectChat::ScreensReflex#load_direct_chat_screen',
      roomPreviewTarget,
      {
        'update_published_info': false
      }
    )
  }

  // Open a chat screen and hide chat bubble
  openChatScreen() {
    if (this.screensWrapperTarget.classList.contains('d-none')) {
      this.screensWrapperTarget.classList.remove('d-none')
      this.loadChatScreen()
    } else {
      this.closeChatScreen()
    }
  }

  // Close chat screen and show chat bubble
  closeChatScreen() {
    this.screensWrapperTarget.classList.add('d-none');
  }

  _viewContext(targetChatRoomId) {
    if (this.screensWrapperTarget.classList.contains('d-none')) return

    if (!this.chatScreenTarget.classList.contains('d-none')) {
      return this.VIEW_CONTEXTS['chatScreenOverview']
    }
    else if ($(this.directChatScreenTarget).find(`chat_room_screen_${targetChatRoomId}`).length) {
      return this.VIEW_CONTEXTS['currentChatRoom']
    }
  }

  // Open a screen to chat directly with an user
  openDirectChatScreen(e) {
    this.chatScreenTarget.classList.add('d-none');
    this.directChatScreenTarget.classList.remove('d-none');

    this.loadDirectChatScreen(e.currentTarget);
  }

  backToChatScreen(){
    this.directChatScreenTarget.classList.add('d-none')
    this.chatScreenTarget.classList.remove('d-none')
    let currentChatRoomId = this.directChatScreenController.element.dataset.chat_room_id
    let previous_chat_tab = getCookie('_chat_tab')

    let opts = {
      "action": "back_from_chat_room",
      "chat_room_id": currentChatRoomId,
      "previous_chat_tab": previous_chat_tab
    }

    this.loadChatScreen(opts)
  }

  informNewMessage() {
    this.chatModalCtrlr.chatBubbleTarget.classList.add('nav-item-chat--unread');
  }

  clearNewMessage() {
    this.chatBubbleTarget.classList.remove('nav-item-chat--unread');
  }

  _preopenDirectChatRoom() {
    let chatRoomId = queryString.parse(location.search).chat_room_id
    if (!chatRoomId) return

    waitUntil(100, 10, () => {
      return this.isActionCableConnectionOpen()
    }, () => {
      setTimeout(() => {
        this.openChatScreen()

        waitUntil(200, 10, () => {
          return !!$(`.message-preview#chat_room_${chatRoomId}`).length
        }, () => {
          $(`.message-preview#chat_room_${chatRoomId}`).length &&
            $(`.message-preview#chat_room_${chatRoomId}`)[0].click()
        })
      }, 500)
    })
  }
}
