// Import libraries from webpacker libraries
import CableReady from 'cable_ready';
import Rails from '@rails/ujs';
import { serialize } from 'object-to-formdata';

// Import custom libraries
import ApplicationController from '@controllers/application_controller';
import { scrollToBottom, stringToHTML, selectFirstMessage, showLoaderFor, removePasteFormat } from '@lib/helpers/view_helpers';


export default class extends ApplicationController {
  static targets = [
    'chatRoomScreen', 'chatInput', 'statusTxt', 'statusInput', 'sendBtn', 'inviteExhibitorBtn', 'iconSend', 'iconInvite', 'tabList', 'chatTab', 'messageList', 'fieldWrapper', 'loadmoreIndicator'
  ]

  static values = { oldestMsgOffset: Number }

  ////
  // OVERRIDE METHODS
  ////
  connect(){
    super.connect();
    // Trigger tooltips
    $('.invite__load-exhibitor-btn').tooltip();

    this.subscribeChatRoomChannel(this.element.dataset.chat_room_id)

    scrollToBottom(this.messageListTarget)
    if (this.hasChatInputTarget) removePasteFormat(this.chatInputTarget)

    if (this.activeTab) {
      this.showTabChat(this.activeTab.dataset.tab_id)
    }

    showLoaderFor(this.loadmoreIndicatorTarget, 'md')
    this.loadMoreMessages = this.loadMoreMessages.bind(this) // binding so we can refer to this stimulus class

    if (!this.registeredOnScroll) {
      $(this.messageListTarget).on('scroll', _.debounce(this.loadMoreMessages, 100) )
      this.registeredOnScroll = true
    }
  }

  disconnect() {
    if (!this.chatRoomChannel) return
    this.chatRoomChannel.unsubscribe()
  }

  loadMoreMessages() {
    if (!this.loadmoreIndicatorTarget.classList.contains('d-none')) return

    this.messageListTarget.dataset.lastScrollTop = this.messageListTarget.dataset.lastScrollTop || 0

    // Scrolling down, skip
    if ($(this.messageListTarget).scrollTop() > this.messageListTarget.dataset.lastScrollTop) {
      this.messageListTarget.dataset.lastScrollTop = $(this.messageListTarget).scrollTop()
      return
    }

    // Not reach near the top yet, skip
    if ($(this.messageListTarget).scrollTop() > 10) {
      this.messageListTarget.dataset.lastScrollTop = $(this.messageListTarget).scrollTop()
      return
    }

    this.loadmoreIndicatorTarget.classList.remove('d-none')

    Rails.ajax({
      url: Routes.more_messages_chat_room_path({ id: this.element.dataset.chat_room_id }),
      type: 'GET',
      data: $.param({ offset: this.oldestMsgOffsetValue + 1 }),
      success: (resp) => {
        this.oldestMsgOffsetValue += resp.data.messages_length
        $(this.chatRoomScreenTarget).prepend(resp.data.html)
      },
      complete: () => {
        this.loadmoreIndicatorTarget.classList.add('d-none')
      }
    })
  }

  reflexError(element, reflex, error) {
    if(reflex == 'DirectChat::ScreensReflex#load_direct_chat_screen') {
      return
    }

    if (reflex == 'DirectChat::ChatRoomReflex#create_message') {
      return
    }

    alertify.error(I18n.t('messages.notify.general.error'))
  }
  ////
  // NEW METHODS
  ////


  // Wrapper controller of chat screens
  get wrapperController() {
    let controllers = this.application.controllers.filter(controller => {
      return controller.context.identifier == 'direct-chat--wrapper';
    });

    return controllers[0];
  }

  get chatRoom() {
    return {
      id: this.element.dataset?.chat_room_id,
      ownerId: this.element.dataset?.owner_id
    }
  }

  // subscribe a websocket channel
  subscribeChatRoomChannel(chatRoomId) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.chatRoomChannel) {
      this.chatRoomChannel.unsubscribe()
    }

    this.chatRoomChannel = this.application.consumer.subscriptions.create({
      channel: 'DirectChat::ChatRoomChannel', chat_room_id: chatRoomId
    }, {
      initialized: () => {
        // console.log('subscription initialized: ', chatRoomId)
      },
      connected: () => {
        // console.log('subscription connected: ', chatRoomId)
      },
      disconnected: () => {
        // console.log('subscription disconnected: ', chatRoomId)
      },
      rejected: () => {
        // console.log('subscription rejected: ', chatRoomId)
      },
      received: (data) => {
        // console.log('subscription received: ', data)
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  switchTab(e) {
    let tabId = e.target.dataset.tab_id

    this.chatTabTargets.forEach((chatTab)=> {
      if (chatTab.dataset.tab_id == tabId) {
        chatTab.classList.add('active')
      } else {
        chatTab.classList.remove('active')
      }
    })
    this.showTabChat(tabId)
  }

  showTabChat(tabId) {
    // Show related message list and hide other message lists
    this.messageListTargets.forEach((msgList)=> {
      if (msgList.dataset.tab_id == tabId) {

        msgList.classList.remove('d-none')
      } else {
        msgList.classList.add('d-none')
      }
    })
  }

  get activeTab() {
    return this.chatTabTargets.find((el) => $(el).hasClass('active'))
  }
  // TODO: move this method to helper to reuse
  // Only allow input 500 characters
  limitCharacters(e) {
    // Don't limit speacial keys
    if (e.key == 'Backspace' || e.key == 'Delete' || e.key == 'Enter' || e.key == 'Meta') {
      return
    }

    // Limit number of chars
    if ( e.target.innerText.length > 499) {
      e.preventDefault();
        return;
    }
  }

  // Submit message to backend
  postChat(e) {
    if (e.type == 'click' && e.currentTarget == this.sendBtnTarget) {
      // Prevent browser add breakline after hit 'enter'
      e.preventDefault();

      let message = this.chatInputTarget.innerText.trim().substring(0, 500);

      if (_.isEmpty(message)) return;

      // Send this message to service to store and boardcast to other users
      this.stimulate(
        'DirectChat::ChatRoomReflex#create_message',
        this.element,
        message
      ).catch(payload => {
        alertify.error(I18n.t('messages.notify.chat_room.exchange_period_ended'))
      })

      this.chatInputTarget.innerText = '';
    }
  }

  // Update chat box to add new message
  updateChatBox(evt) {
    let msgHTML = evt.detail.html;
    let msgDOM = stringToHTML(msgHTML)
    this.chatRoomScreenTarget.append(msgDOM[0])
    this.oldestMsgOffsetValue += 1
    scrollToBottom(this.messageListTarget)

    // this function is called by a lots of user simutaneously
    let delay = _.random(10, 500)
    setTimeout(() => this.markMessageAsRead(evt.detail.roomMessageId), delay)
  }

  // Refresh chat screen
  refreshScreen(evt) {
    // Only triggers when current chat_room matches the chat_room from event
    if (this.element.dataset.chat_room_id != evt.detail.chatRoomId.toString()) return

    showLoaderFor(this.element);
    this.stimulate(
      'DirectChat::ScreensReflex#load_direct_chat_screen',
      this.element
    )
  }

  markMessageAsRead(roomMessageId) {
    if (!$(this.chatRoomScreenTarget).is(":hidden")) {
      this.stimulate(
        'DirectChat::ScreensReflex#mark_message_as_read',
        roomMessageId
      )
    }
  }

  // update status of chat room (organizer only)
  toggleStatus(e) {
    let _this = this;
    let newStatus = this.statusInputTarget.checked ? 'undone' : 'done';
    let submitData = { chat_room: { status: newStatus } };

    Rails.ajax({
      url: Routes.update_room_status_chat_room_path({ id: _this.element.dataset.chat_room_id }),
      type: 'PATCH',
      data: serialize(submitData),
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = selectFirstMessage(errors)
        alertify.error(error_message)
      },
      success: (e) => {
        let { status } = e.data

        if (status == 'done') {
          this.statusTxtTarget.innerText = I18n.t('shared.direct_chat.screens.direct_chat_screen.texts.done')
          this.statusTxtTarget.classList.remove('undone')
          this.statusTxtTarget.classList.add('done')

          this.chatInputTarget.removeAttribute('contenteditable')
          this.fieldWrapperTarget.classList.add('chat__field-wrapper--disabled')
          this.iconSendTarget.classList.remove('icon-active')
          this.sendBtnTarget.classList.add('no-click')
        } else {
          this.statusTxtTarget.innerText = I18n.t('shared.direct_chat.screens.direct_chat_screen.texts.undone')
          this.statusTxtTarget.classList.remove('done')
          this.statusTxtTarget.classList.add('undone')

          this.chatInputTarget.setAttribute('contenteditable', '')
          this.fieldWrapperTarget.classList.remove('chat__field-wrapper--disabled')
          if($(this.chatInputTarget).text().length > 0){
            this.iconSendTarget.classList.add('icon-active')
          }
          this.sendBtnTarget.classList.remove('no-click')
        }

        // Notify success message
        alertify.success(I18n.t('messages.notify.general.edit.success'))
      }
    })
  }

  // Attendee agrees public info (attendee only)
  attendeePublishInfo(){
    showLoaderFor(this.element);
    this.stimulate(
      'DirectChat::ScreensReflex#load_direct_chat_screen',
      this.element,
      {
        'attendee_published_info': true
      }
    )
  }

  // Exhibitor confirms to join a chat room
  exhibitorJoinRoom() {
    let _this = this
    showLoaderFor(this.element);

    this.stimulate(
      'DirectChat::ScreensReflex#load_direct_chat_screen',
      this.element,
      {
        'exhibitor_join_room': true,
        'exhibitor_approach': true
      }
    )
    .then((e) => {
      _this.updateAttendeeList(_this.chatRoom.ownerId)
    })
    .catch(payload => { // Notify error message
      alertify.error(I18n.t('messages.notify.chat_room.cant_approach'))
      this.wrapperController.closeChatScreen()
    })
  }

  // Load list of invitable exhibitors to join the current chat room
  loadExhibitorsList() {
    this.stimulate(
      'DirectChat::ChatRoomReflex#load_invitable_exhibitors_list',
      this.element
    )
  }

  loadInvitableExhibitorsListSuccess() {
    // Fixes after replace the view from refex
    // Recalculate the position of popup
    Popper.Defaults.modifiers.computeStyle.enabled = false;

    let tooltipHeight = $('#invite__exhibitors-list').outerHeight()
    let tooltipOffsetTop = $('#invite__exhibitors-list').offset().top

    if(tooltipOffsetTop != 0) {
      let tooltipOffsetBottom = tooltipHeight + tooltipOffsetTop
      let iconInviteOffsetTop = $(".invite__load-exhibitor-btn").offset().top
      $('#invite__exhibitors-list').css('opacity', `1`);
      // 74: offset between exhibitors modal bottom offset and invite icon top offset
      if(tooltipOffsetBottom - iconInviteOffsetTop < 74) {
        let yPosition = iconInviteOffsetTop - tooltipOffsetBottom - 20
        $('#invite__exhibitors-list').css('transform', `translate3d(-157px, ${yPosition}px , 0px)`);
      } else {
        let yPosition = iconInviteOffsetTop - tooltipOffsetBottom - 4
        $('#invite__exhibitors-list').css('transform', `translate3d(-157px, ${yPosition}px , 0px)`);
      }
    } else {
      $('#invite__exhibitors-list').css('opacity', `0`);
    }
  }


  inviteExhibitor(e) {
    let _this = this;
    let invited_exhibitor_id = e.currentTarget.dataset.exhibitor_id
    let submitData = { invited_user_id: invited_exhibitor_id};

    Rails.ajax({
      url: Routes.invite_user_joining_chat_room_path({ id: _this.element.dataset.chat_room_id }),
      type: 'POST',
      data: serialize(submitData),
      error: (e) => {
        let errors = e.errors;
        let error_message = selectFirstMessage(errors)
        alertify.error(error_message)
      },
      success: (e) => {
      }
    })
  }

  updateAttendeeList(attendeeId){
    let $attendeeListElement = document.getElementById('attendee_list')
    if (!$attendeeListElement) return;

    let eventServiceId = $attendeeListElement.dataset.event_service_id
    Rails.ajax({
      url: Routes.approach_maximum_info_event_service_path({ id: eventServiceId }),
      type: 'GET',
      error: (e) => {},
      success: (e) => {
        let { reach_user_percent, reach_user_current, reach_user_limit } = e.data

        // disable approach related button
        let $approachBtn = document.getElementById(`approach_${attendeeId}`)
        if($approachBtn) {
          $approachBtn.classList.remove('unapproached')
          $approachBtn.classList.remove('btn-primary')
          $approachBtn.classList.add('approached')
          $approachBtn.classList.add('btn-outline-primary')
          $approachBtn.innerText = I18n.t('shared.events.attendee_list.labels.status_approached')
        }

        // update approach number
        let $progressBar = document.getElementsByClassName('progressbar-fill')[0]
        $progressBar.style.width = `${reach_user_percent}%`;

        let $processNumber = document.getElementsByClassName('progress-figure')[0];
        let $currentNumber = $processNumber.querySelector('.current_number');
        let $maxNumber = $processNumber.querySelector('.max_number');

        $currentNumber.innerText = reach_user_current
        $maxNumber.innerText = reach_user_limit
        // check maximum and disable unapproached buttons

        if(reach_user_percent >= 100) {
          let approachButtons = document.getElementsByClassName('approach_btn unapproached')
          Array.from(approachButtons).forEach((button) => {
            button.setAttribute('disabled', true)
          })
        }
      }
    })
  }

  calcHeight(){
    this.checkHeight()
    this.checkInputs()
  }

  checkInputs(){
    if($(this.chatInputTarget).text().length > 0){
      this.iconSendTarget.classList.add('icon-active')
    }else{
      this.iconSendTarget.classList.remove('icon-active')
    }
  }

  iconAfterSend(){
    this.checkInputs()
    $(this.chatInputTarget).css('padding-top','5px')
    $(this.chatInputTarget).css('padding-bottom','5px')
  }

  checkHeight(){
    if($(this.chatInputTarget).height()>=44){
      $(this.chatInputTarget).css('padding-top','1px')
      $(this.chatInputTarget).css('padding-bottom','1px')
    }else{
      $(this.chatInputTarget).css('padding-top','5px')
      $(this.chatInputTarget).css('padding-bottom','5px')
    }
  }
}
