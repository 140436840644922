import { Controller } from "stimulus"

export default class extends Controller {
  // MEMO: 会社メンバー廃止に向けて登録導線(headerButton)を一旦コメントアウト
  // Ref. https://www.notion.so/MUST-d04a0ee1cac8479ca65c2090a78af65c

  // static targets = ['service', 'member', 'headerButton']
  static targets = ['service', 'member']

  connect() {
    super.connect()

    this.loadSelectedTab()
  }

  loadSelectedTab() {
    let hash = window.location.hash;
    let anchor = $('a[href$="'+hash+'"]');

    if (anchor.length > 0){
      anchor.click();
      this.activateTab(anchor[1])
    }
  }

  displayScreen(e) {
    // this.checkHeaderButton(e.target)
    this.activateTab(e.target)

    window.location.hash = e.target.id
  }

  activateTab(e) {
    let tabs = [
      this.serviceTarget, this.memberTarget
    ]

    // this.checkHeaderButton(e)

    _.forEach(tabs, (el)=> {
      if(e.dataset.tab_id === el.dataset.tab_id) {
        el.classList.add('active')
      }else {
        el.classList.remove('active')
      }
    })
  }

  // checkHeaderButton(e) {
  //   if (this.headerButtonTarget.dataset.tab ==  e.id) {
  //     $(this.headerButtonTarget).removeClass('d-none')
  //   } else {
  //     $(this.headerButtonTarget).addClass('d-none')
  //   }
  // }
}
