import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['root', 'close']

  connect() {
    StimulusReflex.register(this)
  }

  hoverHelp(e){
    $(".mini-form").css("transform",'translate(19px, -66px)')
  }

  showScreen(el) {
    this._animateShowScreen()

    this.stimulate('MyCompany::DrawerReflex#load_drawer', el.target)
      .catch(payload => {
        window.location.href = Routes.root_path()
      })
  }

  loadDrawerSuccess() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  closeScreen(el) {
    this.animateHideScreen(el)
    $('#list-services').removeClass('no-pointer-events')
    $('.service-line').removeClass('active')

    let serviceId = this.closeTarget.dataset.service_id
    let email_notifications = []

    for(let i=0; i<3; i++) {
      let domValue = $(`#dom-${i}`).val()

      if(domValue) {
        email_notifications.push(`"${domValue}"`)
      }
    }

    $(`#service-${serviceId}`).attr('data-service_email_notifications', `[${email_notifications}]`)
  }

  animateHideScreen(e) {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')

    this.closeTarget.classList.add('d-none')
  }

  _animateShowScreen() {
    if (!this.rootTarget.classList.contains('show'))  {
      this.rootTarget.classList.add('animate')
      this.rootTarget.classList.add('show')
    }
  }
}
