
import ApplicationController from '@controllers/application_controller';
import { confirmModal } from '@lib/helpers/js_modals';
export default class extends ApplicationController {
  static targets = ['menu']

  contentCopy(e) {
    let $questionTarget = $(`#${e.target.dataset.question_dom_id}`)

    let map = {}

    $questionTarget.find('input[type=hidden]').each((idx, el) => {
      if(el.dataset.nested) {
        if(!map[el.dataset.nested]) map[el.dataset.nested] = {}
        if(!map[el.dataset.nested][el.dataset.domId]) map[el.dataset.nested][el.dataset.domId] = {}

        map[el.dataset.nested][el.dataset.domId][el.dataset.key] = el.value
      } else {
        if(el.dataset.key) map[el.dataset.key] = el.value
      }
    })

    let jsonData = JSON.stringify(map)

    this.stimulate('ContentSurveys::QuestionMenuReflex#copy_content', e.target, jsonData)
    e.preventDefault()
  }

  toggleStatus(e) {
    let $questionTarget = $(`#${e.target.dataset.question_dom_id}`)
    let $statusTarget = $questionTarget.find('*[data-key="status"]').first()

    let currentStatus = $statusTarget.val()
    let status = currentStatus == 'published' ? 'unpublished' : 'published'

    // Set text for menu
    e.target.innerText = currentStatus == 'published' ? '公開する' : '非表示にする'

    // Set value for hidden field
    $statusTarget.val(status)

    if (currentStatus == 'published') {
      $questionTarget.find('.input-content').addClass('inactive')
      e.target.classList.remove('text-danger')
      e.target.classList.add('text-primary')
    } else {
      $questionTarget.find('.input-content').removeClass('inactive')
      e.target.classList.add('text-danger')
      e.target.classList.remove('text-primary')
    }

    e.preventDefault()
  }

  delete(e) {
    let $questionTarget = $(`#${e.target.dataset.question_dom_id}`)
    let questionTitle = $questionTarget.find('*[data-key="title"]').val()

    let confirmMessage = "削除しますがよろしいですか？"

    if (questionTitle) {
      confirmMessage = `${questionTitle}を削除しますがよろしいですか？`
    }

    confirmModal({
      message: confirmMessage,
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        $questionTarget.find('*[data-key="destroy"]').val(true)
        $questionTarget.hide()
        this.drawerController.checkValidForm()
      }
    })
    e.preventDefault()
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }
}