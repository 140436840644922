// event_videoからの移管ファイル

// Import stimulus libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import { el } from 'redom';
import { Constant } from '@video_lib/constant.js.erb';

export default class extends SimpleAjaxForm {
  static values = { emServiceId: String, selectedCount: Number };
  static targets = ['selectTag', 'docOption'];

  connect() {
    super.connect();
    $(this.selectTagTarget).selectpicker();
  }

  reload(emDocuments) {
    // Clear current options
    $(this.docOptionTargets).remove();

    // Generate then append new options
    let optionDoms = emDocuments.map(({ id, title }) =>
      this._optionDom({ id, title })
    );
    $(this.selectTagTarget).append(optionDoms);

    // Reset selected count
    this.selectedCountValue = 0;

    // Refresh
    this._refresh();
  }

  populateServiceData(emService) {
    // Clear all current options
    $(this.docOptionTargets).remove();

    this.selectedCountValue = emService.documents.length;

    this._refresh();
  }

  pickDocument(evt) {
    let option = this.docOptionTargets.find(
      (opt) => opt.dataset.emDocumentId === evt.target.value
    );
    let id = option.dataset.emDocumentId;
    let title = option.dataset.emDocumentTitle;

    this._docsViewCtrlr.addItem({ id, title });
    option.remove();
    this.selectedCountValue += 1;
    this._refresh();
  }

  manuallyPickDocument(emDocumentId) {
    let option = this.docOptionTargets.find(
      (opt) => opt.dataset.emDocumentId === emDocumentId.toString()
    );
    let id = option.dataset.emDocumentId;
    let title = option.dataset.emDocumentTitle;

    this._docsViewCtrlr.addItem({ id, title });
    option.remove();
    this.selectedCountValue += 1;
    this._refresh();
  }

  addOption({ id, title }) {
    let dom = this._optionDom({ id, title });
    $(this.selectTagTarget).append(dom);

    this.selectedCountValue -= 1;
    this._refresh();
  }

  // PRIVATE METHODS
  _optionDom({ id, title }) {
    let attrs = {
      'data-em-document-id': id,
      'data-em-document-title': title,
      value: id,
    };
    attrs[`data-${this.context.identifier}-target`] = 'docOption';

    return el('option', title, attrs);
  }

  _refresh() {
    let shouldDisable =
      this.selectedCountValue >=
      Constant.settings.video.video_services.max_docs;
    this.selectTagTarget.disabled = shouldDisable;
    $(this.selectTagTarget).selectpicker('refresh');
  }

  get _docsViewCtrlr() {
    return this.application.controllers.find((ctrlr) => {
      return (
        ctrlr.context.identifier ===
          'shared--videos--form--service-documents-select-view' &&
        ctrlr.emServiceIdValue === this.emServiceIdValue
      );
    });
  }
}
