// Import webpacker libraries
import { Controller } from 'stimulus'

// Import custom libraries
import { setCookie } from '@lib/helpers/set_cookie_helper';
import { stringToHTML } from '@lib/helpers/view_helpers';

export default class extends Controller {
  static targets = ['tabList', 'chatTab', 'messageList', 'chatPreview']

  connect(){
    super.connect()
    $('.title__lock-icon').tooltip();

    if (!_.isUndefined(this.activeTab)) {
      this.showPreviewMessage(this.activeTab.dataset.tab_id)
    }
  }

  switchTab(e) {
    let tabId = e.target.dataset.tab_id

    setCookie('_chat_tab', tabId)
    // Active the clicked tab
    this.chatTabTargets.forEach((chatTab)=> {
      if (chatTab.dataset.tab_id == tabId) {
        chatTab.classList.add('active')
      } else {
        chatTab.classList.remove('active')
      }
    })

    this.showPreviewMessage(tabId)
  }

  reloadTabChat(e) {
    e.detail.forEach((data) => {
      let tabList = Array.from(this.tabListTarget.children)
      let tab = tabList.find((e) => e.dataset.tab_id == data.id)
      tab.firstElementChild.innerHTML = data.name
    })
  }

  showPreviewMessage(tabId) {
    // Show related message list and hide other message lists
    this.messageListTargets.forEach((msgList)=> {
      if (msgList.dataset.tab_id == tabId) {

        msgList.classList.remove('d-none')
      } else {
        msgList.classList.add('d-none')
      }
    })
  }

  //
  // Reload preview message and badge when got new notification
  //

  reloadPreviewChat(e) {
    let _this = this
    let data = e.detail
    let chatRoomId = data.msgPreview.chatRoom.id

    this.messageListTargets.forEach((list) => {
      let chatRoom = Array.from(list.children).find((e) => e.dataset.chat_room_id == chatRoomId)
      if (!_.isUndefined(chatRoom)) { // Reload existing preview message
        _this.reloadExistingPreview(list, chatRoom, data)
      } else if (_.indexOf(data.msgPreview.tabs, list.dataset.tab_id) >= 0) {
        // Append new message to the top
        list.insertBefore(stringToHTML(e.detail.msgPreviewPartial)[0], list.children[0])
      }
    })
  }

  updateChatTabs(tabs) {
    this.chatTabTargets.forEach((tab) => {
      let tabText = tab.innerText.replace(/ \(\d+\)/, "") // Strip the last part with number
      let unseenCount = tabs[_.camelCase(tab.dataset.tab_id)].unseenCount
      if (unseenCount > 0) tabText = `${tabText} (${unseenCount})`

      tab.innerText = tabText
    })
  }

  updateChatRoomPreview(previewData) {
    let chatRoomPreview = previewData.chatRoomPreview
    let tabs = previewData.tabs
    let chatRoomId = previewData.chatRoomId
    let unseenCount = previewData.unseenCount

    let oldTabs = this.messageListTargets.filter((list) => $(list).find(`#chat_room_${chatRoomId}`).length).map((list) => list.dataset.tab_id)

    if (oldTabs.sort().join(',') != tabs.sort().join(',')) {
      // Exist in old tabs but not in new tabs
      let toDecrease = oldTabs.filter((tabId) => !tabs.includes(tabId))

      // Decrease count for old tabs
      toDecrease.forEach(tabId => this._decreaseChatTabUnseenCount(tabId, unseenCount))
    }

    // Increase count for new tab
    tabs.forEach(tabId => this._increaseChatTabUnseenCount(tabId))

    $(this.chatPreviewTargets.filter(preview => preview.dataset.chat_room_id == chatRoomId.toString())).remove()

    this.messageListTargets.filter(wrapper => tabs.includes(wrapper.dataset.tab_id)).forEach((wrapper) => {
      $(wrapper).prepend($(chatRoomPreview))
    })
  }

  _addNewOrUpdatePreviewMessage(messageWrap, preview, chatRoomId) {
    let $msgPreview = $(messageWrap).find(`#chat_room_${chatRoomId}`)

    if (!$msgPreview.length) {
      // Prepend to the list
      $(messageWrap).prepend($(preview))
    }
    else {
      // Move to first of the list, then update
      $msgPreview.prependTo($(messageWrap))
      $msgPreview.replaceWith($(preview))
    }
  }

  _increaseChatTabUnseenCount(tabId) {
    let chatTab = this.chatTabTargets.find(chatTab => chatTab.dataset.tab_id == tabId)
    if (!chatTab) return

    let currentCount = chatTab.innerText.match(/\d+/)
    currentCount = !!currentCount ? currentCount[0] : 0
    currentCount = parseInt(currentCount)
    let newCount = currentCount + 1

    let tabText = chatTab.innerText.replace(/ \(\d+\)/, "") // Strip the last part with number

    if (newCount > 0) tabText = `${tabText} (${newCount})`

    chatTab.innerText = tabText
  }

  _decreaseChatTabUnseenCount(tabId, unseenCount) {
    let chatTab = this.chatTabTargets.find(chatTab => chatTab.dataset.tab_id == tabId)
    if (!chatTab) return

    let currentCount = chatTab.innerText.match(/\d+/)
    currentCount = !!currentCount ? currentCount[0] : 0
    currentCount = parseInt(currentCount)
    let newCount = currentCount - unseenCount

    let tabText = chatTab.innerText.replace(/ \(\d+\)/, "") // Strip the last part with number

    if (newCount > 0) tabText = `${tabText} (${newCount})`

    chatTab.innerText = tabText
  }

  reloadExistingPreview(list, room, data) {
    if (data.reload) {
      let dom = stringToHTML(data.msgPreviewPartial)
      room.innerHTML = dom[0].innerHTML
    } else {
      let bodyElements = room.firstElementChild.children
      this.replaceBadgePreview(bodyElements, data)
      this.replaceMsgPreview(bodyElements, data)
      this.replaceTimestampPreview(bodyElements, data)
    }
    // Move new message to the top
    list.insertBefore(room, list.children[0])
  }

  replaceBadgePreview(bodyElements, data) {
    let thumbnailDom = this.childHasClass(bodyElements, 'message-preview__thumbnail')
    let badgeDom = this.childHasClass(thumbnailDom.children, 'thumbnail__badge')

    if (!_.isUndefined(badgeDom)) {
      badgeDom.innerHTML = data.msgPreview.unseenCount
    } else if (_.gt(stringToHTML(data.unseenBadge).length, 0)){
      thumbnailDom.append(stringToHTML(data.unseenBadge)[0])
    }
  }

  replaceMsgPreview(bodyElements, data) {
    let contentDom = this.childHasClass(bodyElements, 'message-preview__content')
    let msgDom = this.childHasClass(contentDom.children, 'content__message')
    msgDom.innerHTML = data.msgPreview.lastMessage
  }

  replaceTimestampPreview(bodyElements, data) {
    let timestampDom = this.childHasClass(bodyElements, 'message-preview__timestamp')
    timestampDom.firstElementChild.innerHTML = data.msgPreview.timeLabel
  }

  childHasClass(children, className) {
    return Array.from(children).find((e) => $(e).hasClass(className))
  }

  get activeTab() {
    return this.chatTabTargets.find((el) => $(el).hasClass('active'))
  }

  get activeTabScreen() {
    return this.messageListTargets.find((el) => !$(el).hasClass('d-none'))
  }
}
