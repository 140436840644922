import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '@channels/consumer'
import * as ActionCable from '@rails/actioncable'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
// jsファイルで、consumerを使用するために、以下を追加
application.consumer = consumer
// slimファイルでconsumer使用するために、以下を追加
window.consumer = consumer
StimulusReflex.initialize(application, { isolate: true })

StimulusReflex.debug       = (process.env.RAILS_ENV === 'development' || process.env.RAILS_ENV === 'staging')
ActionCable.logger.enabled = (process.env.RAILS_ENV === 'development' || process.env.RAILS_ENV === 'staging')
