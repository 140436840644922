import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.too_long', { count: 50 })}`
      }
    }
  }

  connect() {
    super.connect()
  }

  failedSubmit(evt) {
    let [response, _status, _xhr] = evt.detail;

    let errors = response.errors

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        this.displayError(el, errors[attribute])
      }
    })
  }

  successSubmit(evt) {
    location.href = evt.detail[0].data.redirect_url
  }

}
