
import ApplicationController from '@controllers/application_controller';
import { stringToHTML } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = ['wrapper', 'contentWrapper']


  connect() {
    super.connect()
  }

  appendContent(e) {
    let msgHTML = e.detail;
    let msgDOM = stringToHTML(msgHTML)

    $(this.contentWrapperTarget).html(msgDOM[0])

    e.preventDefault()
  }

  openPopupMenu(e, data = {}) {
    let jsonData = JSON.stringify(data)

    this.stimulate('ContentSurveys::PopupMenuReflex#render_content', e.target, jsonData)

    this.wrapperTarget.classList.add('animate')
    this.wrapperTarget.classList.add('show')
  }

  closeOptionScreen() {
    this.wrapperTarget.classList.remove('animate')
    this.wrapperTarget.classList.remove('show')

    this.inputsController.removeAllActive()
  }

  get inputsController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--forms--inputs')
  }
}
