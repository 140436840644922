import _ from 'lodash';
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form'
import I18n from '@video_lib/i18n-js/init.js.erb';
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'
import { letterRegex, numberRegex } from "@lib/helpers/validation_helper"

export default class extends SimpleAjaxForm {
  static targets = ['editPasswordForm', 'form', 'cancel', 'required', 'submitBtn']
  static validators = { validPassword: { attributes: ['password'] } }

  static rules = {
    current_password: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'blank') }`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'too_short', { count: 8 }) }`,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'too_long', { count: 32 }) }`
      }
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'blank') }`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'too_short', { count: 8 }) }`,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'too_long', { count: 32 }) }`
      }
    },
    password_confirmation: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'blank') }`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'too_short', { count: 8 }) }`,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'password', 'too_long', { count: 32 }) }`
      }
    }
  }

  connect() {
    super.connect()
    this.allowSubmit()
  }

  validPassword({attr, value}) {
    if(!value.match(letterRegex)) {
      this.errors.add(attr, I18n.t('activerecord.errors.models.user.attributes.password.password_only_letters_validate'))
    } else if(!value.match(numberRegex)) {
      this.errors.add(attr, I18n.t('activerecord.errors.models.user.attributes.password.password_only_number_validate'))
    }
  }

  validForm() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed
  }

  closeEditPasswordForm() {
    this.formTarget.classList.add('d-none')
    document.querySelector('[data-video-profile-form-target="profilePassword"]').classList.remove('d-none')
    this.clearForm()
    this.allowSubmit()
  }

  successSubmit(e) {
    window.location.href = Routes.new_user_session_path()
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.validForm()

    if (isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }

    // Confirm password must same as new password
    if (this.attributes.get('password_confirmation').el != el) return

    if (el.value != this.attributes.get('password').el.value) {
      this.displayError(el, [ActiveRecordErrorMessageHelper('user', 'confirm_password', 'not_match')])
      this.allowSubmit(false)
    }
  }

  get profileFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'video-profile-form')
  }
}