// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['form', 'submitBtn', 'required']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.blank')}`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_short', { count: 8 })}`,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_long', { count: 32 })}`
      }
    },
    confirm_password: (value) => {
      if (_.isEmpty(value)) return;

      return {
        length: {
          minimum: 8,
          maximum: 32,
          tooShort: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_short', { count: 8 })}`,
          tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_long', { count: 32 })}`
        }
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////
  connect() {
    super.connect()
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
      el.classList.add("error")
      this.allowSubmit(false)
    } else {
      this.clearError(el)

      if (this.attributes.get('password').el == el) {
        this.attributes.get('confirm_password').el.value = ''
        this.clearError(this.attributes.get('confirm_password').el)
      }

      this.allowSubmit(true)
    }

    // Confirm password must same as new password
    if (this.attributes.get('confirm_password').el != el) return
    if (el.value != this.attributes.get('password').el.value) {
      this.displayError(el, [I18n.t('activerecord.errors.models.attendee.attributes.confirm_password.not_match')])
      this.allowSubmit(false)
    }
  }

  ////
  // NEW METHODS
  ////

  // Input all required field or not
  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.inputAllRequireFields();

    if(isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

}
