// Import webpack libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form'
import Rails from "@rails/ujs";
import { serialize } from 'object-to-formdata';

export default class extends SimpleAjaxForm {
  static values = { id: String, needSurvey: Boolean, needLogin: Boolean, emEventId: String }

  static targets = [
    'containerDownload', 'checkboxDownload', 'btnDownload'
  ]

  handleDownloadRequest(e) {
    // 未ログインの場合、ログインモーダルを表示
    if (this.needLoginValue) return this._showModalLogIn();

    // 資料ダウンロード
    return this.startDownload();
  }

  selectContentDocument(evt) {
    this._toggleOverlay(evt)
    this._toggleButtonDownload()
  }

  startDownload(e) {
    let _this = this

    this.trackDocumentDownload()

    $.each($(_this._containerDownloads), function (_index, item) {
      let container = $(item)

      let checkbox = container.find('.js-checkbox-download')
      let link = container.find('.js-link-download')
      if (checkbox.prop('checked')) {
        link[0].click()
      }
    })
  }

  trackDocumentDownload() {
    let dataset = this.btnDownloadTarget.dataset

    let data = {
      type:       dataset.trackingType,
      service_id: dataset.serviceId,
      video_id:   dataset.videoId,
      page:       dataset.page
    }

    Rails.ajax({
      url: Routes.trackings_path(),
      type: 'POST',
      data: serialize({ data: data }),
      success: (response) => {
      },
      error: () => {
      }
    })
  }

  ////
  // PRIVATE METHODS
  ////

  _showModalLogIn() {
    this._modalLogin.modal('show')
  }

  _toggleOverlay(evt) {
    let contentDocument = $(evt.target).closest('.js-content-document')
    contentDocument.toggleClass('document-item--overlay')
  }

  _toggleButtonDownload() {
    let checkedBoxes = this._checkedBoxesDownload

    if (checkedBoxes.length) {
      $(this.btnDownloadTarget).removeAttr('disabled')
    } else {
      $(this.btnDownloadTarget).attr('disabled', 'disabled')
    }
  }

  get _containerDownloads() {
    return $(this.containerDownloadTargets)
  }

  get _checkedBoxesDownload() {
    return $(this.element).find('.js-checkbox-download:checked')
  }

  get _modalLogin() {
    return $('.js-modal-login')
  }
}
