// event_videoからの移管ファイル

import ApplicationController from "../application_controller";
import CableReady from 'cable_ready';

export default class extends ApplicationController {
  static targets = ['export']

  connect() {
    super.connect()

    this.connection = setTimeout(() => {
      console.log('connecting...')
      this.subscribeChannel()
    }, 200)
  }

  requestExport(e) {
    this.exportTarget.classList.add('disabled')

    this.stimulate('MyCompany::ActivityReflex#request_export', e.target)
  }

  readyDownload(e) {
    if (e.detail.clientId != this.exportTarget.dataset.client_id) return

    this.exportTarget.classList.remove('disabled')

    if (e.detail.fileUrl == '') return
    let link = document.createElement('a')
    link.href = e.detail.fileUrl

    link.click()
  }

  subscribeChannel() {
    this.downloadChannel = this.application.consumer.subscriptions.create({
      channel: 'DownloadChannel'
    }, {
      initialized: () => {
        // console.log('subscription initialized: ', chatRoomId)
      },
      connected: () => {
        console.log('subscription connected')
        clearTimeout(this.connection)
      },
      disconnected: () => {
        // console.log('subscription disconnected: ', chatRoomId)
      },
      rejected: () => {
        // console.log('subscription rejected: ', chatRoomId)
      },
      received: (data) => {
        // console.log('subscription received: ', data)
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }
}
