// Import libraries from webpacker libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['form', 'required', 'submitBtn']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.blank')}`
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////
  connect() {
    super.connect()
  }

  validForm() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.validForm()

    if (isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    document.location.href = response.data.redirect_path
  }
}
