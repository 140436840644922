// Import libraries from webpacker libraries
import { Controller } from 'stimulus'
import _ from 'lodash';
import { serialize } from 'object-to-formdata';

// Import custom libraries
import I18n from '../../../lib/i18n-js/init.js.erb';
import { selectFirstMessage } from '../../../lib/helpers/view_helpers';
import { confirmModal } from '../../../lib/helpers/js_modals';

export default class extends Controller {
  static targets = ['pickUpFlagChk']

  connect() {
    super.connect()

    this._initSortableService()
    this.checkReachPickedUpLimit()

    $('#service-list .table-row').on('click', function(e) {
      if(e.target.classList.contains('slider') || e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') return

      $(this).find('.js-hidden-anchor')[0].click()
    })
  }

  // Open more menu
  clickMoreMenu(e) {
    let element = $(e.currentTarget).closest('.table-td-aside')

    $(element).css('z-index', 1)
    $(element).on('hide.bs.dropdown', function() {
      $(element).css('z-index', '0')
    })
  }

  _initSortableService() {
    var widthHelper = function(e, ui) {
      ui.children().each(function() {
        $(this).width($(this).width())
      })
      return ui
    }

    $('#service-list').sortable({
      animation: 150,
      handle: ".service-indicator",
      axis: 'y',
      helper: widthHelper,
      update: function(e, ui) {
        Rails.ajax({
          url: $(this).data('url'),
          type: 'PATCH',
          data: $(this).sortable('serialize')
        })
      },
      stop: function(e, ui) {
        // Remove left, top generated by sortable, prevent flaky drag
        ui.item.removeAttr("style")
      }
    })
  }

  // DELETE EVENT_SERVICE
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.event_service.confirm_modals.delete_event_service.title'),
      message: I18n.t('organizer.event_service.confirm_modals.delete_event_service.content'),
      labels: {
        ok_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }

  selectPickUpFlagCheckBox(eventServiceId) {
    let checkBox = this.pickUpFlagChkTargets.find((checkBox) =>
      checkBox.dataset.eventServiceId === eventServiceId
    )
    return checkBox;
  }

  checkReachPickedUpLimit() {
    let toggleStatuses =  this.pickUpFlagChkTargets
    let toggleStatusesChecked = []
    let toggleStatusesUnChecked = []

    for (let i = 0; i < toggleStatuses.length; i++) {
      if(toggleStatuses[i].checked) {
        toggleStatusesChecked.push(toggleStatuses[i])
      }
    }

    for (let i = 0; i < toggleStatuses.length; i++) {
      if(!toggleStatuses[i].checked) {
        toggleStatusesUnChecked.push(toggleStatuses[i])
      }
    }

    if(toggleStatusesChecked.length > 2) {
      toggleStatusesUnChecked.forEach((toggleStatusUnchecked) => {
        toggleStatusUnchecked.setAttribute('disabled', '')
      })
    } else {
      toggleStatuses.forEach((toggleStatus) => {
        toggleStatus.removeAttribute('disabled')
      })
    }
  }

  switchPickUpFlag(e) {
    let eventServiceId = e.currentTarget.dataset.event_service_id;

    let $matchedCheckBox = this.selectPickUpFlagCheckBox(eventServiceId);
    let newStatus = $matchedCheckBox.checked;
    let submitData = { event_service: { picked_up_flag: newStatus } };

    this.checkReachPickedUpLimit()

    Rails.ajax({
      url: Routes.organizer_event_service_path({ id: eventServiceId }),
      type: 'PATCH',
      data: serialize(submitData),
      error: (e) => {
        let errors = e.errors;
        // Restore the checkbox value
        $matchedCheckBox.checked = !newStatus;
        // Show first error message
        let error_message = selectFirstMessage(errors);
        alertify.error(error_message);
      },
      success: (e) => {
        // Notify success message
        alertify.success(I18n.t('messages.notify.general.edit.success'))
      }
    })
  }
}
