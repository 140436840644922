// Import libraries from webpacker libraries
import _ from 'lodash';
import Rails from '@rails/ujs';
import { serialize } from 'object-to-formdata';

// Import custom libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import {
  generateHTMLOptions,
  generateDOMOptions,
  showLoaderFor,
} from '@lib/helpers/view_helpers';
import { nextMoment } from '@lib/helpers/time_helpers';
import { confirmModal } from '@lib/helpers/js_modals';
import { fullpageSpinner } from '@lib/fullpage_spinner';
import UploadAndPreviewImage from '@lib/helpers/upload_and_preview_logo_helper';

export default class extends SimpleAjaxForm {
  static values = { maxSpeakers: Number, maxServices: Number };

  static targets = [
    'form',
    'serviceSelect',
    'dateSelect',
    'startTimeSelect',
    'endTimeSelect',
    'stageSelect',
    'serviceList',
    'serviceItem',
    'removeServiceBtn',
    'speakerList',
    'statusBtn',
    'addSpeakerBtn',
    'speakerItem',
    'imagePreview',
    'imageInput',
    'errorLogo',
  ];

  ////
  // VALIDATION RULES
  ////
  static rules = {
    title: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(
          'activerecord.errors.models.event_content.attributes.title.blank'
        )}`,
      },
      length: {
        maximum: 60,
        tooLong: `^${I18n.t(
          'activerecord.errors.models.event_content.attributes.title.too_long',
          { count: 60 }
        )}`,
      },
    },
    subtitle: {
      length: {
        maximum: 60,
        tooLong: `^${I18n.t(
          'activerecord.errors.models.event_content.attributes.subtitle.too_long',
          { count: 60 }
        )}`,
      },
    },
    start_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(
          'activerecord.errors.models.event_content.attributes.start_time.blank'
        )}`,
      },
    },
    end_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(
          'activerecord.errors.models.event_content.attributes.end_time.blank'
        )}`,
      },
    },
    content: {
      length: {
        maximum: 1000,
        tooLong: `^${I18n.t(
          'activerecord.errors.models.event_content.attributes.content.too_long',
          { count: 1000 }
        )}`,
      },
    },
  };

  ////
  // OVERRIDED METHODS
  ////

  connect() {
    super.connect();

    StimulusReflex.register(this);
    this.initSelectPicker();
    this._initSortableService();
    this._initSortableSpeaker();
  }

  initSelectPicker() {
    $(this.serviceSelectTarget).selectpicker();
    $(this.dateSelectTarget).selectpicker();
    $(this.startTimeSelectTarget).selectpicker();
    $(this.endTimeSelectTarget).selectpicker();
    $(this.stageSelectTarget).selectpicker();
  }

  // Element to show error for an input element
  errorMessageEl(el) {
    let errorNode = null;
    if (el.nodeName == 'SELECT') {
      if (el == this.startTimeSelectTarget || el == this.endTimeSelectTarget) {
        errorNode = el.parentNode.parentNode.parentNode.parentNode
          .querySelector('.summary-errror-line')
          .querySelector('.error-message');
      } else {
        errorNode = el.parentNode.parentNode.querySelector('.error-message');
      }
    } else if (el == this.logoInputTarget) {
      return this.errorLogoTarget;
    } else {
      errorNode = el.nextElementSibling;
    }
    return errorNode;
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr));

      // submitボタンをdisabledにする
      this.disabledSubmitButton(true);
    } else {
      this.clearError(el);

      if (this.allErrorCount() == 0) {
        // submitボタンのdisabledを解除
        this.disabledSubmitButton(false);
      }
    }
  }

  afterValidateAll(e) {
    // Validate all children controllers
    let speakerControllers = this.speakerControllers;
    let anyError = false;

    speakerControllers.forEach((speakerController) => {
      speakerController.validateAllManually();

      if (speakerController.errors.hasAny()) {
        anyError = true;
      }
    });

    // Don't submit form if exist any errors
    if (anyError) {
      e.preventDefault();
    }
  }
  ////
  // NEW METHODS
  ////

  // Upload the company's logo
  uploadEventContentImage(el) {
    let message = UploadAndPreviewImage(el, this.imagePreviewTarget, 5);
    if (message) {
      this.errorLogoTarget.innerHTML = message;
    } else {
      this.clearError(el.target);
    }
  }

  // PRIVATE METHODS
  _initSortableSpeaker() {
    $(this.speakerListTarget).sortable({
      animation: 150,
      axis: 'y',
      stop: function (e, ui) {
        let items = e.target.querySelectorAll('.event-speaker-item');

        items.forEach((el, idx) => {
          el.querySelector('.content_speaker_position_idx').value = idx + 1;
        });
      },
    });
  }

  _initSortableService() {
    $(this.serviceListTarget).sortable({
      animation: 150,
      axis: 'y',
      stop: function (e, ui) {
        let items = e.target.querySelectorAll('.event_service_item');

        items.forEach((el, idx) => {
          el.querySelector('.content_service_position').value = idx + 1;
        });
      },
    });
  }

  // Define messages to notify
  get messages() {
    let messages = {
      success: I18n.t('messages.notify.general.success'),
    };

    return messages;
  }

  // Data of this even content
  get eventContent() {
    let eventContentData = {};
    let formData = this.formTarget.dataset;

    eventContentData['eventSlug'] = formData.eventSlug;
    eventContentData['id'] = formData.eventContentId;

    return eventContentData;
  }

  // Event Content displayed outside timetable
  get displayedEventContent() {
    return document.getElementById(`event_content_${this.eventContent.id}`);
  }

  // List of speaker controllers
  get speakerControllers() {
    return this.application.controllers.filter((controller) => {
      return (
        controller.context.identifier === 'organizer--content-speakers--item'
      );
    });
  }

  reloadTimeComponent(e) {
    this.stimulate(
      'Organizers::Events::EventContentReflex#load_event_content_form_time',
      e.target.value
    );
  }

  // Change date will effect stages list
  changeDate(e) {
    let eventSlug = this.formTarget.dataset.eventSlug;
    let eventDateId = e.target.value;
    let _this = this;

    Rails.ajax({
      url: Routes.organizer_event_date_path(eventSlug, eventDateId),
      type: 'GET',
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = _this.pickFirstMessage(errors);
        alertify.error(error_message);
      },
      success: (e) => {
        let event_stages = e.event_stages;
        let stageOptions = [];
        // Generate event stages as options of a select
        event_stages.forEach(({ id, name }) => {
          stageOptions.push({ value: id, label: name });
        });
        // Update options list and refresh boostrap select
        _this.stageSelectTarget.innerHTML = generateHTMLOptions(stageOptions);
        $(this.stageSelectTarget).selectpicker('refresh');
      },
    });
  }

  // Change start time will effect end time list
  changeStartTime(e) {
    let newStartTime = e.target.value;
    let stageEndTime = e.target.dataset.endTime;

    const oldStartTime = e.target.dataset.defaultStartTime;
    const eventContentEndTime = document.querySelector(
      '#event_content_end_time'
    );
    const oldEndTime = eventContentEndTime.selectedOptions[0].value;

    const oldStartDateTime = new Date(`2024/01/01 ${oldStartTime}`);
    const oldEndDateTime = new Date(`2024/01/01 ${oldEndTime}`);
    const timeDifference = oldEndDateTime - oldStartDateTime;

    const newStartDateTime = new Date(`2024/01/01 ${newStartTime}`);
    const newEndDateTime = new Date(
      newStartDateTime.getTime() + timeDifference
    );
    const newEndTime = newEndDateTime.toTimeString().substring(0, 5);

    // Update end time options (increment is 5 minutes)
    let moment = nextMoment(newStartTime, 0).minutes;
    let stageEndTimeMins = nextMoment(stageEndTime, 0).minutes; // 23 * 60  (~ 19:00)
    let timeOptions = [];

    for (; moment <= stageEndTimeMins; moment += 5) {
      let hmTime = nextMoment(moment, 0).textTime;
      timeOptions.push({ value: hmTime, label: hmTime });
    }
    this.endTimeSelectTarget.innerHTML = generateHTMLOptions(timeOptions);

    // Select new end time
    if (
      [...this.endTimeSelectTarget.options].some(
        (option) => option.value === newEndTime
      )
    ) {
      this.endTimeSelectTarget.value = newEndTime;
    } else {
      // 新しい終了時刻が選択肢にない場合、最も近い時間を選択
      this.endTimeSelectTarget.value = [
        ...this.endTimeSelectTarget.options,
      ].reduce((prev, curr) => {
        return Math.abs(new Date(`2024/01/01 ${curr.value}`) - newEndDateTime) <
          Math.abs(new Date(`2024/01/01 ${prev.value}`) - newEndDateTime)
          ? curr
          : prev;
      }).value;
    }
    // Refresh boostrap select
    $(this.endTimeSelectTarget).selectpicker('refresh');

    e.target.setAttribute('data-default-start-time', newStartTime);
    e.target.setAttribute('data-default-end-time', newEndTime);
  }

  // 紹介サービス変更時の処理
  changeService(e) {
    let _this = this;
    if (_this.maxServicesReached()) return;

    let serviceId = e.target.value;
    let existedService = null;

    // Check if service item DOM is existing in service list or not
    // If already existed, show it
    _this.removeServiceBtnTargets.forEach((serviceItem) => {
      let serviceData = serviceItem.dataset;

      if (serviceData.id == serviceId) {
        existedService = serviceItem;
      }
    });

    if (existedService) {
      _this.processServiceItem(existedService, 'show');

      // submitボタンのdisabledを解除
      _this.disabledSubmitButton(false);

      // 他の項目のバリデーションチェックをするためvalidateAllを発火させる
      _this.dispatchValidateAll();

      if (_this.maxServicesReached()) {
        _this.disableServicesSelect();
      }

      return;
    }

    // If not, get a service item
    Rails.ajax({
      // PATH => organizer/content_services#content_service_item
      url: Routes.content_service_item_organizer_content_services_path({
        service_id: serviceId,
      }),
      type: 'GET',
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = _this.pickFirstMessage(errors);
        alertify.error(error_message);
      },
      success: (e) => {
        // レスポンス =>「render partial: 'shared/timetables/event_contents/content_service_item'」
        let serviceItemHTML = e.body.innerHTML;

        // 選択されたサービスを追加
        _this.serviceListTarget.innerHTML += serviceItemHTML;

        // submitボタンのdisabledを解除
        _this.disabledSubmitButton(false);

        // 他の項目のバリデーションチェックをするためvalidateAllを発火させる
        _this.dispatchValidateAll();

        // Remove selected service option and refresh boostrap selectpicker
        _this.serviceSelectTarget.remove(
          _this.serviceSelectTarget.selectedIndex
        );

        $(_this.serviceSelectTarget).selectpicker('refresh');

        // サービス選択上限判定
        if (_this.maxServicesReached()) {
          _this.disableServicesSelect();
        }
      },
    });
  }

  // 紹介サービス：削除
  removeService(e) {
    this.processServiceItem(e.target, 'remove');

    // Re-add service option to service select
    // Note: using DOM options to avoid rendering bug of boostrap select (add empty option when refresh)
    let service = e.target.dataset;
    let serviceOption = [];

    serviceOption.push({ value: service.id, label: service.name });

    let domOption = generateDOMOptions(serviceOption)[0];

    this.serviceSelectTarget.appendChild(domOption);
    $(this.serviceSelectTarget).selectpicker('refresh');

    if (
      !this.maxServicesReached() &&
      $(this.serviceSelectTarget).is(':disabled')
    ) {
      this.enableServicesSelect();
    }

    const servicesCount = this.serviceItemTargets.filter(
      (item) => !item.classList.contains('d-none')
    ).length;

    // submitボタンのdisabled制御
    if (servicesCount == 0) {
      this.disabledSubmitButton(true);
    } else {
      this.disabledSubmitButton(false);

      // 他の項目のバリデーションチェックをするためvalidateAllを発火させる
      this.dispatchValidateAll();
    }
  }

  // show/remove Service Item
  processServiceItem($detectElement, option) {
    let $serviceItem = $detectElement.parentNode;
    let $serviceIdFld = $serviceItem.querySelector('.content_service_id');
    let $destroyFld = $serviceItem.querySelector('.content_service_destroy');
    let isRegisterService = false;

    if (!_.isEmpty($serviceIdFld.value)) {
      isRegisterService = true;
    }

    if (option == 'show') {
      $serviceItem.classList.remove('d-none');
      $destroyFld.value = false;
    } else {
      // Remove service
      // Case 1: This service registered with this event content -> Hidden DOM and set field _destroy = true
      // Case 2: This service doesn't register with this event content -> Remove DOM
      if (isRegisterService) {
        $serviceItem.classList.add('d-none');
        $destroyFld.value = true;
      } else {
        $serviceItem.remove();
      }
    }
  }

  addSpeaker() {
    let _this = this;
    if (_this.maxSpeakersReached()) return;

    Rails.ajax({
      url: Routes.content_speaker_item_organizer_content_speakers_path(),
      type: 'GET',
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = _this.pickFirstMessage(errors);
        alertify.error(error_message);
      },
      success: (e) => {
        let speakerItemDOM = e.body;
        // Add the new speaker form
        _this.speakerListTarget.append(speakerItemDOM);

        if (_this.maxSpeakersReached()) {
          $(_this.addSpeakerBtnTarget)
            .removeClass('status-enabled')
            .addClass('status-disabled');
        }
      },
    });
  }

  // Remove speaker
  confirmRemoveSpeaker(e) {
    let _this = this;

    confirmModal({
      title: I18n.t(
        'organizer.modals.confirm_modals.delete_content_speaker.title'
      ),
      message: I18n.t(
        'organizer.modals.confirm_modals.delete_content_speaker.content'
      ),
      labels: {
        ok_btn: I18n.t(
          'organizer.modals.confirm_modals.buttons.confirm_button'
        ),
        cancel_btn: I18n.t(
          'organizer.modals.confirm_modals.buttons.cancel_button'
        ),
      },
      onOk: () => {
        _this.removeSpeaker(e);
      },
    });
  }

  removeSpeaker(e) {
    let $detectElement = e.target;
    let $speakerItem = $detectElement.parentNode;
    let $speakerIdFld = $speakerItem.querySelector('.content_speaker_id');
    let $destroyFld = $speakerItem.querySelector('.content_speaker_destroy');
    let isRegisterSpeaker = false;

    if (!_.isEmpty($speakerIdFld.value)) {
      isRegisterSpeaker = true;
    }

    // Remove speaker
    // Case 1: This speaker registered with this event content -> Hidden DOM and set field _destroy = true
    // Case 2: This speaker doesn't register with this event content -> Remove DOM
    if (isRegisterSpeaker) {
      $speakerItem.classList.add('d-none');
      $destroyFld.value = true;
    } else {
      $speakerItem.remove();
    }

    if (
      !this.maxSpeakersReached() &&
      $(this.addSpeakerBtnTarget).hasClass('status-disabled')
    ) {
      $(this.addSpeakerBtnTarget)
        .removeClass('status-disabled')
        .addClass('status-enabled');
    }
  }

  // Public/Unpublic event content status
  switchStatus(e) {
    let _this = this;
    let currentStatus = e.target.value;
    // Only allow update status of an existed event content
    if (_.isEmpty(currentStatus)) return;

    // Show confirm modal if switch from published to unpublished
    if (currentStatus == 'published') {
      confirmModal({
        title: I18n.t('organizer.modals.confirm_modals.unpublish_item.title'),
        message: I18n.t(
          'organizer.modals.confirm_modals.unpublish_item.content'
        ),
        labels: {
          ok_btn: I18n.t(
            'organizer.modals.confirm_modals.buttons.confirm_button'
          ),
          cancel_btn: I18n.t(
            'organizer.modals.confirm_modals.buttons.cancel_button'
          ),
        },
        onOk: () => {
          _this.updateStatus('unpublished', _this.toggleStatusBtn);
        },
      });
    } else {
      _this.updateStatus('published', _this.toggleStatusBtn);
    }
  }

  // Send request to update event content status
  updateStatus(newStatus, callback) {
    let _this = this;
    let submitData = {
      event_content: { status: newStatus },
      status_only: true,
    };

    Rails.ajax({
      url: Routes.organizer_event_content_path({
        id: this.eventContent.id,
        eventSlug: this.eventContent.eventSlug,
      }),
      type: 'PATCH',
      data: serialize(submitData),
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = _this.pickFirstMessage(errors);
        alertify.error(error_message);
      },
      success: (e) => {
        callback(_this, newStatus);
      },
    });
  }

  // Update value and text of status button and UI of the content in timetable outside
  toggleStatusBtn(controller, newStatus) {
    controller.statusBtnTarget.value = newStatus;

    if (newStatus == 'published') {
      controller.statusBtnTarget.classList.add('btn-outline-danger');
      controller.statusBtnTarget.classList.remove('btn-outline-primary');
      controller.statusBtnTarget.innerText = I18n.t(
        'organizer.event_content.event_content_modal.buttons.unpublished'
      );

      controller.displayedEventContent.classList.remove('unpublished');

      alertify.success(I18n.t('messages.notify.event.publish.success'));
    } else {
      controller.statusBtnTarget.classList.add('btn-outline-primary');
      controller.statusBtnTarget.classList.remove('btn-outline-danger');
      controller.statusBtnTarget.innerText = I18n.t(
        'organizer.event_content.event_content_modal.buttons.published'
      );

      controller.displayedEventContent.classList.add('unpublished');

      alertify.success(I18n.t('messages.notify.event.unpublish.success'));
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path;
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors;
    let _this = this;
    let error_attributes = Object.keys(errors);

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute]);
      }
    });
  }

  maxSpeakersReached() {
    let speakersCount = this.speakerItemTargets.filter(
      (item) => !item.classList.contains('d-none')
    ).length;
    return speakersCount >= this.maxSpeakersValue;
  }

  maxServicesReached() {
    let servicesCount = this.serviceItemTargets.filter(
      (item) => !item.classList.contains('d-none')
    ).length;
    return servicesCount >= this.maxServicesValue;
  }

  enableServicesSelect() {
    $(this.serviceSelectTarget).prop('disabled', false).selectpicker('refresh');
  }

  disableServicesSelect() {
    $(this.serviceSelectTarget).prop('disabled', true).selectpicker('refresh');
  }

  startSpinner() {
    fullpageSpinner.spin();
  }

  stopSpinner() {
    fullpageSpinner.stop();
  }

  hoverButton(e) {
    let miniForm = e.currentTarget.children[0];
    let miniFormHeight = $(miniForm).outerHeight() / 2;

    $('.mini-form').css('transform', `translate(19px, -${miniFormHeight}px)`);
  }

  // submitボタンのdisabled制御
  disabledSubmitButton(disabled) {
    const submitBtn = document.querySelector('#submit-btn');

    if (disabled) {
      // submitボタンをdisabledにする
      submitBtn.setAttribute('disabled', true);
    } else {
      // submitボタンのdisabledを解除
      submitBtn.removeAttribute('disabled');
    }
  }

  // validateAll発火
  dispatchValidateAll() {
    // 他の項目のバリデーションチェックをするためvalidateAllを発火させる
    this.formTarget.dispatchEvent(new Event('ajax:beforeSend'));
  }

  allErrorCount() {
    let allErrorCount = 0;

    const servicesCount = this.serviceItemTargets.filter(
      (item) => !item.classList.contains('d-none')
    ).length;

    if (servicesCount == 0) {
      allErrorCount = 1;
    } else {
      allErrorCount = 0;
    }

    const errorCount = Array.from(
      document.querySelectorAll('.error-message')
    ).filter((item) => item.innerHTML != '').length;

    allErrorCount = allErrorCount + errorCount;

    return allErrorCount;
  }
}
