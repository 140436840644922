import ApplicationController from '@controllers/application_controller';
import { autoOpenServiceModal } from '@lib/helpers/auto_open_service_modal';

export default class extends ApplicationController {
  static targets = ['root', 'descriptionSp']

  connect() {
    super.connect()
    this.setupChatBoxHeight()
    autoOpenServiceModal()

    window.addEventListener('resize', () => this.setupChatBoxHeight())
  }

  expandDescription(e) {
    let description = this.descriptionSpTarget.querySelector('.description')
    let navigation = this.descriptionSpTarget.querySelector('.navigation')

    if ($(description).hasClass('expanded')) {
      $(description).removeClass('expanded')
      $(navigation).removeClass('down')
      $(navigation).addClass('up')
    } else {
      $(description).addClass('expanded')
      $(navigation).removeClass('up')
      $(navigation).addClass('down')
    }
  }

  setupChatBoxHeight() {
    if(!this.hasRootTarget) return

    let contentSectionHeight = $(this.rootTarget).find('.content-section-top').outerHeight() || 0
    let notificationHeight = $('.notification_wrapper').outerHeight() || 0

    $(this.rootTarget).find('.chat-box-wrapper').css({ height: `calc(100vh - 60px - ${contentSectionHeight + notificationHeight}px)` })
  }
}
