import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  company_link(event) {
    window.location.href = event.currentTarget.dataset.to_path
  }
}
