// Import custom libraries
import { SimpleAjaxForm } from '../../../lib/helpers/simple_ajax_form';
import UploadAndPreviewImage from '../../../lib/helpers/upload_and_preview_logo_helper';
import I18n from '../../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = [ 'avatarFld', 'avatarPreview' ]

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.content_speaker.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.content_speaker.attributes.name.too_long', { count: 40 })}`
      }
    },
    position: (value) => {
      if (_.isEmpty(value)) return;

      return {
        length: {
          maximum: 100,
          tooLong: `^${I18n.t('activerecord.errors.models.content_speaker.attributes.position.too_long', { count: 100 })}`
        }
      }
    },
    introduce: (value) => {
      if (_.isEmpty(value)) return;

      return {
        length: {
          maximum: 1000,
          tooLong: `^${I18n.t('activerecord.errors.models.content_speaker.attributes.introduce.too_long', { count: 1000 })}`
        }
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////

  connect() {
    super.connect()
  }

  ////
  // NEW METHODS
  ////

  updateAvatar(e) {
    let errorMessage = UploadAndPreviewImage(e, this.avatarPreviewTarget, 5)

    if (errorMessage) {
      this.avatarPreviewTarget.classList.add('error')
      this.displayError(this.avatarFldTarget, ['< 5Mb'])
    } else {
      this.avatarPreviewTarget.classList.remove('error')
      this.clearError(this.avatarFldTarget)
    }
  }
}
