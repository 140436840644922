import _ from 'lodash';
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['editPasswordForm', 'form', 'cancel', 'required', 'submitBtn']
  static validators = { validEmail: { attributes: ['email' ]  } }

  static rules = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.email.blank')}`
      },
      email: {
        message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
      }
    }
  }

  connect() {
    super.connect()
  }

  validForm() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed
  }

  closeChangeEmailForm() {
    this.formTarget.classList.add('d-none')
    this.profileFormController.profileEmailTarget.classList.remove('d-none')
    this.clearForm()
    this.profileFormController.reloadChangeEmail()
  }

  successSubmit(e) {
    alertify.success(I18n.t('messages.notify.user.change_email.success'))
    this.closeChangeEmailForm()
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.validForm()

    if (isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }
  }

  get profileFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'profile-form')
  }
}