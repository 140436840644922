import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  openModal(event) {
    const card = event.currentTarget;
    const serviceId = card.dataset.serviceId;
    const eventSlug = card.dataset.eventSlug;

    Rails.ajax({
      url: Routes.show_service_detail_modal_event_exhibition_hall_path(serviceId, { event_slug: eventSlug }),
      type: 'GET',
      success: (data) => {
        $('#service-detail-modal').modal('show');
      },
      error: (e) => {
        alertify.error('サービスの読み込みに失敗しました。');
      },
    });
  }
}
