import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    super.connect()

    StimulusReflex.register(this)
  }

  showDrawerScreen(e) {
    this.activityDrawerController._animateShowScreen(e)

    this.stimulate('MyCompany::ActivityReflex#load_drawer', e.target)

    $('#list-services').addClass('no-pointer-events')
  }

  loadDrawerSuccess() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  get activityDrawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'my-company--activity-drawer')
  }
}
