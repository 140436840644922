// Import custom libraries
import _ from 'lodash'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [ 'stageSearch' ]

  connect() {
    super.connect()
    this.validateCheckbox()
  }

  select(e) {
    $('#table-content').children().find(`.stage-select-${e.target.id}`).each((index, element) => {
      if(element.tagName === 'BUTTON' && $(element).hasClass('btn-outline-primary') ) {
        $(element).removeClass('btn-outline-primary')
        $(element).addClass('btn-outline-default')

        $(element).find('.i-Check').addClass('i-Hidden')
        $(element).find('.i-Check').removeClass('i-Check')
      } else {
        $(element).addClass('btn-outline-primary')
        $(element).removeClass('btn-outline-default')

        $(element).find('.i-Hidden').addClass('i-Check')
        $(element).find('.i-Hidden').removeClass('i-Hidden')
      }
    })

    this.stimulate('Timetables::FilterStageReflex#filter', e.target.dataset.date_id, this.stageCheckedIds)
  }

  buttonSelect(e) {
    if($(e.target).hasClass('btn-outline-primary')) {
      $(e.target).removeClass('btn-outline-primary')
      $(e.target).addClass('btn-outline-default')

      $(e.target).find('.i-Check').addClass('i-Hidden')
      $(e.target).find('.i-Check').removeClass('i-Check')
    } else {
      $(e.target).addClass('btn-outline-primary')
      $(e.target).removeClass('btn-outline-default')

      $(e.target).find('.i-Hidden').addClass('i-Check')
      $(e.target).find('.i-Hidden').removeClass('i-Hidden')
    }

    $('#table-content').children().find(`.stage-select-${e.target.id}`).each((index, element) => {
      if(element.type === 'checkbox' && element.checked) {
        element.checked = false
      } else {
        element.checked = true
      }
    })

    let buttonChecked = $(":button").hasClass('btn-outline-primary')

    if(!buttonChecked) {
      $(e.target).addClass('btn-outline-primary')
      $(e.target).removeClass('btn-outline-default')

      $(e.target).find('.i-Hidden').addClass('i-Check')
      $(e.target).find('.i-Hidden').removeClass('i-Hidden')

      $('#table-content').children().find(`.stage-select-${e.target.id}`).each((index, element) => {
        if(element.type === 'checkbox' && element.id === e.target.id) {
          element.checked = true
        } else {
          element.checked = false
        }
      })

      return false
    }

    this.stimulate('Timetables::FilterStageReflex#filter', e.target.dataset.date_id, this.stageActiveIds)
  }

  validateCheckbox() {
    $('.filtered').click(function() {
      let checkboxChecked = $("input[type=checkbox]:checked").length

      if(!checkboxChecked) {
        return false;
      }
    })
  }

  get stageCheckedIds() {
    return this.stageSearchTargets.filter(checkbox => checkbox.checked)
                                  .map(checkbox => checkbox.dataset.stage_id)
  }

  get stageActiveIds() {
    return this.stageSearchTargets.filter(button => $(button).hasClass('btn-outline-primary'))
                                  .map(button => button.dataset.stage_id)
  }
}
