export const autoOpenServiceModal = () => {
  // Open service detail modal if exists service detail params
  let serviceDetailParam = location.search.match(/service_id=(\d+)/)
  if(!!serviceDetailParam) {
    // Load content for modal
    let serviceId = serviceDetailParam[1];
    let eventSlug = location.pathname.split('/')[2]

    Rails.ajax({
      url: Routes.show_service_detail_modal_event_exhibition_hall_path(serviceId, { event_slug: eventSlug }),
      type: 'GET',
      error: (e) => {},
      success: (e) => {
        // Open the modal
        $('#service-detail-modal').modal('show');
      }
    })
  }
}
