import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['root', 'close']
  static values = { openOnInit: String}

  connect() {
    super.connect()

    StimulusReflex.register(this)

    if (this.openOnInitValue) {
      this.showActivityScreen()
    }
  }

  showActivityScreen(e) {
    Rails.ajax({
      url: `/activities/check_access?show_activity=${this.openOnInitValue}`,
      type: 'GET',
      success: (response) => {
        this.stimulate('MyCompany::ActivityReflex#load_drawer', response)
          .then((e) => {
            this.rootTarget.classList.add('animate')
            this.rootTarget.classList.add('show')  
            $(`#${response["data"].id}`).addClass('active')
            $('#list-services').addClass('no-pointer-events')    
          })
      },
      error: () => {
      }
    })
  }

  closeScreen(e) {
    this._animateHideScreen(e)
    $('.service-line').removeClass('active')
    $('#list-services').removeClass('no-pointer-events')
  }

  _animateShowScreen(e) {
    let currentTarget = e.currentTarget

    if (!this.rootTarget.classList.contains('show'))  {
      currentTarget.classList.add('active')
      this.rootTarget.classList.add('animate')
      this.rootTarget.classList.add('show')
    }
  }

  _animateHideScreen(e) {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')
    this.closeTarget.classList.add('d-none')
  }
}
