import _ from 'lodash';
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['editPasswordForm', 'form', 'cancel', 'required', 'submitBtn']
  static validators = { validPassword: { attributes: ['password'] } }

  static rules = {
    current_password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.blank')}`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${I18n.t('activerecord.errors.models.user.attributes.password.too_short', { count: 8 })}`,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.password.too_long', { count: 32 })}`
      }
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.blank')}`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${I18n.t('activerecord.errors.models.user.attributes.password.too_short', { count: 8 })}`,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.password.too_long', { count: 32 })}`
      }
    },
    password_confirmation: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.blank')}`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${I18n.t('activerecord.errors.models.user.attributes.password.too_short', { count: 8 })}`,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.password.too_long', { count: 32 })}`
      }
    }
  }

  connect() {
    super.connect()
    this.allowSubmit()
  }

  validForm() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed
  }

  closeEditPasswordForm() {
    this.formTarget.classList.add('d-none')
    this.profileFormController.profilePasswordTarget.classList.remove('d-none')
    this.clearForm()
    this.allowSubmit()
  }

  successSubmit(e) {
    window.location.href = Routes.event_login_path({ event_slug: e.target.dataset.eventSlug });
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.validForm()

    if (isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }

    // Confirm password must same as new password
    if (this.attributes.get('password_confirmation').el != el) return

    if (el.value != this.attributes.get('password').el.value) {
      this.displayError(el, [I18n.t('activerecord.errors.models.attendee.attributes.confirm_password.not_match')])
      this.allowSubmit(false)
    }
  }

  get profileFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'profile-form')
  }
}