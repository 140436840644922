// Import libraries from webpacker libraries
import _ from 'lodash';

// Import custom libraries
import ApplicationController from './application_controller';
import Player from "@vimeo/player";
import YTPlayer from "yt-player";
import {serialize} from 'object-to-formdata';

export default class extends ApplicationController {
  static targets = [ 'wrapperVideo', 'video' ]

  connect() {
    super.connect();

    this.watchedSeconds = [];

    // The timeupdate event is still being fired even though user has navigated to other pages.
    // When this flag is true, ignore the timeupdate event.
    this.stopTimeupdate = false

    if(this.provider == 'youtube') {
      this.previewYoutubeVideo()
    } else if(this.provider == 'vimeo') {
      this.previewVimeoVideo()
    }
  }

  disconnect() {
    this.stopTimeupdate = true
    super.disconnect();
  }

  previewVimeoVideo() {
    let options = {
      url:       this.videoURL,
      controls: true,
      autoplay: true,
      quality:  '1080p'
    };

    this.player = new Player('video-place', options);

    if(!this._isOrganizer()) {
      this.player.on('timeupdate', (timeupdate) => this._handleTimeupdate(timeupdate))
    }
  }

  previewYoutubeVideo() {
    const match = this.videoURL.match(/https:\/\/www\.youtube\.com\/watch\?v=([\w\-]+)/);
    const videoId = match?.[1] || '';

    this.player = new YTPlayer('#video-place');
    this.player.load(videoId, {autoplay: true});

    if(!this._isOrganizer()) {
      this.player.on('timeupdate', (timeupdate) => this._handleTimeupdate(timeupdate))
    }
  }

  _handleTimeupdate(timeupdate) {
    const content   = document.querySelector("[data-event-halls--contents--detail-component-content-id-value]");
    const contentId = content.getAttribute("data-event-halls--contents--detail-component-content-id-value");
    // 休憩時間中だとtrue、コンテンツ配信中だとfalseを返却
    const isNoContent = content.firstChild.firstChild.classList.contains('no-content-announcement');

    if (this.stopTimeupdate || !contentId || isNoContent) return;

    if (localStorage.getItem(`content_${contentId}`)) {
      this.watchedSeconds = JSON.parse(localStorage.getItem(`content_${contentId}`));
    }else {
      this.watchedSeconds = [];
    }

    // 現在の視聴時間(秒)
    let currentWatchingSecond;
    switch (this.provider) {
      case "youtube":
        currentWatchingSecond = parseInt(timeupdate);
        break;
      case "vimeo":
        currentWatchingSecond = parseInt(timeupdate.seconds);
        break;
    }

    // 視聴時間が0秒以下 または すでに視聴時間リストに登録済みの場合処理終了
    if (currentWatchingSecond <= 0 || this.watchedSeconds.includes(currentWatchingSecond)) return;

    this._setWatchedSeconds(currentWatchingSecond, contentId);

    if (this.watchedSeconds.length % 60 === 0) {
      // 配信視聴：リード生成
      this._sendTrackingTime(this.watchedSeconds.slice(-60), contentId);
    }
  }

  // 視聴時間リストを保存
  _setWatchedSeconds(time, contentId) {
    this.watchedSeconds.push(time);
    localStorage.setItem(`content_${contentId}`, JSON.stringify(this.watchedSeconds));
  }

  _sendTrackingTime(timeBatch, contentId) {
    // 配信視聴：リード生成
    Rails.ajax({
      url:  Routes.cache_time_tracking_event_event_halls_path(this.eventSlug),
      type: "POST",
      data: serialize({
        time_batch: timeBatch,
        stage_id:   this.stageId,
        content_id: contentId
      }),
      success: (response) => {
        if (response.data.needReload == true) {
          location.reload();
        }
      },
      error: () => {
        alertify.error(I18n.t("messages.notify.general.error"));
      },
    });
  }

  get stageId() {
    return this.wrapperVideoTarget.dataset.stage_id
  }

  get videoURL() {
    return this.wrapperVideoTarget.dataset.video_url
  }

  get provider() {
    return this.wrapperVideoTarget.dataset.provider
  }

  get eventSlug() {
    return this.wrapperVideoTarget.dataset.event_slug
  }

  _isOrganizer() {
    return /^\/organizer\//.test(window.location.pathname)
  }
}
