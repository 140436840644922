import _ from 'lodash';
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = [ 'root' ]

  connect() {
    super.connect()

    if (_.isEqual(this.rootTarget.dataset.push_state, 'push')) {
      let eventContentId = this.rootTarget.dataset.event_content_id

      // Update search params url with event content param when load modal
      var url = new URL(location.href);
      var searchParams = url.searchParams;
      searchParams.set('event_content_id', eventContentId);
      url.search = searchParams.toString();
      var newUrl = url.toString();

      window.history.replaceState({}, document.title, newUrl);
      Turbolinks.controller.lastRenderedLocation = { "absoluteURL": location.href, "requestURL": location.href }

      // When close modal remove the event_content_id param
      $("#event-content-detail-modal").on("hidden.bs.modal", function () {
        searchParams = url.searchParams;
        searchParams.delete('event_content_id');
        url.search = searchParams.toString();
        newUrl = url.toString();
        window.history.replaceState({}, document.title, newUrl);
        Turbolinks.controller.lastRenderedLocation = { "absoluteURL": location.href, "requestURL": location.href }
      });
    }
  }
}
