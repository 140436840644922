
import ApplicationController from '@controllers/application_controller';
import { confirmModal } from '@lib/helpers/js_modals';
import { serialize } from 'object-to-formdata';

export default class extends ApplicationController {
  static targets = ['root', 'close', 'contentWrapper', 'tabList', 'drawerTab', 'contentScreen', 'previewScreen', 'formElement', 'submitBtn', 'footerWrapper', 'footer', 'footerForm']

  connect() {
    super.connect()

    if (!_.isUndefined(this.activeTab)) {
      this.showTabContent(this.activeTab.dataset.tab_id)
    }

    this.checkValidForm()
  }

  saveDraft(e) {
    const distribution_document_title = document.querySelector('#distribution_document_distribution_document_title')

    // ファイル名：50文字超過バリデーション
    if (distribution_document_title.value.length > 50) {
      alertify.error('資料タイトルが規定の文字数を超えているため失敗しました。タイトルを50文字以内に設定してください。')
      return
    }

    this._submitFormData(e)
  }

  publish(e) {
    const distribution_document_title = document.querySelector('#distribution_document_distribution_document_title')

    // ファイル名：50文字超過バリデーション
    if (distribution_document_title.value.length > 50) {
      alertify.error('資料タイトルが規定の文字数を超えているため失敗しました。タイトルを50文字以内に設定してください。')
      return
    }

    this._submitFormData(e)
  }

  _submitFormData(el) {
    var _this = this

    let submitUrl = el.target.dataset.url
    let submitType = el.target.dataset.submitType == 'update' ? 'PUT' : 'POST'
    let tabId = this.activeTab.dataset.tab_id

    let overallFormData    = new FormData(this.overallFormController.formTarget);
    const questionFormData = new FormData(this.questionFormController.formTarget);

    for (const pair of questionFormData.entries()) {
      overallFormData.append(pair[0], pair[1]);
    }

    overallFormData.append('button_type', el.target.dataset.buttonType);
    overallFormData.append('status', this.footerFormTarget.dataset.originalPage);

    Rails.ajax({
      url: submitUrl,
      type: submitType,
      data: overallFormData,
      error: (e) => {
      },
      success: (e) => {
        if(e.data.redirect_path) {
        // Redirect page after publish survey
          window.location.href = e.data.redirect_path
        } else {
          // Reload survey list
          _this.stimulate('ContentSurveys::SurveyListReflex#reload_survey_list', el.target)

          // Reload survey drawer
          _this.stimulate('ContentSurveys::DrawerReflex#load_survey', el.target, e.data.content_survey_id, 'view_survey', tabId)
        }
        alertify.success(I18n.t("messages.notify.general.success"))
      }
    })
  }

  loadSurvey(el) {
    let surveyId = el.detail.surveyId
    let drawerAction = el.detail.drawerAction
    let drawerOrigin = el.detail.drawerOrigin || ''

    this.stimulate('ContentSurveys::DrawerReflex#load_survey', this.rootTarget, surveyId, drawerAction, '', drawerOrigin)
  }

  // Stimulus reflex callbacks
  reloadSurveyListSuccess(el) {
    let id = el.dataset.surveyId
    let $current = $('#survey-list').find(`[data-survey-id='${id}']`).parent()
    let $contentList = $('#survey-list').find('.content-list')

    $current.addClass('current')
    $contentList.addClass('no-pointer-events')

    let parentTop = $contentList.offset().top
    let childTop = $current.offset().top

    $contentList.scrollTop(childTop - parentTop)
  }

  loadSurveySuccess(e) {
    if(this.hasCloseTarget) {
      this._animateShowScreen()
      this.checkValidForm()

      $('[data-toggle="tooltip"]').tooltip()
      this.closeTarget.classList.remove('d-none')
    }
  }

  closeSurvey(e) {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')

    // Clear all previous content
    this.contentWrapperTarget.innerHTML = ''
  }

  checkValidForm() {
    if(!this.overallFormController && !this.questionFormController) return

    let valid     = this.overallFormController.validForm() && this.questionFormController.validForm()
    let checked   = this.overallFormController.toggleStatusTarget.checked
    let scheduled = this.submitBtnTarget.dataset.isScheduled

    if(valid) {
      this.submitBtnTarget.removeAttribute('disabled')
      $('[data-toggle="tooltip"]').tooltip('dispose');
      if(scheduled) {
        this.submitBtnTarget.classList.add('btn-outline-accent')
        if(!checked) {
          this.submitBtnTarget.classList.remove('btn-outline-accent')
        }
      }
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
      $('[data-toggle="tooltip"]').tooltip()

      if(this.overallFormController.validInput() && !this.overallFormController.validContent() && this.questionFormController.validQuestion()) {
        $('.disabled-button').attr('data-original-title', I18n.t('components.events.contents.questionnaires.forms.overall_form_component.tooltip.unpublished_content'))
      } else {
        $('.disabled-button').attr('data-original-title', I18n.t('components.events.contents.questionnaires.forms.overall_form_component.tooltip.invalid'))
      }

      if(scheduled) {
        this.submitBtnTarget.classList.remove('btn-outline-accent')
      }
    }
  }

  switchTab(e) {
    let tabId = e.target.dataset.tab_id

    this.drawerTabTargets.forEach((tab)=> {
      if (tab.dataset.tab_id == tabId) {
        tab.classList.add('active')
      } else {
        tab.classList.remove('active')
      }
    })

    this.showTabContent(tabId)
  }

  showTabContent(tabId) {
    this.contentScreenTargets.forEach((msgList)=> {
      if (msgList.dataset.tab_id == tabId) {
        msgList.classList.remove('d-none')
      } else {
        msgList.classList.add('d-none')
      }
    })

    if (tabId == 'statistics') {
      $(this.footerWrapperTarget).addClass('d-none')
    } else {
      $(this.footerWrapperTarget).removeClass('d-none')
    }
  }

  showScreen(el) {
    let surveyId = el.target.dataset.surveyId
    let drawerAction = el.target.dataset.drawerAction

    let tabId = ''
    if (!_.isEmpty(this.activeTab)) {
      this.activeTab.dataset.tab_id
    }

    // Clear all previous data
    this.contentWrapperTarget.html = ''

    this._animateShowScreen()
    this.stimulate('ContentSurveys::DrawerReflex#load_survey', el.target, surveyId, drawerAction, tabId)
      .catch(payload => {
        window.location.reload()
      })
  }

  showScreenPreview(el) {
    let surveyId = el.target.dataset.surveyId
    let drawerAction = el.target.dataset.drawerAction

    let tabId = ''
    if (!_.isEmpty(this.activeTab)) {
      this.activeTab.dataset.tab_id
    }

    // Clear all previous data
    this.contentWrapperTarget.html = ''

    this._animateShowScreen()
    this.stimulate('ContentSurveys::DrawerReflex#load_survey', el.target, surveyId, 'preview', tabId)
  }

  showPreview(el) {
    $('#preview').addClass('animate show')
    $('#button_preview').removeClass('d-none')
    $('[data-toggle="tooltip"]').tooltip()
  }

  closeScreen(el) {
    if(_.isEmpty(el.target.dataset.confirm_to_close)) {
      this.animateHideScreen(el)
      this._trackDismissSurveyActivity()
    } else {
      confirmModal({
        title: '',
        message: el.target.dataset.confirm_to_close,
        labels: {
          ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
          cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
        },
        onOk: () => {
          this.animateHideScreen(el)
          this._trackDismissSurveyActivity()
        }
      })
    }
  }

  closeScreenPreview(el) {
    this.animateHideScreen(el)
  }

  _trackDismissSurveyActivity() {
    let drawerOrigin = $(this.closeTarget).data('drawer_origin')
    let drawerOrigins = $(this.closeTarget).data('drawer_origins')
    if (drawerOrigin != drawerOrigins['event_halls']) return

    let trackingData = {
      event_id: $(this.closeTarget).data('event_id'),
      item_type: 'ContentSurvey',
      item_id: $(this.closeTarget).data('survey_id'),
      action_type: 'dismiss_survey',
      page: 'event_halls'
    }

    Rails.ajax({
      url: Routes.record_activity_tracking_activities_path(),
      type: 'POST',
      data: serialize(trackingData),
      dataType: 'json'
    })
  }

  scrollContent(e) {
    $($(e.currentTarget).find(".current")).addClass("d-none")
  }

  animateHideScreen(e) {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')

    this.closeTarget.classList.add('d-none')

    let $current = $('#survey-list').find('.current')
    let $contentList = $('#survey-list').find('.content-list')

    $current.removeClass('current')
    $contentList.removeClass('no-pointer-events')
  }

  _animateShowScreen() {
    if (!this.rootTarget.classList.contains('show'))  {
      this.rootTarget.classList.add('animate')
      this.rootTarget.classList.add('show')
    }
    // showLoaderFor(this.contentWrapperTarget)
  }

  _initTooltip() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  get activeTab() {
    return this.drawerTabTargets.find((el) => $(el).hasClass('active'))
  }

  get overallFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--overall-form')
  }

  get questionFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--question-form')
  }
}
