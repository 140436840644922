// Import libraries from webpacker libraries
import _ from 'lodash';
import Rails from '@rails/ujs';

// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';
import { confirmModal } from '../../lib/helpers/js_modals';
import { generateVideoUrl, generateTextVideoPath } from '../../lib/helpers/generate_video_url';
import { autoOpenContentModal } from '@lib/helpers/auto_open_content_modal';

export default class extends SimpleAjaxForm {
  static targets = [ 'createForm', 'createDropdown', 'createStageDropdown','editForm', 'editDropdown', 'nameInput', 'startTimeSelect', 'endTimeSelect','videoProvider', 'videoId', 'video', 'videoPathText', 'updateStageSetup', 'header', 'timetableContent', 'errorMessage']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.blank')}`
      },
      length: {
        maximum: 30,
        tooLong: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.too_long', { count: 30 })}`
      }
    },
    start_public_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.start_time.blank')}`
      }
    },
    end_public_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.end_time.blank')}`
      }
    }
  }

  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect();
    StimulusReflex.register(this)

    if (this.hasEditFormTarget) {
      this.previewVideo();
    }

    this.reIndexTimeBlock()
    this.reloadPreviewContent()
    this.reloadHeaderShadow()
    this.reloadPropagation()
    autoOpenContentModal('', 'organizer')
  }

  validateAll() {
    super.validateAll()
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
    } else {
      this.clearError(el)
    }
  }

  ////
  // NEW METHODS
  ////

  changeStartTime(e) {
    let startTime = e.target.value;
    let endTime = this.endTimeSelectTarget.value;

    if (startTime < endTime) {
      $(this.endTimeSelectTarget).val(endTime)
    }
  }

  // Close dropdown menu
  closeDropdown(e) {
    this.createStageDropdownTarget.classList.remove('show')
  }


  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  //// CREATE
  // After creating successfully, redirect to previous page
  successCreate(e) {
    this.successSubmit(e)
  }

  // After creating failed, show errors and hightligh like validate()
  failedCreate(e) {
    this.failedSubmit(e)
  }

  //// EDIT
  // After editing successfully, redirect to previous page
  successEdit(e) {
    this.successSubmit(e)
  }

  // After creating failed, show errors and hightligh like validate()
  failedEdit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  displayError(el, errorMessages) {
    if (el.nextElementSibling && el.nextElementSibling.classList.contains('error-message')) {
      this.errorMessageEl(el).innerHTML = errorMessages[0]
    } else {
      el.parentElement.parentElement.lastElementChild.innerHTML = errorMessages
    }
    el.classList.add('error');
  }

  //// DELETE
  delete(event) {
    let url = event.target.dataset.url;
    let deleteStage = this.nameInputTarget.value
    let _this = this

    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_item.title'),
      message: I18n.t('organizer.modals.confirm_modals.delete_item.content', { item_name: deleteStage }),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        Rails.ajax({
          url: url,
          type: 'DELETE',
          error: function(e) {
            let errors = e.errors;
            // Show first error message
            let error_message = _this.pickFirstMessage(errors)
            alertify.error(error_message)
          }
        });
      }
    })
  }

  previewVideo() {
    let provider = this.videoProviderTarget.value

    this.videoPathTextTarget.innerHTML = generateTextVideoPath(provider)

    if (_.isEqual(provider, 'vimeo')) {
      this.videoPathTextTarget.classList.add('readonly-text--small')
    } else {
      this.videoPathTextTarget.classList.remove('readonly-text--small')
    }

    for (let i = 0; i < this.videoIdTargets.length; i++) {
      let videoSrc = generateVideoUrl(provider, this.videoIdTargets[i].value)
      if (_.isEmpty(videoSrc)) {
        $(this.videoTargets[i]).hide();
      } else {
        $(this.videoTargets[i]).show()
        this.videoTargets[i].src = videoSrc
      }
    }
  }

  reIndexTimeBlock() {
    // Change this selector to find whatever your 'boxes' are
    var boxes = $(".time-block");
    // Set up click handlers for each box
    boxes.on('show.bs.dropdown', function() {
      var el = $(this), // The box that was clicked
      max = 0;

      // Find the highest z-index
      boxes.each(function() {
        // Find the current z-index value
        var z = parseInt($(this).css("z-index"), 16 );
        // Keep either the current max, or the current z-index, whichever is higher
        max = Math.max(max, z);
      });

      // Set the box that was clicked to the highest z-index plus one
      el.css("z-index", max + 1);
    });

    boxes.on('hide.bs.dropdown', function() {
      boxes.each(function() {
        if (this.dataset.zindex) {
          $(this).css("z-index", this.dataset.zindex)
        }
      })
    })
  }

  changeStatus(e) {
    let mode = e.target.dataset.mode
    confirmModal({
      title: '',
      message: I18n.t(`shared.timetables.index.stage.confirm.${mode}`),
      labels: {
        ok_btn: I18n.t('shared.common.buttons.ok'),
        cancel_btn: I18n.t('shared.common.buttons.cancel')
      },
      onOk: () => {
        try {
          this.stimulate('Organizers::Events::EventStageReflex#toggle_status', e.target)
        } catch(err) {
          console.error(err)
          Rollbar.error(err)
        }
      },
      onCancel: () => {
      }
    })
  }

  checkDropdown(e){
    $('.more-menu').hide()
    //restart event date list
    $('.edit-wrapper.d-flex').addClass('d-none')
    $('.edit-wrapper.d-flex').removeClass('d-flex')

    $('.view-wrapper.d-none').addClass('d-flex')
    $('.view-wrapper.d-none').removeClass('d-none')
    //restart create event date
    $('.add-wrapper.d-flex').addClass('d-none')
    $('.add-wrapper.d-flex').removeClass('d-flex')

    $('.top-section.d-none').addClass('d-flex')
    $('.top-section.d-none').removeClass('d-none')
  }

  checkMoreMenuDropdown(e) {
    $(e.currentTarget).find('.dropdown-menu-left').removeClass('show')
    $(e.currentTarget).find('.more-menu').show()
  }

  reloadHeaderShadow() {
    if (!this.hasTimetableContentTarget) return

    var _this = this

    $(this.timetableContentTarget).on('scroll',function() {
      if($(_this.timetableContentTarget).scrollTop() > 0) {
        _this.headerTarget.classList.add('box-shadow')
      } else {
        _this.headerTarget.classList.remove('box-shadow')
      }
    })
  }

  reloadPreviewContent() {
    $('.content-item').on('click', function(e) {
      // If the element that triggered the event is a child of actions-shortcut, ignore this event
      if ($(e.target).parents('.actions-shortcut').length) return;

      $(this).find('.js-hidden-anchor')[0].click();
    })

    $('.content-title').each(function(_index, title) {
      if (title.classList.contains('lines-truncated-1')) {
        $clamp(title, { clamp: 1 })
      }
      else if (title.classList.contains('lines-truncated-2')) {
        $clamp(title, { clamp: "unset" })
      }
    })
  }

  reloadPropagation() {
    $('[data-subtoggle="tooltip"]').tooltip();

    // Stop closing dropdown-menu when click in dropdown menu
    $('.manipulate-stage-dropdown').on('click', function(e) {
      e.stopPropagation();
    });
  }
}
