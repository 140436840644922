// Import libraries from webpacker libraries
import _, { has } from 'lodash';

// Import custom libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import FileSizeLessThan from '@lib/helpers/check_file_size_less_than_helper';
import pdfPreview from '@lib/helpers/pdf_preview_helper';

export default class extends SimpleAjaxForm {
  static values = {
    modeTypes: Object
  }
  static targets = ['form', 'modeTypeFld', 'modeTypeLabel', 'selectTag', 'releaseTime', 'required', 'timeOptions', 'surveyContentWrapper', 'toggleStatus']

  static rules = {
    title: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.blank')}`
      },
      length: {
        maximum: 60,
        tooLong: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.too_long', { count: 60 })}`
      }
    },
    subtitle: {
      length: {
        maximum: 60,
        tooLong: `^${I18n.t('activerecord.errors.models.event_content.attributes.subtitle.too_long', { count: 60 })}`
      }
    },
    start_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.start_time.blank')}`
      }
    },
    end_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.end_time.blank')}`
      }
    },
    description: {
      length: {
        maximum: 255,
        tooLong: `^${I18n.t('activerecord.errors.models.event_content.attributes.content.too_long', { count: 255 })}`
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////

  connect() {
    super.connect()

    StimulusReflex.register(this)
    this.initSelectPlaceholder()
    this.initSelectPicker()
  }

  initSelectPicker() {
    $('.selectpicker').selectpicker()
  }

  eventContentSelect() {
    this.stimulate('ContentSurveys::FormReflex#load_event_content', this.selectTagTarget)
    this.initSelectPlaceholder()
  }

  loadEventContentSuccess() {
    this.contentIsValid()
    this.inputChange()
  }

  contentIsValid() {
    let submitBtn = this.drawerController.submitBtnTarget
    let scheduled = this.drawerController.submitBtnTarget.dataset.isScheduled

    this.surveyContentWrapperTargets.forEach((el) => {
      let contentIsValid = el.dataset.content_is_valid

      if(contentIsValid == 'false') {
        this.toggleStatusTarget.checked = false
        this.modeTypeFldTarget.value = 'manual'
        submitBtn.classList.remove('btn-accent')
        submitBtn.classList.add('btn-primary')
        submitBtn.textContent = I18n.t('components.events.contents.questionnaires.forms.overall_form_component.button.manual')
        this.toggleStatusTarget.setAttribute('disabled', '')
        this.modeTypeLabelTarget.classList.remove('color-green')
        this.modeTypeLabelTarget.textContent = I18n.t('components.events.contents.questionnaires.forms.overall_form_component.toggle.manual')
        this.releaseTimeTarget.classList.add('d-none')
        if(scheduled) {
          submitBtn.classList.remove('btn-primary')
        }
      } else {
        this.toggleStatusTarget.checked = true
        this.modeTypeFldTarget.value = 'auto'
        this.toggleStatusTarget.removeAttribute('disabled')
        this.modeTypeLabelTarget.classList.add('color-green')
        this.modeTypeLabelTarget.textContent = I18n.t('components.events.contents.questionnaires.forms.overall_form_component.toggle.auto')
        this.releaseTimeTarget.classList.remove('d-none')
        submitBtn.classList.add('btn-accent')
        submitBtn.classList.remove('btn-primary')
        submitBtn.textContent = I18n.t('components.events.contents.questionnaires.forms.overall_form_component.button.auto')

        if(scheduled) {
          submitBtn.classList.remove('btn-accent')
          submitBtn.classList.remove('btn-primary')
          submitBtn.classList.add('btn-outline-accent')
        }
      }
    })
  }

  validForm() {
    let allInputed = true
    let selected = true
    let contentPublish = ''

    this.surveyContentWrapperTargets.forEach((el) => {
      contentPublish = el.dataset.status == 'published'
    })

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    if(this.hasSelectTagTarget && this.selectTagTarget.value == "") {
      selected = false
    }

    // 配布資料チェック
    const distribution_document_exist = document.querySelector('#distribution_document_distribution_document_exist');

    if (distribution_document_exist.checked) {
      const distribution_document_title = document.querySelector('#distribution_document_distribution_document_title');
      const distribution_document_upload_file = document.querySelector('.js_distribution_document_upload_file');
      const existing_document_preview = document.querySelector('#existing_document_preview');

      // 資料タイトルチェック
      if (_.isEmpty(distribution_document_title.value)) {
        allInputed = false
      }

      // 資料アップロードチェック
      if(_.isEmpty(distribution_document_upload_file.value) && _.isEmpty(existing_document_preview.dataset.distribution_document_file_attachment_url)) {
        allInputed = false
      }
    }

    return allInputed && selected && contentPublish
  }

  validInput() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed
  }

  validContent() {
    let contentPublish = ''

    this.surveyContentWrapperTargets.forEach((el) => {
      if(el.dataset.status == undefined){
        contentPublish = true
      } else {
        contentPublish = el.dataset.status == 'published'
      }
    })

    return contentPublish
  }

  inputChange() {
    let closeBtnDataset = this.drawerController.closeTarget.dataset

    this.drawerController.checkValidForm()
    this.drawerController.closeTarget.dataset.confirm_to_close = closeBtnDataset.confirm_text
  }

  initSelectPlaceholder() {
    if(!this.hasSelectTagTarget) return

    if(this.selectTagTarget.value == "") {
      this.selectTagTarget.classList.add('place-holder-color')
    } else {
      this.selectTagTarget.classList.remove('place-holder-color')
    }
  }

  toggleStatus(e) {
    let checked = e.target.checked
    let submitBtn = this.drawerController.submitBtnTarget

    // Update value for hidden status field
    let modeType = checked ? this.modeTypesValue['auto'] : this.modeTypesValue['manual']
    this.modeTypeFldTarget.value = modeType

    // Update text
    this.modeTypeLabelTarget.innerText = I18n.t(`components.events.contents.questionnaires.forms.overall_form_component.toggle.${modeType}`)
    submitBtn.innerText = I18n.t(`components.events.contents.questionnaires.forms.overall_form_component.button.${modeType}`)

    if(modeType == 'auto') {
      submitBtn.classList.remove('btn-primary')
      submitBtn.classList.remove('btn-outline-accent')
      submitBtn.classList.add('btn-accent')
    } else {
      submitBtn.classList.remove('btn-accent')
      submitBtn.classList.remove('btn-outline-accent')
      submitBtn.classList.add('btn-primary')
    }

    // Update label color
    if(checked) {
      this.modeTypeLabelTarget.classList.add('color-green')
      this.releaseTimeTarget.classList.remove('d-none')
    } else {
      this.modeTypeLabelTarget.classList.remove('color-green')
      this.releaseTimeTarget.classList.add('d-none')
    }
  }

  hoverButton(e) {
    let height_w = $(window).height()

    let posLeft = $(e.currentTarget).offset().left + 20
    let posTop = $(e.currentTarget).offset().top

    let miniForm = e.currentTarget.children[0]
    let miniFormHeight = $(miniForm).height() / 2
    let calcHeight = height_w - posTop + miniFormHeight
    let condition = 77 + miniFormHeight - posTop

    $(miniForm).addClass('current')

    if(miniFormHeight > 80 && condition > 61) {
      $(miniForm).css('transform', `translate(${posLeft-20}px,-${height_w - posTop - 28}px)`)
    } else {
      $(miniForm).css('transform', `translate(${posLeft}px,-${calcHeight}px)`)
    }
  }

  hoveroutButton(e) {
    let miniForm = e.currentTarget.children[0]
    $(miniForm).removeClass('current')
    $(miniForm).removeClass('d-none')
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }

  // 配布資料：あり／なし切り替え
  toggleDistributionDocumentExist(event) {
    const distribution_document_area        = document.querySelector('.js_distribution_document_area');
    const distribution_document_exist_true  = document.querySelector('.js_distribution_document_exist_true');
    const distribution_document_exist_false = document.querySelector('.js_distribution_document_exist_false');

    if (event.target.checked) {
      distribution_document_area.classList.remove('d-none');
      distribution_document_exist_true.classList.remove('d-none');
      distribution_document_exist_false.classList.add('d-none');
    } else {
      distribution_document_area.classList.add('d-none');
      distribution_document_exist_true.classList.add('d-none');
      distribution_document_exist_false.classList.remove('d-none');
    }

    // スクロール
    document.querySelector('#js_distribution_document').scrollIntoView({
      behavior: 'smooth'
    });
  }

  // 配布資料アップロード：ファイル選択
  distributionDocumentUpload(event) {
    document.querySelector('.js_distribution_document_upload_file').click();

    event.preventDefault();
  }

  // 配布資料アップロード：プレビュー
  distributionDocumentReceive(event) {
    const document_file = event.target.files[0];
    const no_image_document_preview = document.querySelector('.no_image_document_preview');
    const existing_document_preview = document.querySelector('#existing_document_preview');
    const new_document_preview = document.querySelector('#new_document_preview');

    // ファイルサイズ：25MB超過バリデーション
    if (!FileSizeLessThan(document_file, 25 * 1024 * 1024)) {
      alertify.error('資料のファイルサイズが規定のサイズを超えているため失敗しました。ファイルサイズを25MB以内にしてください。')
      return
    }

    no_image_document_preview.classList.add('d-none');
    existing_document_preview.classList.add('d-none');
    new_document_preview.classList.remove('d-none');
    pdfPreview(document_file, new_document_preview, 1, 1.0);

    event.preventDefault();
  }
}
