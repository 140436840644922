// Import libraries from webpacker libraries
import Rails from '@rails/ujs';

// Import custom libraries
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values  = { triggeringCtrlrId: String }
  static targets = ['root', 'form', 'downloadBtn']

  connect() {
    super.connect()
  }

  showModal(triggeringCtrlrId, downloadableType, downloadableId, eventSlug, docIds) {
    // triggeringCtrlrId: id of the controller that triggers this modal open
    this.triggeringCtrlrIdValue = triggeringCtrlrId

    let url = Routes.open_modal_event_attendees_download_surveys_path({
      event_slug:          eventSlug,
      downloadable_type:   downloadableType,
      downloadable_id:     downloadableId,
      ctrlr:               this.identifier,
      triggering_ctrlr_id: triggeringCtrlrId,
      document_ids:        docIds
    })

    Rails.ajax({
      url:      url,
      type:     'GET',
      dataType: 'script'
    })
  }

  closeModal() {
    $(this.rootTarget).modal('hide')
  }

  // 名刺情報取得：資料ダウンロード
  submitSuccess(_evt) {
    this.triggeringCtrlr.forEach((controller) => {
      controller.needSurveyValue = false
    })

    this.triggeringCtrlr[0].downloadDocuments()
    this.closeModal()
  }

  updateDownloadBtnStatus(evt) {
    this.downloadBtnTarget.disabled = !evt.currentTarget.checked
  }

  get triggeringCtrlr() {
    return this.application.controllers.filter(controller => {
      return controller.idValue === this.triggeringCtrlrIdValue
    });
  }
}
