import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets= ['select']

  connect() {
    super.connect()

    this.initSelectPicker()
  }

  initSelectPicker() {
    $('.selectpicker').selectpicker()
  }

  eventServiceSelected(e) {
    let _this = this
    let serviceId = e.target.value

    if(!serviceId) return

    _this.stimulate('Organizers::ServiceInvitations::ActionsReflex#reload_service_list', serviceId)
      .then((e) => {
        let eventService = $('#service-list').find(`[data-service_id=${serviceId}]`)[0]
        let eventSlug = eventService.dataset.event_slug

        new TableScroll('.table-aside-container')

        Rails.ajax({
          url: Routes.show_service_detail_modal_event_exhibition_hall_path(serviceId, {event_slug: eventSlug}),
          type: 'GET',
          success: (response) => {
            $('#service-detail-modal').modal('show');
            $('#eventServicesModal').modal("hide")

            $(_this.selectTargets).find(`[value=${_this.selectTarget.value}]`).remove()
            $(_this.selectTarget).selectpicker('refresh')
          }
        })

        _this.getListController().connect()
      })
  }

  getListController() {
    return this.application.controllers.find((controller) => controller.identifier === 'organizer--event-services--list')
  }
}
