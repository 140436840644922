import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static values  = { explainTexts: Object, eventSlug: String, email: String, exportLoc: String, exportLocs: Object, exportTypes: Object, eventServiceId: String, privateExportType: String }
  static targets = ['root', 'explainText', 'datalength', 'inputChoice', 'firstChoice', 'exportBtn', 'exportHint']

  connect() {
    super.connect()

    this.currentExportType = ''
    this.contentId = ''
  }

  showScreen({ exportType, contentId = '', eventServiceId = ''}) {
    // contentId is available when exportType is contentInfo
    this._prepareScreen(exportType, contentId, eventServiceId)

    if (!this.rootTarget.classList.contains('show')) this._animateShowScreen()
  }

  animateHideScreen() {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.remove('show')
  }

  toHistoryScreen(e) {
    let eventServiceId = e.target.dataset.event_service_id

    this._hideScreen()
    this.historyScreenCtrlr.showScreen({ animate: false, eventServiceId: eventServiceId })
  }

  handleChoiceSelect(evt) {
    // Update explaination text
    let text = this.explainTextsValue['leads'][evt.target.value]

    this._updateExplainText(text)

    // set currentExportType to use later
    this.currentExportType = evt.target.value
    this.contentId = ''

    Rails.ajax({
      url: Routes.datalength_event_exhibitors_csv_exports_path({ export_type: evt.target.value, event_slug: this.eventSlugValue, event_service_id: this.eventServiceIdValue }),
      type: 'GET',
      success: (resp) => {
        this.datalengthTarget.innerText = resp.data.datalength

        if (resp.data.datalength == 0) {
          this.exportBtnTarget.classList.add('disabled')
        }
        else {
          this.exportBtnTarget.classList.remove('disabled')
        }
      }
    })
  }

  // エクスポートボタン実行
  handleExport(_e) {
    Rails.ajax({
      // POST => exhibitors/csv_exports#request_export
      url:     Routes.request_export_event_exhibitors_csv_exports_path({ export_type: this.currentExportType, event_slug: this.eventSlugValue, content_id: this.contentId, event_service_id: this.eventServiceIdValue }),
      type:    'POST',
      success: (_resp) => {
        this._hideScreen()
        this.proceedingScreenCtrlr.showScreen(this.emailValue, this.privateExportTypeValue)
      },
      error: () => {
        this._hideScreen()
        window.location.reload()
      }
    })
  }

  _animateShowScreen() {
    this.rootTarget.classList.add('animate')
    this.rootTarget.classList.add('show')
  }

  _hideScreen() {
    this.rootTarget.classList.remove('animate')
    this.rootTarget.classList.remove('show')
  }

  _updateExplainText(text) {
    $(this.explainTextTarget).html(text)
  }

  _prepareScreen(exportType, contentId, eventServiceId) {
    if (this.exportLocValue == this.exportLocsValue['home']) {
      // Set currentExportType and contentId to be used later
      this.currentExportType = exportType
      this.contentId = contentId

      Rails.ajax({
        url: Routes.datalength_event_exhibitors_csv_exports_path({ export_type: this.currentExportType, event_slug: this.eventSlugValue, content_id: this.contentId, event_service_id: eventServiceId }),
        type: 'GET',
        success: (resp) => {
          // エクスポート件数
          this.datalengthTarget.innerText = resp.data.datalength

          // Disable export button if length is 0
          if (resp.data.datalength == 0) {
            this.exportBtnTarget.classList.add('disabled')
          } else {
            this.exportBtnTarget.classList.remove('disabled')
          }

          // Remove export hint unless export_type is download_booth
          if (exportType == this.exportTypesValue['download_booth']) {
            this.exportHintTarget.classList.remove('d-none')
          } else {
            this.exportHintTarget.classList.add('d-none')
          }

          // エクスポート説明文更新
          let text = this._explainText(exportType)
          this._updateExplainText(text)
        }
      })
    }
    else if (this.exportLocValue == this.exportLocsValue['leads']) {
      this.firstChoiceTarget.checked = false
      this.firstChoiceTarget.click()
    }
  }

  _explainText(exportType) {
    if (exportType == this.exportTypesValue['download_booth']) {
      return this.explainTextsValue['home']['download_booth']['main']
    }
    else {
      return this.explainTextsValue['home'][exportType]
    }
  }

  get proceedingScreenCtrlr() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier === 'events--export-csv-screens--proceeding-screen')
  }

  get historyScreenCtrlr() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier === 'events--export-csv-screens--history-screen')
  }
}
