// Import libraries from webpacker libraries
import _ from 'lodash';
import { serialize } from 'object-to-formdata';

// Import custom libraries
import { SimpleAjaxForm } from '../lib/helpers/simple_ajax_form';
import I18n from '../lib/i18n-js/init.js.erb';
import { confirmModal } from '../lib/helpers/js_modals';
import { scrollToBottom } from '../lib/helpers/view_helpers';

export default class extends SimpleAjaxForm {
  static targets = ['form', 'required', 'submitBtn', 'listForm' , 'placeHolder', 'limitViewContainer', 'limitEditContainer', 'bannerPreview', 'bannerFileInput', 'input', 'button', 'companyMemberWrapper']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    reach_user_limit: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_service.attributes.reach_user_limit.blank')}`
      }
    },
    first_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.first_name.blank')}`
      },
      length: {
        maximum: 20,
        tooLong: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.first_name.too_long', { count: 20 })}`
      }
    },
    last_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.last_name.blank')}`
      },
      length: {
        maximum: 20,
        tooLong: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.last_name.too_long', { count: 20 })}`
      }
    }
  }

  connect() {
    super.connect()

    StimulusReflex.register(this)
    this.checkValid = _.debounce(this.checkValid, 400).bind(this)
  }

  reflexError(element, reflex, error) {
    alertify.error(this.messages.error);
  }

  ////
  // NEW METHODS
  ////

  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error'),
      "toggle_failure": I18n.t('messages.notify.service_invitation.toggle.failure'),
      "edit_success": I18n.t('messages.notify.general.edit.success')
    }

    return messages;
  }

  checkValid(e) {
    this._clearCompanyMemberWrapper()

    this.stimulate('Users::ServiceMemberReflex#check_valid', e.target, this.inputTarget.value)
  }

  sendInvite(e) {
    let _this = this

    this.stimulate('Users::ServiceMemberReflex#invite_member', e.target, this.inputTarget.value)
      .then((e) => {
        _this._clearInvitationForm()
        scrollToBottom(_this.profileFormController.listFormTarget)
      })

    alertify.success(I18n.t("messages.notify.company_member.invite_success"))
  }

  _clearInvitationForm() {
    $(this.buttonTargets).remove()
    this.inputTarget.value = ''
    this._clearCompanyMemberWrapper()
  }

  _clearCompanyMemberWrapper() {
    if($(this.companyMemberWrapperTargets).length > 0) {
      $(this.companyMemberWrapperTargets).remove()
    }
  }

  clickMoreMenu(e) {
    let element = $(e.currentTarget).closest('.invitation-list')
    $(element).css('z-index','1')

    $(element).on('hide.bs.dropdown', function () {
      $(element).css('z-index','0')
    })
  }

  cancelInvitation() {
    this.clearForm()

    this.invitationFormTarget.classList.add('d-none')
    this.placeHolderTarget.classList.remove('d-none')
    this.placeHolderTarget.classList.add('d-flex')
  }

  // Clear all inputs, highlights and error messages
  clearForm() {
    let _this = this;
    this.attributes.forEach(({ el }) => {
      if (el.name == 'event_service[reach_user_limit]') return

      el.classList.remove('error');
      el.value = ''
      _this.errorMessageEl(el).innerHTML = '';
    })
  }

  // Input all required field or not
  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this
    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  // DELETE EVENT_SERVICE
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.event_service.confirm_modals.delete_event_service.title'),
      message: I18n.t('organizer.event_service.confirm_modals.delete_event_service.content'),
      labels: {
        ok_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }

  editLimitField(e) {
    let _this = this;
    let eventServiceId = this.element.dataset.event_service_id;
    let reachUserLimit = this.attributes.get('reach_user_limit').value;

    let submitData = { event_service: { reach_user_limit: reachUserLimit }, status_only: true };

    Rails.ajax({
      url: Routes.organizer_event_service_path({ id: eventServiceId }),
      type: 'PATCH',
      data: serialize(submitData),
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = _this.pickFirstMessage(errors)
        alertify.error(error_message)
      },
      success: (e) => {
        let { reach_user_limit } = e.data

        $(`#event-service-${eventServiceId}-reach-user-limit`).text(reach_user_limit)

        // Notify success message
        alertify.success(_this.messages.edit_success)
      }
    })
  }

  openFileChooser(_evt) {
    this.bannerFileInputTarget.click()
  }

  uploadBanner(_evt) {
    let _this = this
    let eventServiceId = this.element.dataset.event_service_id;
    let submitData = { event_service: { banner: this.bannerFileInputTarget.files[0] }, status_only: true };

    Rails.ajax({
      url: Routes.organizer_event_service_path({ id: eventServiceId }),
      type: 'PATCH',
      data: serialize(submitData),
      error: ({ errors }) => {
        // Clear file input
        _this.bannerFileInputTarget.value = null

        // Show first error message
        let error_message = _this.pickFirstMessage(errors)
        alertify.error(error_message)
      },
      success: (_response) => {
        _this.bannerPreviewTarget.src = window.URL.createObjectURL(this.bannerFileInputTarget.files[0]);
        alertify.success(_this.messages.edit_success)
      }
    })
  }

  // MEMBERS
  active(e) {
    this.stimulate('ServiceMembers::MenuReflex#active', e.target)

    alertify.success('有効にしました')
  }

  unactive(e) {
    this.stimulate('ServiceMembers::MenuReflex#unactive', e.target)

    alertify.success('無効にしました')
  }

  cancel(e) {
    this.stimulate('ServiceMembers::MenuReflex#cancel', e.target)

    alertify.success('招待を取り消しました')
  }

  resend(e) {
    this.stimulate('ServiceMembers::MenuReflex#resend', e.target)

    alertify.success('招待メールを送信しました')
  }

  get profileFormController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'profile-form';
    });
  }
}
