import CableReady from 'cable_ready';
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static subscription = null
  static values = { stageId: String }

  connect() {
    super.connect()
    this._subscribeStageActivitiesChannel(this.stageIdValue)

    // Workaround to avoid global affect on styling html and body element
    $('html, body').addClass('in-guests-stage-comments')
  }

  disconnect() {
    if (!this.constructor.subscription) return

    this.constructor.subscription.unsubscribe()

    super.disconnect()
  }

  _subscribeStageActivitiesChannel(stageId) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'EventHalls::StageActivitiesChannel', event_stage_id: stageId, user_role: 'guest'
    }, {
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  triggerReflexUpdateStage(evt) {
    this.stimulate('Guests::StageReflex#reflex_update_stage', evt.detail.targets)
  }
}
