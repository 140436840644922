import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static values = { guestName: String, uiGroupId: String }

  showEditNameForm() {
    setTimeout(() => this._editFormCtrlr.show($(this.element).parents('.guest-info-wrap').find('.js-guest-name').text()), 50)
  }

  get _editFormCtrlr() {
    return this.application.controllers.find(ctrlr => {
      return ctrlr.identifier == 'guests--edit-name-form-component' && ctrlr.uiGroupIdValue == this.uiGroupIdValue
    })
  }
}
