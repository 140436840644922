// Import libraries from webpacker libraries
import _ from 'lodash';
import CableReady from 'cable_ready'
import { serialize }    from 'object-to-formdata';

// Import custom libraries
import ApplicationController from '@controllers/application_controller'
import { removePasteFormat } from '@lib/helpers/view_helpers';
import { generateDOMStageMessage } from '@lib/helpers/stage_helper';
import { showLoaderFor } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = ['chatBoxSpWrapper', 'chatInputSpWrapper', 'messageSpPreview', 'toggleSp', 'noMessageSp', 'messageScreen', 'inputFld', 'sendBtn', 'loadmoreIndicator']

  static subscription = null
  static values = { oldestMsgOffset: Number, page: 1 }
  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect()
    this.subscribeStageChatChannel(this.messageScreenTarget.dataset.event_stage_id)
    this.oldestMsgOffsetValue = this.messageScreenTarget.dataset.oldest_msg_offset
    showLoaderFor(this.loadmoreIndicatorTarget, 'md')
    this.loadMoreMessages = this.loadMoreMessages.bind(this) // binding so we can refer to this stimulus class
    this.hasInputFldTarget && removePasteFormat(this.inputFldTarget)
    if (!this.registeredOnScroll) {
      $(this.messageScreenTarget).on('scroll', _.debounce(this.loadMoreMessages, 100) )
      this.registeredOnScroll = true
    }
    this.scrollToBottom()
  }

  disconnect() {
    if (!this.constructor.subscription) return

    this.constructor.subscription.unsubscribe()
  }

  ////
  // NEW METHODS
  ////

  subscribeStageChatChannel(eventStageId) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    const channel = {channel: 'EventHalls::ChatChannel', event_stage_id: eventStageId};
    const existingSubscription = this.application.consumer.subscriptions.subscriptions.find(sub => sub.identifier === JSON.stringify(channel));

    // 既にサブスクリプションを作成している場合は、新たに作成しない
    if(existingSubscription) return;

    this.constructor.subscription = this.application.consumer.subscriptions.create(channel, {
      initialized: () => {
        // console.log('subscription initialized: ', eventStageId)
      },
      connected: () => {
        // console.log('subscription connected: ', eventStageId)
      },
      disconnected: () => {
        // console.log('subscription disconnected: ', eventStageId)
      },
      rejected: () => {
        // console.log('subscription rejected: ', eventStageId)
      },
      received: (data) => {
        // console.log('subscription received: ', data)
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  // Only allow input 500 characters
  limitCharacters(e) {
    // Don't limit speacial keys
    if (e.key == 'Backspace' || e.key == 'Delete' || e.key == 'Enter' || e.key == 'Meta') {
      return
    }

    // Limit number of chars
    if ( e.target.innerText.length > 499) {
      e.preventDefault();
        return;
    }
  }

  loadMoreMessages() {
    if (!this.loadmoreIndicatorTarget.classList.contains('d-none')) return

    this.messageScreenTarget.dataset.lastScrollTop = this.messageScreenTarget.dataset.lastScrollTop || 0

    // Scrolling down, skip
    if ($(this.messageScreenTarget).scrollTop() > this.messageScreenTarget.dataset.lastScrollTop) {
      this.messageScreenTarget.dataset.lastScrollTop = $(this.messageScreenTarget).scrollTop()
      return
    }

    // Not reach near the top yet, skip
    if ($(this.messageScreenTarget).scrollTop() > 10) {
      this.messageScreenTarget.dataset.lastScrollTop = $(this.messageScreenTarget).scrollTop()
      return
    }

    this.loadmoreIndicatorTarget.classList.remove('d-none')

    Rails.ajax({
      url: Routes.more_messages_event_event_halls_path({ event_slug: this.messageScreenTarget.dataset.event_slug }),
      type: 'GET',
      data: $.param({ offset: this.oldestMsgOffsetValue + 1, page: (this.pageValue + 1) }),
      success: (resp) => {
        this.oldestMsgOffsetValue += resp.data.messages_length
        this.pageValue++
        const firstElement = $(this.messageScreenTarget).find('.chat-item-component').first()
        $(this.messageScreenTarget).prepend(resp.data.html)
        let previousHeight = 0;
        firstElement.prevAll().each(function() {
          previousHeight += $(this).outerHeight();
        });
        $(this.messageScreenTarget).scrollTop(previousHeight);
      },
      complete: () => {
        this.loadmoreIndicatorTarget.classList.add('d-none')
      }
    })
  }

  // Submit message to backend
  postChat(e) {
    if (e.type == 'click' && e.currentTarget == this.sendBtnTarget) {
      // Prevent browser add break line after hit 'enter'
      e.preventDefault();

      let message = this.inputFldTarget.innerText.trim().substring(0, 500);
      let eventStageId = this.messageScreenTarget.dataset.event_stage_id;

      if (_.isEmpty(message)) return;

      try {
        // Send this message to service to store and broadcast to other users
        this.stimulate(
          'EventHalls::ChatReflex#create_message',
          message,
          eventStageId
        )
          .catch(payload => {
            window.location.reload()
          })
      } catch(err) {
        console.error(err)
        Rollbar.error(err)
      }

      this.inputFldTarget.innerText = '';

      // Open chat box SP
      if(!this.chatBoxSpOpen) this.expandChatBox()
    }
  }

  expandChatBox() {
    if(!this.hasChatBoxSpWrapperTarget) return

    let wrapperDataset = this.chatBoxSpWrapperTarget.dataset

    if(wrapperDataset.expanded == 'false') {
      wrapperDataset.expanded = true
      $(this.chatBoxSpWrapperTarget).addClass('expanded')
      $(this.chatInputSpWrapperTarget).removeClass('d-none')
      $(this.messageScreenTarget).removeClass('d-none')
      $(this.messageSpPreviewTarget).addClass('d-none')
    } else {
      wrapperDataset.expanded = false
      $(this.chatBoxSpWrapperTarget).removeClass('expanded')
      $(this.chatInputSpWrapperTarget).addClass('d-none')
      $(this.messageScreenTarget).addClass('d-none')
      $(this.messageSpPreviewTarget).removeClass('d-none')
    }

    this.changeExpandButton()
    this.updateNoMessage()
    this.scrollToBottom()
  }

  changeExpandButton() {
    let wrapperDataset = this.chatBoxSpWrapperTarget.dataset

    if(wrapperDataset.expanded == 'false') {
      $(this.toggleSpTarget).find('.material-icons').removeClass('icon-down')
      $(this.toggleSpTarget).find('.material-icons').addClass('icon-up')
      $(this.toggleSpTarget).find('.caption-1').text('全てのコメントを見る')
    } else {
      $(this.toggleSpTarget).find('.caption-1').text('閉じる')
      $(this.toggleSpTarget).find('.material-icons').removeClass('icon-up')
      $(this.toggleSpTarget).find('.material-icons').addClass('icon-down')
    }
  }

  updateNoMessage() {
    let wrapperDataset = this.chatBoxSpWrapperTarget.dataset

    if (wrapperDataset.expanded == 'true') {
      if(!this.hasMessage) {
        $(this.toggleSpTarget).removeClass('d-none')
        $(this.noMessageSpTarget).addClass('d-none')
      }
    } else {
      if(!this.hasMessage) {
        $(this.toggleSpTarget).addClass('d-none')
        $(this.noMessageSpTarget).removeClass('d-none')
        $(this.chatInputSpWrapperTarget).removeClass('d-none')
      }
    }
  }

  // Update chat box to add new message
  updateChatBox(e) {
    Rails.ajax({
      url: Routes.render_message_event_event_halls_path({ event_slug: this.messageScreenTarget.dataset.event_slug }),
      type: 'POST',
      data: serialize({
        message: e.detail.msg,
        event_stage: e.detail.stage
      }),
      success: (resp) => {
        // コメント部分の文字列HTMLをDOMに変換
        const tempEl = document.createElement('div');
        tempEl.innerHTML = resp.data.html;
        // コメントのHTMLを追加
        this.messageScreenTarget.append(tempEl.firstElementChild);
        this.oldestMsgOffsetValue++;
        this.scrollToBottom();
      }
    })
  }

  previewLatestMsg(evt) {
    let stageId = this.element.dataset.event_stage_id

    let latestMsg = _.cloneDeep(evt.detail)
    latestMsg['messageId'] = ''

    let msgPreviewDOM = generateDOMStageMessage(latestMsg, stageId)
    if(this.hasMessageSpPreviewTarget) $(this.messageSpPreviewTarget).html(msgPreviewDOM)
  }

  focusinChatBox(e) {
    if(!this.chatBoxSpOpen) this.expandChatBox(e)
  }

  // Scroll chat box to bottom
  scrollToBottom() {
    this.messageScreenTarget.scrollTop = this.messageScreenTarget.scrollHeight
  }

  // Remove a message in chat box
  removeMsgChatBox(e) {
    let data = e.detail;
    let $message = $(`[data-id=${data.deletedMessageId}]`)

    if (!$message) return;

    $message.remove()
  }

  chatMessageAvatarClass(role, stageId, authorId) {
    if (role == 'organizer') {
      return `stage-${stageId}-organizer-message-avatar`
    }
    else {
      return `stage-${stageId}-author-${authorId}-message-avatar`
    }
  }

  chatMessageNameClass(role, stageId, authorId) {
    if (role == 'organizer') {
      return `stage-${stageId}-organizer-message-name`
    }
    else {
      return `stage-${stageId}-author-${authorId}-message-name`
    }
  }

  get hasMessage() {
    return $(this.messageScreenTarget).children().length > 0
  }

  get chatBoxSpOpen() {
    if(this.hasChatBoxSpWrapperTarget) {
      return this.chatBoxSpWrapperTarget.dataset.expanded == 'true'
    } else {
      return false
    }
  }
}
