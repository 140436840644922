// Import libraries from webpacker libraries
import _ from 'lodash';

import { stringToHTML } from '@lib/helpers/view_helpers';

// Import custom libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['form', 'questionWrapper']

  static rules = {
    title: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.blank')}`
      },
      length: {
        maximum: 60,
        tooLong: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.too_long', { count: 60 })}`
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////

  connect() {
    super.connect()
    this._initSortableQuestion()

    StimulusReflex.register(this)
  }

  validForm() {
    let allInputed = true
    let hasQuestion = false
    let inputValidate = $(this.formTarget).find('*[data-required="true"]')
    let questions = $(this.formTarget).find('*[data-key="destroy"]')

    questions.each((index, el) => {
      if(el.value == "false") {
        hasQuestion = true
      }
    })

    inputValidate.each((index, el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false
      }
    })

    return allInputed && hasQuestion
  }

  validQuestion() {
    let hasQuestion = false
    let questions = $(this.formTarget).find('*[data-key="destroy"]')

    questions.each((index, el) => {
      if(el.value == "false") {
        hasQuestion = true
      }
    })

    return hasQuestion
  }

  inputChange() {
    let closeBtnDataset = this.drawerController.closeTarget.dataset

    this.drawerController.checkValidForm()
    this.drawerController.closeTarget.dataset.confirm_to_close = closeBtnDataset.confirm_text
  }

  appendOption(e) {
    let msgHTML = e.detail.view
    let msgDOM = stringToHTML(msgHTML)

    let appendTarget = e.detail.target

    if (appendTarget) {
      this.questionWrapperTarget.querySelector(`#${appendTarget}`).after(msgDOM[0])
    } else {
      this.questionWrapperTarget.append(msgDOM[0])
    }

    this.closePopupMenu()
    this.inputChange()
    this._initTooltip()
    e.preventDefault()
  }

  appendQuestionOption(e) {
    let $target = $(`#${e.detail.questionOptionDomId}`);

    let msgHTML = e.detail.render
    let msgDOM = stringToHTML(msgHTML)

    $target.append(msgDOM[0])

    this.inputChange()
  }

  closePopupMenu() {
    this.popupMenuController.closeOptionScreen()
  }

  openPopupMenu(e) {
    this.popupMenuController.openPopupMenu(e)

    e.preventDefault()
  }

  _initSortableQuestion() {
    var _this = this

    $('#questionList').sortable({
      animation: 150,
      axis: 'y',
      helper: 'clone',
      start: function(_e, ui ) {
        $('.input-wrapper').addClass('no-pointer-events')
        ui.helper.addClass("dragging");
        ui.item.children().each((_i, child) => {
          child.classList.remove('hide')
        })
      },
      stop: function(_e, ui ) {
        $('.input-wrapper').removeClass('no-pointer-events')

        _this.reIndexQuestionPosition()
        _this.inputChange()
      }
    })
  }

  _initTooltip() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  reIndexQuestionPosition() {
    // Re-index position
    $('.input-wrapper').each((idx, el) => {
      el.querySelector('*[data-sort="question"]').value = idx + 1
    })
  }

  get popupMenuController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--popup-menu')
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }
}
