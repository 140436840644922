// event_videoからの移管ファイル

// Import stimulus libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
// Import helper libraries
import { GenerateHTMLOptionsHelper } from '@video_lib/helpers/generate_html_options_helper';
import { setOptionsDataAttributeHelper } from '@video_lib/helpers/set_options_data_attribute_helper';
import { confirmModal } from '@video_lib/helpers/js_modals';
import _ from 'lodash';
import Rails from '@rails/ujs';
import { el } from 'redom';

export default class extends SimpleAjaxForm {
  ////
  // TARGETS
  ////

  static targets = [
    'selectEmEventId',
    'selectEmEventContentId',
    'btnReloadBasicInfo',
    'contentOpt',
    'contentSelectWrap',
    'applyContentBtnWrap',
  ];

  ////
  // INIT METHODS
  ////

  connect() {
    super.connect();
    this.initSelectEmEventId();
    this.initSelectEmEventContentId();
  }

  initSelectEmEventId() {
    let _this = this;
    let select = _this.selectEmEventId;

    // select.selectpicker()
    _this.createErrorElement(select);
  }

  initSelectEmEventContentId() {
    let _this = this;
    let select = _this.selectEmEventContentId;

    // select.selectpicker()
    _this.createErrorElement(select);
  }

  ////
  // EVENT METHODS
  ////

  changeEmEvent(evt) {
    let emEventId = evt.target.value;

    this.applyContentBtnWrapTarget.classList.add('d-none');

    this.reloadEventContentSelect(emEventId);
    this._videoServicesCtrlr.clearItems();
    this._emServiceSelectCtrlr.ajaxPopulateProgress();
    this._emServiceSelectCtrlr.ajaxPopulate(emEventId);
  }

  reloadEventContentSelect(eventId) {
    let $select = $(this.selectEmEventContentIdTarget);
    let selectWrap = this.contentSelectWrapTarget;

    if (!eventId) {
      $(this.contentOptTargets).remove();
      selectWrap.classList.add('d-none');
      return;
    }

    selectWrap.classList.add('d-none');

    Rails.ajax({
      url: Routes.owner_event_contents_path({ event_id: eventId }),
      type: 'GET',
      dataType: 'JSON',
      success: (response) => {
        $(this.contentOptTargets).remove();

        let optionDoms = response.data.map((emEventContent) =>
          this._emContentSelectOptionDom(emEventContent)
        );
        $select.append(optionDoms);

        selectWrap.classList.remove('d-none');
      },
    });
  }

  _emContentSelectOptionDom(emEventContent) {
    let optAttrs = {
      value: emEventContent.id,
      'data-em-event-content': JSON.stringify(emEventContent),
    };
    optAttrs[`data-${this.identifier}-target`] = 'contentOpt';

    return el('option', emEventContent.title, optAttrs);
  }

  changeEmEventContentId(evt) {
    let emEventContentId = evt.target.value;

    if (emEventContentId) {
      this.applyContentBtnWrapTarget.classList.remove('d-none');
    } else {
      this.applyContentBtnWrapTarget.classList.add('d-none');
    }

    this.btnReloadBasicInfoTarget.dataset.emEventContentId = emEventContentId;
  }

  openModalConfirmReloadBasicInfo(evt) {
    let i18nPrefix =
      'javascripts.controllers.shared.videos.form.event.modal_confirm_reload_basic_info';

    // 関連コンテンツのデータを使用しますか？
    confirmModal({
      title: I18n.t(`${i18nPrefix}.title`),
      message: I18n.t(`${i18nPrefix}.message`),
      onOk: () => {
        let emEventContentId = evt.target.dataset.emEventContentId;
        let opt = this.contentOptTargets.find(
          (opt) => opt.value == emEventContentId
        );
        let emEventContent = JSON.parse(opt.dataset.emEventContent);
        this.reloadVideoData(evt, emEventContent);
      },
    });
  }

  // 関連コンテンツのデータで上書き
  reloadVideoData(evt, emEventContent) {
    this.reloadVideoBasicInfo(emEventContent);
    this.reloadContentSpeakers(emEventContent);

    // 関連サービスの選択肢を更新
    this._emServiceSelectCtrlr.populateEventContentData(emEventContent);
    this._videoServicesCtrlr.populateEventContentData(emEventContent);

    this.editVideoController.validateAllForce(evt);
  }

  reloadVideoBasicInfo(emEventContent) {
    this.inputTitle.val(emEventContent.title);
    this.inputSubtitle.val(emEventContent.subtitle);
    this.inputOverview.val(emEventContent.content);
  }

  reloadContentSpeakers(data) {
    let _this = this;

    let list = _this.listContentSpeakers;
    let contentSpeakers = list.find('.js-content-speaker');
    contentSpeakers.each((index, contentSpeaker) => {
      _this.contentSpeakersController.deleteContenSpeakerHTML(
        $(contentSpeaker)
      );
    });

    let template = _this.templateContentSpeaker;
    data.content_speakers.forEach((item) => {
      list.append(template);

      let avatar = _this.imgContentSpeakersAvatar.last();
      avatar.attr('src', item.avatar);

      let inputAvatar = _this.inputContentSpeakersAvatar.last();
      inputAvatar.val('');

      let hiddenAvatarUrl = _this.hiddenContentSpeakersAvatarUrl.last();
      hiddenAvatarUrl.val(item.avatar);

      let name = _this.inputContentSpeakersName.last();
      name.val(item.name);

      let position = _this.inputContentSpeakersPosition.last();
      position.val(item.position);

      let introduce = _this.inputContentSpeakersIntroduce.last();
      introduce.val(item.introduce);

      _this.contentSpeakersController.updateUniqId(
        $('.js-content-speaker').last()
      );
    });

    _this.contentSpeakersController.updateContentSpeakersCount();
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get selectEmEventId() {
    return $(this.selectEmEventIdTarget);
  }

  get selectEmEventContentId() {
    return $(this.selectEmEventContentIdTarget);
  }

  get inputTitle() {
    return $('.js-input-title');
  }

  get inputSubtitle() {
    return $('.js-input-subtitle');
  }

  get inputOverview() {
    return $('.js-input-overview');
  }

  get listContentSpeakers() {
    return $('.js-list-content-speakers');
  }

  get imgContentSpeakersAvatar() {
    return $('.js-img-content-speaker-avatar');
  }

  get inputContentSpeakersAvatar() {
    return $('.js-input-content-speaker-avatar');
  }

  get hiddenContentSpeakersAvatarUrl() {
    return $('.js-hidden-content-speaker-avatar-url');
  }

  get inputContentSpeakersName() {
    return $('.js-input-content-speaker-name');
  }

  get inputContentSpeakersPosition() {
    return $('.js-input-content-speaker-position');
  }

  get inputContentSpeakersIntroduce() {
    return $('.js-input-content-speaker-introduce');
  }

  get selectEmServiceId() {
    return this._videoServicesCtrlr.selectEmServiceId;
  }

  get listVideoServices() {
    return this._videoServicesCtrlr.listVideoServices;
  }

  ////
  // GETTER VALUE METHODS
  ////

  get emEventAjaxUrl() {
    let _this = this;
    let ajaxUrl = _this.selectEmEventIdTarget.dataset.ajaxUrl;
    return ajaxUrl.replace('EM_EVENT_ID', _this.emEventId);
  }

  get emEventId() {
    return this.selectEmEventId.val();
  }

  get emEventContentId() {
    return this.selectEmEventContentId.val();
  }

  get templateContentSpeaker() {
    return this.contentSpeakersController.templateContentSpeaker;
  }

  ////
  // GETTER CONTROLLER
  ////

  get editVideoController() {
    return this.getController('shared--videos--edit-video');
  }

  get contentSpeakersController() {
    return this.getController('shared--videos--form--content-speakers');
  }

  get _videoServicesCtrlr() {
    return this.getController('shared--videos--form--video-services');
  }

  get _emServiceSelectCtrlr() {
    return this.getController('shared--videos--form--em-service-select');
  }
}
