// Import custom libraries
import _ from 'lodash'
import ApplicationController from '../application_controller'
import { showLoaderFor } from '../../lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = [ 'textSearch', 'itemSearch', 'searchAll' ]

  connect(){
    super.connect()
  }

  select(el) {
    if (this.targets.find('searchAll') != null) {
      $(this.searchAllTarget).prop('checked', this.allChecked)
    }

    this.performReflex(el)
  }

  search(_el) {
    var input = this.textSearchTarget.value;
    var filter = input.toUpperCase();

    this.itemSearchTargets.forEach((el, _i) => {
      var txtValue = el.parentElement.textContent || el.parentElement.innerText;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        el.parentElement.parentElement.classList.remove('d-none');
        el.classList.add('filtered');
      } else {
        el.parentElement.parentElement.classList.add('d-none');
        el.classList.remove('filtered');
      }
    })
  }

  checkAll(el) {
    if (this.targets.find('searchAll') != null) {
      var value = $(this.searchAllTarget).prop('checked')
      this.filtered.forEach(el => $(el).prop('checked', value))
    }

    this.performReflex(el)
  }

  performReflex(el) {
    // ページング
    if (el.target.parentElement.dataset.page != null) {
      this.stimulate('Exhibitors::AttendeeSearchReflex#perform', { serializeForm: true })
        .catch(payload => {
          window.location.reload()
        })

      el.preventDefault()
      return
    }

    if (_.isEqual(el.target.dataset.type, 'input')) {
      // テキスト入力
      if (_.isEmpty(el.target.value)) {
        this.labelInputSearch(el).removeClass('search__label--active')
      } else {
        this.labelInputSearch(el).addClass('search__label--active')
      }

      this.stimulate('Exhibitors::AttendeeSearchReflex#perform', { serializeForm: true })
        .catch(payload => {
          window.location.reload()
        })
    } else {
      // チェックボックス選択
      if (this.filteredChecked.length > 0) {
        this.labelInputSearch(el).addClass('search__label--active')
      } else {
        this.labelInputSearch(el).removeClass('search__label--active')
      }

      this.stimulate('Exhibitors::AttendeeSearchReflex#perform', { serializeForm: true })
        .catch(payload => {
          window.location.reload()
        })
    }
  }

  performSuccess() {
    // Reload tooltip
    this.itemColumns.tooltip()
    SyncScroll.reset()
  }

  labelInputSearch(e) {
    return $(e.target.parentElement).parents('.search__content').find('.search__label').addClass('search__label--active')
  }

  get itemColumns() {
    return $('.table-data__column__item')
  }

  get checkedIds() {
    return this.itemSearchTargets.filter(checkbox => checkbox.checked)
                                 .map(checkbox => checkbox.id)
  }

  get allChecked() {
    return this.filteredChecked.length === this.filtered.length
  }

  get filteredChecked() {
    return this.filtered.filter(checkbox => checkbox.checked)
  }

  get filtered() {
    return this.itemSearchTargets.filter(checkbox => $(checkbox).hasClass('filtered'));
  }
}
