// event_videoからの移管ファイル

// Import stimulus libraries
import { SimpleAjaxForm }              from '@video_lib/helpers/simple_ajax_form'

// Import helper libraries
import { UploadAndPreviewImageHelper } from '@video_lib/helpers/upload_and_preview_image_helper'

export default class extends SimpleAjaxForm {
  ////
  // TARGETS
  ////

  static targets = ['inputThumbnail']

  ////
  // EVENT METHODS
  ////

  previewThumbnail(event) {
    let _this = this

    let input = _this.inputThumbnail[0]
    let img = _this.imgThumbnail[0]
    // don't valid video thumbnail size
    let size = ''

    let message = UploadAndPreviewImageHelper(input, img, size)
    if (message) {
      _this.displayError(input, [message])
      $(input).val('')
    } else {
      _this.clearError(input)
    }
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    let [response, _status, _xhr] = event.detail
    let html = response.data.html_updated_user

    $('#js-video-updated-user').html(html)
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get imgThumbnail() {
    return $('.js-img-thumbnail')
  }

  get inputThumbnail() {
    return $(this.inputThumbnailTarget)
  }
}
