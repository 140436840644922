import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ['targetVideoItems', 'targetEventItems']

  connect(){
    super.connect()
  }

  toggle_arrow_icon(event) {
    const search_item = event.target.dataset.searchItem;
    const arrow_drop_up = document.querySelector('.' + search_item + '_arrow_drop_up');
    const arrow_drop_down = document.querySelector('.' + search_item + '_arrow_drop_down');

    arrow_drop_up.classList.toggle('d-none');
    arrow_drop_down.classList.toggle('d-none');
  }

  change_datetime(){
    const start_datetime = document.querySelector('#search_start_datetime');
    const end_datetime = document.querySelector('#search_end_datetime');

    if (start_datetime.value || end_datetime.value) {
      document.querySelector('.datetime_search_label').classList.add('search__label--active');
    } else {
      document.querySelector('.datetime_search_label').classList.remove('search__label--active');
    }
  }

  live_text_search(event) {
    const input_text = event.target.value.toUpperCase();
    const section_name = event.target.dataset.sectionName;
    const search_items = this.target_items(section_name);

    search_items.forEach((element) => {
      const search_text = element.dataset.search_name;
      const visible_target_element = document.querySelector('.' + element.dataset.visible_target_element);

      if (search_text.toUpperCase().indexOf(input_text) > -1) {
        visible_target_element.classList.remove('d-none');
        element.classList.add('filtered');
      } else {
        visible_target_element.classList.add('d-none');
        element.classList.remove('filtered');
      }
    })
  }

  check_all(event) {
    const section_name = event.target.dataset.section_name;
    const checked_value = event.target.checked;
    const checkbox_items = this.target_items(section_name);

    checkbox_items.filter(item => item.classList.contains('filtered')).forEach(element => element.checked = checked_value);

    if (checked_value == true) {
      document.querySelector('.' + section_name + '_search_label').classList.add('search__label--active');
    } else {
      document.querySelector('.' + section_name + '_search_label').classList.remove('search__label--active');
    }
  }

  checkbox_checked(event) {
    const section_name = event.target.dataset.section_name;
    const checked_value = event.target.checked;
    const checkbox_items = this.target_items(section_name);

    if (checkbox_items.filter(element => element.checked).length > 0) {
      document.querySelector('.' + section_name + '_search_label').classList.add('search__label--active');
    } else {
      document.querySelector('.' + section_name + '_search_label').classList.remove('search__label--active');
    }
  }

  target_items(section_name){
    let items = [];

    if (section_name == 'target_video') {
      items = this.targetVideoItemsTargets;
    } else if (section_name == 'target_event') {
      items = this.targetEventItemsTargets;
    }

    return items;
  }
}
