// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';
import { confirmModal } from '../../lib/helpers/js_modals';
export default class extends SimpleAjaxForm {
  static targets = ['form', 'submitBtn', 'roleSelect'];

  ////
  // VALIDATION RULES
  ////

  static i18n_error_base = 'activerecord.errors.models.user';

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(`${this.i18n_error_base}.attributes.name.blank`)}`,
      },
      length: {
        maximum: 40,
        tooLong: `^${I18n.t(
          `${this.i18n_error_base}.attributes.name.too_long`,
          { count: 40 }
        )}`,
      },
    },
    company_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(
          `${this.i18n_error_base}.attributes.company_name.blank`
        )}`,
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t(
          `${this.i18n_error_base}.attributes.company_name.too_long`,
          { count: 100 }
        )}`,
      },
    },
    role: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t(`${this.i18n_error_base}.attributes.role.blank`)}`,
      },
    },
    email: () => {
      return {
        email: {
          message: `^${I18n.t(
            `${this.i18n_error_base}.attributes.email.invalid`
          )}`,
        },
      };
    },
  };

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    return el.nextElementSibling;
  }

  connect() {
    super.connect();

    if (this.allowInitSelect()) {
      $(this.roleSelectTarget).selectpicker();
    }
  }

  clearForm() {
    let _this = this;

    this.attributes.forEach(({ el }) => {
      if (el.tagName == 'SELECT') {
        this.resetSelectPicker();
      } else {
        el.classList.remove('error');
        el.value = '';
        _this.errorMessageEl(el).innerHTML = '';
      }
    });
  }

  clearError(el) {
    if (el.tagName != 'SELECT') {
      el.classList.remove('error');
      this.errorMessageEl(el).innerHTML = '';
    }
  }

  ////
  // NEW METHODS
  ////
  // Define error message to notify
  get messages() {
    let messages = {
      error: I18n.t('messages.notify.general.error'),
    };
    return messages;
  }

  allowInitSelect() {
    return $('.selectpicker').length > 0;
  }

  resetSelectPicker() {
    $(this.roleSelectTarget).val('owner');
    $(this.roleSelectTarget).selectpicker('refresh');
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = '';
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    if (isAllow) {
      this.submitBtnTarget.removeAttribute('disabled');
    } else {
      this.submitBtnTarget.setAttribute('disabled', '');
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path;
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message

    alertify.error(this.messages.error);

    let [response, _status, _xhr] = e.detail;
    let errors = response.errors;
    let _this = this;

    let error_attributes = Object.keys(errors);

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute]);
      }
    });
  }

  // Delete user
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('admin.confirm_modals.delete_user.title'),
      message: I18n.t('admin.confirm_modals.delete_user.content'),
      labels: {
        ok_btn: I18n.t('admin.confirm_modals.delete_user.buttons.confirm'),
        cancel_btn: I18n.t('admin.confirm_modals.delete_user.buttons.cancel'),
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function () {
            alertify.error(I18n.t('messages.notify.general.error'));
          },
        });
      },
    });
  }
}
