export class TableScroll {
  constructor(element) {
    if (!element) {
      throw new Error('First argument is query selector to your navigation.')
    }

    this.tableContainer = element instanceof HTMLElement ? element : document.querySelector(element)
    this.tableScroll = this.tableContainer.querySelector('.table-aside')
    this.tableFixedHeader = this.tableScroll.querySelector('.table-header-aside')
    this.tableFixedRows = this.tableScroll.querySelectorAll('.table-td-aside')

    this.initSeparate()
    this.initClickable()

    var _this = this
    window.onresize = function(_event) {
      _this.initSeparate()
    }
  }

  initSeparate() {
    if (!this.tableFixedHeader || !this.tableFixedRows) return

    if (this.tableContainer.offsetWidth < this.tableScroll.scrollWidth) {
      this.tableFixedHeader.classList.add('separate')
      this.tableFixedRows.forEach((el) => el.classList.add('separate'))
    } else {
      this.tableFixedHeader.classList.remove('separate')
      this.tableFixedRows.forEach((el) => el.classList.remove('separate'))
    }
  }

  initClickable() {
    this.rows = document.querySelectorAll('.table-row')

    this.rows.forEach((el) => {
      if(el.classList.contains('clickable')) {
        el.addEventListener('click', (e) => {
          if(e.target.classList.contains('clickable')) return

          let anchor = el.querySelector('.js-hidden-anchor')
          if(anchor) anchor.click()
        })
      }
    })
  }
}