import { Controller } from "stimulus"
import { Attributes } from "./attributes"
import { Errors } from "./errors"
import { Validator } from "./validator"
import { attributeFromElement } from "./element_attribute"
import { restrictedDomains, letterRegex, numberRegex } from "@lib/helpers/validation_helper"

export class ValidationController extends Controller {
  static rules = {} // override in subclass
  static validators = [] // override in subclass

  connect() {
    this.errors = new Errors()
    this.attributes = new Attributes(this)
    this._validator = new Validator(this)
  }

  validEmail({attr, value}) {
    let domain = value.split('@')[1]
    let isInvalid = restrictedDomains.includes(domain)

    if(isInvalid) {
      this.errors.add(attr, '')
    }
  }

  validPassword({attr, value}) {
    if(!value.match(letterRegex)) {
      this.errors.add(attr, I18n.t('shared.users.profiles.password_only_letters_validate'))
    } else if(!value.match(numberRegex)) {
      this.errors.add(attr, I18n.t('shared.users.profiles.password_only_number_validate'))
    }
  }

  validate(event) {
    const el        = event.currentTarget
    const attribute = attributeFromElement(el)

    if (!this.attributes.has(attribute)) {
      this.attributes.add(attribute, el)
    }

    const { value } = this.attributes.get(attribute)

    this.runValidator(attribute)
    this.afterValidate({ el, attr: attribute, value })
  }

  validateManually(el) {
    const attribute = attributeFromElement(el)

    if (!this.attributes.has(attribute)) {
      this.attributes.add(attribute, el)
    }

    const { value } = this.attributes.get(attribute)

    this.runValidator(attribute)
    this.afterValidate({ el, attr: attribute, value })
  }

  afterValidate(attribute) {
    // override in subclass
  }

  afterValidateAll(event) {
    // override in subclass
  }

  runValidator(attribute) {
    this._validator.run(attribute)
  }

  validateAll(event) {
    this.attributes.refresh()
    this.errors.clear()

    this.attributes.forEach(({ el, value }, attribute) => {
      this.runValidator(attribute)

      if (this.errors.hasAny()) {
        event.preventDefault()
      }

      this.afterValidate({ el, attr: attribute, value })
    })

    this.afterValidateAll(event)
  }

  get validatejs() {
    return Validator.validatejs
  }

  static get validatejs() {
    return Validator.validatejs
  }
}
