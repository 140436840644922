import I18n from '@video_lib/i18n-js/init.js.erb';

export const confirmModal = ({ title, message, onOk = () => {}, onCancel = () => {} }) => {
  alertify.confirm(title, message, onOk, onCancel)
          .set('closable', false)
          .set('reverseButtons', true)
          .set('labels', {
            ok: I18n.t('buttons.confirm'),
            cancel: I18n.t('buttons.cancel')
          })
          .set('movable', false)
}
