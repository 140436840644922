// event_videoからの移管ファイル

// Import webpack libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { Constant }                       from '@video_lib/constant.js.erb'
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'

export default class extends SimpleAjaxForm {
  static targets = ['form', 'serviceWrapper']

  static rules = {
    title: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('video', 'title', 'blank') }`
      },
      length: {
        maximum: Constant.settings.video.title.length.maximum,
        message: `^${ ActiveRecordErrorMessageHelper('video', 'title', 'too_long', { count: Constant.settings.video.title.length.maximum }) }`
      }
    },
    subtitle: {
      length: {
        maximum: Constant.settings.video.subtitle.length.maximum,
        message: `^${ ActiveRecordErrorMessageHelper('video', 'subtitle', 'too_long', { count: Constant.settings.video.subtitle.length.maximum }) }`
      }
    },
    overview: {
      length: {
        maximum: Constant.settings.video.overview.length.maximum,
        message: `^${ ActiveRecordErrorMessageHelper('video', 'overview', 'too_long', { count: Constant.settings.video.overview.length.maximum }) }`
      }
    },
    recorded_at: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('video', 'recorded_at', 'blank') }`
      },
      format: {
        pattern: /[1-9][0-9]{3}\/[0-1][0-9]\/[0-3][0-9]/, // yyyy/mm/dd
        message: `^${ ActiveRecordErrorMessageHelper('video', 'recorded_at', 'invalid') }`
      }
    },
    thumbnail: function(value, attribute, validatorOptions, attributes, globalOptions) {
      let thumbnail = $('.js-img-thumbnail')
      let input = $('.js-input-thumbnail')

      if (thumbnail.attr('src')) {
        input.next().empty()
        return true
      }

      return {
        presence: {
          allowEmpty: false,
          message: `^${ ActiveRecordErrorMessageHelper('video', 'thumbnail', 'blank') }`
        }
      }
    }
  }

  connect() {
    this.fetchContent()
  }

  fetchContent() {
    let request = this.serviceWrapperTarget.dataset.url

    fetch(request)
      .then((response) => {
        response.text().then((text) => (this.renderContent(text)));
      })
      .catch((error) => {
        this.renderContent("Could not load data");
      });
  }

  renderContent(content) {
    this.serviceWrapperTarget.innerHTML = content
    this.dispatchEvent("lazy_load:complete")
  }

  dispatchEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }

  ////
  // VALIDATE METHODS
  ////

  validateAll(event) {
    let _this = this

    // skip validate with draft video
    if (_this.status == 'draft') {
      this.footerController.enableButtons()
      return
    }

    this.footerController.validateAll(event)
    this.contentSpeakersController.validateAll(event)
    this.videoSectionController.validateAll(event)
    this.videoServicesController.validateAll(event)

    super.validateAll(event)
    this.footerController.enableButtons()
  }

  validateAllForce(event) {
    super.validateAll(event)
    this.footerController.enableButtons()
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    let _this = this

    alertify.success(I18n.t('alertify.success'))

    _this.footerController.enableButtons()
    _this.basicInfoController.successSubmit(event)
    _this.contentSpeakersController.successSubmit(event)
    _this.videoServicesController.successSubmit(event)
    _this.contentDocumentsController.successSubmit(event)
    // _this.videoSectionController.successSubmit(event)
    this.footerController.successSubmit(event)

    let [response, _status, _xhr] = event.detail
    let redirect_path = response.data.redirect_path
    if (redirect_path) {
      window.location.href = redirect_path
    }
  }

  failedSubmit(event) {
    this.footerController.enableButtons()

    alertify.error(I18n.t('alertify.error'))

    let [response, _status, _xhr] = event.detail
    let errors = response.errors
    let _this = this
    let error_attributes = Object.keys(errors)

    if(typeof(this.attributes) != 'undefined'){
      this.attributes.forEach(({ attribute, el }) => {
        if (_.includes(error_attributes, attribute)) {
          _this.displayError(el, errors[attribute])
        }
      })
    }
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get hiddenStatus() {
    return $('.js-hidden-status')
  }

  ////
  // GETTER VALUE METHODS
  ////

  get status() {
    return this.hiddenStatus.val()
  }

  get basicInfoController() {
    return this.getController('shared--videos--form--basic-info')
  }

  get footerController() {
    return this.getController(Constant.settings.stimulus.video_form.footer_controller)
  }

  get contentSpeakersController() {
    return this.getController('shared--videos--form--content-speakers')
  }

  get contentDocumentsController() {
    return this.getController('shared--videos--form--content-documents')
  }

  get videoSectionController() {
    return this.getController('shared--videos--form--video-section')
  }

  get videoServicesController() {
    return this.getController('shared--videos--form--video-services')
  }
}
