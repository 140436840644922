// event_videoからの移管ファイル

import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import Rails from '@rails/ujs';
import { el } from 'redom';
import { Constant } from '@video_lib/constant.js.erb';
import { showLoaderFor } from '@video_lib/helpers/view_helpers';

export default class extends SimpleAjaxForm {
  static targets = ['selectTag', 'serviceOpt'];

  enableOption(emServiceId) {
    let opt = this.serviceOptTargets.find(
      (opt) => opt.value == emServiceId.toString()
    );
    opt.disabled = false;

    this._refresh();
  }

  pickService(evt) {
    if (!evt.target.value) return;

    let option = this.serviceOptTargets.find(
      (opt) => opt.value === evt.target.value
    );

    let emService = JSON.parse(option.dataset.emService);

    this.selectTagTarget.value = '';
    option.disabled = true;

    this._videoServicesCtrlr.addVideoServiceItem(emService);

    this._refresh();
  }

  populateEventContentData(emEventContent) {
    // Enable all options
    this.serviceOptTargets.forEach((opt) => (opt.disabled = false));

    // Disable any options that match the value of serviceIds
    let serviceIds = emEventContent.services.map((service) =>
      service.id.toString()
    );
    this.serviceOptTargets.forEach(
      (opt) => (opt.disabled = serviceIds.includes(opt.value))
    );

    this._refresh();
  }

  ajaxPopulateProgress() {
    this.selectTagTarget.classList.add('d-none');
    showLoaderFor(this.editVideoController.serviceWrapperTarget, 'sm');
    this.editVideoController.serviceWrapperTarget.classList.add('mb-16');
  }

  ajaxPopulate(emEventId) {
    // FIXME: 存在しないエンドポイントへのアクセス
    let path = !emEventId
      ? Routes.event_management_services_path()
      : Routes.owner_services_path({ event_id: emEventId });

    Rails.ajax({
      url: path,
      type: 'GET',
      dataType: 'JSON',
      success: (response) => {
        $(this.serviceOptTargets).remove();
        let optionDoms = response.data.map((emService) =>
          this._optionDom(emService)
        );
        $(this.selectTagTarget).append(optionDoms);

        this._refresh();
      },
      complete: () => {
        this.editVideoController.serviceWrapperTarget.lastChild.remove();
        this.selectTagTarget.classList.remove('d-none');
      },
    });
  }

  _optionDom(emService) {
    let ctrlr = this.identifier;
    let optAttrs = {
      value: emService.id,
      'data-em-service': JSON.stringify(emService),
    };
    optAttrs[`data-${ctrlr}-target`] = 'serviceOpt';

    return el('option', emService.name, optAttrs);
  }

  _refresh() {
    let disabledOpts = this.serviceOptTargets.filter((opt) => opt.disabled);
    let shouldDisable =
      disabledOpts.length >= Constant.settings.video.video_services.max_assign;
    this.selectTagTarget.disabled = shouldDisable;
  }

  get _videoServicesCtrlr() {
    return this.getController('shared--videos--form--video-services');
  }

  get editVideoController() {
    return this.getController('shared--videos--edit-video');
  }
}
