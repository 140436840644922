export const autoOpenContentModal = (originTarget = '', userRole = '', isRegisterForm = '') => {
  // Open event content modal if exists event content params
  let eventContentParam = location.search.match(/event_content_id=(\d+)/)
  if(!!eventContentParam) {
    // Load content for modal
    let eventContentId = eventContentParam[1];
    let eventSlug = location.pathname.split('/')[2]

    let url = userRole === 'organizer' ? Routes.show_event_content_modal_organizer_event_content_path(eventSlug, originTarget, eventContentId) :
                                         Routes.show_event_content_modal_event_event_content_path(eventContentId, { event_slug: eventSlug, request_origin: originTarget, is_register_form: isRegisterForm })

    window.Rails.ajax({
      url: url,
      type: 'GET',
      error: (e) => {},
      success: (e) => {
        $('#event-content-detail-modal').modal('show');
      }
    })
  }
}
