import _ from 'lodash';
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import I18n from '@video_lib/i18n-js/init.js.erb';
import { restrictedDomains } from "@lib/helpers/validation_helper";

export default class extends SimpleAjaxForm {
  static targets = ['form', 'required']
  static validators = { validEmail: { attributes: ['email_01', 'email_02', 'email_03' ]  } }

  static rules = {
    email_01: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
        }
      }
    },
    email_02: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
        }
      }
    },
    email_03: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
        }
      }
    }
  }

  connect() {
    super.connect()
    StimulusReflex.register(this)
  }

  validEmail({attr, value}) {
    let domain = value.split('@')[1]
    let isInvalid = restrictedDomains.includes(domain)
    let $element = $(`[data-attr="my-company--service-email-notification-form.${attr}"]`)
    let alreadyAdded = this.emailAddedValidate($element[0])

    if(isInvalid) {
      this.errors.add(attr, I18n.t('activerecord.errors.models.user.attributes.email.restrict_domain'))
    } else if(alreadyAdded) {
      this.errors.add(attr, [I18n.t('activerecord.errors.models.user.attributes.email.taken')])
    }
  }

  emailAddedValidate(el) {
    let isAdded = false

    this.requiredTargets.filter((email) => email.id != el.id).forEach((elem) => {
      let isBlank = el.value == '' && elem.value == ''

      if(!isBlank && el.value == elem.value) {
        isAdded = true
      }
    })

    return isAdded
  }

  getEmailNotifications() {
    let emailNotification = []

    this.requiredTargets.forEach((el) => {
      if(!_.isEmpty(el.value)) {
        emailNotification.push(el.value)
      }
    })

    return emailNotification
  }

  allowSubmit(el) {
    let serviceId = this.formTarget.dataset.serviceId
    let emailNotifications = {
      "service": {
        "email_notifications": this.getEmailNotifications()
      }
    }

    if(!(this.errors.dataMap.size > 0)) {
      this.stimulate('MyCompany::DrawerReflex#update_email', serviceId, emailNotifications)
    }
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
    } else {
      this.clearError(el)

      this.allowSubmit(el)
    }
  }
}
