const findElement = (root, selector) => {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export const getMetaValue = (name) => {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export const showLoaderFor = (target, size = 'xl') => {
  // Supported sizes are sm, md, lg and xl
  $(target).append(`<div class='loader ${size} position-center-auto'>`);
}
