import ApplicationController from '@controllers/application_controller';
import { stringToHTML } from '@lib/helpers/view_helpers';
import { confirmModal } from '@lib/helpers/js_modals';
export default class extends ApplicationController {
  static targets = ['input', 'addEventService', 'eventServiceListWrapper', 'serviceList', 'form']

  connect() {
    super.connect()

    this.initSelectPicker()

    setTimeout(() => {
      if(!this.inputTarget.value) {
        this.inputTarget.focus()
      }
    }, 200)
  }

  initSelectPicker() {
    $('.selectpicker').selectpicker()
  }

  initTooltip() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  hideTooltip() {
    $('[data-toggle="tooltip"]').tooltip('hide')
  }

  submit(el) {
    let formAttrs = $(this.formTarget).serializeArray()
    let submitUrl = this.formTarget.dataset.url
    let method = this.formTarget.method

    this.selectServices.forEach((el) => formAttrs.push(el) )

    if (this.inputTarget.value) {
      Rails.ajax({
        url: submitUrl,
        type: method,
        data: $.param(formAttrs),
        error: (e) => {
        },
        success: (e) => {
          this.stimulate("Organizers::ServiceGroups::ServiceReflex#reload", el.target)
        }
      })
    } else {
    }
  }

  addEventService(e) {
    this.stimulate("Organizers::ServiceGroups::ServiceReflex#append", e.target)
      .then((e) => {
        $(e.element).find(`[value=${e.element.value}]`).remove()
        $(e.element).selectpicker('refresh');
        this.initTooltip()
      })
  }

  deleteService(e) {
    var _this = this

    this.hideTooltip()
    this.stimulate("Organizers::ServiceGroups::ServiceReflex#remove", e.target)
      .then((e) => {
        _this.stimulate("Organizers::ServiceGroups::ServiceReflex#reload", _this.serviceListTarget)
        $(_this.serviceListTarget).remove()
      })
  }


  publishGroup(e) {
    this.stimulate("Organizers::ServiceGroups::ServiceReflex#publish_group", e.target)
      .then((e) => {
        this.initTooltip()
      })
  }

  unpublishGroup(e) {
    confirmModal({
      message: '下書きに戻すと、来場者に表示されませんが大丈夫ですか？',
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.stimulate("Organizers::ServiceGroups::ServiceReflex#unpublish_group", e.target)
          .then((e) => {
            this.initTooltip()
          })
      }
    })
  }

  deleteGroup(e) {
    confirmModal({
      message: 'グループを削除しますが、よろしいですか？',
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.stimulate("Organizers::ServiceGroups::ServiceReflex#delete_group", e.target)
            .then((e) => {
              $('#service-group-modal').modal('hide')
            })
      }
    })
  }

  renderEventServiceSuccess() {
    let eventServiceIds = []

    this.serviceListTargets.forEach((el) => {
      eventServiceIds.push(el.dataset.event_service_id)
    })

    return eventServiceIds
  }

  renderEventService(e) {
    let msgHTML = e.detail
    let msgDOM = stringToHTML(msgHTML)

    $(this.eventServiceListWrapperTarget).append((msgDOM[0]))
  }

  get selectServices() {
    var eventServiceIds = []

    this.serviceListTargets.forEach((el) => {
      eventServiceIds.push({ name: 'service_group[event_services_ids][]', value: el.dataset.id })
    })

    return eventServiceIds
  }
}
