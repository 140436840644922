import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import * as moment from 'moment';

// Handle event_date create and edit
export default class extends SimpleAjaxForm {
  static values = { formType: String }
  static targets = [ 'dateInput' ]

  validateDate(evt) {
    let validDate = moment(this.dateInputTarget.value, 'YYYY/MM/DD', true).isValid()
    if (validDate) return true // Continue with normal ajax flow

    evt.preventDefault() // Stop execution of ajax request
    let errMsg = I18n.t('activerecord.errors.models.event_date.attributes.date.invalid')
    alertify.error(errMsg)
  }

  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let error_message = this.pickFirstMessage(response.errors)

    // Notify error messages of date field
    alertify.error(error_message)
  }

  //// CREATE
  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  submitForm(evt) {
    if (evt.type == 'keydown' && evt.keyCode == 13) {
      Rails.fire(this.element, 'submit')
    }
  }

  dismissForm(_evt) {
    this.clearForm()

    if (this.formTypeValue === 'create') {
      this._actionDateCtrlr.dismissCreateForm()
    }
    else if (this.formTypeValue === 'edit') {
      this._actionDateCtrlr.switchToViewDate()
    }
  }

  get _actionDateCtrlr() {
    return this.application.controllers.find(ctrlr => {
      return (ctrlr.identifier === 'organizer--timetables--action-date-menu' &&
              ctrlr.element.dataset.id === this.element.dataset.id)
    });
  }
}
