module.exports = {
  initScroll: function () {
    $(window).scroll(function() {
      scroll = $(this).scrollTop()
      if (scroll > 70 ) {
        $('.header').addClass('header--scroll')
      } else {
        $('.header').removeClass('header--scroll')
      }
    })
  }
}
