import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';

export default class extends SimpleAjaxForm {
  static targets = ['input', 'form', 'wrapper']

  connect() {
    $(this.wrapperTarget).on('click', function(e) {
      e.stopPropagation()
    })

    StimulusReflex.register(this)
    this.checkValid = _.debounce(this.checkValid, 250).bind(this)
    this.initDropdownAnimate()
  }

  initDropdownAnimate() {
    $('.dropdown').on('hidden.bs.dropdown', function () {
      this.querySelector('.invitation-input').classList.remove('animate')
    })

    $('.dropdown').on('shown.bs.dropdown', function () {
      setTimeout(() => {
        this.querySelector('.invitation-input').classList.add('animate')
      }, 100)
    })
  }

  checkValid(e) {
    e.preventDefault()
    this.stimulate('Companies::MemberReflex#check_valid', e.target, this.inputTarget.value)
  }

  sendInvite(e) {
    this.stimulate('Companies::MemberReflex#send_invite', e.target, this.inputTarget.value)

    e.preventDefault()
  }

  sendInviteSuccess(e) {
    $('.action-button').remove()
    this.inputTarget.value = ''
    $(this.wrapperTarget).removeClass('show')

    alertify.success(I18n.t("messages.notify.company_member.invite_success"))
  }
}
