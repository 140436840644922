// event_videoからの移管ファイル

// Import libraries from webpacker libraries
import { VideoPlayer } from '@video_lib/helpers/video_player';
import CableReady from 'cable_ready';
import Rails from '@rails/ujs';
import { serialize } from 'object-to-formdata';

// Import custom libraries
import { Constant } from '@video_lib/constant.js.erb';
import { confirmModal } from '@video_lib/helpers/js_modals';

export default class extends VideoPlayer {
  static targets = ['videoPlayer'];
  static subscriptions = { videoUpdate: null };

  connect() {
    super.connect();

    if (this.hasPlayerTarget) this.initPlayerModal();
    this.subscribeUpdateVideoChannel(this.videoId);
  }

  disconnect() {
    if (!this.constructor.subscriptions?.videoUpdate) return;

    this.constructor.subscriptions.videoUpdate.unsubscribe();
  }

  subscribeUpdateVideoChannel(videoId) {
    // Don't subscribe when turbolink is in preview loading
    // if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
    //   return
    // }

    // if (this.constructor.subscriptions.videoUpdate) {
    //   this.constructor.subscriptions.videoUpdate.unsubscribe()
    // }

    this.constructor.subscriptions.videoUpdate =
      this.application.consumer.subscriptions.create(
        {
          channel: 'UpdateVideoChannel',
          video_id: videoId,
        },
        {
          initialized: () => {
            // console.log('subscription initialized')
          },
          connected: () => {
            // console.log('subscription connected')
          },
          disconnected: () => {
            // console.log('subscription disconnected')
          },
          rejected: () => {
            // console.log('subscription rejected')
          },
          received: (data) => {
            // console.log('subscription received: ', data)
            if (data.cableReady) {
              CableReady.perform(data.operations);
              if (this.hasPlayerTarget) {
                this.player = this.initPlayer;
                this.initPlayerModal();
              }
              this.videoSectionController.checkStartAt();
            }
          },
        }
      );
  }

  deleteVideoFile() {
    let formData = {
      video: {
        file: '',
        guid: '',
        status: 'draft',
      },
    };
    let i18nPath =
      'javascripts.controllers.shared.videos.form.form_file.modal_confirm_delete_video';

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        Rails.ajax({
          url: this.deleteFileUrl,
          type: 'DELETE',
          data: serialize(formData),
          success: (response) => {
            this.dispose();
            alertify.success(I18n.t('messages.notify.general.success'));
          },
          error: () => {
            alertify.error(I18n.t('messages.notify.general.error'));
          },
        });
      },
    });
  }

  get deleteFileUrl() {
    return this.videoPlayerTarget.dataset.delete_file_url;
  }

  get videoId() {
    return this.videoPlayerTarget.dataset.video_id;
  }

  get duration() {
    return this.videoPlayerTarget.dataset.duration;
  }

  get footerController() {
    return this.getController(
      Constant.settings.stimulus.video_form.footer_controller
    );
  }

  get videoSectionController() {
    return this.application.controllers.find(
      (controller) =>
        controller.identifier === 'shared--videos--form--video-section'
    );
  }
}
