// Import libraries from webpacker libraries
import _ from 'lodash';

// Import webpacker libraries
import { Controller }                     from 'stimulus'
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { Constant }                       from '@video_lib/constant.js.erb'

export default class extends SimpleAjaxForm {
  static targets = [
    'userInfomation', 'modalCreateUser', 'modalEditMember', 'modalEditOwner'
  ]

  showModalEditOwner(event) {
    this.modalEditOwnerController.initModal(event)
  }

  showModalEditMember(event) {
    this.modalEditMemberController.initModal(event)
  }

  showModalAddNew() {
    this.modalNewUserController.initModal()
  }

  get modalNewUserController() {
    return this.getController(Constant.settings.stimulus.users.modal.new_user_controller)
  }

  get modalEditMemberController() {
    return this.getController(Constant.settings.stimulus.users.modal.edit_member_controller)
  }

  get modalEditOwnerController() {
    return this.getController(Constant.settings.stimulus.users.modal.edit_owner_controller)
  }
}
