// Import custom libraries
import { VideoPlayer } from '@video_lib/helpers/video_player'

export default class extends VideoPlayer {
  connect() {
    super.connect()

    if(this.hasPlayerTarget) this.initPlayerModal()
  }
}
