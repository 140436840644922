// event_videoからの移管ファイル

// Import custom libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';

// Import custom libraries
import { Constant } from '@video_lib/constant.js.erb';
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper';

// Import helper libraries
import { confirmModal } from '@video_lib/helpers/js_modals';
import { el } from 'redom';
import I18n from 'i18n-js';
import { v4 as uuidv4 } from 'uuid';

export default class extends SimpleAjaxForm {
  ////
  // TARGETS
  ////

  static values = { emServiceId: String };

  static targets = ['listWrap', 'videoServiceItem', 'destroyFlag'];

  ////
  // RULES
  ////

  static rules = {
    video_services_count: {
      numericality: {
        lessThanOrEqualTo:
          Constant.settings.video.video_services.length.maximum,
        notLessThanOrEqualTo: `^${ActiveRecordErrorMessageHelper(
          'video',
          'video_services',
          'too_long',
          { count: Constant.settings.video.video_services.length.maximum }
        )}`,
      },
    },
  };

  successSubmit(evt) {
    location.href = evt.detail[0].data.redirect_path;
  }

  confirmDeleteVideoService(evt) {
    let emServiceId = evt.currentTarget.dataset.emServiceId;

    confirmModal({
      title: I18n.t(
        `${this._i18nPrefix}.modal_confirm_delete_video_service.title`
      ),
      message: I18n.t(
        `${this._i18nPrefix}.modal_confirm_delete_video_service.message`
      ),
      onOk: () => {
        let item = this._findVideoServiceItem(emServiceId);
        this._dismissVideoServiceItem(item);
        this._emServiceSelectCtrlr.enableOption(emServiceId);
      },
    });
  }

  populateEventContentData(emEventContent) {
    // Clear all items
    this.clearItems();

    // Add services and pre-select the documents
    let doms = emEventContent.services.map((emService) =>
      this._prepareEventServiceDom(emService)
    );
    $(this.listWrapTarget).append($(doms));

    // Wait until stimulus finishes initializing
    setTimeout(() => {
      // サービスの詳細情報を更新
      emEventContent.services.forEach((emService) => {
        let item = this._findVideoServiceItem(emService.id);
        this._useVideoServiceItem(item);

        this._docsSelectCtrlr(emService.id).populateServiceData(emService);
        this._docsViewCtrlr(emService.id).populateServiceData(emService);
      });
    }, 250);
  }

  clearItems() {
    this.videoServiceItemTargets.forEach((item) => {
      item.dataset.isDestroyed = 'destroyed';
      item.classList.add('d-none');
    });

    this.destroyFlagTargets.forEach((flag) => (flag.value = true));
  }

  addVideoServiceItem(emService) {
    let dom = this._prepareEventServiceDom(emService);
    $(dom).appendTo($(this.listWrapTarget));

    // Wait until stimulus finishes initializing
    setTimeout(() => {
      let item = this._findVideoServiceItem(emService.id);
      this._useVideoServiceItem(item);

      this._docsSelectCtrlr(emService.id).reload(emService.documents);
      this._docsViewCtrlr(emService.id).clearItems();
    }, 250);
  }

  // PRIVATE METHODS

  _findVideoServiceItem(emServiceId) {
    return this.videoServiceItemTargets.find(
      (item) => item.dataset.emServiceId == emServiceId.toString()
    );
  }

  _prepareEventServiceDom(emService) {
    // Reuse dom if exist, otherwise generate a new one
    return (
      this._findVideoServiceItem(emService.id) ||
      this._videoServiceDom(emService)
    );
  }

  _videoServiceDom(emService) {
    let ctrlr = this.identifier;

    // Hidden part
    let namePrefix = 'video[video_services_attributes[]]';

    let idDom = el('input.js-hidden-video-service-id', {
      type: 'hidden',
      name: `${namePrefix}[id]`,
      value: '',
      id: `dom_${uuidv4()}`,
    });
    let emServiceIdDom = el('input.js-hidden-video-service-em-service-id', {
      type: 'hidden',
      name: `${namePrefix}[em_service_id]`,
      value: emService.id,
      id: `dom_${uuidv4()}`,
    });

    let destroyAttrs = {
      type: 'hidden',
      name: `${namePrefix}[_destroy]`,
      value: false,
      'data-em-service-id': emService.id,
      id: `dom_${uuidv4()}`,
    };
    destroyAttrs[`data-${ctrlr}-target`] = 'destroyFlag';

    let destroyDom = el('input.js-hidden-video-service-destroy', destroyAttrs);
    let hiddenDoms = [idDom, emServiceIdDom, destroyDom];

    // Visual part
    let btnAttrs = {
      type: 'button',
      title: I18n.t('delete'),
      'data-action': `click->${ctrlr}#confirmDeleteVideoService`,
      'data-toggle': 'tooltip',
      'data-placement': 'top',
      'data-em-service-id': emService.id,
    };
    let dismissBtnDom = el(
      '.box-content__remove',
      el(
        'button.btn.close.btn--icon.btn--icon-small',
        btnAttrs,
        el('span.material-icons.icons', 'close')
      )
    );
    let infoDom = el('.d-flex', [
      el(
        '.box-content__image',
        el(
          '.thumbnail',
          el(
            '.thumbnail__img.thumbnail__img--minium.image',
            el('img.img.image__center.js-img-video-service-em-service-logo', {
              src: emService.logo,
            })
          )
        )
      ),
      el('.box-content__info', [
        el('.body-2', emService.name),
        el('.body-1.mt-16.line-height-initial', emService.description),
      ]),
    ]);

    let companyDom = el('.box-content__company.mt-xlarge', [
      el(
        '.caption-2.mb-medium',
        I18n.t(`${this._emServiceI18nPrefix}.company`)
      ),
      el('.text-body-2.js-service-company-name', emService.company_name),
    ]);

    let visualDoms = el('.box-content.d-block', [
      dismissBtnDom,
      infoDom,
      companyDom,
      this._genDocsSelectDom(emService),
      this._genDocsViewDom(emService),
    ]);

    let itemAttrs = { 'data-em-service-id': emService.id };
    itemAttrs[`data-${ctrlr}-target`] = 'videoServiceItem';

    return el('.js-video-service', itemAttrs, hiddenDoms.concat(visualDoms));
  }

  _genDocsSelectDom(emService) {
    let captionText = I18n.t(
      `${this._emServiceI18nPrefix}.select_documents.label`,
      { max_docs: Constant.settings.video.video_services.max_docs }
    );
    let captionDom = el('.caption-2.mb-medium', captionText);

    let ctrlr = 'shared--videos--form--service-documents-select';
    let selectAttrs = {
      'data-controller': ctrlr,
      'data-action': `change->${ctrlr}#pickDocument`,
      name: 'em_document_id',
      title: I18n.t(
        `${this._emServiceI18nPrefix}.select_documents.placeholder`
      ),
    };
    selectAttrs[`data-${ctrlr}-target`] = 'selectTag';
    selectAttrs[`data-${ctrlr}-em-service-id-value`] = emService.id;
    selectAttrs[`data-${ctrlr}-selected-count-value`] = '0';

    let docOptDoms = emService.documents.map((document) => {
      let optAttrs = {
        value: document.id,
        'data-em-document-id': document.id,
        'data-em-document-title': document.title,
      };
      optAttrs[`data-${ctrlr}-target`] = 'docOption';
      return el('option', document.title, optAttrs);
    });

    selectAttrs[`data-${ctrlr}-target`] = 'selectTag';
    selectAttrs[`data-${ctrlr}-em-service-id-value`] = emService.id;
    selectAttrs[`data-${ctrlr}-selected-count-value`] = '0';

    let selectDom = el(
      'select.form-control.selectpicker.js-docs-select',
      selectAttrs,
      docOptDoms
    );

    return el('.box-content__documents-select.mt-xlarge', [
      captionDom,
      selectDom,
    ]);
  }

  _genDocItemDom(document) {
    let ctrlr = 'shared--videos--form--service-documents-select-view';
    let docItemAttrs = { 'data-em-document-id': document.id };
    docItemAttrs[`data-${ctrlr}-target`] = 'documentItem';

    let btnAttrs = {
      title: I18n.t('delete'),
      type: 'button',
      'data-toggle': 'tooltip',
      'data-placement': 'top',
      'data-action': `click->${ctrlr}#dismissDocumentItem`,
      'data-em-document-id': document.id,
      'data-em-document-title': document.title,
    };
    btnAttrs[`data-${ctrlr}-target`] = 'dismissBtn';
    let btnDom = el(
      'button.btn.close.btn--icon.btn--icon-small',
      btnAttrs,
      el('span.material-icons.icons', 'close')
    );

    let namePrefix =
      'video[video_services_attributes[]][video_service_documents_attributes[]]';
    let destroyFlagAttrs = {
      type: 'hidden',
      name: `${namePrefix}[_destroy]`,
      value: false,
      'data-em-document-id': document.id,
      id: `dom_${uuidv4()}`,
    };
    destroyFlagAttrs[`data-${ctrlr}-target`] = 'destroyFlag';

    let destroyDom = el('input', destroyFlagAttrs);

    return el('.doc-item', docItemAttrs, [
      el('span', document.title),
      btnDom,
      el('input', {
        type: 'hidden',
        name: `${namePrefix}[id]`,
        value: '',
        id: `dom_${uuidv4()}`,
      }),
      el('input', {
        type: 'hidden',
        name: `${namePrefix}[em_document_id]`,
        value: document.id,
        id: `dom_${uuidv4()}`,
      }),
      destroyDom,
    ]);
  }

  _genDocsViewDom(emService) {
    let ctrlr = 'shared--videos--form--service-documents-select-view';

    let docsWrapAttrs = { 'data-controller': ctrlr };
    docsWrapAttrs[`data-${ctrlr}-target`] = 'root';
    docsWrapAttrs[`data-${ctrlr}-em-service-id-value`] = emService.id;

    return el(
      '.box-content__documents-select-view.mt-medium',
      el(
        '.doc-items-wrap.border-radius-sm.js-docs-select-view.d-none',
        docsWrapAttrs
      )
    );
  }

  _dismissVideoServiceItem(item) {
    item.dataset.isDestroyed = 'destroyed';
    item.classList.add('d-none');

    let destroyFlag = this.destroyFlagTargets.find(
      (flag) => flag.dataset.emServiceId == item.dataset.emServiceId
    );
    destroyFlag.value = true;
  }

  _useVideoServiceItem(item) {
    item.dataset.isDestroyed = '';
    item.classList.remove('d-none');

    let destroyFlag = this.destroyFlagTargets.find(
      (flag) => flag.dataset.emServiceId == item.dataset.emServiceId
    );
    destroyFlag.value = false;
  }

  _docsSelectCtrlr(emServiceId) {
    return this.application.controllers.find((ctrlr) => {
      return (
        ctrlr.identifier == 'shared--videos--form--service-documents-select' &&
        ctrlr.emServiceIdValue == emServiceId.toString()
      );
    });
  }

  _docsViewCtrlr(emServiceId) {
    return this.application.controllers.find((ctrlr) => {
      return (
        ctrlr.identifier ==
          'shared--videos--form--service-documents-select-view' &&
        ctrlr.emServiceIdValue == emServiceId.toString()
      );
    });
  }

  ////
  // GETTER VALUE METHODS
  ////

  get _i18nPrefix() {
    return 'javascripts.controllers.shared.videos.form.video_services';
  }

  get _emServiceI18nPrefix() {
    return 'owner.videos.detail.detail_em_services';
  }

  get _emServiceSelectCtrlr() {
    return this.getController('shared--videos--form--em-service-select');
  }
}
