import { imageTypes } from "@lib/helpers/validation_helper";
import FileSizeLessThan from "./check_file_size_less_than_helper";
export default function UploadAndPreviewImage(el, elSrc, size) {
  let file = el.target.files[0];

  if (imageTypes.indexOf(file.type) == -1) {
    let errorMessage = I18n.t('activerecord.errors.models.service.attributes.logo.invalid')
    return errorMessage
  }
  if (!FileSizeLessThan(file, size * 1024 * 1024)) {
    let errorMessage = I18n.t('activerecord.errors.models.service.attributes.logo.too_large', { count: size })
    return errorMessage
  }
  let url = window.URL.createObjectURL(file);
  elSrc.src = url;
  return ''
}
