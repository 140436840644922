// event_videoからの移管ファイル

// Import custom libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form'
import { Constant }       from '@video_lib/constant.js.erb'
// Import stimulus libraries
import _                  from 'lodash'

export default class extends SimpleAjaxForm {
  static targets = [ 'sectionRow', 'anyone', 'private', 'restrictBar', 'templateForm', 'templateRow' ]

  connect() {
    super.connect()

    this.initSortable()
    this.initRestrict()
  }

  //////
  // init method
  //////

  initSortable() {
    let _this = this

    $('#sortable').sortable({
      cancel: '.js-list-item, .js-add-section',
      axis: 'y',
      containment: 'parent',
      update: (_, ui) => {
        _this.toggleTitleClass(ui.item.prevAll(), 'anyone', true)
        _this.toggleTitleClass(ui.item.nextAll(), 'private', true)
      }
    });
  }

  initRestrict() {
    let breakIndex = this.getBreakRowIndex()
    this.initRestrictCss(breakIndex, false)
  }

  //////
  // action method
  /////

  targetChange() {
    this.initRestrict()
  }

  removeSection(event) {
    let target = event.target.closest('.js-list-item')

    this.setValueWithClass(target, 'input.js-destroy', 'true')
    $(target).addClass('d-none')

    this.checkStartAt()
  }

  toggleForm(event) {
    let templateForm = this.templateFormTarget.content.cloneNode(true)
    let content = templateForm.firstChild

    let selectorId = event.currentTarget.dataset.selector_id
    let jquerySelector = $(selectorId)
    if(jquerySelector.hasClass('d-none')) return

    if(selectorId == undefined) {
      // case edit
      jquerySelector = $(event.currentTarget.closest('.js-list-item'))
      let startAt = jquerySelector.find('input.js-start-at').val()
      let title = jquerySelector.find('input.js-title').val()

      $(content).find('input.js-start-at').val(startAt)
      $(content).find('input.js-title').val(title)
    } else {
      // case add new
      let nextStartAt = this.getNextStartAt()

      $(content).find('input.js-start-at').val(nextStartAt)
    }

    jquerySelector.addClass('d-none')
    jquerySelector.before(content)
  }

  //////
  // validate
  //////

  validateAll(event) {
    if(this.errors.hasAny()) event.preventDefault()
  }

  //////
  // private method
  //////

  getBreakRowIndex() {
    // get index of row is `anyone` and next row is `private`
    let breakRowIndex = _.findIndex(this.sectionRows,(row, index)=>{
      let publicTargetRow = this.getValueWithClass(row, 'input.js-public-target')
      if(publicTargetRow === 'anyone') {
        let nextRow = this.sectionRows[index +1]
        let publicTargetNextRow = this.getValueWithClass(nextRow, 'input.js-public-target')
        if(publicTargetNextRow === 'private') return true
      }
    })
    // if can't find index
    // return targetsLength if all row are `anyone``
    // return -1 if all row are `private`
    if(breakRowIndex == -1) {
      let targetLastRow = this.getValueWithClass(this.sectionRows[this.sectionRows.length - 1], 'input.js-public-target')
      if(targetLastRow === 'anyone') return this.sectionRows.length
    }
    return breakRowIndex
  }

  initRestrictCss(breakRowIndex, withUpdateValue = true) {
    let breakRow = $(this.sectionRows[breakRowIndex])
    let restrictBar = $(this.restrictBarTarget)
    this.checkStartAt()

    if(this.isPrivate) {
      // show restrictBar when video's public_target is private
      restrictBar.removeClass('d-none')

      if(breakRowIndex == -1) {
        // case first section is private, need change all section to private
        restrictBar.insertBefore(this.sectionRows[0])
        this.toggleTitleClass($(this.sectionRows), 'private', withUpdateValue)
        return
      }

      if(breakRowIndex == this.sectionRows.length) {
        // case last section is anyone, need change all section to anyone
        this.toggleTitleClass($(this.sectionRows), 'anyone', withUpdateValue)
        return
      }

      // case normal, have break row
      // chane break row and previous section to anyone
      // change next section to private
      restrictBar.insertAfter(this.sectionRows[breakRowIndex])
      this.toggleTitleClass(breakRow.prevAll(), 'anyone', withUpdateValue)
      this.toggleTitleClass(breakRow, 'anyone', withUpdateValue)
      this.toggleTitleClass(breakRow.nextAll(), 'private', withUpdateValue)
    } else {
      // hide restrictBar when video's public_target is anyone
      restrictBar.addClass('d-none')
      this.toggleTitleClass($(this.sectionRows), 'anyone', withUpdateValue)
    }
  }

  toggleTitleClass(jqueryTargets, publicType, withUpdateValue) {
    if(jqueryTargets === null || jqueryTargets === undefined ) return

    if(publicType == 'private') {
      jqueryTargets.find('span.js-title-label').addClass('label--public-target-private').removeClass('label--bold')
      jqueryTargets.find('span.js-icon').removeClass('d-none')
    } else {
      jqueryTargets.find('span.js-title-label').removeClass('label--public-target-private').addClass('label--bold')
      jqueryTargets.find('span.js-icon').addClass('d-none')
    }
    if(withUpdateValue) jqueryTargets.find('.js-public-target').val(publicType)
  }

  checkStartAt() {
    let _this = this
    _this.errors.clear()

    _.each(_this.sectionRows, (row)=>{
      let $row = $(row)
      if($row.find('input.js-start-at').val() > _this.videoPlayerController.duration) {
        $row.find('span.js-title-label').addClass('label--red')
        this.errors.add('video_section_duration', 'greater than video\' duration')
      }
    })
  }

  getNextStartAt() {
    let lastRow = this.sectionRows[this.sectionRows.length - 1]
    let lastStartAt = $(lastRow).find('input.js-start-at').val()
    // if not have anyone section, next section's start_at = '00:00:00'
    if(lastStartAt == undefined || lastStartAt == '') return '00:00:00'

    // next section's start_at = last section's start at + 5 minutes
    let [hour, minute, second] = lastStartAt.split(':')
    let timeSecond = hour*3600 + minute*60 + parseInt(second) + Constant.settings.video_section.next_start_at
    let duration = this.videoPlayerController.duration

    let nextStartAt = new Date(timeSecond * 1000).toISOString().substr(11, 8)

    if(nextStartAt > duration) return duration

    return nextStartAt
  }

  //////
  // re-use method
  //////

  getValueWithClass(target, className) {
    if(target === undefined) return target
    return $(target).find(className).val()
  }

  setValueWithClass(target, className, value) {
    if(target === undefined) return target
    $(target).find(className).val(value)
  }

  get isPrivate() {
    return this.privateTarget.checked
  }

  get sectionRows() {
    return _.filter(this.sectionRowTargets, (r) => { return $(r).find('input.js-destroy').val() == 'false' })
  }

  get videoPlayerController() {
    return this.getController(Constant.settings.stimulus.video_form.video_player_controller)
  }
}
