module.exports = {
  initTooltip: function () {
    $('[data-toggle="tooltip"]').tooltip()

    $(document).on('mouseenter', '.text, span, td, p, a', function() {
      if (this.offsetWidth < this.scrollWidth) {
        $(this).attr('title', $(this).text())
        $(this).tooltip('show')
      } else {
        $(this).removeAttr('title')
      }
    })
  }
}
