// Import libraries from webpacker libraries
import Rails from '@rails/ujs';

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { Constant }                       from '@video_lib/constant.js.erb'
import { UploadAndPreviewImageHelper }    from '@video_lib/helpers/upload_and_preview_image_helper'
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'
import { urlRegex }                       from '@lib/helpers/validation_helper'
import { confirmModal }                   from '@video_lib/helpers/js_modals'

export default class extends SimpleAjaxForm {
  static targets = ['btnSubmit', 'imagePreview', 'companyLogo', 'btnOpenModalConfirmDeleteCompany']

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('company', 'name', 'blank') }`
      },
      length: {
        maximum: Constant.settings.company.name.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('company', 'name', 'too_long', { count: Constant.settings.company.name.length.maximum }) }`
      }
    },
    site_url: (value) => {
      if (_.isEmpty(value)) return;

      return {
        format: {
          pattern: urlRegex,
          message: `^${ ActiveRecordErrorMessageHelper('company', 'site_url', 'invalid') }`
        }
      }
    },
    address: {
      length: {
        maximum: Constant.settings.company.address.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('company', 'address', 'too_long', { count: Constant.settings.company.address.length.maximum }) }`
      }
    },
    phone: (value) => {
      if (_.isEmpty(value)) return;

      return {
        format: {
          pattern: Constant.settings.company.phone.format,
          message: `^${ ActiveRecordErrorMessageHelper('company', 'phone', 'invalid') }`
        },
        length: {
          maximum: Constant.settings.company.phone.length.maximum,
          minimum: Constant.settings.company.phone.length.minimum,
          tooLong: `^${ ActiveRecordErrorMessageHelper('company', 'phone', 'too_long', { count: Constant.settings.company.phone.length.maximum }) }`,
          tooShort: `^${ ActiveRecordErrorMessageHelper('company', 'phone', 'too_short', { count: Constant.settings.company.phone.length.minimum }) }`
        }
      }
    }
  }

  previewLogo(event) {
    let _this = this

    let input = _this.companyLogoTarget
    let img = _this.imagePreviewTarget
    let size = ''

    let message = UploadAndPreviewImageHelper(input, img, size)
    if (message) {
      _this.displayError(input, [message])
      $(input).val('')
    } else {
      _this.clearError(input)
    }
  }

  successSubmit(event) {
    let [response, _status, _xhr] = event.detail
    window.location.href = response.data.redirect_path
  }

  allowSubmit(isAllow) {
    let _this = this

    let isValid = !_this.errors.hasAny()

    if(isAllow && isValid) {
      _this.btnSubmitTarget.removeAttribute('disabled')
    } else {
      _this.btnSubmitTarget.setAttribute('disabled', '')
    }
  }

  clearForm() {
    let _this = this;

    _this.attributes.forEach(({ el }) => {
      el.classList.remove('error')
      el.value = ''
      _this.errorMessageEl(el).innerHTML = ''
    })

    $(_this.companyLogoTarget).val('')
    $(_this.imagePreviewTarget).removeAttr('src')
  }
}
