// Import custom libraries
import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static targets = []

  // ACTION MENU
  active(e) {
    this.stimulate('Companies::MemberReflex#active', e.target)
      .then((e) => {
        alertify.success('有効にしました')
      })
  }

  unactive(e) {
    this.stimulate('Companies::MemberReflex#unactive', e.target)
      .then((e) => {
        alertify.success('無効にしました')
      })
  }

  cancel(e) {
    this.stimulate('Companies::MemberReflex#cancel', e.target)
      .then((e) => {
        alertify.success('招待を取り消しました')
      })
  }

  resend(e) {
    this.stimulate('Companies::MemberReflex#resend', e.target)
      .then((e) => {
        alertify.success('招待メールを送信しました')
      })
  }
}
