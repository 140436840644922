import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ['input', 'form', 'wrapper']

  connect() {
    super.connect()
    this._initDropdownAnimate()

    $(this.wrapperTarget).on('click', function(e) {
      e.stopPropagation()
    })

    this.checkValid = _.debounce(this.checkValid, 400).bind(this)
  }

  checkValid(e) {
    e.preventDefault()

    this.stimulate('MyCompany::MemberReflex#check_valid', e.target, this.inputTarget.value)
      .catch(payload => {
        window.location.href = Routes.root_path()
      })
  }

  sendInvite(e) {
    let _this = this

    this.stimulate('MyCompany::MemberReflex#send_invite', e.target, this.inputTarget.value)
      .then((e) => {
        _this.inputTarget.value = ''
        $(_this.wrapperTarget).removeClass('show')

        alertify.success(I18n.t("messages.notify.company_member.invite_success"))
      })
    e.preventDefault()
  }

  _initDropdownAnimate() {
    $('.dropdown').on('hidden.bs.dropdown', function () {
      this.querySelector('.invitation-input').classList.remove('animate')
    })

    $('.dropdown').on('shown.bs.dropdown', function () {
      setTimeout(() => {
        this.querySelector('.invitation-input').classList.add('animate')
      }, 100)
    })
  }
}


