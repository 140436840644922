// Import libraries from webpacker libraries
import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { docsCount: Number }
  static targets = ['docsCount']

  increaseCount() {
    this.docsCountValue += 1
    this.docsCountTarget.innerText = this.docsCountValue
  }

  decreaseCount() {
    this.docsCountValue -= 1
    this.docsCountTarget.innerText = this.docsCountValue
  }
}
