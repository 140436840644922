import I18n from '@lib/i18n-js/init.js.erb';

// Remove paste format
export const removePasteFormat = (target) => {
  target.addEventListener("paste", function(e) {
    e.preventDefault();
    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
    document.execCommand("insertHTML", false, text);
  });
}

// Generate DOM options base on an object array with each object as { id: ..., value: ...}
export const generateDOMOptions = (optsArr) => {
  let domOptions = [];

  optsArr.forEach(({ value, label }) => {
    let option = document.createElement('option');
    option.appendChild(document.createTextNode(label));
    option.value = value;

    domOptions.push(option)
  })

  return domOptions;
}

// Generate HTML options
export const generateHTMLOptions = (optsArr) => {
  let htmlOptions = '';

  optsArr.forEach(({ value, label }) => {
    htmlOptions += `<option value="${value}">${label}</option>`
  })

  return htmlOptions;
}

// Scroll to bottom of a dom
export const scrollToBottom = (target) => {
  target.scrollTop = target.scrollHeight
}

export const stringToHTML = (htmlStr) => {
  let parser = new DOMParser();
  let htmlDoc = parser.parseFromString(htmlStr, 'text/html');
  return htmlDoc.body.children
}

export const selectFirstMessage = (msgObj) => {
  let keys = Object.keys(msgObj)
  if (_.isEmpty(keys)) return I18n.t('messages.notify.general.success')

  let firstVal = msgObj[keys[0]]
  if (_.isString(firstVal)) {
    return firstVal
  } else {
    return firstVal[0]
  }
}

export const showLoaderFor = (target, size = 'xl') => {
  // Supported sizes are sm, md, lg and xl
  target.innerHTML = `<div class='loader ${size} position-center-auto'>`;
}

export const showLoaderCenterFor = (target) => {
  $(target).append("<div class='loader-wrapper'><div class='loader xl center-auto'></div></div>");
}
