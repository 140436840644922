import ApplicationController from '@controllers/application_controller';
import { serialize } from 'object-to-formdata';

export default class extends ApplicationController {
  static targets = [
    'root',
    'formHeader',
    'formContent',
    'formFooter',
    'description',
    'completeScreen',
    'submitBtn',
  ];
  static values = { trackingData: Object, needTracking: Boolean };

  connect() {
    super.connect();

    this.formContentTarget.scrollTo({ top: 0 }); // Reset scroll position on reload page

    if (this.needTrackingValue) this._trackViewingSurvey();

    // Prevent enter to submit
    this._preventEnterSubmit();

    setTimeout(() => {
      this._setFormContentHeight();
      this._setBottomAreaHeight();
    }, 200);

    $(window).on('resize', () => {
      this._setFormContentHeight();
      this._setBottomAreaHeight();
    });
  }

  complete(el) {
    // Clear answer form
    $(this.rootTarget).html('');

    // Display completed screen
    $(this.completeScreenTarget).removeClass('d-none');

    if (this._drawerController) {
      // Remove confirm to close when survey completed
      this._drawerController.closeTarget.dataset.confirm_to_close = '';
    }

    if (
      this.completeScreenTarget.dataset.exist_distribution_document != 'true'
    ) {
      // 回答完了画面クローズ
      setTimeout(() => {
        this._drawerController.closeSurvey(el);
      }, 3000);
    }
  }

  failedSubmit(evt) {
    let [response, _status, _xhr] = evt.detail;
    let errors = response.errors;
    alertify.error(errors[0]);
  }

  _trackViewingSurvey() {
    Rails.ajax({
      url: Routes.record_activity_tracking_activities_path(),
      type: 'POST',
      data: serialize(this.trackingDataValue),
      error: (e) => {
        // console.log('activity error')
      },
      success: (e) => {
        // console.log('activity success')
      },
    });
  }

  _preventEnterSubmit() {
    $(this.rootTarget).on('keypress', ':input:not(textarea)', function (e) {
      if (e.key == 'Enter' || e.which == 13) {
        e.preventDefault();
        return false;
      }
    });
  }

  _setFormContentHeight() {
    // 60px: navbar height
    // 24px: margin between formHeader and formContent
    let subtractHeight = $(this.formHeaderTarget).outerHeight() + 24;

    // For answer survey from link, exclude header height
    if (this.rootTarget.dataset.drawerAction == 'answer_survey_link') {
      subtractHeight = subtractHeight + 60;
    }

    if (window.matchMedia('(max-width: 450px)').matches) {
      $(this.formContentTarget).css({
        height: `calc(50vh - ${subtractHeight}px)`,
      });
    } else if (window.matchMedia('(max-width: 992px)').matches) {
      $(this.formContentTarget).css({
        height: `calc(30vh - ${subtractHeight}px)`,
      });
    } else {
      $(this.formContentTarget).css({
        height: `calc(100vh - ${subtractHeight}px)`,
      });
    }
  }

  _setBottomAreaHeight() {
    let $questions = $(
      '.content-surveys--answers--questions--inputs--wrapper-component'
    );
    let $description = $(this.descriptionTarget);
    let questionsHeight = 0;

    if ($questions.length == 1) {
      // 44px: margin between introduction and first question
      questionsHeight +=
        $description.outerHeight() + 44 + $questions.first().outerHeight();
    } else {
      // Only get last 2 questions
      $questions.slice(-2).each((_index, element) => {
        questionsHeight += $(element).outerHeight();
      });

      // 32px: margin between last 2 questions
      // 16px: Padding bottom 32px for submit bottom
      questionsHeight = questionsHeight + 32 + 16;
    }

    // 60px: navbar height
    // 24px: margin between formHeader and formContent
    let subtractHeight =
      $(this.formHeaderTarget).outerHeight() + 24 + questionsHeight;

    if (this.rootTarget.dataset.drawerAction == 'answer_survey_link') {
      subtractHeight = subtractHeight + 60;
    }

    // 127px: 32px + 16px + 47px + 32px
    // 32px: margin between last 2 questions
    // 16px: margin button and questions
    // 47px: submit button height
    // 32px: margin between button and bottom
    if (questionsHeight + 127 <= $(this.formContentTarget).height()) {
      $(this.formFooterTarget).css({
        height: `calc(100vh - ${subtractHeight}px`,
      });
    } else {
      // 95px: 32px + 47px + 16px
      // 32px: margin between button and bottom
      // 47px: submit button height
      // 16px: margin button and questions
      $(this.formFooterTarget).css({ height: '95px' });
    }
  }

  checkValid(_evt) {
    let allAnswersValid = this._questionCtrlrs.every((ctrlr) =>
      ctrlr.isAnswerValid()
    );

    if (allAnswersValid) {
      this._enableSubmitBtn();
    } else {
      this.disableSubmitBtn();
    }
  }

  _enableSubmitBtn() {
    this.submitBtnTarget.classList.remove('disabled');
    this.submitBtnTarget.disabled = false;
  }

  disableSubmitBtn(_evt) {
    this.submitBtnTarget.classList.add('disabled');
    this.submitBtnTarget.disabled = true;
  }

  get _drawerController() {
    return this.application.controllers.find(
      (controller) =>
        controller.identifier == 'events--contents--questionnaires--drawer'
    );
  }

  get _questionCtrlrs() {
    return this.getControllers('content-surveys--answers--question');
  }
}
