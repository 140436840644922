// event_videoからの移管ファイル

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import webpack libraries
import Rails                              from "@rails/ujs";

// Import custom libraries
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'
import { confirmModal }                   from '@video_lib/helpers/js_modals'

export default class extends SimpleAjaxForm {
  static targets = [
    'inputPublicFrom', 'inputPublicTo', 'checkboxPublicUnlimited',
    'hiddenStatus', 'btnDraft', 'btnPublic', 'btnOpenModalDeletePublicRequest',
    'menu', 'btnOpenModalConfirmDeleteVideo'
  ]

  static rules = {
    public_from: {
      customPresence: {
        message: `^${ ActiveRecordErrorMessageHelper('video', 'public_from', 'blank') }`
      },
      lessThan: {
        message: `^`
      }
    },

    public_to: {
      customPresence: {
        message: `^${ ActiveRecordErrorMessageHelper('video', 'public_to', 'blank') }`
      },
      greaterThan: {
        message: `^`
      }
    }
  }

  connect() {
    super.connect()

    this.initCustomValidators()
    this.initDatetimepicker()
  }

  initCustomValidators() {
    let _this = this

    _this.constructor.validatejs.validators.customPresence = (value, options) => {
      if(_this.checkboxPublicUnlimitedTarget.checked) return

      if(value == '') return options.message
    }

    _this.constructor.validatejs.validators.lessThan = (value, options) => {
      if(_this.checkboxPublicUnlimitedTarget.checked) return

      if(value >= _this.inputPublicTo) return options.message
    }

    _this.constructor.validatejs.validators.greaterThan = (value, options) => {
      if(_this.checkboxPublicUnlimitedTarget.checked) return

      if(value <= _this.inputPublicFrom) return options.message
    }
  }

  initDatetimepicker() {
    let _this = this

    if (_this.inputPublicFromTarget) {
      $(_this.inputPublicFromTarget).datetimepicker({
        format: 'Y/m/d H:i',
        onShow(){
          this.setOptions({
            maxDate: _this.inputPublicTo ? _this.inputPublicTo : false
          })
        }
      })
    }

    if (_this.inputPublicToTarget) {
      $(_this.inputPublicToTarget).datetimepicker({
        format: 'Y/m/d H:i',
        onShow(){
          this.setOptions({
            minDate: _this.inputPublicFrom ? _this.inputPublicFrom : false
          })
        }
      })
    }
  }

  toggleMenu() {
    if($(this.menuTarget).hasClass('d-none')) {
      $(this.menuTarget).removeClass('d-none')
    } else {
      $(this.menuTarget).addClass('d-none')
    }
  }

  submitForm(event) {
    $(this.hiddenStatusTarget).val(event.currentTarget.value)
    this.disableButtons()
    Rails.fire(this.editVideoController.formTarget, 'submit')
  }

  openModalConfirmDeleteVideo(event) {
    let _this = this

    let menu = $(_this.menuTarget)
    menu.addClass('d-none')

    let i18nPath = 'javascripts.controllers.shared.videos.form.footer.modal_confirm_delete_video'

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        _this.deleteVideo(event)
      }
    })
  }

  deleteVideo(event) {
    let _this = this

    $.ajax({
      url: _this.deleteVideoAjaxUrl,
      method: 'DELETE',
      dataType: 'JSON',
      success: function(response) {
        let message = I18n.t('alertify.success')
        alertify.success(message)

        let redirect_path = response.data.redirect_path
        if (redirect_path) {
          window.location.href = redirect_path
        }
      },
      error: function (xhr, _options, _error) {
        let message = xhr.responseJSON.errors.messages[0]
        message = message || I18n.t('alertify.error')
        alertify.error(message)
      }
    })
  }

  enableButtonPublic(enable) {
    if(enable) {
      $(this.btnPublicTarget).removeAttr('disabled');
    } else {
      $(this.btnPublicTarget).attr('disabled', 'disabled');
    }
  }

  openModalDeletePublicRequest(event) {
    let _this = this
    let i18nPath = 'javascripts.controllers.shared.videos.form.footer.modal_confirm_delete_public_request'

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        _this.deletePublicRequest()
      }
    })
  }

  deletePublicRequest() {
    let _this = this
    $.ajax({
      url: _this.deletePublicRequestAjaxUrl,
      method: 'DELETE',
      dataType: 'JSON',
      success: function(response) {
        let message = I18n.t('alertify.success')
        alertify.success(message)

        let redirect_path = response.data.redirect_path
        if (redirect_path) {
          window.location.href = redirect_path
        }
      },
      error: function (xhr, _options, _error) {
        let message = xhr.responseJSON.errors.messages[0]
        message = message || I18n.t('alertify.error')

        alertify.error(message)

        window.location.reload()
      }
    })
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    let [response, _status, _xhr] = event.detail
    let html = response.data.html_footer_status

    $('#js-footer-left').html(html)
  }

  ////
  // GETTER VALUE METHODS
  ////

  get deletePublicRequestAjaxUrl() {
    return $(this.btnOpenModalDeletePublicRequestTarget).data('ajax_url')
  }

  get deleteVideoAjaxUrl() {
    return $(this.btnOpenModalConfirmDeleteVideoTarget).data('ajax_url')
  }

  ////
  // VALIDATE METHODS
  ////

  validateAll(event) {
    super.validateAll(event)
  }

  validatePublicRange() {
    this.validateEl(this.inputPublicFromTarget)
    this.validateEl(this.inputPublicToTarget)
    // this.validateEl(this.checkboxPublicUnlimitedTarget)
  }

  toggleInputPublicRange() {
    this.validatePublicRange()
    let disabled = $(this.checkboxPublicUnlimitedTarget).prop('checked')
    $(this.inputPublicFromTarget).attr('disabled', disabled);
    $(this.inputPublicToTarget).attr('disabled', disabled);
  }

  ////
  // GETTER CONTROLLER METHODS
  ////

  get editVideoController() {
    return this.getController('shared--videos--edit-video')
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get inputPublicFrom() {
    return $(this.inputPublicFromTarget).val()
  }

  get inputPublicTo() {
    return $(this.inputPublicToTarget).val()
  }

  ////
  // ADDTIONAL METHODS
  ////

  enableButtons() {
    let _this = this

    if ($('.js-video-file-ready').length) {
      $(_this.btnPublicTarget).removeAttr('disabled')
    }

    $(_this.btnDraftTarget).removeAttr('disabled')
  }

  disableButtons() {
    let _this = this

    $(_this.btnPublicTarget).attr('disabled', 'disabled')
    $(_this.btnDraftTarget).attr('disabled', 'disabled')
  }
}
