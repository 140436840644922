// Import webpacker libraries
import { Controller }                     from 'stimulus'
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { Constant }                       from '@video_lib/constant.js.erb'

export default class extends SimpleAjaxForm {
  static targets = []

  showModalEditService(event) {
    this.modalEditServiceController.initModalEditService(event)
  }

  get modalEditServiceController() {
    return this.getController(Constant.settings.stimulus.companies.services_form_controller)
  }
}
