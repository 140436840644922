// Import webpack libraries
import Rails                              from "@rails/ujs";

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { confirmModal }                   from '@video_lib/helpers/js_modals'

import I18n                               from '@video_lib/i18n-js/init.js.erb'

export default class extends SimpleAjaxForm {
  connect() {
    super.connect()

    let _this = this

    _this.initSelectpicker()
    _this.disableSelectedOption()
  }

  initSelectpicker() {
    let _this = this

    _this.selectPickupEvent.selectpicker()
  }

  disableSelectedOption() {
    let _this = this

    let emEventId = _this.selectedEmEventId
    if (emEventId) {
      _this.disableOption(emEventId)
    }
  }

  ////
  // EVENT METHODS
  ////

  openModalConfirmDeletePickupEvent(event) {
    let _this = this

    event.preventDefault()

    let pickupEvent = $(event.currentTarget).closest('.js-pickup-event')
    let i18nPath    = 'javascripts.controllers.owner.pickup.edit_pickup_event.modal_confirm_delete_pickup_event'

    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        _this.deletePickupEvent(pickupEvent)
      }
    })
  }

  deletePickupEvent(pickupEvent) {
    let _this = this

    let emEventId = _this.selectedEmEventId
    _this.enableOption(emEventId)

    let html = `
      <div class="js-pickup-event-deleted">
        <input type="hidden" name="pickup_event[em_event_id]" id="pickup_event_em_event_id" value=""     class="js-hidden-pickup-event-em-event-id">
        <input type="hidden" name="pickup_event[destroy]"     id="pickup_event_destroy"     value="true" class="js-hidden-pickup-event-destroy">
      </div>
    `
    pickupEvent.replaceWith(html)
    _this.submitForm()
  }

  choosePickupEvent(event) {
    let _this = this

    let select = $(event.target)
    let option = select.find('option:selected')
    let data = option.data()

    let emEventId = select.val()

    if (!emEventId) return

    _this.enableOption(_this.selectedEmEventId)

    select.val('')
    select.selectpicker('refresh')

    _this.disableOption(emEventId)

    _this.listPickupEvent.html(_this.templatePickupEvent)

    let pickupEvent = _this.listPickupEvent.find('.js-pickup-event')

    _this.displayPickupEvent(pickupEvent, data)
    _this.submitForm()
  }

  displayPickupEvent(pickupEvent, data) {
    // params
    let emEventId = pickupEvent.find('.js-hidden-pickup-event-em-event-id')
    emEventId.val(data.emEventId)

    let destroy = pickupEvent.find('.js-hidden-pickup-event-destroy')
    destroy.val(false)

    // UI
    let thumbnail = pickupEvent.find('.js-img-pickup-event-thumbnail')
    thumbnail.attr('src', data.thumbnail)

    let title = pickupEvent.find('.js-link-pickup-event')
    title.html(data.title)
    title.attr('href', data.href)
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    $('.js-pickup-event-deleted').remove()
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get listPickupEvent() {
    return $('.js-list-pickup-event')
  }

  get selectPickupEvent() {
    return $('select.js-select-pickup-event')
  }

  ////
  // GETTER VALUE METHODS
  ////

  get templatePickupEvent() {
    return $(this.element).data('template_pickup_event')
  }

  get selectedEmEventId() {
    let _this = this

    let pickupEvent = _this.listPickupEvent.find('.js-pickup-event')
    if (pickupEvent.length) {
      return pickupEvent.find('.js-hidden-pickup-event-em-event-id').val()
    }

    return ''
  }

  ////
  // GETTER CONTROLLER METHODS
  ////

  get editPickupController() {
    return this.getController('owner--pickup--edit-pickup')
  }

  ////
  // ADTIONAL METHODS
  ////

  disableOption(value) {
    let _this = this

    let select = _this.selectPickupEvent
    let option = select.find(`option[value="${value}"]`)

    option.attr('disabled', 'disabled')
    select.selectpicker('refresh')
  }

  enableOption(value) {
    let _this = this

    let select = _this.selectPickupEvent
    let option = select.find(`option[value="${value}"]`)

    option.removeAttr('disabled')
    select.selectpicker('refresh')
  }

  submitForm() {
    this.editPickupController.submitForm()
  }
}
