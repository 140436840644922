import { Controller } from "stimulus"

export default class extends Controller {
  showMainScreen(e) {
    if (_.isEqual(e.target.dataset.disabledExport, 'true')) {
      e.preventDefault()
      return
    }

    this.mainScreenCtrlr.showScreen({
      exportType:     e.target.dataset.export_type      || e.target.dataset.exportType,
      contentId:      e.target.dataset.content_id       || e.target.dataset.contentId,
      eventServiceId: e.target.dataset.event_service_id || e.target.dataset.eventServiceId
    })

    e.preventDefault()
  }

  showHistoryScreen(e) {
    let eventServiceId = e.target.dataset.event_service_id
    this.historyScreenCtrlr.showScreen({ animate: true, eventServiceId: eventServiceId })
  }

  get mainScreenCtrlr() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier === 'events--export-csv-screens--main-screen')
  }

  get historyScreenCtrlr() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier === 'events--export-csv-screens--history-screen')
  }
}
