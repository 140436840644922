import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'containerSeeMore', 'btnSeeMore' ]

  toggleSeeMore(event) {
    let _this = this

    event.preventDefault()
    _this.toggleClass()
    _this.toggleText()
  }

  toggleClass() {
    this.containerSeeMore.toggleClass('overview-content--show')
  }

  toggleText() {
    let _this = this
    let seeMoreText = _this.seeMoreText
    let seeLessText = _this.seeLessText

    let btn = _this.btnSeeMore
    let btnText = btn.text()
    let text = ''

    if (btnText == seeMoreText) {
      text = seeLessText
    } else if (btnText == seeLessText) {
      text = seeMoreText
    }

    btn.text(text)
  }

  get containerSeeMore() {
    return $(this.containerSeeMoreTarget)
  }

  get btnSeeMore() {
    return $(this.btnSeeMoreTarget)
  }

  get seeMoreText() {
    return $(this.element).data('see-more-text')
  }

  get seeLessText() {
    return $(this.element).data('see-less-text')
  }
}
