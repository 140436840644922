import ApplicationController from '../application_controller'
import { eventContentClickable } from '@lib/helpers/event_content_modal_clickable'
import { selectFirstMessage } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = ['bookmarkToggler', 'modalToggler']

  connect() {
    super.connect()
    this.setupTooltips()
  }

  disconnect() {
    super.disconnect()
    this.teardownTooltips()
  }

  beforeToggleSubscription() {
    // Dispose previous tooltips
    this.teardownTooltips()
  }

  // イベントホーム画面とタイムテーブル画面から呼ばれる
  byModalToggler(e) {
    const bookmarker = e.currentTarget

    // 視聴予約ボタンの見た目を変更
    this.toggleButtonAppearance(this.status(bookmarker), bookmarker);

    // 視聴予約情報更新
    const cardBtn = document.querySelector(`.card-btn-${bookmarker.dataset.eventContentId}`);

    if(cardBtn) {
      // タイムテーブル画面から呼ばれた場合
      cardBtn.click();
    }else{
      // イベントホーム画面から呼ばれた場合
      this.submitBookmark(bookmarker);
    }
  }

  toggleButtonAppearance(status, bookmarker) {
    $(bookmarker).removeClass('btn-outline-accent')
    $(bookmarker).addClass('btn-accent')
    $(bookmarker).find(`.bookmark-icon`).removeClass('i-Save')
    $(bookmarker).find(`.bookmark-icon`).addClass('i-CheckCircle')

    $(bookmarker).removeClass('text-accent')
    $(bookmarker).find(`.text`).text('予約中')

    // 視聴予約解除不可
    $(bookmarker).attr('style', 'cursor: default;')
    $(bookmarker).attr('onMouseOver', "this.style.background='#F08020'")
  }

  submitBookmark(bookmarker) {
    const eventContentId = bookmarker.dataset.eventContentId
    const eventSlug = bookmarker.dataset.eventSlug

    Rails.ajax({
      url: Routes.subscribe_event_event_content_path(eventContentId, { event_slug: eventSlug }),
      type: 'PUT',
      data: {},
      error: (e) => {
        let errors = e.errors;
        // Show first error message
        let error_message = selectFirstMessage(errors)
        alertify.error(error_message)
      }
    })
  }

  status(target) {
    let status = target.dataset.status

    if (status == 'unsubscribed'){
      status = 'subscribed'
      $(target).attr('data-status', 'subscribed')
    } else {
      status = 'unsubscribed'
      $(target).attr('data-status', 'unsubscribed')
    }

    return status
  }

  byBookmarkToggler(evt) {
    try {
      this.stimulate('Events::EventContentReflex#toggle_subscription', evt.target)
    } catch (err) {
      console.error(err)
      Rollbar.error(err);
    }
  }

  afterToggleSubscription(elm) {
    this.setupTooltips()
    eventContentClickable('.card-item', elm.dataset.actionOrigin)
  }

  setupTooltips() {
    if (this.hasBookmarkTogglerTarget) {
      $(this.bookmarkTogglerTarget).tooltip()
    }

    if (this.hasModalTogglerTarget) {
      $(this.modalTogglerTarget).tooltip()
    }
  }

  teardownTooltips() {
    if (this.hasBookmarkTogglerTarget) {
      $(this.bookmarkTogglerTarget).tooltip('dispose')
    }

    if (this.hasModalTogglerTarget) {
      $(this.modalTogglerTarget).tooltip('dispose')
    }
  }
}
