import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {

  connect() {
    super.connect()
    this.eventContentFormCtrlr.initSelectPicker()
  }

  get eventContentFormCtrlr() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'organizer--event-contents--form';
    });
  }
}