import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  update_file_upload(event) {
    document.querySelector('.js_update_file').click();
  }

  submit_file_upload(event) {
    const update_file_upload_form = document.querySelector('.js_update_file_upload_form')

    $(update_file_upload_form).append("<div class='loader-wrapper'><div class='loader xl center-auto'></div></div>");

    update_file_upload_form.submit();
  }
}
