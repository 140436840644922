import ApplicationController from '@controllers/application_controller';
import _ from 'lodash';

export default class extends ApplicationController {
  static values = { reactorId: String }
  static targets = ['reactionsCount', 'totalReactionsCount', 'reactBtn']

  connect() {
    super.connect();

    this.reactContent = _.throttle(this.reactContent, 250).bind(this)

    if (this._reactionsCount() == 0) this._setupTooltips()
  }

  increaseReaction(evt) {
    // Increase total reactions count
    $(this.totalReactionsCountTarget).text(this._totalReactionsCount() + 1)

    // Increase reactions count if reactor_id matches
    if (evt.detail.reactorId.toString() == this.reactorIdValue) {
      if (this._reactionsCount() == 0) {
        this.reactBtnTarget.classList.add('has-reacted')
        $(this.reactBtnTarget).tooltip('dispose')
      }

      $(this.reactionsCountTarget).text(this._reactionsCount() + 1)
    }
  }

  reactContent(_evt) {
    try {
      this.stimulate('EventHalls::StageReflex#react_content', this.reactBtnTarget)
    } catch (err) {
      console.error(err)
      Rollbar.error(err)
    }
  }

  _reactionsCount() {
    return parseInt($(this.reactionsCountTarget).text())
  }

  _totalReactionsCount() {
    return parseInt($(this.totalReactionsCountTarget).text())
  }

  _setupTooltips() {
    $(this.reactBtnTarget).tooltip('dispose').tooltip({ container: '#content-reaction-wrap' })
  }
}
