// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import libraries from webpacker libraries
import Rails from '@rails/ujs';

// Import custom libraries
import { Constant }                       from '@video_lib/constant.js.erb'
import { UploadAndPreviewImageHelper }    from '@video_lib/helpers/upload_and_preview_image_helper'
import { UploadAndPreviewDocumentHelper } from '@video_lib/helpers/upload_and_preview_document_helper'
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'
import { urlRegex }                       from '@lib/helpers/validation_helper'

export default class extends SimpleAjaxForm {
  static targets = ['serviceModal',
    'form', 'imagePreview', 'serviceLogo', 'btnOpenModalConfirmDeleteCompany', 'btnSubmit', 'serviceDocument', 'documentPreview',
    'serviceName', 'serviceSiteUrl', 'serviceDescription', 'serviceDocumentTitle', 'serviceCompanyId'
  ]

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('service', 'name', 'blank') }`
      },
      length: {
        maximum: Constant.settings.service.name.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('service', 'name', 'too_long', { count: Constant.settings.service.name.length.maximum }) }`
      }
    },
    site_url: (value) => {
      if (_.isEmpty(value)) return;

      return {
        format: {
          pattern: urlRegex,
          message: `^${ ActiveRecordErrorMessageHelper('service', 'site_url', 'invalid') }`
        }
      }
    },
    description: (value) => {
      if (_.isEmpty(value)) return;

      return {
        length: {
          maximum: Constant.settings.service.description.length.maximum,
          tooLong: `^${ ActiveRecordErrorMessageHelper('service', 'description', 'too_long', { count: Constant.settings.service.description.length.maximum }) }`
        }
      }
    }
  }

  initModalEditService(e) {
    let _this = this
    let serviceInfo = $(e.target).closest('.js-edit-service')
    let description = serviceInfo.data('service-description')
    let documenTitle = serviceInfo.data('service-document_title')
    let documentUrl = serviceInfo.data('service-document_preview_url')
    let logo = serviceInfo.data('service-logo')
    let siteUrl = serviceInfo.data('service-site_url')
    let name = serviceInfo.data('service-name')
    let url = serviceInfo.data('url')

    $(_this.imagePreviewTarget).attr('src', logo)
    $(_this.serviceNameTarget).val(name)
    $(_this.serviceSiteUrlTarget).val(siteUrl)
    $(_this.serviceDescriptionTarget).text(description)
    $(_this.serviceDocumentTitleTarget).val(documenTitle)
    $(_this.documentPreviewTarget).attr('src', documentUrl)
    $(_this.formTarget).attr('action', url)
    $(_this.btnOpenModalConfirmDeleteServiceTarget).attr('data-ajax_url', url)
    $('#service-modal').modal('show')
    $('#_method').val('patch')

    _this.attributes.forEach(({ el }) => {
      el.classList.remove('error');
      _this.errorMessageEl(el).innerHTML = '';
    })
    _this.btnSubmitTarget.removeAttribute('disabled')
  }

  previewLogo() {
    let _this = this

    let input = _this.serviceLogoTarget
    let img = _this.imagePreviewTarget
    let size = ''

    let message = UploadAndPreviewImageHelper(input, img, size)
    if (message) {
      _this.displayError(input, [message])
      $(input).val('')
    } else {
      _this.clearError(input)
    }
  }

  successSubmit(event) {
    let [response, _status, _xhr] = event.detail
    window.location.href = response.data.redirect_path
  }

  allowSubmit(isAllow) {
    let _this = this

    let isValid = !_this.errors.hasAny()

    if(isAllow && isValid) {
      _this.btnSubmitTarget.removeAttribute('disabled')
    } else {
      _this.btnSubmitTarget.setAttribute('disabled', '')
    }
  }

  clearForm() {
    let _this = this;
    let companyId = $(_this.serviceCompanyIdTarget).val()
    let url = $(_this.serviceModalTarget).data('default_url')

    _this.attributes.forEach(({ el }) => {
      el.classList.remove('error')
      el.value = ''
      _this.errorMessageEl(el).innerHTML = ''
    })

    $(_this.serviceLogoTarget).val('')
    $(_this.imagePreviewTarget).removeAttr('src')
    $(_this.serviceDocumentTarget).val('')
    $(_this.documentPreviewTarget).removeAttr('src')
    $('#_method').val('post')
    $(_this.formTarget).attr('action', url)
  }

  previewContentDocument() {
    let _this = this

    let input = _this.serviceDocumentTarget
    let img = _this.documentPreviewTarget
    let size = ''

    let message = UploadAndPreviewDocumentHelper(input, img, 25 * 1024 * 1024)
    if (message) {
      _this.displayError(input, [message])
      $(input).val('')
    } else {
      _this.clearError(input)
    }
  }
}
