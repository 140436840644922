// Import libraries from webpacker libraries
import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { maxDocs: Number, userRole: String };
  static targets = ['documentForm', 'newDocFormBtn']

  newDocumentForm(evt) {
    if (this.eligibleDocForms().length >= this.maxDocsValue) return

    let serviceId = evt.currentTarget.dataset.serviceId

    let url = null
    if (serviceId) {
      url = this._standaloneFormUrl(this.userRoleValue, serviceId)
    }
    else {
      url = this._nestedFormUrl(this.userRoleValue)
    }

    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: 'script',
      complete: () => {
        this.updateNewDocFormBtnStatus()
      }
    })
  }

  updateNewDocFormBtnStatus() {
    if (this.eligibleDocForms().length == this.maxDocsValue) {
      this.newDocFormBtnTarget.classList.add('status-disabled')
    }
    else {
      this.newDocFormBtnTarget.classList.remove('status-disabled')
    }
  }

  eligibleDocForms() {
    return this.documentFormTargets.filter((target) => !target.classList.contains('d-none'))
  }

  // Private methods

  _standaloneFormUrl(role, serviceId) {
    if (role == 'organizer') {
      return Routes.append_standalone_form_organizer_documents_path({ service_id: serviceId })
    }
    else if (role == 'owner') {
      return Routes.append_standalone_form_owner_documents_path({ service_id: serviceId })
    }
  }

  _nestedFormUrl(role) {
    if (role == 'organizer') {
      return Routes.append_nested_form_organizer_documents_path()
    }
    else if (role == 'owner') {
      return Routes.append_nested_form_owner_documents_path()
    }
  }
}
