import { el, setChildren } from 'redom';

// TODO: Remove!
export const generateDOMStageMessage = (msgData, stageId, addMenu = false, executorRole = '') => {
  const controller = 'event-stages--chat-item'

  let chatItemDom = el('.chat-item-component.d-flex.align-items-stretch', {
    'data-controller': controller,
    'data-id': msgData.messageId
  });

  let avatarClass = chatMessageAvatarClass(msgData['role'], stageId, msgData['authorId'])
  let chatNameClass = chatMessageNameClass(msgData['role'], stageId, msgData['authorId'])

  // implement Avatar section
  let avatarSection =
    el('.avatar-section.margin-right-8',
      el(`.avatar-container ${msgData.role}`,
        el(`img.rounded-circle.w-100.h-100.logo-cover ${avatarClass}`, { src: msgData.avatar })
      )
    )

  // implement Info section
  let infoSection =
    el('.info-section',
      [
        el('.info-title.d-flex',
          [
            el(`.name.caption-2.margin-right-8 ${msgData.role} ${chatNameClass}`, msgData.userName),
            el('.time.caption-1.text-secondary', msgData.timeLabel)
          ]
        ),
        el('.info-message.caption-1.w-100', msgData.message)
      ]
    )

  // implement Menu section
  let moreMenuSection =
      el('.menu-section.dropdown.clickable',
        [
          el('span.material-icons.position-center', { 'data-toggle': 'dropdown', 'aria-haspopup': 'true', 'aria-expanded': 'false' } , 'more_vert'),
          el('section.more-menu.dropdown-menu.dropdown-menu-right',
            el('.actions-container',
              el('.action',
                el('button.btn.btn-flat.btn-flat-md.btn-small-size.text-left', {
                  'data-action': `click->${controller}#removeChat`,
                  'data-user-name': msgData.userName,
                  'data-message-id': msgData.messageId,
                  'data-event-stage-id': stageId,
                  'data-executor-role': executorRole,
                  'data-confirm-message': deleteChatConfirmMessage(executorRole, msgData.userName),
                }, I18n.t('shared.event_halls.buttons.remove'))
              )
            )
          )
        ]
      )

  let messageComponents = [avatarSection, infoSection]

  if (addMenu) messageComponents.push(moreMenuSection)

  setChildren(chatItemDom, messageComponents)

  return chatItemDom;
}

function chatMessageAvatarClass(role, stageId, authorId) {
  if (role == 'organizer') {
    return `stage-${stageId}-organizer-message-avatar`
  }
  else {
    return `stage-${stageId}-author-${authorId}-message-avatar`
  }
}

function chatMessageNameClass(role, stageId, authorId) {
  if (role == 'organizer') {
    return `stage-${stageId}-organizer-message-name`
  }
  else {
    return `stage-${stageId}-author-${authorId}-message-name text-dark`
  }
}

function deleteChatConfirmMessage(role, name) {
  if (role == 'guest') {
  return I18n.t('guests.event_stages.comments.delete_chat.confirm_message')
  }
  else {
    return I18n.t('organizer.modals.confirm_modals.delete_chat_item.content', { user_name: name })
  }
}
