import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['inputFld', 'errorMessage']
  static values = { uiGroupId: String }

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.too_long', { count: 50 })}`
      }
    }
  }

  connect() {
    super.connect()
    this._listenClickOutside()
  }

  _resetForm() {
    // Clear previous input name, error
    this.inputFldTarget.classList.remove('error')
    this.inputFldTarget.value = ''
    this.errorMessageTarget.innerText = ''
  }

  _listenClickOutside() {
    $(document).on('click', (evt) => {
      // Ignore if not visible
      if (this.element.classList.contains('d-none')) return

      if (!$(evt.target).closest(this.element).length) {
        this.dismissForm()
      }
    })
  }

  show(name) {
    this._resetForm()
    this.inputFldTarget.value = name
    this.element.classList.remove('d-none')
    this.inputFldTarget.focus()
  }

  dismissForm() {
    this.element.classList.add('d-none')
  }

  successSubmit(evt) {
    $('.js-guest-name').text(evt.detail[0].data.name)
    this.dismissForm()
    alertify.success(I18n.t('messages.notify.general.edit.success'))
  }

  failedSubmit(_evt) {
    alertify.error(I18n.t('messages.notify.general.edit.error'))
  }
}
