// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../../lib/helpers/simple_ajax_form';
import I18n from '../../../lib/i18n-js/init.js.erb';
export default class extends SimpleAjaxForm {
  static targets = ['form', 'submitBtn']

  ////
  // VALIDATION RULES
  ////

  static rules = {
    email: {
      email: {
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.invalid')}`
      },
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.blank')}`
      }
    },
  }

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    if (el == this.logoInputTarget) {
      return this.errorLogoTarget;
    }
    return el.nextElementSibling;
  }

  // Trigger an action after validate an attribute

  ////
  // NEW METHODS
  ////
  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }
    return messages;
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    // alertify.error(this.messages.error)

    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    this.attributes.forEach(({ attribute, el }) => {
      _this.displayError(el, [errors])
    })
  }
}
