import _ from 'lodash';
// import Rails from '@rails/ujs';
import ApplicationController from '../../application_controller'
import { confirmModal } from '@lib/helpers/js_modals'

// Import custom libraries
import I18n from '@lib/i18n-js/init.js.erb';

export default class extends ApplicationController {
  static targets = ['editBtn', 'changeStatusBtn', 'deleteBtn']

  beforeToggleStatus(elm) {
    if (elm.dataset.actionOrigin == 'status_bar') {
      elm.disabled = true
    }
  }

  // switch content status
  toggleStatus(e) {
    let confirm = e.target.dataset.confirm_modal;

    if (!!confirm) {
      confirmModal({
        title: '',
        message: I18n.t('shared.timetables.index.confirm_modals.unpublic'),
        labels: {
          ok_btn: I18n.t('shared.common.buttons.ok'),
          cancel_btn: I18n.t('shared.common.buttons.cancel')
        },
        onOk: () => {
          this.stimulate('Organizers::Events::EventContentReflex#toggle_status', e.target)
            .then((e) => {
              this.toggleStatusSuccess(e)
            })
        }
      })
    } else {
      this.stimulate('Organizers::Events::EventContentReflex#toggle_status', e.target)
        .then((e) => {
          this.toggleStatusSuccess(e)
        })
    }
    e.preventDefault()
  }

  // after reflex with new ui, notify message base on new status
  toggleStatusSuccess(e) {
    if (e.data.attrs['data-action_origin'] == 'status_bar') $('#event-content-detail-modal').modal('hide')

    let message = ''
    if(this.element.dataset.status == 'published') {
      message = I18n.t('messages.notify.event.unpublish.success')
    }
    else {
      message = I18n.t('messages.notify.event.publish.success')
    }

    this.eventStageController.reloadPreviewContent()

    alertify.success(message)
  }

  edit(e) {
    $('.modal').modal('hide');
  }

  // Destroy action
  destroy(event) {
    confirmModal({
      title: I18n.t('organizer.companies.confirm_modals.delete_company.title'),
      message: I18n.t('organizer.companies.confirm_modals.delete_company.content'),
      labels: {
        ok_btn: I18n.t('organizer.companies.confirm_modals.delete_company.buttons.confirm'),
        cancel_btn: I18n.t('organizer.companies.confirm_modals.delete_company.buttons.cancel')
      },
      onOk: () => {
        this.stimulate('Organizers::Events::EventContentReflex#destroy', event.target)
      }
    })
  }

  destroySuccess() {
    alertify.success(I18n.t('messages.notify.general.delete.success'))
  }

  distribute(event) {
    let message = event.currentTarget.dataset.confirm_message

    confirmModal({
      title: '',
      message: I18n.t(`shared.timetables.index.confirm_modals.${message}`),
      labels: {
        ok_btn: I18n.t('shared.common.buttons.ok'),
        cancel_btn: I18n.t('shared.common.buttons.cancel')
      },
      onOk: () => {
        try {
          this.stimulate('Organizers::Events::EventContentReflex#distribute', event.target)
        } catch(err) {
          console.error(err)
          Rollbar.error(err)
        }
      }
    })
  }

  undistribute(e) {
    confirmModal({
      title: '',
      message: I18n.t('shared.timetables.index.confirm_modals.undistribute'),
      labels: {
        ok_btn: I18n.t('shared.common.buttons.ok'),
        cancel_btn: I18n.t('shared.common.buttons.cancel')
      },
      onOk: () => {
        try {
          this.stimulate('Organizers::Events::EventContentReflex#undistribute', e.target)
        } catch(err) {
          console.error(err)
          Rollbar.error(err)
        }
      }
    })
  }

  predistribute(event) {
    let message = event.currentTarget.dataset.confirm_message

    confirmModal({
      title: '',
      message: I18n.t(`shared.timetables.index.confirm_modals.${message}`),
      labels: {
        ok_btn: I18n.t('shared.common.buttons.ok'),
        cancel_btn: I18n.t('shared.common.buttons.cancel')
      },
      onOk: () => {
        this.stimulate('Organizers::Events::EventContentReflex#predistribute', event.target)
      }
    })
  }

  unpredistribute(event) {
    let message = event.currentTarget.dataset.confirm_message

    confirmModal({
      title: '',
      message: I18n.t(`shared.timetables.index.confirm_modals.${message}`),
      labels: {
        ok_btn: I18n.t('shared.common.buttons.ok'),
        cancel_btn: I18n.t('shared.common.buttons.cancel')
      },
      onOk: () => {
        this.stimulate('Organizers::Events::EventContentReflex#undistribute', event.target)
      }
    })
  }

  pickup(e) {
    this.stimulate('Organizers::Events::EventContentReflex#pickup', e.target)
  }

  get eventStageController() {
    return this.application.controllers.find(controller => {
      return controller.identifier === "organizer--event-stage"
    });
  }
}
