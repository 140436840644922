import ApplicationController from '@controllers/application_controller';
import { showLoaderFor } from '@lib/helpers/view_helpers';
import { confirmModal } from '@lib/helpers/js_modals';
export default class extends ApplicationController {
  static targets = ['contentTitle']

  connect() {
    let icon = $(".content-target__icon")
    for(let i = 0; i<icon.length; i++ ){
      let width = $($(icon[i]).parents()[1]).find(".content-target__released").width()
      $($(icon[i]).parents()[0]).css('right',`calc(100% - 60.2% - ${width}px + 6px)`)
    }

    $(window).on('load', function(){
      // let icon = $(".content-target__icon")
      for(let i = 0; i<icon.length; i++ ){
        let width = $($(icon[i]).parents()[1]).find(".content-target__released").width()
        $($(icon[i]).parents()[0]).css('right',`calc(100% - 60.2% - ${width}px + 6px)`)
      }
    })

    $(window).on('resize', function(){
      // let icon = $(".content-target__icon")
      for(let i = 0; i<icon.length; i++ ){
        let width = $($(icon[i]).parents()[1]).find(".content-target__released").width()
        $($(icon[i]).parents()[0]).css('right',`calc(100% - 60.2% - ${width}px + 6px)`)
      }
    })
    super.connect()
  }

  changeButton(e) {
    if($(e.target).is(":checked")){
      $($(e.target).parents()[1]).addClass('current-check')
    } else {
      $($(e.target).parents()[1]).removeClass('current-check')
    }

    if(this.checkNumberCheckbox() == 1){
      this.stimulate('ContentSurveys::HeaderBtnReflex#change_to_unpublish')
    } else {
      this.stimulate('ContentSurveys::HeaderBtnReflex#change_to_create')
    }
  }

  checkNumberCheckbox(e){
    let num = $('.box:checked').length
    if(num > 0){
      return 1
    }
  }

  changeData(e){
    confirmModal({
      title: '',
      message: I18n.t('organizer.modals.confirm_modals.unpublish_survey.message'),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        let survey_ids = this.idsSurveyCheckbox()
        this.stimulate('ContentSurveys::ContentReflex#unpublish_list', e.target, survey_ids)
      }
    })
  }

  unpublishListSuccess(e) {
    this.stimulate('ContentSurveys::SurveyListReflex#reload_survey_list', e)
    this.stimulate('ContentSurveys::HeaderBtnReflex#change_to_create', e)
  }

  idsSurveyCheckbox(e) {
    let num = $('.box:checked').length
    let arr = []
    for(var i = 0; i < num; i++) {
      arr.push($('.box:checked')[i].dataset.id)
    }
    return arr
  }
}