import ApplicationController from '@controllers/application_controller';
import { translate } from 'i18n-js';
import { transform } from 'lodash';

export default class extends ApplicationController {
  hoverButton(e) {
    let positionTopContainer = 0

    let $wrapperContainer
    let containerHeight = $(window).height()

    let $currentIconTarget = $(e.currentTarget)
    let $currentPopupTarget = $(e.currentTarget.querySelector('.dropdown-menu'))
    let transformY = $currentPopupTarget.height() / 2 + $currentIconTarget.innerHeight()

    if($('.wrapper-container').length > 0) {
      $wrapperContainer = $('.wrapper-container')

      containerHeight = $wrapperContainer.innerHeight()
      positionTopContainer = $wrapperContainer.offset().top
    }

    let positionPopupFromTop = $currentIconTarget.offset().top - positionTopContainer
    let positionPopupFromBottom = containerHeight + positionTopContainer - $currentIconTarget.offset().top

    if(positionPopupFromTop <= $currentPopupTarget.innerHeight() / 2) {
      transformY = $currentIconTarget.height() / 2
    } else if (positionPopupFromBottom < $currentPopupTarget.innerHeight()) {
      transformY = $currentPopupTarget.innerHeight() - $currentIconTarget.height()
    }

    $currentPopupTarget.css('transform', `translate(16px,-${transformY}px)`)
  }
}
