import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import FileSizeLessThan from '@lib/helpers/check_file_size_less_than_helper';
import pdfPreview from '@lib/helpers/pdf_preview_helper';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static values = {
    formType: String, acceptedFileTypes: Array, maxFileSize: Number,
    documentId: String, serviceId: String, fresh: Boolean,
    maxDocsCount: Number
  }

  static targets = [
    'form', 'saveBtn', 'newDocument', 'existsDocument',
    'fileInput', 'ignoredField', 'root', 'titleEdit',
    'titleView', 'btnGroup', 'field', 'titleViewWrapper', 'documentField'
  ]

  ////
  // VALIDATION RULES
  ////
  static rules = {
    title: {
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.document.attributes.title.too_long', { count: 50 })}`
      },
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.document.attributes.title.blank')}`
      }
    },
  }

  connect() {
    super.connect()
    this.titleEditTarget.focus()
  }

  isFresh() {
    return this.freshValue
  }

  validateAllManually() {
    this.discardUnsavedTitle()
    super.validateAllManually()
    this.validateFile()

    let anyBadField = !!this.fieldTargets.find(field => field.classList.contains('error'))
    if (anyBadField) {
      this.ignoredFieldTarget.value = true
    }
  }

  validateFile() {
    if (this.fileInputTarget.files.length == 0) {
      this.displayError(this.fileInputTarget, [I18n.t('activerecord.errors.models.document.attributes.file.blank')])
    }
  }

  // upload document
  setFileInput(evt) {
    this.existsDocumentTarget.classList.add('d-none')
    this.newDocumentTarget.classList.remove('d-none')
    let file = evt.target.files[0]

    if (!this.acceptedFileTypesValue.includes(file.type)) {
      let error_message = I18n.t('activerecord.errors.models.document.attributes.file.invalid')
      this.displayError(this.documentFieldTarget, [error_message])
      return
    }

    let maxFileSize = this.maxFileSizeValue
    if (!FileSizeLessThan(file, maxFileSize * 1024 * 1024)) {
      let error_message = I18n.t('activerecord.errors.models.document.attributes.file.too_large', { count: maxFileSize })
      this.displayError(this.documentFieldTarget, [error_message])
      return
    }

    this.clearError(this.documentFieldTarget)
    pdfPreview(file, this.newDocumentTarget, 1, 1.5)
  }
  // OVERRIDED METHODS
  ////

  ////
  // NEW METHODS
  ////
  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }

    return messages;
  }

  selectFile() {
    this.fileInputTarget.click()
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }
  ////

  dismissForm(_evt) {
    this.ignoredFieldTarget.value = true
    this.rootTarget.classList.add('d-none')
    this.docsTabContentCtrlr.updateNewDocFormBtnStatus()
  }

  applyAndExitTitleEdit() {
    this.titleViewTarget.innerText = this.titleEditTarget.value
    this.titleViewWrapperTarget.classList.remove('d-none')
    this.titleEditTarget.classList.add('d-none')
    this.btnGroupTarget.classList.add('d-none')
  }

  discardAndExitTitleEdit() {
    this.clearError(this.titleEditTarget)
    this.titleEditTarget.value = this.titleViewTarget.innerText
    this.titleViewWrapperTarget.classList.remove('d-none')
    this.titleEditTarget.classList.add('d-none')
    this.btnGroupTarget.classList.add('d-none')
  }

  handleCancelBtn(evt) {
    if (this.isFresh()) {
      this.dismissForm(evt)
    }
    else {
      this.discardAndExitTitleEdit()
    }
  }

  discardUnsavedTitle() {
    this.titleEditTarget.value = this.titleViewTarget.innerText
  }

  enableTitleEdit() {
    this.titleViewWrapperTarget.classList.add('d-none')
    this.titleEditTarget.classList.remove('d-none')
    this.btnGroupTarget.classList.remove('d-none')
    this.titleEditTarget.focus()
  }

  applyTitleChange(evt) {
    this.validateManually(this.titleEditTarget)

    if (!this.titleEditTarget.classList.contains('error')) {
      this.titleViewTarget.innerText = this.titleEditTarget.value
      this.applyAndExitTitleEdit()

      if (this.isFresh()) this.freshValue = false
    }
  }

  deleteDocument(evt) {
    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_document.title'),
      message: I18n.t('organizer.modals.confirm_modals.delete_document.content'),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        this.ignoredFieldTarget.value = true
        this.rootTarget.classList.add('d-none')
        this.docsTabContentCtrlr.updateNewDocFormBtnStatus()
      }
    })
  }

  handleTitleKeydown(evt) {
    if (evt.key == 'Enter') evt.preventDefault()
  }

  get docsTabContentCtrlr() {
    return this.application.controllers.find(({ identifier }) => {
      return identifier == 'shared--manager--services--documents-tab-content'
    })
  }
}
