import { Controller } from 'stimulus';
import { ComponentSpinner } from '@lib/component_spinner';
import { autoOpenServiceModal } from '@lib/helpers/auto_open_service_modal';
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['form'];

  connect() {
    super.connect()

    autoOpenServiceModal()
  }

  applyFilter(e) {
    let searchAttrs = $(this.formTarget).serialize()

    this.stimulate('ExhibitionHalls::SearchReflex#search', e.target, searchAttrs)
      .catch(payload => {
        window.location.reload()
      })

    e.preventDefault()
  }

  resetForm(e) {
    $(this.formTarget).find('input#search_term').val('')
    $(this.formTarget).find('input.genre-item__checkbox').prop('checked', false)

    let searchAttrs = $(this.formTarget).serialize()

    this.stimulate('ExhibitionHalls::SearchReflex#search', e.target, searchAttrs)
      .catch(payload => {
        window.location.reload()
      })

    e.preventDefault()
  }
}
