(() => {
  let owlFn = () => {
    $('.owl-carousel:not([owl-carousel-skip-global])').owlCarousel({
      items: 3,
      margin: 24,
      loop: false,
      dots: false,
      nav: true,
      navText: ['<span class="material-icons icons">navigate_before</span>', '<span class="material-icons icons">navigate_next</span>'],
      responsive: {
        0 : {
          items : 1,
          margin: 0,
        },
        768 : {
          items : 3,
          margin: 24,
        }
      }
    })
  }

  $(document).one('turbolinks:load', owlFn);

  $(document).on('turbolinks:render', owlFn);

  $(document).on('turbolinks:before-render', function(){
    $('.owl-carousel:not([owl-carousel-skip-global])').trigger('destroy.owl.carousel')
  });
})()
