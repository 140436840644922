// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import I18n from '@video_lib/i18n-js/init.js.erb';
import UploadAndPreviewImage from "@lib/helpers/upload_and_preview_logo_helper";

const queryString = require('query-string')

export default class extends SimpleAjaxForm {
  static targets = ['form', 'showMode', 'attendeeInfo', 'avatarPreview', 'avatar', 'errorAvatar', 'profilePassword', 'profileEmail', 'listForm', 'submitBtn', 'chatNotificationFlagChk', 'downloadDocumentNotificationFlagChk']
  static validators = { validSelect: { attributes: ['job_category', 'job_title', 'industry', 'company_size', 'province'] } }

  ////
  // VALIDATION RULES
  ////
  static rules = {
    first_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.name.too_long', { count: 100 })}`
      }
    },
    last_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.name.too_long', { count: 100 })}`
      }
    },
    company_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.name.too_long', { count: 100 })}`
      }
    },
    address: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.name.blank')}`
      },
      length: {
        maximum: 500,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.name.too_long', { count: 500 })}`
      }
    },
    department_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.name.too_long', { count: 100 })}`
      }
    },
    position: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.name.too_long', { count: 100 })}`
      }
    },
    telephone: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.user.attributes.phone.blank')}`
      },
      length: {
        maximum: 11,
        minimum: 10,
        tooLong: `^${I18n.t('activerecord.errors.models.user.attributes.phone.too_long', { count: 11 })}`,
        tooShort: `^${I18n.t('activerecord.errors.models.user.attributes.phone.too_short', { count: 10 })}`
      },
      format: {
        pattern: "^[0-9]+$",
        message: `^${I18n.t('activerecord.errors.models.user.attributes.phone.invalid')}`
      }
    }
  }

  connect() {
    super.connect()

    StimulusReflex.register(this)
    this.initSelectPicker()
    this._preopenProfileModal()
    this.formTarget.dispatchEvent(new Event('ajax:beforeSend'));
  }

   // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error'),
      "success": I18n.t('messages.notify.general.edit.success')
    }

    return messages;
  }

  initSelectPicker() {
    $('.selectpicker').selectpicker()
  }

  reloadSuccess() {
    super.connect()
    this.switchProfileMode('show')
    this.initSelectPicker()
    this.allowSubmit(true)
  }

  reloadEditFormSuccess() {
    super.connect()
    this.initSelectPicker()
  }

  // プロフィールモーダルを開いた時の表示制御
  openModalSetMode() {
    if(this.element.dataset.set_mode == 'edit') {
      this.switchProfileMode('edit')
    } else {
      this.switchProfileMode('show')
    }
  }

  editMode(e) {
    let statusMode = e.target.dataset.edit_mode

    if(statusMode == 'true') {
      this.formTarget.dispatchEvent(new Event('ajax:beforeSend'));
      this.switchProfileMode('edit')
    } else {
      this.switchProfileMode('show')
      this.stimulate('Users::VideoProfileUpdateReflex#reload_edit_form')
    }
  }

  switchProfileMode(mode) {
    const profile_show_mode_area = document.querySelector('#profile_show_mode_area');
    const profile_edit_mode_area = document.querySelector('#profile_edit_mode_area');

    if (mode == 'edit') {
      // editを表示
      profile_show_mode_area.classList.add("d-none")
      profile_edit_mode_area.classList.remove("d-none")
      document.querySelector('.edit-user-form').classList.remove("d-none")
    } else if (mode == 'show') {
      // showを表示
      profile_show_mode_area.classList.remove("d-none")
      profile_edit_mode_area.classList.add("d-none")
    }
  }

  // Open edit password form
  editPassword(e) {
    this.editPasswordController.formTarget.classList.remove('d-none')
    this.profilePasswordTarget.classList.add('d-none')
  }

  // Open change email form
  changeEmail(e) {
    this.changeEmailController.formTarget.classList.remove('d-none')
    this.profileEmailTarget.classList.add('d-none')
    $("#user_email").val('')
  }

  reloadChangeEmail() {
    this.stimulate('Users::VideoProfileUpdateReflex#reload_unconfirm_email')
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    if (el == this.avatarTarget) {
      return this.errorAvatarTarget;
    }
    return el.nextElementSibling;
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    if (isAllow && !this.errors.dataMap.size > 0) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    alertify.success(I18n.t("messages.notify.general.success"))

    this.stimulate('Users::VideoProfileUpdateReflex#reload')

    this.reloadSuccess
  }

  // After validate
  afterValidate({ el, attr}) {
    if(el.tagName == 'SELECT') {
      if (!_.isEmpty(this.errorMessage(attr))) {
        this.displayError(el.parentNode.parentNode.querySelector('.dropdown'), this.errorMessage(attr))

        this.allowSubmit(false)
      } else {
        this.clearError(el.parentNode.parentNode.querySelector('.dropdown'))

        this.allowSubmit(true)
      }
    } else {
      super.afterValidate({ el, attr })
    }
  }

  ////
  // NEW METHODS
  ////

  // Valid select
  validSelect({attr, value}) {
    let message = `${I18n.t('activerecord.errors.models.user.attributes.select.blank')}`
    let isInvalid = _.isEmpty(value)

    if(isInvalid) {
      this.errors.add(attr, message)
      $(event.target).next().css('border-color', 'red')
    }
  }

  // Upload the attendee's avatar
  uploadProfileAvatar(el) {
    let message = UploadAndPreviewImage(el, this.avatarPreviewTarget, 5)
    if (message) {
      this.displayError(el.target, [message])
      this.clearElement(el.target)
    } else {
      this.clearError(el.target)
    }
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    alertify.error(this.messages.error)

    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  get editPasswordController() {
    return this.application.controllers.find(controller => controller.identifier === 'users--video-edit-password')
  }

  get changeEmailController() {
    return this.application.controllers.find(controller => controller.identifier === 'users--video-change-email')
  }

  get dropdownMenuCtrlr() {
    return this.getController('dropdown-menu')
  }

  _preopenProfileModal() {
    let openNotiSettings = queryString.parse(location.search).open_noti_settings
    if (!openNotiSettings) return

    let $tabOpen = $(this.dropdownMenuCtrlr.element).find(`.item a[onclick="tab('profile')"]`)
    $tabOpen[0].click()

    setTimeout(() => {
      $('#notifications-tab').tab('show')
    }, 250)
  }
}
