import _ from 'lodash';
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = [ "slide", "nextBtn", "prevBtn" ]
  static values = { index: Number }

  connect() {
    super.connect()
    this.reloadTooltip()
  }

  reloadTooltip() {
    $('body > .tooltip').remove()
    $('[data-toggle="tooltip"]').tooltip()
  }

  next(e) {
    this.indexValue++
    this.showCurrentSlide()
    this.stimulate('Organizers::Timetables::TimetableReflex#perform', e.target)
      .catch(payload => {
        window.location.reload()
      })
  }

  prev(e) {
    this.indexValue--
    this.showCurrentSlide()
    this.stimulate('Organizers::Timetables::TimetableReflex#perform', e.target)
      .catch(payload => {
        window.location.reload()
      })
  }

  indexValueChanged() {
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    try {
      this.slideTargets.forEach((element, index) => {
        element.hidden = index != this.indexValue
      })
      if ( this.indexValue == 0){
        $(this.prevBtnTarget).addClass('disabled')
        $(this.nextBtnTarget).removeClass('disabled')
      }else if ( this.indexValue > 0 && this.indexValue < this.slideTargets.length - 1){
        $(this.prevBtnTarget).removeClass('disabled')
        $(this.nextBtnTarget).removeClass('disabled')
      }else if ( this.indexValue == this.slideTargets.length - 1 ){
        $(this.nextBtnTarget).addClass('disabled')
        $(this.prevBtnTarget).removeClass('disabled')
      }
    } catch (error) {
    }
  }
}
