import ApplicationController from '@controllers/application_controller';
import { eventContentClickable } from '@lib/helpers/event_content_modal_clickable';

export default class extends ApplicationController {
  static targets = [
    'registeredEventContentTab', 'attendedEventContentTab', 'downloadedDocumentTab',
    'registeredEventContent', 'attendedEventContent', 'donwloadedDocument'
  ]

  connect() {
    super.connect()

    eventContentClickable('.card-item', this.element.dataset.content_subscr_loc)
  }

  activeTab(evt){
    let tabs = [
      this.registeredEventContentTabTarget, this.attendedEventContentTabTarget, this.downloadedDocumentTabTarget,
      this.registeredEventContentTarget, this.attendedEventContentTarget, this.donwloadedDocumentTarget
    ]

    _.forEach(tabs, (el)=> {
      if(evt.target.dataset.tab_id === el.dataset.tab_id) {
        el.classList.add('active-item')
      }else {
        el.classList.remove('active-item')
      }
    })
  }
}
