import ApplicationController from '@controllers/application_controller';
import { serialize } from 'object-to-formdata';
import { isBlankString } from '@lib/helpers/utilities_helper';

export default class extends ApplicationController {
  static targets = ['navigatorDown', 'choice', 'textInput']
  static values = { questionsCount: Number, position: Number, isLast: Boolean, isRequired: Boolean, answerCaching: Boolean, type: String, types: Object }

  connect() {
    super.connect()

    this.cacheTextAnswer = _.debounce(this.cacheTextAnswer, 250).bind(this)
  }

  toNextQuestion(evt) {
    let question = this._findQuestion(this.positionValue + 1)
    let currentQuestionTop = this.element.offsetTop
    let currentQuestionHeight = this.element.offsetHeight
    let nextQuestionTop = this.element.nextElementSibling.offsetTop
    let nextQuestionHeight = this.element.nextElementSibling.offsetHeight
    let parentHeight = this.element.parentNode.parentElement.offsetHeight
    let height = currentQuestionHeight + nextQuestionHeight
    let offset = currentQuestionTop - 15

    if (currentQuestionHeight > 269 && height > parentHeight) {
      offset = nextQuestionTop - (currentQuestionHeight / 2)
    }

    this._deactivate()

    this._$container.animate({
      scrollTop: offset
    }, 200, () => {
      question.activate()
    })

    // Update progress
    let percentage = (this.positionValue + 1)/this.questionsCountValue
    this._progressController.updateProgress(percentage)
  }

  toPreviousQuestion(evt) {
    let previousQuestion = this._findQuestion(this.positionValue - 1)
    let previousQuestion2 = this._findQuestion(this.positionValue - 2)
    let parentHeight = this.element.parentNode.parentElement.offsetHeight

    // Deactive self and activate previous question
    this._deactivate()

    if (this.positionValue >= 2) {
      let offset = 0

      if(this.positionValue > 2) {
        let previousQuestion2Height = previousQuestion2.element.offsetHeight
        let previousQuestion2Top = previousQuestion2.element.offsetTop
        let previousQuestionTop = previousQuestion.element.offsetTop
        let previousQuestionHeight = previousQuestion.element.offsetHeight
        let height = previousQuestion2Height + previousQuestionHeight

        if(previousQuestion2Height > 269 && height > parentHeight) {
          offset = previousQuestionTop - (previousQuestion2Height / 2)
        } else {
          offset = previousQuestion2Top  - 15
        }
      } else {
        offset = 0
      }

      this._$container.animate({
        scrollTop: offset
      }, 200, () => {
        previousQuestion.activate()
      })
    }
    else {
      previousQuestion.activate()
    }

    // Update progress
    let percentage = (this.positionValue - 1)/this.questionsCountValue
    this._progressController.updateProgress(percentage)
  }

  checkInputText(evt) {
    if (!this.isRequiredValue) return // Do nothing if the question is not required
    if (this.isLastValue) return // If not last question, check to enable/disable next question button

    if (!isBlankString(evt.target.value)) {
      this.navigatorDownTarget.classList.remove('disabled')
    }
    else {
      this.navigatorDownTarget.classList.add('disabled')
    }
  }

  cacheTextAnswer(evt) {
    if (!this.answerCachingValue) return

    let contentSurveyId = evt.target.dataset.contentSurveyId
    let drawerAction = evt.target.dataset.drawerAction
    let questionId = evt.target.dataset.questionId
    let answer = evt.target.value

    this._reflexCacheAnswer(contentSurveyId, drawerAction, questionId, answer)
  }

  cacheSelectionAnswer(evt) {
    if (!this.answerCachingValue) return

    let contentSurveyId = evt.currentTarget.dataset.contentSurveyId
    let drawerAction = evt.currentTarget.dataset.drawerAction
    let questionId = evt.currentTarget.dataset.questionId
    let answer = this.choiceTargets.filter(choice => !!choice.checked).map(choice => choice.value)

    this._reflexCacheAnswer(contentSurveyId, drawerAction, questionId, answer)
  }

  checkSelection(_evt) {
    if (!this.isRequiredValue) return // Do nothing if the question is not required
    if (this.isLastValue) return // If not last question, check to enable/disable next question button

    let anyChecked = this.choiceTargets.some(target => target.checked)
    if (anyChecked) {
      this.navigatorDownTarget.classList.remove('disabled')
    }
    else {
      this.navigatorDownTarget.classList.add('disabled')
    }
  }

  _reflexCacheAnswer(surveyId, drawerAction, questionId, answer) {
    this.stimulate('ContentSurveys::DrawerReflex#cache_answer', this.element, surveyId, drawerAction, questionId, answer)
  }

  _buildAnswerCacheData(contentSurveyId, questionId, answer, eventSlug) {
    return {
      id: contentSurveyId,
      event_slug: eventSlug,
      content_survey: {
        question_id: questionId,
        answer: answer
      }
    }
  }

  _deactivate() {
    this.element.classList.remove('active')
  }

  activate() {
    this.element.classList.add('active')
  }

  isAnswerValid() {
    if (!this.isRequiredValue) return true

    if (this.typeValue == this.typesValue['text'] || this.typeValue == this.typesValue['textarea']) {
      return !isBlankString(this.textInputTarget.value)
    }

    if (this.typeValue == this.typesValue['select_single'] || this.typeValue == this.typesValue['select_multiple']) {
      return this.choiceTargets.some(input => !!input.checked)
    }
  }

  _findQuestion(position) {
    return this.application.controllers.find((ctrlr) => {
      return (ctrlr.identifier === 'content-surveys--answers--question' &&
              ctrlr.positionValue === position)
    })
  }

  get _$container() {
    return $('.scrollable-area')
  }

  get _progressController() {
    return this.application.controllers.find(ctrlr => ctrlr.identifier == 'content-surveys--answers--progress')
  }
}
