// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import UploadAndPreviewImage from '@lib/helpers/upload_and_preview_logo_helper';
import { confirmModal } from '@lib/helpers/js_modals';
import { restrictedDomains } from "@lib/helpers/validation_helper"

export default class extends SimpleAjaxForm {
  static targets = ['form', 'submitBtn', 'imagePreview', 'email']
  static validators = { validEmail: { attributes: ['email_01', 'email_02', 'email_03' ]  } }

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.service.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.service.attributes.name.too_long', { count: 50 })}`
      }
    },
    description: {
      length: {
        maximum: 255,
        tooLong: `^${I18n.t('activerecord.errors.models.service.attributes.description.too_long', { count: 255 })}`
      }
    },
    email_01: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
        }
      }
    },
    email_02: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
        }
      }
    },
    email_03: (value) => {
      if (_.isEmpty(value)) return;
      return {
        email: {
          message: `^${I18n.t('activerecord.errors.models.user.attributes.email.invalid')}`
        }
      }
    }
  }

  // Upload logo
  uploadServiceLogo(el) {
    let message = UploadAndPreviewImage(el, this.imagePreviewTarget, 5)
    if (message) {
      this.displayError(el.target, [message])
      this.clearElement(el.target)
    } else {
      this.clearError(el.target)
    }
  }

  validEmail({attr, value}) {
    let domain = value.split('@')[1]
    let isInvalid = restrictedDomains.includes(domain)
    let $element = $(`[data-attr="shared--manager--service-form.${attr}"]`)
    let alreadyAdded = this.emailAddedValidate($element[0])

    if(isInvalid) {
      this.errors.add(attr, I18n.t('activerecord.errors.models.user.attributes.email.restrict_domain'))
    } else if(alreadyAdded) {
      this.errors.add(attr, [I18n.t('activerecord.errors.models.attendee.attributes.email.taken')])
    }
  }

  ////
  // NEW METHODS
  ////

  // Check if email already added
  emailAddedValidate(el) {
    let isAdded = false

    this.emailTargets.filter((email) => email.id != el.id).forEach((elem) => {
      let isBlank = el.value == '' && elem.value == ''

      if(!isBlank && el.value == elem.value) {
        isAdded = true
      }
    })

    return isAdded
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }
  ////
  // OVERRIDED METHODS
  ////

  ////
  // NEW METHODS
  ////
  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }

    return messages;
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let errors = this.errors.dataMap.size > 0

    if (isAllow && !errors) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    alertify.error(this.messages.error)

    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  validateAll(evt) {
    super.validateAll(evt)
    this.nestedDocumentFormCtrlrs.forEach(ctrlr => ctrlr.validateAllManually())
  }

  // DELETE SERVICE
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.services.confirm_modals.delete_service.title'),
      message: I18n.t('organizer.services.confirm_modals.delete_service.content'),
      labels: {
        ok_btn: I18n.t('organizer.services.confirm_modals.delete_service.buttons.confirm'),
        cancel_btn: I18n.t('organizer.services.confirm_modals.delete_service.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }

  get nestedDocumentFormCtrlrs() {
    return this.application.controllers.filter(({ identifier }) => {
      return identifier == 'shared--manager--services--nested-document-form'
    })
  }
}
