import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  active(e) {
    this.stimulate('MyCompany::MemberReflex#active', e.target)
      .then((e) => {
        alertify.success('有効にしました')
      })
      .catch(payload => {
        window.location.href = Routes.root_path()
      })
  }

  unactive(e) {
    this.stimulate('MyCompany::MemberReflex#unactive', e.target)
      .then((e) => {
        alertify.success('無効にしました')
      })
      .catch(payload => {
        window.location.href = Routes.root_path()
      })
  }

  resend(e) {
    this.stimulate('MyCompany::MemberReflex#resend', e.target)
      .then((e) => {
        alertify.success('招待メールを送信しました')
      })
  }

  cancel(e) {
    this.stimulate('MyCompany::MemberReflex#cancel', e.target)
      .then((e) => {
        alertify.success('招待を取り消しました')
      })
  }
}
