// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';
import { confirmModal } from '../../lib/helpers/js_modals';
import UploadAndPreviewImage from "../../lib/helpers/upload_and_preview_logo_helper";

export default class extends SimpleAjaxForm {

  static values = {
    publishTypes: Object
  }

  static targets = [
    'form', 'submitBtn', 'required', 'thumbnailPreview', 'headerImagePreview',
    'organizerAvatarPreview', 'slugFld', 'startExchangePeriod',
    'publishTypeFld', 'publishTypeLabel', 'publishTypeExplain', 'lockIcon', 'copyIcon', 'wrapperSlug', 'errorMessages'
  ]

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.event.attributes.name.too_long', { count: 50 })}`
      }
    },
    slug: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.slug.blank')}`
      },
      length: {
        maximum: 50,
        minimum: 3,
        tooLong: `^${I18n.t('activerecord.errors.models.event.attributes.slug.too_long', { count: 50 })}`,
        tooShort: `^${I18n.t('activerecord.errors.models.event.attributes.slug.too_short', { count: 3 })}`
      },
      format: {
        pattern: /^[a-zA-Z0-9-_]+$/,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.slug.invalid')}`
      }
    },
    start_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.start_time.blank')}`
      }
    },
    end_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.end_time.blank')}`
      }
    },
    start_exchange_period: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.start_exchange_period.blank')}`
      }
    },
    exchange_period: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.exchange_period.blank')}`
      }
    },
    contact_email: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.contact_email.blank')}`
      },
      email: {
        message: `^${I18n.t('activerecord.errors.models.event.attributes.contact_email.invalid')}`
      }
    },
    site_url: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.contact_email.blank')}`
      }
    },
    outline: (value) => {
      if (_.isEmpty(value)) return;

      return {
        length: {
          maximum: 200,
          tooLong: `^${I18n.t('activerecord.errors.models.event.attributes.outline.too_long', { count: 200 })}`
        }
      }
    },
    organizer_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event.attributes.organizer_name.blank')}`
      },
      length: {
        maximum: 20,
        tooLong: `^${I18n.t('activerecord.errors.models.event.attributes.outline.too_long', { count: 20 })}`
      }
    }
  }

  connect() {
    super.connect()
  }

  wrapperSlugFocusin() {
    if($(this.errorMessagesTarget).text()==''){
      $(this.wrapperSlugTarget).css('border', '1px solid black')
    }else{
      $(this.wrapperSlugTarget).css('border', '1px solid red')
    }

  }

  wrapperSlugFocusout() {
    if($(this.errorMessagesTarget).text()==''){
      $(this.wrapperSlugTarget).css('border', '1px solid #EBEBEB')
    }else{
      $(this.wrapperSlugTarget).css('border', '1px solid red')
    }
  }

  ////
  // OVERRIDED METHODS
  ////

  // Trigger an action after validate an attribute
  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
      el.classList.add("error")
      this.allowSubmit(false)
    } else {
      this.clearError(el)
      this.allowSubmit(true)
    }
  }

  // Trigger an action after validating all attributes
  afterValidateAll(event) {
    this.allowSubmit(false);
  }

  ////
  // NEW METHODS
  ////

  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }
    if (this.submitBtnTarget.value == 'create') {
      messages['success'] = I18n.t('messages.notify.general.register.success')
    } else {
      messages['success'] = I18n.t('messages.notify.general.edit.success')
    }

    return messages;
  }

  // Input all required field or not
  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.inputAllRequireFields();

    if(isAllow && allRequiredInput) {
      $(this.submitBtnTarget).removeClass('disabled')
    } else {
      $(this.submitBtnTarget).addClass('disabled')
    }
  }

  changeStartDate(e) {
    if (_.isEmpty(e.target.value) || !_.isEmpty(this.startExchangePeriodTarget.value)) {
      return
    }
    $(this.startExchangePeriodTarget).val(e.target.value)
  }

  changePublishType(evt) {
    let checked = evt.target.checked

    // Update value for hidden publish type field
    let publishType = checked ? this.publishTypesValue['limited'] : this.publishTypesValue['common']
    this.publishTypeFldTarget.value = publishType

    // Update text
    this.publishTypeLabelTarget.innerText = I18n.t(`organizer.events.event_form.fields.publish_type.${publishType}.label`)
    this.publishTypeExplainTarget.innerText = I18n.t(`organizer.events.event_form.fields.publish_type.${publishType}.explain`)

    // Hide/show lock icon, update label color
    if (checked) {
      this.lockIconTarget.classList.remove('d-none')
      this.copyIconTarget.classList.remove('d-none')
      this.publishTypeLabelTarget.classList.add('color-green')
    }
    else {
      this.lockIconTarget.classList.add('d-none')
      this.copyIconTarget.classList.add('d-none')
      this.publishTypeLabelTarget.classList.remove('color-green')
    }
  }

  // Clipboard Copy Path
  copyPath(event){
    let url = event.target.dataset.url
    var aux = document.createElement("input");

    // Get the text from the element passed into the input
    aux.setAttribute("value", url);

    // Append the aux input to the body
    document.body.appendChild(aux);

    // Highlight the content
    aux.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the input from the body
    document.body.removeChild(aux);

    alertify.success(I18n.t('messages.notify.general.success'))
  }

  // Delete Event
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.event.confirm_modals.delete_event.title'),
      message: I18n.t('organizer.event.confirm_modals.delete_event.content'),
      labels: {
        ok_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    // Notify success message
    if (this.submitBtnTarget.value == 'create') {
      document.location.href = response.data.redirect_path
    } else {
      document.location.href = response.data.redirect_path
    }
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    alertify.error(this.messages.error)
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)
    let first_error_attribute = this.attributes.dataMap.get(error_attributes[0]).el
    first_error_attribute.scrollIntoView(false)
    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])

      }
    })
  }

  // DELETE COMPANY
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.event.confirm_modals.delete_event.title'),
      message: I18n.t('organizer.event.confirm_modals.delete_event.content'),
      labels: {
        ok_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event.confirm_modals.delete_event.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
          }
        });
      }
    })
  }

  uploadEventImage(el) {
    let message = UploadAndPreviewImage(el, this.thumbnailPreviewTarget, 5)
    if (message) {
      this.displayError(el.target, [message])
      this.clearElement(el.target)
    } else {
      this.clearError(el.target)
    }
  }

  uploadOrganizerAvatar(el) {
    let message = UploadAndPreviewImage(el, this.organizerAvatarPreviewTarget, 5)
    if (message) {
      this.displayError(el.target, [message])
      this.clearElement(el.target)
    } else {
      this.clearError(el.target)
    }
  }

  uploadHeaderImage(el) {
    let message = UploadAndPreviewImage(el, this.headerImagePreviewTarget, 5)
    if (message) {
      this.displayError(el.target, [message])
      this.clearElement(el.target)
    } else {
      this.clearError(el.target)
    }
  }

  updateSlugDisability(status) {
    if(this.slugFldTarget.disabled == true){
      this.wrapperSlugTarget.classList.remove('not-allow')
    }else{
      this.wrapperSlugTarget.classList.add('not-allow')
    }
    this.slugFldTarget.disabled = (status == 'disabled')
  }
}
