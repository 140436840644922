
import ApplicationController from '@controllers/application_controller';
import { stringToHTML } from '@lib/helpers/view_helpers';
export default class extends ApplicationController {
  static targets = ['question', 'menu']

  connect() {
    super.connect()
  }

  openOptionSetting(e) {
    if (e.target.dataset.type != 'edit') return

    let map = {}

    e.target.querySelectorAll('input[type=hidden]').forEach((el) => {
      if(el.dataset.nested) {
        if(!map[el.dataset.nested]) map[el.dataset.nested] = {}
        if(!map[el.dataset.nested][el.dataset.domId]) map[el.dataset.nested][el.dataset.domId] = {}

        map[el.dataset.nested][el.dataset.domId][el.dataset.key] = el.value
      } else {
        if(el.dataset.key) map[el.dataset.key] = el.value
      }
    })

    this.popupMenuController.openPopupMenu(e, map)
    this.removeAllActive()

    e.target.classList.add('active')
    e.preventDefault()
  }

  focusinButton(e) {
    if(!this._getIconTarget(e)) return
    this.removeAllHover()

    e.target.classList.add('dragging')
    this._getIconTarget(e).classList.remove('hide')
  }

  focusoutButton(e) {
    if(!this._getIconTarget(e)) return
    this._toggleCloseMoreMenu()

    e.target.classList.remove('dragging')
    this._getIconTarget(e).classList.add('hide')
  }

  removeAllHover() {
    $('.input-wrapper').each((_i, el) => $(el).removeClass('dragging'))
    $('.input-wrapper__select-icon').each((_i, el) => $(el).addClass('hide'))
  }

  removeAllActive() {
    $('.input-wrapper').each((_i, el) => $(el).removeClass('active'))
  }

  _getIconTarget(element) {
    return element.target.querySelector('.input-wrapper__select-icon')
  }

  _getQuestionTarget(element) {
    return element.target.querySelector('.input-wrapper')
  }

  _toggleCloseMoreMenu() {
    $('.more-menu').filter((_i, el) => {
      if ($(el).hasClass('show')) {
        $(el).dropdown('toggle')
      }
    })
  }

  get hasMenuOpen() {
    let menus = $('.more-menu').filter((_i, el) => $(el).hasClass('show'))
    return menus.length > 0
  }

  get popupMenuController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--popup-menu')
  }
}