import _ from 'lodash';

// Import custom libraries
import { SimpleAjaxForm } from '../../../lib/helpers/simple_ajax_form';
import I18n from '../../../lib/i18n-js/init.js.erb';
import { confirmModal } from '../../../lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static targets = [ 'form', 'required', 'submitBtn', 'invitationForm', 'placeHolder']
  ////
  // VALIDATION RULES
  ////
  static rules = {
    first_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.first_name.blank')}`
      },
      length: {
        maximum: 20,
        tooLong: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.first_name.too_long', { count: 20 })}`
      }
    },
    last_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.service.blank')}`
      },
      length: {
        maximum: 20,
        tooLong: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.last_name.too_long', { count: 20 })}`
      }
    },
    email: (value) => {
      // FIXME: Skip format error if empty

      return {
        presence: {
          allowEmpty: false,
          message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.email.blank')}`
        },
        email: {
          message: `^${I18n.t('activerecord.errors.models.service_invitation.attributes.email.invalid')}`
        }
      }
    }
  }

  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect()
    StimulusReflex.register(this)
  }

  ////
  // NEW METHODS
  ////
  // Define error message to notify

  reflexError(element, reflex, error) {
    alertify.error(this.messages.error);
  }

  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error'),
      "toggle_failure": I18n.t('messages.notify.service_invitation.toggle.failure')
    }

    return messages;
  }

  showInvitationForm() {
    this.placeHolderTarget.classList.remove('d-flex')
    this.placeHolderTarget.classList.add('d-none')
    this.invitationFormTarget.classList.remove('d-none')
  }

  cancelInvitation() {
    super.clearForm()

    this.invitationFormTarget.classList.add('d-none')
    this.placeHolderTarget.classList.remove('d-none')
    this.placeHolderTarget.classList.add('d-flex')
  }

  // Input all required field or not
  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allRequiredInput = this.inputAllRequireFields();

    if(isAllow && allRequiredInput) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    alertify.error(this.messages.error)

    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this
    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  // DELETE EVENT_SERVICE
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.event_service.confirm_modals.delete_event_service.title'),
      message: I18n.t('organizer.event_service.confirm_modals.delete_event_service.content'),
      labels: {
        ok_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.confirm'),
        cancel_btn: I18n.t('organizer.event_service.confirm_modals.delete_event_service.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }
}
