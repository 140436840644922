import { Controller } from 'stimulus'

export default class extends Controller {
  redirect() {
    // open link in new tab
    window.location.href = this.href
  }

  get href() {
    return $(this.element).data('href')
  }
}
