import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['root']

  connect() {
    super.connect()
    this._setupTooltips()
  }

  _setupTooltips() {
    let $elms = $(this.rootTarget).find('[data-toggle="tooltip"]')

    $elms.each((_ind, elm) => {
      if (elm.classList.contains('error')) {
        $(elm).tooltip({ boundary: 'window', template: this._errorTooltipTemplate() })
      }
      else {
        $(elm).tooltip({ boundary: 'window' })
      }
    })
  }

  _errorTooltipTemplate() {
    return '<div class="events--export-csv-screens--history-item-component--error-tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
  }
}
