// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("@channels")
require("video_lib/owl_carousel")

let header = require("packs/video/header")
let tooltip = require("packs/video/tooltip")
let scrollTop = require("packs/video/scroll_top")

// Config to use jquery as $ sign
window.jQuery = $
window.$ = $

import "../common/js/menu_toggle"
import '../video_lib/carousel/assets/owl.carousel.min.css'
import '../video_lib/carousel/owl.carousel.min'
import 'lazysizes'

import 'jquery-ui/ui/widgets/sortable.js'
import 'jquery-ui/ui/disable-selection.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import 'bootstrap-select/dist/js/i18n/defaults-ja_JP.min.js'
import 'video.js/dist/video.min.js'
import 'videojs-hls-quality-selector/dist/videojs-hls-quality-selector.min.js'
import 'videojs-contrib-quality-levels/dist/videojs-contrib-quality-levels.min.js'
import 'jquery-datetimepicker/build/jquery.datetimepicker.full.min.js'
import 'videojs-event-tracking/dist/videojs-event-tracking.min.js'

// Config for alertifyjs to notify messages
import { alertify } from '@video_lib/alertifyjs/init'
window.alertify = alertify

// Config for JS Routes
import * as Routes from '@video_lib/js-routes/init.js.erb'
window.Routes = Routes

import { TableScroll } from "../common/js/tablescroll"
window.TableScroll = TableScroll

import 'controllers'

// Config for I18n js
import I18n from '@video_lib/i18n-js/init.js.erb'
window.I18n = I18n

import "../video_stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// TODO: take into account of turbolinks
$(window).on('load', function() {
  // init scroll
  header.initScroll()
  // init tooltip
  tooltip.initTooltip()
  // owlCarousel.initOwlCarousel()
  // init scroll_top
  scrollTop.initScrollTop()
})
