// Import libraries from webpacker libraries
import _ from 'lodash';
import Rails from '@rails/ujs';

// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';
import { confirmModal } from '../../lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static targets = [ 'createForm', 'createDropdown', 'editForm', 'editDropdown', 'nameInput', 'startTimeSelect', 'endTimeSelect','videoProvider', 'videoId', 'video' ]

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.too_long', { count: 50 })}`
      }
    }
  }

  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect();
    StimulusReflex.register(this)
  }

  validateAll() {
    super.validateAll()
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
    } else {
      this.clearError(el)
    }
  }

  ////
  // NEW METHODS
  ////

  connectGenre(e) {
    this.stimulate('Organizers::Genres::RelationReflex#trigger', e.target)
  }

  // Close dropdown menu
  closeDropdown(e) {
    let dropdownType = e.target.dataset.dropdownType

    if (dropdownType == 'create') {
      this.createDropdownTarget.classList.remove('show')
      super.clearForm()
    } else {
      this.editDropdownTarget.classList.remove('show')
    }
  }

  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  //// CREATE
  // After creating successfully, redirect to previous page
  successCreate(e) {
    this.successSubmit(e)
  }

  // After creating failed, show errors and hightligh like validate()
  failedCreate(e) {
    this.failedSubmit(e)
  }

  //// EDIT
  // After editing successfully, redirect to previous page
  successEdit(e) {
    this.successSubmit(e)
  }

  // After creating failed, show errors and hightligh like validate()
  failedEdit(e) {
    this.failedSubmit(e)
  }

  //// DELETE
  delete(event) {
    let url = event.target.dataset.url;
    let deleteStage = this.nameInputTarget.value
    let _this = this

    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_item.title'),
      message: I18n.t('organizer.modals.confirm_modals.delete_item.content', { item_name: deleteStage }),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        Rails.ajax({
          url: url,
          type: 'DELETE',
          error: function(e) {
            let errors = e.errors;
            // Show first error message
            let error_message = _this.pickFirstMessage(errors)
            alertify.error(error_message)
          }
        });
      }
    })
  }

  reload() {
    Turbolinks.visit(location.href)
  }
}
