// event_videoからの移管ファイル

// Import custom libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form'
import { Constant }       from '@video_lib/constant.js.erb'

// Import stimulus libraries
import _                  from 'lodash'

export default class extends SimpleAjaxForm {
  static targets = [
    'form',
    'startAt',
    'title',
    'templateRow'
  ]

  // TODO: エラーメッセージを出力していない
  static rules = {
    start_at: {
      presence: {
        allowEmpty: false,
        message: `^`
      },
      lessThanOrEqualTo: {
        message: `^`
      },
      unique: {
        message: `^`
      }
    },
    title: {
      presence: {
        allowEmpty: false,
        message: `^`
      },
      length: {
        maximum: Constant.settings.video_section.title.length.maximum,
        tooLong: `^`
      }
    }
  }

  connect() {
    super.connect()

    this.initCustomValidators()
    this.initTimepicker()
  }

  //////
  // init
  //////

  // 「目次」の開始時刻のバリデーション
  initCustomValidators() {
    let _this = this

    _this.constructor.validatejs.validators.lessThanOrEqualTo = (value, options) => {

      // 動画の時間(動画の長さ)
      let duration = _this.videoPlayerController.duration

      if(value > duration) return options.message
    }

    _this.constructor.validatejs.validators.unique = (value, options) => {
      let editStartAt = $(this.originalSelector).find('input.js-start-at').val()
      let listStartAt = _.map(_this.videoSectionController.sectionRows, (row)=>{
        let startAt = $(row).find('input.js-start-at').val()
        if(editStartAt != startAt) return startAt
      })

      if(_.includes(listStartAt, value)) return options.message
    }
  }

  initTimepicker() {
    let _this = this

    $('.timepicker').datetimepicker({
      datepicker: false,
      format: 'H:i:s',
      step: Constant.settings.timepicker_step,
      onChangeDateTime: (currentTime, input) => {
        _this.validateEl(input[0])
      }
    });
  }

  //////
  // method action
  //////

  closeForm() {
    $(this.originalSelector).removeClass('d-none')
    this.context.element.remove()
  }

  submitForm(event) {
    let _this = this
    let isEdit = $(_this.originalSelector).hasClass('js-list-item')

    _this.validateAll(event)

    if (_this.errors.hasAny()) return

    let insertPosition = _this.getInsertPosition()
    let lastRow        = _this.sectionRows[_this.sectionRows.length - 1]
    let publicTarget   = _this.getPublicTarget(insertPosition)
    let content        = this.getDeletedRow()
    let templateRow    = _this.templateRowTarget.content.cloneNode(true)

    if(content != undefined) {
      // case section's start_at existed, but section was deleted (_destroy = true)

      // change _destroy = false, and show section
      $(content).find('input.js-destroy').val('false')
      $(content).removeClass('d-none')

      if(isEdit) {
        // case edit, change current edit section to delete
        $(_this.originalSelector).find('input.js-destroy').val('true')
      } else {
        // case new, show button again
        $(this.originalSelector).removeClass('d-none')
      }
    } else {
      // case section's start_at not existed
      $(this.originalSelector).removeClass('d-none')

      if(isEdit) {
        // case edit, get edit section, now is hidding
        content = _this.originalSelector
      } else {
        // case new, get from template
        content = templateRow.firstChild
      }
    }

    _this.appendDataToTemplate(content, publicTarget)
    _this.insertRow(insertPosition, lastRow, content)

    this.context.element.remove()
  }

  //////
  // private method
  //////

  insertRow(insertPosition, lastRow, content) {
    let sortable = document.querySelector('.js-sortable')
    if(lastRow == undefined || insertPosition === undefined) {
      // case not have any section or insert before first section
      sortable.childNodes[0].before(content)
    } else {
      // normal case, insert after section
      insertPosition.after(content)
    }

    this.videoSectionController.initRestrict()
  }

  getPublicTarget(insertPosition) {
    // case insert section before first section, always return `anyone`
    if(insertPosition == undefined) return 'anyone'

    return $(insertPosition).find('input.js-public-target').val()
  }

  getInsertPosition() {
    // find index of first section have start_at > new section's start_at
    let index = _.findIndex(this.sectionRows, (row) => {
      return $(row).find('input.js-start-at').val() > this.startAt
    })
    // if not have index, mean new section need insert after last section
    if(index == -1) return this.sectionRows[this.sectionRows.length - 1]
    // normal case, returns the section that was used to insert the new section after it
    // maybe return undefined(index == 0), it should be insert before, L145
    return this.sectionRows[index - 1]
  }

  getDeletedRow() {
    let _this = this

    return _.find(_this.sectionDeleteRows, (row) => {
      let jRow = $(row)
      return jRow.find('input.js-start-at').val() == _this.startAt && jRow.find('input.js-destroy').val() == 'true'
    })
  }

  appendDataToTemplate(content, publicTarget) {
    let jqueryContent = $(content)

    let spanTime = jqueryContent.find('span.js-start-at')
    let inputStartAt = spanTime.find('input').val(this.startAt)
    spanTime.text(this.startAt).append(inputStartAt)

    let spanTitle = jqueryContent.find('span.js-title')
    let inputTitle = spanTitle.find('input').val(this.title)
    spanTitle.text(this.title).attr('title', this.title).attr('data-original-title', this.title).append(inputTitle)
    spanTitle.tooltip()

    jqueryContent.find('input.js-public-target').val(publicTarget)
  }

  get startAt() {
    return $(this.startAtTarget).val()
  }

  get title() {
    return $(this.titleTarget).val()
  }

  get originalSelector() {
    return this.context.element.nextElementSibling
  }

  get templateRowTarget() {
    return this.videoSectionController.templateRowTarget
  }

  get sectionRows() {
    return this.videoSectionController.sectionRows
  }

  get sectionDeleteRows() {
    return _.filter(this.videoSectionController.sectionRowTargets, (r) => { return $(r).find('input.js-destroy').val() == 'true' })
  }

  get videoSectionController() {
    return this.getController('shared--videos--form--video-section')
  }

  get videoPlayerController() {
    return this.getController(Constant.settings.stimulus.video_form.video_player_controller)
  }
}
