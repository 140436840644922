// Import custom libraries
import Dropzone              from 'dropzone'
import _                     from 'lodash'
import ApplicationController from '../application_controller'
import { showLoaderCenterFor } from '../../lib/helpers/view_helpers';
import { confirmModal } from '@lib/helpers/js_modals';

Dropzone.autoDiscover = false;
export default class extends ApplicationController {
  static targets = ['root', 'upload', 'icon', 'input', 'submit']

  connect() {
    super.connect()

    this._subscribeChannel()
    this._initDropzone()
  }

  // インポート情報表示
  processFileComplete(e) {
    this.stimulate('Organizers::Users::ImportReflex#render', this.uploadTarget, this.importedComponentEl.dataset.event_id, this.importedComponentEl.dataset.file_id)
  }

  // インポートファイルを元に来場者招待メール送信
  importPatch(e) {
    this.stimulate('Organizers::Users::ImportReflex#import', e.target, this.importedComponentEl.dataset.event_id, this.importedComponentEl.dataset.file_id)
  }

  // 「Errorのみ表示」の切り替え
  filterPatch(e) {
    this.stimulate('Organizers::Users::ImportReflex#filter', e.target, this.importedComponentEl.dataset.event_id, e.target.checked, this.importedComponentEl.dataset.file_id)
  }

  removeImportUser(e) {
    confirmModal({
      message: '削除してもよろしいですか？',
      labels: {
        ok_btn: 'はい',
        cancel_btn: 'キャンセル',
      },
      onOk: () => {
        this.stimulate('Organizers::Users::ImportReflex#remove_import_user', e.target, this.importedComponentEl.dataset.event_id, e.target.dataset.row_number, this.importedComponentEl.dataset.file_id)
        .then((e) => {
          alertify.success(I18n.t('messages.notify.general.success'));
        });
      },
    });
  }

  // ファイルアップロードのドラッグ&ドロップの初期設定
  _initDropzone() {

    // 多重実行防止
    if (Dropzone.instances.some(dz => dz.element === document.querySelector('#mydropzone'))) return

    Dropzone.autoDiscover = false;
    let _this = this

    let dropzone = new Dropzone ("#mydropzone", {
      dictInvalidFileType: _this.errorMessageInvalidType,
      acceptedFiles: _this.acceptedFiles,
      dictDefaultMessage: "",
      uploadMultiple: false,
      maxFiles:1,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      addedfile: function(file) {
        // ローダー表示
        showLoaderCenterFor(this.element)
      },
      error: function(file, response) {
        alertify.error(response)
      },
      success: function(data, response) {
        _this.importedComponentEl.dataset.file_id = response.data.file_id
        $(this.element).addClass('no-pointer-events')
        this.files = []
        // 来場者インポート一覧を表示
        _this.processFileComplete()
      }
    });
  }

  _subscribeChannel() {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'Organizers::ImportProcessingChannel'
    }, {
      connected: () => {
      },
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  get acceptedFiles() {
    if(this.hasInputTarget) return this.inputTarget.dataset.accept
  }

  get importedComponentEl() {
    return document.querySelector("#imported-component");
  }

  get errorMessageInvalidType() {
    let validContentTypes = _.replace(this.acceptedFiles, /\./g, ' ').trim()

    return `${I18n.t('messages.notify.import.error', { valid_content_types:  validContentTypes})}`
  }
}