document.addEventListener("DOMContentLoaded", function(event) {
  let collapseWrapper = document.querySelector('.collapse-wrapper')

  if(!collapseWrapper) return

  let toggleMenus = document.querySelectorAll('.navbar-toggler')

  toggleMenus.forEach((toggleMenu) => {
    let closeMenu = document.querySelector('.navbar-close')

    toggleMenu.addEventListener("click", (e) => {
      let collapseWrapper = document.querySelector('.collapse-wrapper')
      let navbarCollapse = collapseWrapper.querySelector('.navbar-collapse')

      navbarCollapse.classList.add('animate')
      navbarCollapse.classList.add('show')
    });

    closeMenu.addEventListener("click", (e) => {
      let collapseWrapper = document.querySelector('.collapse-wrapper')
      let navbarCollapse = collapseWrapper.querySelector('.navbar-collapse')

      navbarCollapse.classList.remove('animate')
      navbarCollapse.classList.remove('show')
    });
  })
});