import Turbolinks from 'turbolinks'
import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  beforeReflex (element, reflex) {
    // document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, error) {
    // show success message etc...
  }

  reflexError (element, reflex, error) {
    if(error != 'disable_exhibitor') {
      alertify.error(I18n.t('messages.notify.general.error'))
    }
  }

  afterReflex (element, reflex) {
    // document.body.classList.remove('wait')
  }

  reload () {
    Turbolinks.visit(location.href)
  }

  getController(controllerName) {
    return this.getControllers(controllerName)[0]
  }

  getControllers(controllerName) {
    return this.application.controllers.filter(controller => {
      return controller.context.identifier === controllerName
    })
  }
}
