// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { ValidationController } from '@video_lib/stimulus_validation/validation_controller';
import I18n from '@video_lib/i18n-js/init.js.erb';

export class SimpleAjaxForm extends ValidationController {
  ////
  // VALIDATION RULES => will be defined in child classes
  ////

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    return el.nextElementSibling;
  }

  // Get error message of an attr
  // Default: Show error in the next html element
  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr) : [];
  }

  // Trigger an action after validate an attribute
  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }
  }

  // Trigger an action after validating all attributes
  afterValidateAll(event) {
    // Don't allow multiple submit
    this.allowSubmit(false);
  }

  ////
  // NEW METHODS
  ////

  // Messages will be defined in child class for per form
  get messages() {
    let messages = {
      "success": I18n.t('messages.notify.general.success'),
      "error": I18n.t('messages.notify.general.error')
    }

    return messages;
  }

  // Display error of an element
  displayError(el, errorMessages) {
    el.classList.add('error');
    this.errorMessageEl(el).innerHTML = errorMessages[0];
  }

  // Clear error of an element
  clearError(el) {
    el.classList.remove('error');
    this.errorMessageEl(el).innerHTML = '';
  }

  // Clear all inputs, highlights and error messages
  clearForm() {
    let _this = this;

    this.attributes.forEach(({ el }) => {
      el.classList.remove('error');
      el.value = ''
      _this.errorMessageEl(el).innerHTML = '';
    })
  }

  // Get first error message (1 level)
  pickFirstMessage(msgObj) {
    let keys = Object.keys(msgObj)
    if (_.isEmpty(keys)) return this.messages.error

    let firstVal = msgObj[keys[0]]
    if (_.isString(firstVal)) {
      return firstVal
    } else {
      return firstVal[0]
    }
  }

   // Will be defined in child class
  allowSubmit(isAllow) {}

  // After submit successfully
  // Will be defined in child class
  successSubmit(e) { }

  // After submit failed
  // Will be defined in child class
  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  // some jQuery library (like bootstrap-select)
  // will insert HTML to DOM and break DOM structure in HTML

  // Ex:
  // HTML code (this structure required by SimpleAjaxForm - method errorMessageEl)
  // <select class="selectppicker"></select>
  // <span class="text text--error error-messenger"></span>

  // HTML display in browser
  // <select class="selectppicker"></select>
  // <button></button> <!-- and other elements created by bootstrap-select -->
  // <span class="text text--error error-messenger"></span>

  // Use this method to create error element after call jQuery library
  createErrorElement(target) {
    target.after('<span class="text text--error error-messenger"></span>')
  }

  getController(controllerName) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === controllerName
    })
  }
}
