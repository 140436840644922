import { autoOpenContentModal } from '@lib/helpers/auto_open_content_modal'

export const eventContentClickable = (target, originTarget, isRegisterForm = '') => {
  $(target).on('click', function(_evt) {
    if(_evt.target.classList.contains('btn')) return;

    $(this).find('.js-hidden-anchor')[0].click();
  })

  autoOpenContentModal(originTarget, '', isRegisterForm)
}
