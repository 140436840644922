// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'
import { ActiveRecordErrorMessageHelper } from '@video_lib/helpers/active_record_error_message_helper'
import { Constant }                       from '@video_lib/constant.js.erb'

export default class extends SimpleAjaxForm {
  static targets = [
    'modal', 'btnInvitation', 'required'
  ]

  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'name', 'blank') }`
      },
      length: {
        maximum: Constant.settings.user.name.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'name', 'too_long', { count: Constant.settings.user.name.length.maximum }) }`
      }
    },
    company_name: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'company_name', 'blank') }`
      },
      length: {
        maximum: Constant.settings.user.company_name.length.maximum,
        tooLong: `^${ ActiveRecordErrorMessageHelper('user', 'company_name', 'too_long', { count: Constant.settings.user.company_name.length.maximum }) }`
      }
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^${ ActiveRecordErrorMessageHelper('user', 'email', 'blank') }`
      },
      email: {
        message: `^${ ActiveRecordErrorMessageHelper('user', 'email', 'invalid') }`
      }
    }
  }

  initModal() {
    let _this = this
    _this.clearForm()

    $(_this.modalTarget).modal('show')
  }

  successSubmit(event) {
    let [response, _status, _xhr] = event.detail
    window.location.href = response.data.redirect_path
  }

  inputAllRequireFields() {
    let allInputed = true
    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  allowSubmit(isAllow) {
    let _this = this
    let isValid = _this.inputAllRequireFields() && !_this.errors.hasAny()

    if(isAllow && isValid) {
      this.btnInvitationTarget.removeAttribute('disabled')
    } else {
      this.btnInvitationTarget.setAttribute('disabled', '')
    }
  }
}
