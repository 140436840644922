// Import libraries from webpacker libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import Rails from '@rails/ujs';

// Import custom libraries
import I18n from '@lib/i18n-js/init.js.erb';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static targets = [ 'editForm', 'deleteForm', 'viewWrapper', 'editWrapper', 'dateInput', 'addWrapper', 'showWrapper' ]
  ////
  // OVERRIDED METHODS
  ////
  connect() {
    super.connect()
    this.element['controller'] = this
  }

  ////
  // NEW METHODS
  ////
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }

    return messages;
  }

  //// EDIT
  // Hide viewWrapper and show editWrapper of a date
  switchToEditDate() {
    let viewWrapper = this.viewWrapperTarget
    let editWrapper = this.editWrapperTarget

    viewWrapper.classList.add('d-none')
    viewWrapper.classList.remove('d-flex')

    editWrapper.classList.remove('d-none')
    editWrapper.classList.add('d-flex')
    $(editWrapper).css('background','#F2F5FA')
  }

  switchToViewDate() {
    let viewWrapper = this.viewWrapperTarget
    let editWrapper = this.editWrapperTarget

    viewWrapper.classList.add('d-flex')
    viewWrapper.classList.remove('d-none')

    editWrapper.classList.remove('d-flex')
    editWrapper.classList.add('d-none')
    $(editWrapper).css('background','#FFFFFF')
    $('.more-menu').hide()
  }

  openMenu(e) {
    var pos = e.target.getBoundingClientRect()
    var y = - 933 + (750 - $('.day-container').height()) + pos.y
    var cons = $('.time-table-aside').height()
    $(window).on('resize', function(){
      var win = $(window).height()-176
      var time = $('.time-table-aside').height()
      var temp = cons - win
      if(win == time){
        var y2 = y + temp
        $(e.target.nextElementSibling).css('transform',`translate3d(128px,${y2}px, 0px)`)
        $(e.target.nextElementSibling).css('width', '160px')
        if($('.time-table-aside').height() + 90 < pos.y){
          $('.more-menu').hide()
        }
      }
    })
    $('.more-menu').hide()
    $(e.target.nextElementSibling).toggle()
    $(e.target.nextElementSibling).css('transform',`translate3d(128px,${y}px, 0px)`)
    $(e.target.nextElementSibling).css('width', '160px')
  }

  openAddMenu(e){
    this.addWrapperTarget.classList.remove('d-flex')
    this.addWrapperTarget.classList.add('d-none')

    this.showWrapperTarget.classList.add('d-flex')
    this.showWrapperTarget.classList.remove('d-none')
    $(this.showWrapperTarget).css('background','#F2F5FA')
  }

  dismissCreateForm() {
    this.addWrapperTarget.classList.add('d-flex')
    this.addWrapperTarget.classList.remove('d-none')

    this.showWrapperTarget.classList.remove('d-flex')
    this.showWrapperTarget.classList.add('d-none')
    $(this.showWrapperTarget).css('background','#FFFFFF')
  }

  //// DELETE
  //
  deleteDate(evt) {
    let deletedDate = evt.target.dataset.date

    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_item.title'),
      message: I18n.t('organizer.modals.confirm_modals.delete_item.content', { item_name: deletedDate }),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        Rails.fire(this.deleteFormTarget, 'submit')
      }
    })
  }

  // Submit deleting event date successfully
  successDelete(e) {
    let [response, _status, _xhr] = e.detail;
    document.location.href = response.data.redirect_path
  }

  // Submit deleting event date failed
  failedDelete(e) {
    let [response, _status, _xhr] = e.detail;
    let error_message = this.pickFirstMessage(response.errors)
    // Notify error message
    alertify.error(error_message)
  }

  _editFormCtrlr() {
    return this.application.controllers.find(ctrlr => {
      return (ctrlr.identifier === 'organizer--event-date' &&
              ctrlr.element.dataset.id === this.element.dataset.id)
    });
  }
}
