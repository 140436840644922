// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { ValidationController } from '../../lib/stimulus_validation/validation_controller';
import I18n from '../../lib/i18n-js/init.js.erb';
import { selectFirstMessage } from './view_helpers';

export class SimpleAjaxForm extends ValidationController {
  ////
  // VALIDATION RULES => will be defined in child classes
  ////

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    if(el.parentElement.className.trim() == 'wrapper-slug'){
      return el.parentNode.nextElementSibling
    }else{
      return el.nextElementSibling
    }
  }

  // Get error message of an attr
  // Default: Show error in the next html element
  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr) : [];
  }

  // Trigger an action after validate an attribute
  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))

      this.allowSubmit(false)
    } else {
      this.clearError(el)

      this.allowSubmit(true)
    }
  }

  // Trigger an action after validating all attributes
  afterValidateAll(event) {
    // Don't allow multiple submit
    this.allowSubmit(false);
  }

  ////
  // NEW METHODS
  ////

  // Messages will be defined in child class for per form
  get messages() {
    let messages = {
      "success": I18n.t('messages.notify.general.success'),
      "error": I18n.t('messages.notify.general.error')
    }

    return messages;
  }

  // Display error of an element
  displayError(el, errorMessages) {
    el.classList.add('error');
    this.errorMessageEl(el).innerHTML = errorMessages[0];

    let description = el.parentElement.querySelector('.small-message')
    if(!errorMessages[0] && description) {
      $(description).addClass('text-danger')
    }
  }

  // Clear error of an element
  clearError(el) {
    el.classList.remove('error');
    this.errorMessageEl(el).innerHTML = '';
    let description = el.parentElement.querySelector('.small-message')

    if(description) {
      $(description).removeClass('text-danger')
    }
  }

  // Clear all inputs, highlights and error messages
  clearForm() {
    let _this = this;

    this.attributes.forEach(({ el }) => {
      el.classList.remove('error');
      el.value = ''
      _this.errorMessageEl(el).innerHTML = '';
    })
  }

  // Validate all attributes without an event
  validateAllManually() {
    this.attributes.refresh()
    this.errors.clear()

    this.attributes.forEach(({ el, value }, attribute) => {
      this.runValidator(attribute)

      this.afterValidate({ el, attr: attribute, value })
    })
  }

  // Get first error message (1 level)
  pickFirstMessage(msgObj) {
    return selectFirstMessage(msgObj);
  }

   // Will be defined in child class
  allowSubmit(isAllow) {}

  // After submit successfully
  // Will be defined in child class
  successSubmit(e) { }

  // After submit failed
  // Will be defined in child class
  failedSubmit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  getController(controllerName) {
    return this.getControllers(controllerName)[0]
  }

  getControllers(controllerName) {
    return this.application.controllers.filter(controller => {
      return controller.context.identifier === controllerName
    })
  }
}
