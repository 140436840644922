import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static values = { needSurvey: Boolean, id: String, downloadableType: String, downloadableId: String, eventSlug: String }
  static targets = [ 'hiddenDownloadBtn' ]

  downloadDocuments(_evt) {
    if (this.needSurveyValue) {
      this.downloadSurveyModalCtrlr.showModal(
        this.idValue,
        this.downloadableTypeValue,
        this.downloadableIdValue,
        this.eventSlugValue,
        this.selectedDocumentIds
      )
    }
    else {
      this.hiddenDownloadBtnTarget.click()
    }
  }

  get selectedDocumentIds() {
    return [this.hiddenDownloadBtnTarget.dataset.documentId]
  }

  get downloadSurveyModalCtrlr() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'download-survey-modal';
    });
  }
}
