import _ from 'lodash';

import ApplicationController from '@controllers/application_controller';
import { showLoaderFor } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static loaded  = false
  static targets = ['dataTable']

  connect() {
    super.connect()
  }

  disconnect() {
    this.loaded = false
  }

  get chatModalController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'direct-chat--chat-modal';
    });
  }

  // Wrapper controller of chat screens
  get wrapperController() {
    let controllers = this.application.controllers.filter(controller => {
      return controller.context.identifier == 'direct-chat--wrapper';
    });

    return controllers[0];
  }

  performSuccess() {
    SyncScroll.reset()
  }

  openServiceChatScreen(e){
    this.wrapperController.screensWrapperTarget.classList.remove('d-none');
    this.loadDirectChatScreen(e.currentTarget);
  }

  loadDirectChatScreen(target){
    // Show loader
    showLoaderFor(this.wrapperController.screensWrapperTarget)
    // Load direct chat screen
    this.stimulate(
      'DirectChat::ScreensReflex#create_and_load_direct_chat_screen',
      target,
      {
        'room_info_type': 'ServiceRoom',
        'owner_id': target.dataset.owner_id,
        'event_id': target.dataset.event_id,
        'service_id': target.dataset.service_id
      }
    )
      .catch(payload => {
        window.location.reload()
      })
  }
}
