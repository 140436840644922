// Import libraries from webpacker libraries
import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['select']

  connect() {
    super.connect()
  }

  select(e) {
    let domId = e.target.dataset.dom_id
    let parentItem = this._downloadForm.rootTarget.querySelector(`#${domId}`);
    let toggle = parentItem.querySelector(`#slider-${domId}`)

    if (e.target.checked) {
      $(parentItem).removeClass('disabled')
      if(toggle) $(toggle.parentElement).removeClass('no-pointer-events')
    } else {
      $(parentItem).addClass('disabled')
      if(toggle) $(toggle.parentElement).addClass('no-pointer-events')
    }

    if (this._inputSelected.length == 0) {
      $(this._downloadButton).addClass('disabled')
    } else {
      $(this._downloadButton).removeClass('disabled')
    }

    $(this._countDescription).text(`${this._inputSelected.length}サービスの出展社に名刺情報が公開され、資料ダウンロードをします`)

    this._update(e)
  }

  request(e) {
    this._update(e)
  }

  _update(e) {
    let requestDownloadOptions = new Map()
    let requestExplainOptions = new Map()

    this._selectInputs.forEach(el => requestDownloadOptions.set(el.dataset.event_service_id, el.checked) )
    this._requestInputs.forEach(el => requestExplainOptions.set(el.dataset.event_service_id, el.checked) )

    this.stimulate('ServiceGroups::DownloadReflex#update', e.target, Object.fromEntries(requestDownloadOptions), Object.fromEntries(requestExplainOptions))
  }

  get _inputSelected() {
    return Array.prototype.slice.call(this._selectInputs).filter(el => el.checked)
  }

  get _countDescription() {
    return this._downloadForm.rootTarget.querySelectorAll('[name=count]')
  }

  get _selectInputs() {
    return this._downloadForm.rootTarget.querySelectorAll('[name=select]')
  }

  get _requestInputs() {
    return this._downloadForm.rootTarget.querySelectorAll('[name=request]')
  }

  get _downloadButton() {
    return this._downloadForm.rootTarget.querySelector('[name=download]')
  }

  get _downloadForm() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'exhibition-halls--service-groups--download-detail'
    });
  }
}
