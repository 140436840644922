// Import libraries from webpacker libraries
import _ from 'lodash';

// Import stimulus libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form'

export default class extends SimpleAjaxForm {
  static targets = [
    'modal', 'userName', 'companyName', 'email', 'invitationDatetime', 'registeredDatetime'
  ]

  initModal(e) {
    let userInfo = $(e.target).parent('.js-user-info')
    $(this.userNameTarget).text(userInfo.find('.list__name').text())
    $(this.companyNameTarget).text(userInfo.find('.list__company').text())
    $(this.emailTarget).text(userInfo.find('.list__email').text())
    $(this.invitationDatetimeTarget).text(userInfo.find('.list__upload-date').text())
    $(this.registeredDatetimeTarget).text(userInfo.find('.list__register-date').text())
    $(this.modalTarget).modal('show')
  }
}
