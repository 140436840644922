module.exports = {
  initScrollTop: function () {
    $(document).on('click', '.js-scroll-to', function (event) {
      event.preventDefault()

      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 70
      }, 200)
    })
  }
}
