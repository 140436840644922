
import ApplicationController from '@controllers/application_controller';
export default class extends ApplicationController {
  static targets = ['wrapper', 'option']

  connect() {
    super.connect()
  }

  selectOption(e) {
    this.stimulate('ContentSurveys::OptionTypeReflex#render_option', e.target)
  }

  renderOptionSuccess() {
    this.drawerController.checkValidForm()
  }

  focusinButton(e) {
    this._getQuestionTypeHover(e).classList.remove('d-none')
  }

  focusoutButton(e) {
    this._getQuestionTypeHover(e).classList.add('d-none')
  }

  _getQuestionTypeHover(element) {
    return element.target.querySelector('.question-type__option-hover')
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }
}