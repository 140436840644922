// Import webpack libraries
import { SimpleAjaxForm } from '@video_lib/helpers/simple_ajax_form';
import Rails from "@rails/ujs";
import { serialize } from 'object-to-formdata';

export default class extends SimpleAjaxForm {

  static values = { id: String, needSurvey: Boolean, needLogin: Boolean, eventId: String }
  static targets = ['downloadButton']

  handleDownloadRequest() {
    // 未ログインの場合、ログインモーダルを表示
    if (this.needLoginValue) return this._showModalLogIn();

    // 資料ダウンロード
    return this.startDownload();
  }

  startDownload() {
    // 行動履歴の記録
    this._trackDocumentDownload();
    // 資料ダウンロード
    this._documentDownload();
  }

  // ダウンロードボタンの情報更新
  changeDownloadButtonStasus(event) {
    const referralServiceDocument = this._findClosestParent(event.target, ".referral-service__document");
    const checkedElements = referralServiceDocument.querySelectorAll(".js-checkbox-download:checked");
    const downloadButtonElement = referralServiceDocument.querySelector(".document__button button");
    const downloadCountElement = downloadButtonElement.querySelector(".selected-count");

    // ダウンロードボタンの件数を更新
    const checkedCount = checkedElements.length;
    // チェックボックスのチェック件数を返却
    downloadCountElement.textContent = checkedCount;

    // ダウンロードボタンの活性・非活性の切り替え
    if (checkedCount === 0) {
      downloadButtonElement.setAttribute('disabled', 'disabled');
    } else {
      downloadButtonElement.removeAttribute('disabled');
    }

    let documentIds = [];
    checkedElements.forEach((element) => {
      documentIds.push(element.dataset.document_id);
    });

    downloadButtonElement.dataset.documentIds = documentIds;
  }

  ////
  // PRIVATE METHODS
  ////

  _showModalLogIn() {
    this._modalLogin.modal('show');
  }

  // 一番近い親要素を返却
  _findClosestParent(element, selector) {
    while (element && !element.matches(selector)) {
      element = element.parentElement;
    }

    return element;
  }

  _documentDownload() {
    const referralServiceDocumentElement = this._findClosestParent(this.downloadButtonTarget, ".referral-service__document");
    const documentListElement = referralServiceDocumentElement.querySelector(".document__list");
    const documentItems = documentListElement.querySelectorAll(".document-item");
    const documentDownload = async () => {
      for (const documentItem of documentItems) {
        const ischecked = documentItem.querySelector(".js-checkbox-download").checked;
        const downloadLinkElement =  documentItem.querySelector(".js-link-download");

        if (ischecked) downloadLinkElement.click();
        await new Promise(resolve => setTimeout(resolve, 500));
      }
    }
    documentDownload();
  }

  _trackDocumentDownload() {
    const data = {
      type:       this.downloadButtonTarget.dataset.tracking_type,
      service_id: this.downloadButtonTarget.dataset.service_id,
      video_id:   this.downloadButtonTarget.dataset.video_id,
      page:       this.downloadButtonTarget.dataset.page
    }

    Rails.ajax({
      url: Routes.trackings_path(),
      type: 'POST',
      data: serialize({ data: data })
    })
  }

  get _modalLogin() {
    return $('.js-modal-login');
  }
}
