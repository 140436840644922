import { Controller } from "stimulus"

export default class extends Controller {

  showDrawerScreen(e) {
    this.drawerController.showScreen(e)

    $('#list-services').addClass('no-pointer-events')
    e.target.classList.add('active')
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'my-company--drawer')
  }
}
