import ApplicationController from '../application_controller'
import _ from 'lodash';

export default class extends ApplicationController {
  static targets = ['cardList']

  connect () {
    super.connect()

    let _this = this

    if (this.cardListTarget.dataset.auto_open == 'true') {
      // Handle open modal
      setTimeout(() => {
        $('#tutorial-detail-modal').modal('show');
      }, 70)
    }

    $("#tutorial-detail-modal").on("hidden.bs.modal", function(e) {
      // Handle when user close modal, update registration show modal
      _this.stimulate('Timetables::TutorialModalReflex#reload', e.target)
    });

    $('#tutorial-detail-modal').on('shown.bs.modal', function (e) {
      _this._initSlick()
      setTimeout(() => {
        $(_this.cardListTarget).addClass('show')
      }, 100)
    })
  }

  _initSlick() {
    $(this.cardListTarget).not('.slick-initialized').slick({
      infinite: false,
      draggable: false,
      slidesToShow: 1,
      prevArrow: "<button class='i-ArrowLeft btn-icon btn-icon-normal btn-icon-xl bg-none no-hover tutorials-arrow-left'></button>",
      nextArrow: "<button class='i-ArrowRight btn-icon btn-icon-primary btn-icon-xl bg-none no-hover tutorials-arrow-right'></button>"
    })
    $(this.cardListTarget).slick('refresh')
  }
}
