import _ from 'lodash';

// Calculate next moment from start time in next minutes
export const nextMoment = (time, minuteNum = 0) => {
  let totalMins = 0;
  if (_.isString(time)) {
    let [hours, minutes] = time.split(':'); // time format as "HH:MM"
    totalMins = (+hours) * 60 + (+minutes);
  } else { // asume time is minutes as integer
    totalMins = time
  }

  let nextTotalMins = totalMins + minuteNum;
  let nextTimeHours = Math.floor(nextTotalMins / 60);
  let nextTimeMinutes = nextTotalMins % 60

  let hour = _.padStart(nextTimeHours, 2, '0');
  let minute = _.padStart(nextTimeMinutes, 2, '0');

  return {
    textTime: `${hour}:${minute}`,
    minutes: nextTotalMins
  }
}
