// Import libraries from webpacker libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import Rails from '@rails/ujs';

// Import custom libraries
import I18n from '@lib/i18n-js/init.js.erb';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static targets = [  ]
  ////
  // OVERRIDED METHODS
  ////
  connect() {
    super.connect()
  }

  openEditForm(e) {
    e.stopPropagation();
    let editForm = $(e.currentTarget).find('.dropdown-menu-left')
    editForm.addClass('show')
    editForm.css('transform','translate(-180px,-115px)')
  }

  deleteStage(e) {
    this.editFormController.delete(e)
  }

  changeStatus(e) {
    this.eventStageController.changeStatus(e)
  }

  get editFormController() {
    return this.application.controllers.find(controller => controller.identifier === 'organizer--timetables--stages--edit-form')
  }

  get eventStageController() {
    return this.application.controllers.find(controller => controller.identifier === 'organizer--event-stage')
  }
}
