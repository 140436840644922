export const ConvertIntegerToFileSizeStringHelper = (size) => {
  let units = ['B', 'KB', 'MB', 'GB']

  for (var i = units.length - 1; i >= 0; i--) {
    let unit = units[i]
    let unitSize = Math.pow(1024, i)
    if (size >= unitSize) {
      return `${size / unitSize}${unit}`
    }
  }
}
