// Import libraries from webpacker libraries
import _ from 'lodash';
import Rails from '@rails/ujs';
import I18n from '@lib/i18n-js/init.js.erb';

// Import custom libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import { confirmModal } from '@lib/helpers/js_modals';
import { generateVideoUrl } from '@lib/helpers/generate_video_url';

export default class extends SimpleAjaxForm {
  static targets = [ 'createForm', 'createDropdown', 'editForm', 'editDropdown', 'nameInput', 'startTimeSelect', 'endTimeSelect','videoProvider', 'videoId', 'video', 'videoPathText', 'updateStageSetup', 'header', 'timetableContent', 'errorMessage']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.blank')}`
      },
      length: {
        maximum: 30,
        tooLong: `^${I18n.t('activerecord.errors.models.event_stage.attributes.name.too_long', { count: 30 })}`
      }
    },
    start_public_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.start_time.blank')}`
      }
    },
    end_public_time: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.end_time.blank')}`
      }
    }
  }

  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect();
    StimulusReflex.register(this)

    this.previewVideo();
    this.reloadPropagation()
  }

  afterValidate({ el, attr }) {
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
    } else {
      this.clearError(el)
    }
  }

  ////
  // NEW METHODS
  ////
  changeStartTime(e) {
    let startTime = e.target.value;
    let endTime = this.endTimeSelectTarget.value;

    if (startTime < endTime) {
      $(this.endTimeSelectTarget).val(endTime)
    }
  }

  // Close dropdown menu
  closeDropdown(e) {
    let editDropdownTarget = this.editDropdownTargets.find((e) => e.classList.contains('show'))
    editDropdownTarget.classList.remove('show')

    // Reset preview video default
    this.videoTarget.src = generateVideoUrl(this.videoProviderTarget.value, this.videoIdTarget.value)
    $(this.videoTarget).show()

    // Clear errors
    for (let i = 0; i < this.errorMessageTargets.length; i++) {
      this.errorMessageTargets[i].innerHTML = ''
    }

    // Reset css name input default
    this.nameInputTarget.classList.remove("error")

    // Reset edit form default
    this.editFormTarget.reset()
  }


  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  // After editing successfully, redirect to previous page
  successEdit(e) {
    this.successSubmit(e)
  }

  // After creating failed, show errors and hightligh like validate()
  failedEdit(e) {
    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  displayError(el, errorMessages) {
    if (el.nextElementSibling && el.nextElementSibling.classList.contains('error-message')) {
      this.errorMessageEl(el).innerHTML = errorMessages[0]
    } else {
      el.parentElement.parentElement.lastElementChild.innerHTML = errorMessages
    }
    el.classList.add('error');
  }

  //// DELETE
  delete(event) {
    let url = event.target.dataset.url;
    let deleteStage = event.target.dataset.event_stage_name;
    let _this = this;

    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_item.title'),
      message: I18n.t('organizer.modals.confirm_modals.delete_item.content', { item_name: deleteStage }),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        Rails.ajax({
          url: url,
          type: 'DELETE',
          error: function(e) {
            let errors = e.errors;
            // Show first error message
            let error_message = _this.pickFirstMessage(errors)
            alertify.error(error_message)
          }
        });
      }
    })
  }

  previewVideo() {
    const videoSrc = generateVideoUrl(this.videoProviderTarget.value, this.videoIdTarget.value)

    if (_.isEmpty(videoSrc)) {
      $(this.videoTarget).hide();
    } else {
      $(this.videoTarget).show()
      this.videoTarget.src = videoSrc
      this.videoIdTarget.value = this.videoIdTarget.value
    }
  }

  reloadPropagation() {
    $('.selectpicker').selectpicker();

    // Stop closing dropdown-menu when click in dropdown menu
    $('.manipulate-stage-dropdown').on('click', function(e) {
      e.stopPropagation();
    });
  }
}
