// Import custom libraries
import ApplicationController from '../application_controller';
import { showLoaderFor } from '../../lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = ['searchForm', 'exportCsv']

  openDirectChatScreen(e){
    this.wrapperController.screensWrapperTarget.classList.remove('d-none');
    this.loadDirectChatScreen(e.currentTarget);
  }

  loadDirectChatScreen(target){
    // Show loader
    showLoaderFor(this.wrapperController.screensWrapperTarget)
    // Load direct chat screen
    this.stimulate(
      'DirectChat::ScreensReflex#create_and_load_direct_chat_screen',
      target,
      {
        'room_info_type': 'SupportRoom',
        'owner_id': target.dataset.owner_id,
        'event_id': target.dataset?.event_id
      }
    )
  }

  search({ key }) {
    if (key == 'Enter') this.searchFormTarget.submit();
  }

  exportUserCsv(e) {
    this.stimulate('Events::RegisteredUserReflex#export_csv', e.target)

    this.exportCsvTarget.classList.add('no-pointer-events')

    let loader = this.exportCsvTarget.querySelector('.loader')
    let icon = this.exportCsvTarget.querySelector('.download-icon')

    loader.classList.remove('d-none')
    icon.classList.add('d-none')

    e.preventDefault()
  }

  readyDownload(e) {
    // if (e.detail.clientId != this.exportCsvTarget.dataset.client_id) return

    this.exportCsvTarget.classList.remove('no-pointer-events')

    let loader = this.exportCsvTarget.querySelector('.loader')
    let icon = this.exportCsvTarget.querySelector('.download-icon')


    loader.classList.add('d-none')
    icon.classList.remove('d-none')

    if (e.detail.fileUrl == '') return

    let link = document.createElement('a')
    link.href = e.detail.fileUrl

    link.click()
  }

  // Wrapper controller of chat screens
  get wrapperController() {
    let controllers = this.application.controllers.filter(controller => {
      return controller.context.identifier == 'direct-chat--wrapper';
    });

    return controllers[0];
  }

  get chatModalController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'direct-chat--chat-modal';
    });
  }
}
