import ApplicationController from '@controllers/application_controller';

export default class extends ApplicationController {
  static values = { stageId: String }
  static targets = ['reactionsCount']

  connect() {
    super.connect()
  }

  // Hiding the number of claps on the guest comment screen
  // increaseReactions(_evt) {
  //   let currentCount = parseInt(this.reactionsCountTarget.dataset.currentCount)
  //   currentCount += 1

  //   this.reactionsCountTarget.innerText = currentCount.toLocaleString()
  //   this.reactionsCountTarget.dataset.currentCount = currentCount
  // }

}
