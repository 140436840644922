// Import custom libraries
import ApplicationController from './application_controller';
import { showLoaderFor } from '../lib/helpers/view_helpers';

export default class extends ApplicationController {
  static targets = []

  ////
  // OVERRIDE METHODS
  ////


  ////
  // NEW METHODS
  ////
  check_disable_exhibitor(el) {
    let eventSlug = el.currentTarget.dataset.slug

    Rails.ajax({
      url: Routes.history_items_event_exhibitors_csv_exports_path({ event_slug: eventSlug }),
      type: 'GET',
      dataType: '',
      error: () => {
        window.location.reload()
      }
    })
  }

  // Wrapper controller of chat screens
  get wrapperController() {
    let controllers = this.application.controllers.filter(controller => {
      return controller.context.identifier == 'direct-chat--wrapper';
    });

    return controllers[0];
  }

  get chatModalController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'direct-chat--chat-modal';
    });
  }

  openSupportChatScreen(e) {
    this.wrapperController.screensWrapperTarget.classList.remove('d-none');
    this.loadDirectChatScreen(e.currentTarget);
  }

  loadDirectChatScreen(target) {
    // Show loader
    showLoaderFor(this.wrapperController.screensWrapperTarget)
    // Load direct chat screen
    this.stimulate(
      'DirectChat::ScreensReflex#create_and_load_direct_chat_screen',
      target,
      {
        'room_info_type': 'SupportRoom',
        'owner_id': target.dataset.owner_id,
        'event_id': target.dataset?.event_id
      }
    )
  }

  ajaxRequest(url, successCallback, errorCallback) {
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: '',
      error: () => {
        errorCallback();
      },
      success: (data) => {
        successCallback(data);
      }
    });
  }

  showServiceDetailModal(event) {
    const serviceId = event.currentTarget.dataset.serviceId;
    const eventSlug = event.currentTarget.dataset.eventSlug;
    const requestUrl = Routes.show_service_detail_modal_event_exhibition_hall_path(eventSlug, serviceId);
    const sample = event.currentTarget.dataset.sample;
    console.log(sample);

    this.ajaxRequest(requestUrl, (_) => {
      $("#service-detail-modal").modal('show');
    }, () => {
      window.location.reload();
    });
  }

  showProfileModal(event) {
    event.preventDefault();
    const eventSlug = event.currentTarget.dataset.eventSlug;

    this.ajaxRequest(Routes.profile_modal_event_users_profiles_path(eventSlug), () => {
      $("#profile-tab").tab('show');
      document.getElementsByClassName("show-mode")[0].classList.add("d-none");
      document.getElementsByClassName("edit-user-form")[0].classList.remove("d-none");
    }, () => {
      window.location.reload();
    });
  }

  showAccountModal(event) {
    event.preventDefault();
    $("#account-tab").tab('show');
  }

  showMemberModal(event) {
    event.preventDefault();
    const eventSlug = event.currentTarget.dataset.eventSlug;

    this.ajaxRequest(Routes.profile_modal_event_users_profiles_path(eventSlug), () => {
      $("#member-tab").tab('show');
    }, () => {
      window.location.reload();
    });
  }
}
