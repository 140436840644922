// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '../../lib/helpers/simple_ajax_form';
import I18n from '../../lib/i18n-js/init.js.erb';

export default class extends SimpleAjaxForm {
  static targets = ['form', 'required']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    email: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.blank')}`
      },
      email: {
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.invalid')}`
      }
    }
  }

  ////
  // OVERRIDED METHODS
  ////
  connect() {
    super.connect()
  }

  afterValidate({ el, attr }) {
    // When this attribute is invalid, highlight and show error
    if (!_.isEmpty(this.errorMessage(attr))) {
      this.displayError(el, this.errorMessage(attr))
      el.classList.add("error")
    } else {
      this.clearError(el)
      if (this.attributes.get('email').el == el) {
        this.clearError(this.attributes.get('email').el)
      }
    }
  }
}
