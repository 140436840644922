import { Spinner } from 'spin.js';

export const fullpageSpinner = {
  spin() {
    this.spinner = new Spinner().spin()

    if (!$('body').children('.fullpage-spinner-container').length) {
      $('body').prepend("<div class='fullpage-spinner-container'></div>")
    }

    $('.fullpage-spinner-container').addClass('show-me').append($(this.spinner.el))
  },
  stop() {
    $('.fullpage-spinner-container').removeClass('show-me')
    this.spinner.stop()
  }
}
