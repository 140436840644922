import { stringToHTML } from '@lib/helpers/view_helpers';
import I18n from '@lib/i18n-js/init.js.erb';
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import { confirmModal } from '@lib/helpers/js_modals';

export default class extends SimpleAjaxForm {
  static values = { inputOptionIds: Array }
  static targets = ['wrapper', 'optionListWrapper', 'option']

  static rules = {
    title: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.blank')}`
      }
    }
  }

  connect() {
    super.connect()

    StimulusReflex.register(this)
    this._initSortableOption()
    this.validateOptionInput()
  }

  _initSortableOption() {
    $('#option-list').sortable({
      animation: 150,
      handle: ".question-adjustment__indicator",
      axis: 'y',
      stop: function(e, ui) {
        let $questionOptionTarget = $(`#${e.target.dataset.question_option_dom_id}`)
        let $questionOptions = $questionOptionTarget.find('.option__wrapper')
        let $changedOption = $questionOptionTarget.find(`#${ui.item[0].dataset.dom_id}`)

        if (ui.item.index() == 0) {
          $changedOption.insertBefore($questionOptions[ui.item.index()])
        } else {
          $changedOption.insertAfter($questionOptions[ui.item.index()])
        }

        $questionOptionTarget.find('.option__wrapper').each((idx, el) => {
          el.querySelector('*[data-sort="option"]').value = idx + 1
        })
      }
    })
  }

  changeValue(e) {
    let $target = $(`#${e.target.dataset.domId}`)

    if (e.target.dataset.key == 'title') {
      $target.find('*[data-key="title"]').val(e.target.value)
      $target.find('.question-item__header-title').text(e.target.value)

    } else if (e.target.dataset.key == 'placeholder') {
      $target.find('*[data-key="placeholder"]').val(e.target.value)
      $target.find('.input__text').attr("placeholder", e.target.value)
    }
  }

  deleteOption(e) {
    let $wrapperTarget = $(`#${e.target.dataset.wrapper_dom_id}`)
    let $target = $(`#${e.target.dataset.dom_id}`)

    $wrapperTarget.remove()
    $target.find('*[data-key="destroy"]').val(true)
    $target.hide()

    this.checkDeleteOption($target.parent())
  }

  checkDeleteOption(target) {
    let $options = target.find('*[data-key="destroy"]')
    let $nonDestroy = $options.filter((_i, e) => e.value == 'false' )

    if($nonDestroy.length == 1) {
      $nonDestroy.each((_i, e) => {
        let $inputTarget = $(`#edit-${e.dataset.domId}`)
        $inputTarget.find('*[data-key="destroy"]').addClass('disabled')
      })
    } else {
      $nonDestroy.each((_i, e) => {
        let $inputTarget = $(`#edit-${e.dataset.domId}`)
        $inputTarget.find('*[data-key="destroy"]').removeClass('disabled')
      })
    }
  }

  hideOption(e) {
    let $wrapperTarget = $(`#${e.target.dataset.wrapper_dom_id}`)
    let $target = $(`#${e.target.dataset.dom_id}`)

    if ($target.find('*[data-key="visible"]').val() == 'true') {
      $wrapperTarget.addClass('input-adjustment__wrapper--hide')
      $wrapperTarget.find('*[data-key="visibility"]').html('visibility_off')

      $target.addClass('d-none')
      $target.find('*[data-key="visible"]').val(false)
    } else {
      $wrapperTarget.removeClass('input-adjustment__wrapper--hide')
      $wrapperTarget.find('*[data-key="visibility"]').html('visibility')

      $target.removeClass('d-none')
      $target.find('*[data-key="visible"]').val(true)
    }

    let $options = $target.parent().find('*[data-key="visible"]')
    let $visibleOptions = $options.filter((_i, e) => e.value == 'true' )

    if ($visibleOptions.length == 1) {
      $visibleOptions.each((_i, e) => {
        let $inputTarget = $wrapperTarget.parent().find(`#edit-${e.dataset.domId}`)
        $inputTarget.find('*[data-key="visibility"]').addClass('disabled')
      })
    } else {
      $visibleOptions.each((_i, e) => {
        let $inputTarget = $wrapperTarget.parent().find(`#edit-${e.dataset.domId}`)
        $inputTarget.find('*[data-key="visibility"]').removeClass('disabled')
      })
    }
  }

  changeOptionValue(e) {
    let $target = $(`#${e.target.dataset.domId}`)

    $target.find('*[data-key="content"]').val(e.target.value)
    $target.find('.option__label').text(e.target.value)
  }

  validateOptionInput() {
    this.inputOptionIdsValue.forEach((optionId) => {
      this.constructor.rules[optionId] = {
        presence: {
          allowEmpty: false,
          message: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.blank')}`
        }
      }
    })
  }

  changeRequire(e) {
    let $target = $(`#${e.target.dataset.domId}`)
    let $badge = $target.find('.badge-header')
    let $requiredText = $('*[data-key="required-text"]')

    if (e.target.checked) {
      $badge.text('必須')
      $requiredText.text('必須')
      $badge.addClass('badge-header--active')
      $target.find('*[data-key="required"]').val(true)
    } else {
      $badge.text('任意')
      $requiredText.text('任意')
      $badge.removeClass('badge-header--active')
      $target.find('*[data-key="required"]').val(false)
    }
  }

  addOption(e) {
    this.stimulate('ContentSurveys::QuestionAdjustmentReflex#render_content', e.target)

    e.preventDefault()
  }

  renderContentSuccess(e) {
    let $parentTarget = $(`#${e.dataset.questionDomId}`)

    this.checkDeleteOption($parentTarget)
  }

  deleteQuestion(e) {
    confirmModal({
      message: "削除しますがよろしいですか？",
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        let $target = $(`#${e.target.dataset.domId}`)

        $target.find('*[data-key="destroy"]').val(true)
        $target.hide()

        this.popupMenuController.closeOptionScreen()
      }
    })
    e.preventDefault()
  }

  hideQuestion(e) {
    let $target = $(`#${e.target.dataset.domId}`)

    $target.find('.input-content').addClass('inactive')
    $target.find('*[data-key="status"]').val('unpublished')

    let $toggleMenu = $target.find('*[data-key="toggle-status"').first()

    let currentStatus = $toggleMenu.val() == 'published' ? 'unpublished' : 'published'

    $toggleMenu[0].innerText = currentStatus == 'published' ? '公開する' : '非表示にする'

    if (currentStatus == 'published') {
      $toggleMenu.removeClass('warn')
      $toggleMenu.addClass('active')
    } else {
      $toggleMenu.addClass('warn')
      $toggleMenu.removeClass('active')
    }

    this.popupMenuController.closeOptionScreen()

    e.preventDefault()
  }

  renderContent(e) {
    let msgHTML = e.detail
    let msgDOM = stringToHTML(msgHTML)

    this.constructor.rules[msgDOM[0].dataset.dom_id] = {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.event_content.attributes.title.blank')}`
      }
    }
    $(this.optionListWrapperTarget).append(msgDOM[0])
  }

  get popupMenuController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--popup-menu')
  }
}