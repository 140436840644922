import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import FileSizeLessThan from '@lib/helpers/check_file_size_less_than_helper';
import pdfPreview from '@lib/helpers/pdf_preview_helper';
import { serialize } from 'object-to-formdata';
import { confirmModal } from '@lib/helpers/js_modals'

export default class extends SimpleAjaxForm {
  static values = {
    formType: String, acceptedFileTypes: Array, maxFileSize: Number,
    documentId: String, documentableType: String, documentableId: String,
    maxDocsCount: Number, managerRole: String
  }

  static targets = [
    'newDocument', 'loading', 'preview', 'existsDocument', 'fileInput', 'root',
    'titleEdit', 'titleView', 'btnGroup', 'field',
    'submitBtn', 'titleViewWrapper', 'timestampValue', 'documentField',
    'documentId', 'documentCreatedAt', 'documentDownloadPath'
  ]

  ////
  // VALIDATION RULES
  ////
  static rules = {
    title: {
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.document.attributes.title.too_long', { count: 50 })}`
      },
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.document.attributes.title.blank')}`
      }
    },
  }

  connect() {
    super.connect()
    if (this.formTypeValue == 'new') {
      this.titleEditTarget.focus()
    }


    let _this = this

    $('#serviceModal').on('hidden.bs.modal', function () {
      _this.clearGetImageInterval()
    });


    if (!this.loadingTarget.classList.contains('d-none')) {
      _this.getPreviewImage(this.loadingTarget.dataset.document_id)
    }
  }

  validateAllManually() {
    super.validateAllManually()
    this.validateFile()
  }

  validateFile() {
    let file = this.fileInputTarget.files[0]

    if (!file) {
      this.displayError(this.documentFieldTarget, [I18n.t('activerecord.errors.models.document.attributes.file.blank')])
      if (this.formTypeValue == 'new') this.allowSubmit(false)
      return
    }

    if (!this.acceptedFileTypesValue.includes(file.type)) {
      let error_message = I18n.t('activerecord.errors.models.document.attributes.file.invalid')
      this.displayError(this.documentFieldTarget, [error_message])
      if (this.formTypeValue == 'new') this.allowSubmit(false)
      return
    }

    if (file.size === 0) {
      if (this.formTypeValue == 'new') this.allowSubmit(false)
      return
    }

    let maxFileSize = this.maxFileSizeValue
    if (!FileSizeLessThan(file, maxFileSize * 1024 * 1024)) {
      let error_message = I18n.t('activerecord.errors.models.document.attributes.file.too_large', { count: maxFileSize })
      this.displayError(this.documentFieldTarget, [error_message])
      if (this.formTypeValue == 'new') this.allowSubmit(false)
      return
    }

    pdfPreview(file, this.newDocumentTarget, 1, 1.5)
    this.clearError(this.documentFieldTarget)
    if (this.formTypeValue == 'new') this.allowSubmit(true)
  }

  uploadFile(evt) {
    this.existsDocumentTarget.classList.add('d-none')
    this.newDocumentTarget.classList.remove('d-none')
    $(this.previewTarget).attr("src", '');

    let file = evt.target.files[0]

    if (!file) return

    if (!this.acceptedFileTypesValue.includes(file.type)) {
      let error_message = I18n.t('activerecord.errors.models.document.attributes.file.invalid')
      this.displayError(this.documentFieldTarget, [error_message])
      return
    }

    let maxFileSize = this.maxFileSizeValue
    if (!FileSizeLessThan(file, maxFileSize * 1024 * 1024)) {
      let error_message = I18n.t('activerecord.errors.models.document.attributes.file.too_large', { count: maxFileSize })
      this.displayError(this.documentFieldTarget, [error_message])
      return
    }

    pdfPreview(file, this.newDocumentTarget, 1, 1.5)
    this.loadingTarget.classList.remove('d-none')

    Rails.ajax({
      url: this._updateUrl(this.documentIdValue),
      type: 'PATCH',
      data: serialize({ document: { file: file } }),
      error: (response, _status, _xhr) => {
        alertify.error(this.messages.error)
        this.displayError(evt.target, response.errors.file)
      },
      success: (data) => {
        this.clearError(this.documentFieldTarget)
        this.getPreviewImage(data.data.document_id)
      }
    })
  }

  getPreviewImage(documentId) {
    let _this = this
    _this.getImageInterval = setInterval(() => {
      Rails.ajax({
        url: this._previewUrl(documentId),
        type: 'GET',
        error: (response, _status, _xhr) => {
        },
        success: (data) => {
          if(data.data.preview_url) {
            $(_this.previewTarget).attr("src", data.data.preview_url);
            _this.loadingTarget.classList.add('d-none')
            this.newDocumentTarget.classList.add('d-none')
            this.existsDocumentTarget.classList.remove('d-none')

            this.clearGetImageInterval()
          } else {
            console.log('preview loading ...')
          }
        }
      })
    }, 1000)
  }

  clearGetImageInterval() {
    clearInterval(this.getImageInterval)
  }

  // Define error message to notify
  get messages() {
    let messages = {
      error: I18n.t('messages.notify.general.error'),
      success: I18n.t('messages.notify.general.success')
    }

    return messages;
  }

  selectFile() {
    this.fileInputTarget.click()
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }
  ////

  // Disable/enable submit button
  allowSubmit(isAllow) {
    let allFieldsValid = !this.fieldTargets.find(field => field.classList.contains('error'))

    if (isAllow && allFieldsValid) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  dismissForm(_evt) {
    this.rootTarget.classList.add('d-none')
    this.docsTabContentCtrlr.updateNewDocFormBtnStatus()
  }

  applyAndExitTitleEdit() {
    this.titleViewTarget.innerText = this.titleEditTarget.value
    this.titleViewWrapperTarget.classList.remove('d-none')
    this.titleEditTarget.classList.add('d-none')
    this.btnGroupTarget.classList.add('d-none')
  }

  discardAndExitTitleEdit() {
    this.titleEditTarget.value = this.titleViewTarget.innerText
    this.titleViewWrapperTarget.classList.remove('d-none')
    this.titleEditTarget.classList.add('d-none')
    this.btnGroupTarget.classList.add('d-none')
  }

  handleCancelBtn(evt) {
    if (this.formTypeValue == 'new') {
      this.dismissForm(evt)
    }
    else if (this.formTypeValue == 'edit') {
      this.clearError(this.titleEditTarget)
      this.discardAndExitTitleEdit()
    }
  }

  enableTitleEdit() {
    this.titleViewWrapperTarget.classList.add('d-none')
    this.titleEditTarget.classList.remove('d-none')
    this.btnGroupTarget.classList.remove('d-none')
    this.titleEditTarget.focus()
    this.formTypeValue = 'edit'
  }

  updateTitle(evt) {
    this.validateManually(this.titleEditTarget)

    if (this.titleEditTarget.classList.contains('error')) return

    Rails.ajax({
      url: this._updateUrl(this.documentIdValue),
      type: 'PATCH',
      data: serialize({ document: { title: this.titleEditTarget.value } }),
      error: () => {

      },
      success: (response) => {
        this.clearError(this.titleEditTarget)
        this.timestampValueTarget.innerText = response.data.updated_at
        this.titleViewTarget.innerText = this.titleEditTarget.value
        this.applyAndExitTitleEdit()
        alertify.success(this.messages.success)
      }
    })
  }

  // サービス資料：新しく追加する
  submitNewDocument(evt) {
    this.validateAllManually()
    this.loadingTarget.classList.remove('d-none')

    let anyErrorField = !!this.fieldTargets.find(field => field.classList.contains('error'))
    if (anyErrorField) return

    let submitData = {
      document: {
        file: this.fileInputTarget.files[0],
        title: this.titleEditTarget.value,
        documentable_type: this.documentableTypeValue,
        documentable_id: this.documentableIdValue
      }
    }

    Rails.ajax({
      url: this._createUrl(),
      type: 'POST',
      data: serialize(submitData),
      error: (response, _status, _xhr) => {
        alertify.error(this.messages.error)
      },
      success: (response) => {
        this.getPreviewImage(response.data.document_id)
        this.formTypeValue = 'edit'
        this.documentDownloadPathTarget.setAttribute('href', response.data.document_download_path)
        this.documentIdTarget.innerText = response.data.document_id
        this.documentCreatedAtTarget.innerText = response.data.created_at
        this.timestampValueTarget.innerText = response.data.updated_at
        this.documentIdValue = response.data.document_id
        this.titleViewTarget.innerText = this.titleEditTarget.value
        this.applyAndExitTitleEdit()
        this.docsTabCtrlr.increaseCount()
        this.docsTabContentCtrlr.updateNewDocFormBtnStatus()
        alertify.success(this.messages.success)
      }
    })
  }

  titleFocusout(evt) {
    this.validate(evt)
  }

  handleSubmitBtn(evt) {
    evt.preventDefault()

    if (this.formTypeValue == 'edit') {
      this.updateTitle(evt)
    }
    else if (this.formTypeValue == 'new') {
      this.submitNewDocument(evt)
    }
  }

  handleFileChanged(evt) {
    if (this.formTypeValue == 'edit') {
      this.uploadFile(evt)
    }
    else if (this.formTypeValue == 'new') {
      this.validateFile()
    }
  }

  deleteDocument(evt) {
    confirmModal({
      title: I18n.t('organizer.modals.confirm_modals.delete_document.title'),
      message: I18n.t('organizer.modals.confirm_modals.delete_document.content'),
      labels: {
        ok_btn: I18n.t('organizer.modals.confirm_modals.buttons.confirm_button'),
        cancel_btn: I18n.t('organizer.modals.confirm_modals.buttons.cancel_button')
      },
      onOk: () => {
        Rails.ajax({
          url: this._destroyUrl(this.documentIdValue),
          type: 'DELETE',
          error: (_response, _status, _xhr) => {
          },
          success: () => {
            this.docsTabCtrlr.decreaseCount()
            this.rootTarget.classList.add('d-none')
            alertify.success(this.messages.success)
            this.docsTabContentCtrlr.updateNewDocFormBtnStatus()
          }
        })
      }
    })
  }

  handleTitleKeydown(evt) {
    if (evt.key == 'Enter') evt.preventDefault()
  }

  _destroyUrl(documentId) {
    if (this.managerRoleValue == 'organizer') {
      return Routes.organizer_document_path({ id: documentId })
    }
    else if (this.managerRoleValue == 'owner') {
      return Routes.owner_document_path({ id: documentId })
    }
  }

  _createUrl() {
    if (this.managerRoleValue == 'organizer') {
      return Routes.organizer_documents_path()
    }
    else if (this.managerRoleValue == 'owner') {
      return Routes.owner_documents_path()
    }
  }

  _updateUrl(documentId) {
    if (this.managerRoleValue == 'organizer') {
      return Routes.organizer_document_path({ id: documentId })
    }
    else if (this.managerRoleValue == 'owner') {
      return Routes.owner_document_path({ id: documentId })
    }
  }

  _previewUrl(documentId) {
    if (this.managerRoleValue == 'organizer') {
      return Routes.preview_document_organizer_document_path({ id: documentId })
    }
    else if (this.managerRoleValue == 'owner') {
      return Routes.preview_document_owner_document_path({ id: documentId })
    }
  }

  get docsTabCtrlr() {
    return this.application.controllers.find(({ identifier }) => {
      return identifier == 'shared--manager--services--documents-tab'
    })
  }

  get docsTabContentCtrlr() {
    return this.application.controllers.find(({ identifier }) => {
      return identifier == 'shared--manager--services--documents-tab-content'
    })
  }
}
