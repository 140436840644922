import ApplicationController from '@controllers/application_controller';
import { stringToHTML, showLoaderFor } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static values = { stageId: String, userId: String, executorRole: String }
  static targets = ['messagesWrap', 'loadmoreIndicator']
  static values = { oldestMsgOffset: Number, page: 1 }

  connect() {
    super.connect()
    showLoaderFor(this.loadmoreIndicatorTarget, 'md')
    this.oldestMsgOffsetValue = this.messagesWrapTarget.dataset.oldest_msg_offset
    this.loadMoreMessages = this.loadMoreMessages.bind(this)
    if (!this.registeredOnScroll) {
      $(this.messagesWrapTarget).on('scroll', _.debounce(this.loadMoreMessages, 100) )
      this.registeredOnScroll = true
    }
    this.subscribeChannel(this.data.get('eventSlug'))
    this._scrollToBottom()
  }

  subscribeChannel(eventSlug) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscription) {
      this.constructor.subscription.unsubscribe()
    }

    this.constructor.subscription = this.application.consumer.subscriptions.create({
      channel: 'EventHalls::StagesChatChannel', event_slug: eventSlug
    }, {
      received: (data) => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  _scrollToBottom() {
    this.messagesWrapTarget.scrollTop = this.messagesWrapTarget.scrollHeight
  }

  loadMoreMessages() {
    if (!this.loadmoreIndicatorTarget.classList.contains('d-none')) return

    this.messagesWrapTarget.dataset.lastScrollTop = this.messagesWrapTarget.dataset.lastScrollTop || 0

    // Scrolling down, skip
    if ($(this.messagesWrapTarget).scrollTop() > this.messagesWrapTarget.dataset.lastScrollTop) {
      this.messagesWrapTarget.dataset.lastScrollTop = $(this.messagesWrapTarget).scrollTop()
      return
    }

    // Not reach near the top yet, skip
    if ($(this.messagesWrapTarget).scrollTop() > 10) {
      this.messagesWrapTarget.dataset.lastScrollTop = $(this.messagesWrapTarget).scrollTop()
      return
    }

    this.loadmoreIndicatorTarget.classList.remove('d-none')

    Rails.ajax({
      url: Routes.more_comments_guests_event_stage_path({ slug: this.messagesWrapTarget.dataset.event_slug, stage_slug: this.messagesWrapTarget.dataset.stage_slug }),
      type: 'GET',
      data: $.param({ offset: this.oldestMsgOffsetValue + 1, page: (this.pageValue + 1) }),
      success: (resp) => {
        this.oldestMsgOffsetValue += resp.data.messages_length
        this.pageValue++
        const firstElement = $(this.messagesWrapTarget).find('.chat-item-component').first()
        $(this.messagesWrapTarget).prepend(resp.data.html)
        let previousHeight = 0;
        firstElement.prevAll().each(function() {
          previousHeight += $(this).outerHeight();
        });
        $(this.messagesWrapTarget).scrollTop(previousHeight);
      },
      complete: () => {
        this.loadmoreIndicatorTarget.classList.add('d-none')
      }
    })
  }

  updateMessagesInfo(evt) {
    let stageIds = evt.detail.stageIds
    let authorId = evt.detail.authorId

    let avatarSelectors = []
    let nameSelectors = []

    stageIds.forEach(stageId => {
      avatarSelectors.push(`.stage-${stageId}-author-${authorId}-chat-input-avatar`)
      avatarSelectors.push(`.stage-${stageId}-author-${authorId}-message-avatar`)

      nameSelectors.push(`.stage-${stageId}-author-${authorId}-chat-input-name`)
      nameSelectors.push(`.stage-${stageId}-author-${authorId}-message-name`)
    })

    $(avatarSelectors.join(', ')).attr('src', evt.detail.avatarUrl)
    $(nameSelectors.join(', ')).text(evt.detail.name)
  }

  updateChatBoard(e) {
    let msgHTML = e.detail;
    let msgDOM = stringToHTML(msgHTML)[0]

    this.messagesWrapTarget.append(msgDOM)
    this.oldestMsgOffsetValue++
    this._scrollToBottom()
  }

  removeMsgChatBox(evt) {
    $(`[data-id=${evt.detail.deletedMessageId}]`).remove()
  }

  updateChatBoardHeight(evt) {
    // Remove current stage time class
    this.element.classList.remove('no-content', 'before-stage-time', 'on-stage', 'after-stage-time')

    this.element.classList.add(evt.detail.stageTimeClass)
  }

  updateGuestComments(evt) {
    let { guestId, stageId, name } = evt.detail
    $(`.stage-${stageId}-author-${guestId}-message-name`).text(name)
  }
}
