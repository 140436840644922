import alertifylib from 'alertifyjs/build/alertify';

////
// NOTIFIER SETUP
////

// Delay 5s when show notifier
alertifylib.set('notifier','delay', 5);  // MEMO: flashメッセージの表示時間

// Show from bottom left
alertifylib.set('notifier','position', 'bottom-left');

export const alertify = alertifylib;
