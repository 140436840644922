import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['chatBubble']

  ////
  // OVERRIDE METHODS
  ////

  connect() {
    super.connect()
  }

  get wrapperController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier == 'direct-chat--wrapper';
    });
  }

  // Open a chat screen and hide chat bubble
  openChatScreen() {
    // => app/javascript/controllers/direct_chat/wrapper_controller.js
    this.wrapperController.openChatScreen()
  }

  informNewMessage() {
    this.chatBubbleTarget.classList.add('nav-item-chat--unread');
  }

  clearNewMessage() {
    this.chatBubbleTarget.classList.remove('nav-item-chat--unread');
  }

}
