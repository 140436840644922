// Import libraries from webpacker libraries
import _ from 'lodash';
// Import custom libraries
import { SimpleAjaxForm } from '@lib/helpers/simple_ajax_form';
import I18n from '@lib/i18n-js/init.js.erb';
import UploadAndPreviewImage from "@lib/helpers/upload_and_preview_logo_helper";
import { confirmModal } from '@lib/helpers/js_modals';
export default class extends SimpleAjaxForm {
  static targets = ['form', 'submitBtn', 'imagePreview', 'logoInput', 'errorLogo']

  ////
  // VALIDATION RULES
  ////
  static rules = {
    name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.company.attributes.name.blank')}`
      },
      length: {
        maximum: 50,
        tooLong: `^${I18n.t('activerecord.errors.models.company.attributes.name.too_long', { count: 50 })}`
      }
    },
    address: {
      length: {
        maximum: 255,
        tooLong: `^${I18n.t('activerecord.errors.models.company.attributes.address.too_long', { count: 255 })}`
      }
    },
    phone: (value) => {
      if (_.isEmpty(value)) return;

      return {
        length: {
          maximum: 11,
          minimum: 10,
          tooLong: `^${I18n.t('activerecord.errors.models.company.attributes.phone.too_long', { count: 11 })}`,
          tooShort: `^${I18n.t('activerecord.errors.models.company.attributes.phone.too_short', { count: 10 })}`
        },
        format: {
          pattern: "^[0-9]+$",
          message: `^${I18n.t('activerecord.errors.models.company.attributes.phone.invalid')}`
        }
      }
    }
  }

  // Upload the company's logo
  uploadCompanyLogo(el) {
    let message = UploadAndPreviewImage(el, this.imagePreviewTarget, 5)
    if (message) {
      this.displayError(el.target, [message])
      this.clearElement(el.target)
    } else {
      this.clearError(el.target)
    }
  }

  ////
  // OVERRIDED METHODS
  ////

  // Element to show error for an input element
  errorMessageEl(el) {
    if (el == this.logoInputTarget) {
      return this.errorLogoTarget;
    }
    return el.nextElementSibling;
  }

  // Trigger an action after validate an attribute

  ////
  // NEW METHODS
  ////
  // Define error message to notify
  get messages() {
    let messages = {
      "error": I18n.t('messages.notify.general.error')
    }
    return messages;
  }

  // Clear error of an element
  clearElement(el) {
    el.classList.remove('error');
    el.value = ''
  }

  // Disable/enable submit button
  allowSubmit(isAllow) {
    if (isAllow) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', '')
    }
  }

  // After submit successfully, redirect to previous page
  successSubmit(e) {
    let [response, _status, _xhr] = e.detail;

    document.location.href = response.data.redirect_path
  }

  // After submit failed, show errors and hightligh like validate()
  failedSubmit(e) {
    // Notify error message
    alertify.error(this.messages.error)

    let [response, _status, _xhr] = e.detail;
    let errors = response.errors
    let _this = this

    let error_attributes = Object.keys(errors)

    this.attributes.forEach(({ attribute, el }) => {
      if (_.includes(error_attributes, attribute)) {
        _this.displayError(el, errors[attribute])
      }
    })
  }

  // DELETE COMPANY
  startDelete(event) {
    let url = event.target.dataset.url;
    confirmModal({
      title: I18n.t('organizer.companies.confirm_modals.delete_company.title'),
      message: I18n.t('organizer.companies.confirm_modals.delete_company.content'),
      labels: {
        ok_btn: I18n.t('organizer.companies.confirm_modals.delete_company.buttons.confirm'),
        cancel_btn: I18n.t('organizer.companies.confirm_modals.delete_company.buttons.cancel')
      },
      onOk: () => {
        $.ajax({
          url: url,
          type: 'DELETE',
          error: function() {
            alertify.error(I18n.t("messages.notify.general.error"))
        }
      });
      }
    })
  }
}
