import ApplicationController from '@controllers/application_controller';
import { removePasteFormat } from '@lib/helpers/view_helpers';

export default class extends ApplicationController {
  static values = { stageId: String }
  static targets = ['inputFld', 'spInputFld']
  static subscriptions = { chat: null }

  connect() {
    super.connect()
    this.hasSpInputFldTarget && removePasteFormat(this.spInputFldTarget)
    this.subscribeStageChatChannel(this.stageIdValue)
  }

  disconnect() {
    if (!this.constructor.subscriptions?.chat) return

    this.constructor.subscriptions.chat.unsubscribe()
  }

  subscribeStageChatChannel(stageId) {
    // Don't subscribe when turbolink is in preview loading
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    if (this.constructor.subscriptions.chat) {
      this.constructor.subscriptions.chat.unsubscribe()
    }

    this.constructor.subscriptions.chat = this.application.consumer.subscriptions.create({
      channel: 'EventHalls::ChatChannel', event_stage_id: stageId, user_role: 'guest'
    }, {
      received: (data) => {
        // console.log('subscription received: ', data)
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  // Submit message to backend
  postChat(_evt) {
    let message = this.inputFldTarget.value || this.inputFldTarget.innerText
    message = message.trim().substring(0, 500)
    if (_.isEmpty(message)) return;

    try {
      // Send this message to service to store and broadcast to other users
      this.stimulate(
        'Guests::StageReflex#create_message', message, this.stageIdValue
      )
    } catch(err) {
      console.error(err)
      Rollbar.error(err)
    }

    this.inputFldTarget.value = '';
    this.inputFldTarget.innerText = '';
  }

  // Only allow input 500 characters
  limitCharacters(e) {
    // Don't limit speacial keys
    if (e.key == 'Backspace' || e.key == 'Delete' || e.key == 'Enter' || e.key == 'Meta') {
      return
    }

    // Limit number of chars
    if ( e.target.innerText.length > 499) {
      e.preventDefault();
        return;
    }
  }
}
