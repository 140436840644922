import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect()
  }

  filtering_update_list() {
    const update_checked = document.querySelector('.js_filtering_update').checked;
    const error_checked = document.querySelector('.js_filtering_error').checked;

    if (update_checked == true || error_checked == true) {
      // 一旦全行を非表示
      document.querySelectorAll('.js-result-row').forEach((element) => {
        element.classList.add('d-none');
      });

      if (update_checked == true) {
        // update行を表示
        document.querySelectorAll('.result-success').forEach((element) => {
          element.classList.remove('d-none');
        });
      }

      if (error_checked == true) {
        // error行を表示
        document.querySelectorAll('.result-error').forEach((element) => {
          element.classList.remove('d-none');
        });
      }
    } else {
      // 非表示をリセット
      document.querySelectorAll('.js-result-row').forEach((element) => {
        element.classList.remove('d-none');
      });
    }
  }
}
