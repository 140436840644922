import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect()

    this._initSortableService()

    $('[data-toggle="tooltip"]').tooltip()
  }

  _initSortableService() {
    $('#group-list').sortable({
      animation: 200,
      axis: 'x,y',
      update: function(e, ui) {
        Rails.ajax({
          url: $(this).data('url'),
          type: 'PATCH',
          data: $(this).sortable('serialize')
        })
      }
    })
  }
}
