// Import webpack libraries
import Rails                              from "@rails/ujs";

// Import stimulus libraries
import { SimpleAjaxForm }                 from '@video_lib/helpers/simple_ajax_form'

// Import custom libraries
import { confirmModal }                   from '@video_lib/helpers/js_modals'

import I18n                               from '@video_lib/i18n-js/init.js.erb'
import { Constant }                       from '@video_lib/constant.js.erb'

export default class extends SimpleAjaxForm {
  connect() {
    super.connect()

    let _this = this

    _this.initSelectpicker()
    _this.disableSelectedOptions()
    _this.initSortable()
    _this.updateDisplayOrder()
  }

  initSelectpicker() {
    let _this = this

    _this.selectPickupServiceEvent.selectpicker()
    _this.selectPickupServiceVideo.selectpicker()
  }

  disableSelectedOptions() {
    let _this = this

    let selectedEmEventIds = _this.selectedEmEventIds
    selectedEmEventIds.forEach(function(value) {
      _this.disableOption('event', value)
    })

    let selectedVideoIds = _this.selectedVideoIds
    selectedVideoIds.forEach(function(value) {
      _this.disableOption('video', value)
    })
  }

  initSortable() {
    let _this = this

    _this.listPickupService.sortable({
      axis: 'y',
      containment: 'parent',
      update: function(event, ui) {
        _this.updateDisplayOrder()
        _this.submitForm()
      }
    })
  }

  ////
  // EVENT METHODS
  ////

  openModalConfirmDeletePickupService(event) {
    let _this = this

    event.preventDefault()

    let pickupService = $(event.currentTarget).closest('.js-pickup-service')

    let i18nPath = 'javascripts.controllers.owner.pickup.edit_pickup_services.modal_confirm_delete_pickup_service'
    confirmModal({
      title: I18n.t(`${i18nPath}.title`),
      message: I18n.t(`${i18nPath}.message`),
      onOk: () => {
        _this.deletePickupService(pickupService)
      }
    })
  }

  deletePickupService(pickupService) {
    let _this = this

    let id          = pickupService.find('.js-hidden-pickup-service-id').val()
    let serviceId   = pickupService.find('.js-hidden-pickup-service-service-id').val()
    let serviceType = pickupService.find('.js-hidden-pickup-service-service-type').val()
    _this.enableOption(serviceType, serviceId)

    let html = `
      <div class="js-pickup-service-deleted" draggable="true">
        <input type="hidden" name="pickup_services[][id]"            id="pickup_services__id"            value="${id}" class="js-hidden-pickup-service-id">
        <input type="hidden" name="pickup_services[][destroy]"       id="pickup_services__destroy"       value="true"  class="js-hidden-pickup-service-destroy">
        <input type="hidden" name="pickup_services[][service_type]"  id="pickup_services__service_type"  value=""      class="js-hidden-pickup-service-service-type">
        <input type="hidden" name="pickup_services[][service_id]"    id="pickup_services__service_id"    value=""      class="js-hidden-pickup-service-service-id">
        <input type="hidden" name="pickup_services[][display_order]" id="pickup_services__display_order" value=""      class="js-hidden-pickup-service-display-order">
      </div>
    `

    pickupService.replaceWith(html)
    _this.submitForm()
  }

  choosePickupService(event) {
    let _this = this

    let select = $(event.target)
    let option = select.find('option:selected')
    let data = option.data()

    let serviceId = select.val()
    let serviceType = select.data('service-type')

    if (!serviceId) return

    select.val('')
    select.selectpicker('refresh')

    let maximum = _this.maximumPickupServicesLength

    if (_this.pickupServices.length >= maximum) {
      let i18nPath = 'javascripts.controllers.owner.pickup.edit_pickup_services.maximum_pickup_services'
      alertify.error(I18n.t(i18nPath, { maximum: maximum }))
      return
    }

    _this.disableOption(serviceType, serviceId)

    _this.listPickupService.append(_this.templatePickupService(serviceType))

    let pickupService = _this.listPickupService.find('.js-pickup-service').last()

    _this.displayPickupService(pickupService, data)
    _this.updateDisplayOrder()
    _this.submitForm()
  }

  displayPickupService(pickupService, data) {
    let _this = this

    // params
    let id = pickupService.find('.js-hidden-pickup-service-id')
    // id.val('')

    let destroy = pickupService.find('.js-hidden-pickup-service-destroy')
    destroy.val('false')

    let serviceType = pickupService.find('.js-hidden-pickup-service-service-type')
    serviceType.val(data.serviceType)

    let serviceId = pickupService.find('.js-hidden-pickup-service-service-id')
    serviceId.val(data.serviceId)

    let displayOrder = pickupService.find('.js-hidden-pickup-service-display-order')
    // displayOrder.val('')

    // UI
    let thumbnail = pickupService.find('.js-img-pickup-service-thumbnail')
    thumbnail.attr('src', data.thumbnail)

    let link = pickupService.find('.js-link-pickup-service')
    link.html(data.title)
    link.attr('href', data.href)
  }

  ////
  // AFTER SUBMIT METHODS
  ////

  successSubmit(event) {
    let _this = this

    let [response, _status, _xhr] = event.detail
    let pickupServiceIds = response.data.pickup_service_ids

    $('.js-pickup-service-deleted').remove()
    $('.js-pickup-service').each(function(index, item) {
      let id = pickupServiceIds[index]
      $(item).find('.js-hidden-pickup-service-id').val(id)
    })
  }

  ////
  // GETTER ELEMENT METHODS
  ////

  get listPickupService() {
    return $('.js-list-pickup-service')
  }

  get selectPickupServiceEvent() {
    return $('select.js-select-pickup-service-event')
  }

  get selectPickupServiceVideo() {
    return $('select.js-select-pickup-service-video')
  }

  get pickupServices() {
    return this.listPickupService.find('.js-pickup-service')
  }

  selectByType(type) {
    let _this = this

    if (type == 'video' || type == 'Video') {
      return _this.selectPickupServiceVideo
    } else if (type == 'event' || type == 'EventManagementModels::Event') {
      return _this.selectPickupServiceEvent
    }
  }

  ////
  // GETTER VALUE METHODS
  ////

  templatePickupService(serviceType) {
    return $(this.element).data(`template_pickup_service_${serviceType}`)
  }

  get selectedEmEventIds() {
    let _this = this

    let emEventIds = []
    _this.listPickupService.find('.js-pickup-service').each(function(index, item) {
      let pickupService = $(item)
      let serviceType = pickupService.find('.js-hidden-pickup-service-service-type').val()
      let serviceId = pickupService.find('.js-hidden-pickup-service-service-id').val()

      if (serviceType == 'EventManagementModels::Event') {
        emEventIds.push(serviceId)
      }
    })

    return emEventIds
  }

  get selectedVideoIds() {
    let _this = this

    let videoIds = []
    _this.listPickupService.find('.js-pickup-service').each(function(index, item) {
      let pickupService = $(item)
      let serviceType = pickupService.find('.js-hidden-pickup-service-service-type').val()
      let serviceId = pickupService.find('.js-hidden-pickup-service-service-id').val()

      if (serviceType == 'Video') {
        videoIds.push(serviceId)
      }
    })

    return videoIds
  }

  get maximumPickupServicesLength() {
    return Constant.settings.pickup_service.length.maximum
  }

  ////
  // GETTER CONTROLLER METHODS
  ////

  get editPickupController() {
    return this.getController('owner--pickup--edit-pickup')
  }

  ////
  // ADTIONAL METHODS
  ////

  disableOption(type, value) {
    let _this = this

    let select = _this.selectByType(type)
    let option = select.find(`option[value="${value}"]`)

    option.attr('disabled', 'disabled')
    select.selectpicker('refresh')
  }

  enableOption(type, value) {
    let _this = this

    let select = _this.selectByType(type)
    let option = select.find(`option[value="${value}"]`)

    option.removeAttr('disabled')
    select.selectpicker('refresh')
  }

  updateDisplayOrder() {
    $('.js-text-pickup-service-display-order').each(function(index, item) {
      $(item).html(index + 1)
    })

    $('.js-hidden-pickup-service-display-order').each(function(index, item) {
      $(item).val(index + 1)
    })
  }

  submitForm() {
    this.editPickupController.submitForm()
  }
}
