import _ from 'lodash';

// Generate video url base on provider and id
export const generateVideoUrl = (provider, videoURL, autoplay = 0) => {
  if (_.isEmpty(provider) || _.isEmpty(videoURL)) {
    return '';
  }

  let url;
  let match = '';

  switch(provider) {
    case 'youtube':
      match = videoURL.match(/https:\/\/www\.youtube\.com\/watch\?v=([\w\-]+)/);
      if (match && match[1]) url = `https://www.youtube.com/embed/${match[1]}?autoplay=${autoplay}`;
      break;
    case 'vimeo':
      match = videoURL.match(/https:\/\/vimeo\.com\/(event\/)?([\w\-]+)/);
      if (match && match[1] === undefined && match[2]) url = `https://player.vimeo.com/video/${match[2]}?autoplay=${autoplay}`;
      if (match && match[1] === 'event/'  && match[2]) url = `https://vimeo.com/event/${match[2]}/embed?autoplay=${autoplay}`;
      break;
    default:
      url = '';
      break;
  }

  return url;
}

export const generateTextVideoPath = (provider) => {
  if (_.isEmpty(provider)) {
    return 'youtube.com/watch?v=';
  }
  switch(provider) {
    case 'youtube':
      return 'youtube.com/watch?v='
    case 'vimeo':
      return "vimeo.com/<br>vimeo.com/event/"
    default:
      return 'youtube.com/watch?v=';
  }
}