import _ from 'lodash';
import { SimpleAjaxForm } from '../lib/helpers/simple_ajax_form';
import I18n from '../lib/i18n-js/init.js.erb';
// gsapはTimelineMax, TweenLite, Bounce, Backを使うために必要
import { gsap } from "gsap";
export default class extends SimpleAjaxForm {
  static targets = [ 'submitBtn', 'required', 'required1', 'agreement', 'termArea', 'invalidInputsCountWrap', 'invalidInputsCountSpWrap', 'emailField', 'passwordField', 'selectTag', 'tagList', 'submitBtnSurvey', 'timetableDate']
  static validators = { validEmail: { attributes: ['email'] }, validPassword: { attributes: ['password'] } }

  ////
  // VALIDATION RULES
  ////
  static rules = {
    first_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.first_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.first_name.too_long', { count: 100 })}`
      }
    },
    last_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.last_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.last_name.too_long', { count: 100 })}`
      }
    },
    company_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.company_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.company_name.too_long', { count: 100 })}`
      }
    },
    department_name: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.department_name.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.department_name.too_long', { count: 100 })}`
      }
    },
    position: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.position.blank')}`
      },
      length: {
        maximum: 100,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.position.too_long', { count: 100 })}`
      }
    },
    telephone: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.blank')}`
      },
      length: {
        maximum: 11,
        minimum: 10,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.too_long', { count: 11 })}`,
        tooShort: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.too_short', { count: 10 })}`
      },
      format: {
        pattern: "^[0-9]+$",
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.telephone.invalid')}`
      }
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.blank')}`
      },
      email: {
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.email.invalid')}`
      }
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.blank')}`
      },
      length: {
        minimum: 8,
        maximum: 32,
        tooShort: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_short', { count: 8 })}`,
        tooLong: `^${I18n.t('activerecord.errors.models.attendee.attributes.password.too_long', { count: 32 })}`
      }
    }
  }

  static values = { eventContentBookmarks: [] }

  connect() {
    super.connect()

    this.initStepAnimation()
    this.scrollTab()

    this.fetchEventContent()
  }


  fetchEventContent() {
    let eventSlug = this.currentActiveContent.dataset.event_slug
    let params = { date_id: this.currentActiveContent.id }
    if (this.currentActiveContent.dataset.pickup) {
      params = { pickup: true }
    }

    Rails.ajax({
      url: `/events/${eventSlug}/render_contents`,
      type: 'GET',
      data: $.param(params),
      success: (response) => {
        this.renderContent(response.data.html)
      }
    })
  }

  renderContent(content) {
    let eventContentWrapper = this.currentActiveContent.querySelector('.date-time-tables')
    $(eventContentWrapper).html(content)
    this.dispatchEvent("lazy_load:complete")
  }

  dispatchEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }

  get currentActiveContent() {
    return this.timetableDateTargets.find(tab => !$(tab).hasClass('d-none'))
  }

  selectEventDate(e) {
    $('.event-date-button').removeClass('event-date-button--active')
    $('.event-date-button').addClass('event-date-button--unactive')
    $(e.target).removeClass('event-date-button--unactive')
    $(e.target).addClass('event-date-button--active')

    let eventDateId = e.target.dataset.date_id
    this.timetableDateTargets.forEach((el) => {
      el.classList.add('d-none')
    })

    let selectedTab

    if (e.target.dataset.pickup) {
      selectedTab = this.timetableDateTargets.find(tab => tab.dataset.pickup)
    } else {
      selectedTab = this.timetableDateTargets.find(tab => tab.id == eventDateId)
    }

    $(selectedTab).removeClass('d-none')

    if (!selectedTab.querySelector('.date-time-tables').innerHTML) {
      this.fetchEventContent()
    }
  }

  bookmark(e) {
    let contentId = e.target.dataset.event_content_id

    if (_.includes(this.eventContentBookmarksValue, contentId)) {
      let index = this.eventContentBookmarksValue.indexOf(contentId)
      this.eventContentBookmarksValue.splice(index, 1)

      $(`.content-card-${contentId}`).each((idx, el) => {
        this.appendModelHref(el.querySelector('.js-hidden-anchor'), false)
      })

      $(`.content-card-${contentId}`).each((idx, el) => {
        this.appendModelHref(el.querySelector('.js-hidden-anchor'), false)
      })
      $(e.target).parents().find(`.btn-${contentId}`).removeClass('btn-accent')
      $(e.target).parents().find(`.btn-${contentId}`).addClass('btn-outline-accent')
      $(e.target).parents().find(`.text-${contentId}`).text(`${I18n.t('shared.timetables.index.buttons.unsubscribed')}`)
      $(e.target).parents().find(`.icon-${contentId}`).removeClass('i-CheckCircle')
      $(e.target).parents().find(`.icon-${contentId}`).addClass('i-Save')

      $(e.target).parents().find(`.card-btn-${contentId}`).removeClass('content-active')
      $(e.target).parents().find(`.card-icon-${contentId}`).addClass('d-none')
      $(e.target).parents().find(`.card-text-${contentId}`).addClass('text-accent')
      $(e.target).parents().find(`.card-text-${contentId}`).text('視聴予約をする')
    } else {
      this.eventContentBookmarksValue.push(contentId)

      $(`.content-card-${contentId}`).each((idx, el) => {
        this.appendModelHref(el.querySelector('.js-hidden-anchor'), true)
      })

      $(`.content-card-${contentId}`).each((idx, el) => {
        this.appendModelHref(el.querySelector('.js-hidden-anchor'), true)
      })
      $(e.target).parents().find(`.text-${contentId}`).text(`${I18n.t('shared.timetables.index.buttons.subscribed')}`)
      $(e.target).parents().find(`.btn-${contentId}`).removeClass('btn-outline-accent')
      $(e.target).parents().find(`.btn-${contentId}`).addClass('btn-accent')
      $(e.target).parents().find(`.icon-${contentId}`).removeClass('i-Save')
      $(e.target).parents().find(`.icon-${contentId}`).addClass('i-CheckCircle')

      $(e.target).parents().find(`.card-btn-${contentId}`).addClass('content-active')
      $(e.target).parents().find(`.card-icon-${contentId}`).removeClass('d-none')
      $(e.target).parents().find(`.card-text-${contentId}`).removeClass('text-accent')
      $(e.target).parents().find(`.card-text-${contentId}`).text('選択中')
    }
  }

  appendModelHref(el, subscribed) {
    if(!el) return

    // Clear all params
    el.href = el.href.replace('&subscribed=false', "")
    el.href = el.href.replace('&subscribed=true', "")

    if(subscribed) {
      el.href = el.href + "&subscribed=true"
    } else {
      el.href = el.href + "&subscribed=false"
    }
  }

  appendModelHref(el, subscribed) {
    if(!el) return

    // Clear all params
    el.href.replace("&subscribed=false")
    el.href.replace("&subscribed=true")

    if(subscribed) {
      el.href = el.href + "&subscribed=true"
    } else {
      el.href = el.href + "&subscribed=false"
    }
  }

  initStepAnimation() {
    let fill_rotation = 180
    let fix_rotation = fill_rotation * 2
    let mainTl = new TimelineMax({paused: true})
    let _this = this

    $('.step:not(.first) .radial-progress').each(function(i) {
      // i = 0, 1
      let circle = $(this)
      let stepLine = circle.prev('.step-line').find('.progress')
      let circleFill = circle.find('.fill:not(.fix)')
      let circleMask = circle.find('.mask.full')
      let circleFillMix = circle.find('.fill.fix')

      mainTl.to(stepLine, 0.15, {width: "100%"})
			.to(circle, 0.3, {rotation: "-="+fill_rotation}, "fillCircle-"+i+"")
			.to([circleFill, circleMask], 0.3, {rotation: fill_rotation}, "fillCircle-"+i+"")
			.to(circleFillMix, 0.3, {rotation: fix_rotation}, "fillCircle-"+i+"")
      .set(circleFillMix, {rotation: fix_rotation}, "stopPoint-"+i+"")
    })

    $('.radial-progress').each(function(i) {
      mainTl.set($(this).next('span'), {color: "rgb(0,159,159)"}, "stopPoint-"+i+"")
      mainTl.set($(this).next().next('.step-title'), {opacity: "0.3", color: 'rgb(0, 158, 158)'}, "stopPoint-"+(i)+"")
    })

    $('.step .radial-progress').on("click", function() {
      if($(this).hasClass('active')){
        return false;
      }

      _this.animateToDefault();

      $('.active').removeClass('active');

      let index = parseInt(this.dataset.index);

      if(index == 1) {
        _this.initForm()
      } else if(index == 2) {
        _this.initSurvey()
      } else {
        $(".previous").addClass("d-none")
        $(".form-watcher").addClass("d-none")
        $("#term").addClass("d-none")
        _this.enableSubmitButton()
        _this.removeSubmitButtonSurvey()
      }
      mainTl.tweenTo("stopPoint-"+(index-1)+"", {onComplete: _this.animateToActive, onCompleteParams:[$(this)]});
    });
  }

  animateToDefault() {
    let mainCircle = $('.radial-progress.active')
		let innerCircle = mainCircle.find('.inner-circle')
    let number = $('.radial-progress.active').next('span')
    let content = $('.tabContent .content');
    let title = $('.radial-progress.active').next().next('.step-title')

    TweenLite.set(title, {color: "rgb(169, 169, 169)", fontWeight: '400'})
    TweenLite.to(innerCircle, 0.15, {scale: 1})
		TweenLite.set(number, {color: "rgb(0,0,0)"})
    TweenLite.to(mainCircle, 0.3, {scale: 1, zIndex: 1, ease: Bounce.easeOut})
    TweenLite.to(content, 0.7, {autoAlpha: 0});

    innerCircle.css('transform', 'none')
  }

  animateToActive(el) {
    let index = parseInt(el.attr('data-index')) + 1;

		$('.tabContent').find(".step-"+index+"").addClass('active');
    $(el).addClass('active')

    let content = $('.tabContent .content.active');
    let innerCircle = $(el).find('.inner-circle')
    let number = $(el).next('span')
    let title = $(el).next().next('.step-title')

    TweenLite.set(title, {color: "rgb(0, 158, 158)", fontWeight: "700", opacity: 1})
    TweenLite.set(number, {color: "rgb(250,250,250)"})
		TweenLite.to(innerCircle, 0.15, {scale: 0})
    TweenLite.to($(el), 0.3, {scale: 1, zIndex: 10, ease: Back.easeOut})
    TweenLite.fromTo(content, 0.7, {autoAlpha: 0}, {autoAlpha: 1});
  }

  scrollTab() {
    $(window).on('scroll', function(e) {
      if($(this).width() > 991) {
        let scroll = $(window).scrollTop();

        if(scroll >= 180){
          $('.tab.step-nav').addClass('step-nav__fixed')
        } else {
          $('.tab.step-nav').removeClass('step-nav__fixed')
        }
      }
    })
  }

  initSubmitButtonSurvey() {
    this.submitBtnSurveyTargets.forEach((el) => el.classList.remove('d-none'))
    this.submitBtnTargets.forEach((el) => el.classList.add('d-none'))
  }

  removeSubmitButtonSurvey() {
    this.submitBtnSurveyTargets.forEach((el) => el.classList.add('d-none'))
    this.submitBtnTargets.forEach((el) => el.classList.remove('d-none'))
  }

  initForm(el) {
    $(".previous").removeClass("d-none")
    $(".form-watcher").removeClass("d-none")
    $("#term").addClass("d-none")
    this.removeSubmitButtonSurvey()

    this.inputErrors = {} // Keep track of which input has error
    this.required1Targets.forEach(elm => this.inputErrors[elm.id] = _.isEmpty(elm.value))

    this._setupSubmitBtn()
    this._updateInvalidInputCount()
  }

  initSurvey() {
    $(".previous").removeClass("d-none")
    $(".form-watcher").addClass("d-none")
    $("#term").removeClass("d-none")

    this.initSubmitButtonSurvey()

    this._setupSubmitBtnSurvey()
    this.selectedTags.forEach(el => $(el).prop('checked', true))
    this.disableOptions()
    this.allowSubmitSurvey(this.inputAllRequireFields());
    this.setupForm()
  }

  setupForm() {
    this.setupCompanyAddress()
    this.setupCompanyName()
    this.setupLastName()
  }

  setupCompanyName() {
    $('.company-name').each(function(index) {
      let companyName = $(`input#user_profile_attributes_company_name`).val()

      if(companyName) {
        $($('.company-name')[index]).html(companyName)
      }
    })
  }

  setupLastName() {
    $('.last-name').each(function(index) {
      let lastName = $(`input#user_profile_attributes_last_name`).val()

      $($('.last-name')[index]).html(lastName)
    })
  }

  setupCompanyAddress() {
    let province = $(`select#user_profile_attributes_province_id option:selected`).text()
    $('#company_address').html(province)
  }

  toggleAgreement(evt) {
    let checked = evt.target.checked

    this.agreementTargets.forEach((el) => {
      $(el).prop('checked', checked)
    })

    this.allowSubmitSurvey(evt.target.checked)
  }

  _setupSubmitBtnSurvey() {
    if(this.inputAllRequireFields()) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  _setupSubmitBtn() {
    if(this.allRequiredInputValid) {
      this.enableSubmitButton()
      $('#step-3').removeClass('no-pointer-events')
    } else {
      this.disableSubmitButton()
      $('#step-3').addClass('no-pointer-events')
    }
  }

  _updateInvalidInputCount() {
    let errorsCount = Object.keys(this.inputErrors).filter(key => !!this.inputErrors[key]).length

    if (errorsCount == 0) {
      this.invalidInputsCountWrapTargets.forEach((el) => $(el).empty().append(I18n.t('users.registrations.form_watcher.all_inputs_filled')))
    }
    else {
      let $text = $(`<div>${I18n.t('users.registrations.form_watcher.input_missing')}</div>`)
      $text.find('.js-invalid-inputs-count').text(errorsCount)
      this.invalidInputsCountWrapTargets.forEach((el) => $(el).empty().append($text.html()))
    }
  }

  validate(evt) {
    super.validate(evt)

    // Take care of autofill on chrome
    if (this.hasPasswordFieldTarget && evt.target == this.passwordFieldTarget) {
      // Ignore if email field is in initialized state
      if(!this.hasEmailFieldTarget) return

      this.validateManually(this.passwordFieldTarget)
      return
    }

    if (this.hasEmailFieldTarget && evt.target == this.emailFieldTarget) {
      // Ignore if password field is in initialized state
      if (!this.passwordFieldTarget.classList.contains('error') && !this.passwordFieldTarget.value) return

      this.validateManually(this.emailFieldTarget)
    }
  }

  afterValidate({ el, attr }) {
    if (attr == 'question' || attr == 'company_address') {
      this.allowSubmitSurvey(this.inputAllRequireFields());
    } else {
      super.afterValidate({ el, attr })
      this.inputErrors[el.id] = !_.isEmpty(this.errorMessage(attr))
      this._updateInvalidInputCount()
    }
  }

  inputAllRequireFields() {
    let allInputed = true

    this.requiredTargets.forEach((el) => {
      if(_.isEmpty(el.value)) {
        allInputed = false;
      }
    })

    return allInputed;
  }

  checkTelephoneInput(e) {
    if (e.key == '-') e.preventDefault()
  }

  selectProvince(evt) {
    this.inputErrors[evt.target.id] = !evt.target.value
    this._updateInvalidInputCount()
    this.allowSubmit(this.allRequiredInputValid);
  }

  get allRequiredInputValid() {
    let valid = true

    this.required1Targets.forEach((el) => {
      if(_.isEmpty(el.value) || el.classList.contains('error')) {
        valid = false;
        return false
      }
    })

    return valid
  }

  // 「参加登録をする」ボタン：有効化／無効化
  allowSubmitSurvey(isAllow) {
    let checkbox = this.agreementTargets.find((el) => $(el).is(":checked"))

    if(isAllow && this.inputAllRequireFields() && checkbox && this.selectedGenres()) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  selectedGenres() {
    const checked_tags = this.selectTagTargets.filter(checkbox => checkbox.checked)

    if (checked_tags.length > 0) {
      return true
    } else {
      return false
    }
  }

  allowSubmit(isAllow) {
    if(isAllow && this.allRequiredInputValid) {
      this.enableSubmitButton()
      $('#step-3').removeClass('no-pointer-events')
    } else {
      this.disableSubmitButton()
      $('#step-3').addClass('no-pointer-events')
    }
  }

  disableSubmitButton() {
    this.submitBtnTargets.forEach((el) => {
      el.classList.add('disabled')
    })

    this.submitBtnSurveyTargets.forEach((el) => el.classList.add('disabled'))
  }

  enableSubmitButton() {
    this.submitBtnTargets.forEach((el) => {
      el.classList.remove('disabled')
    })

    this.submitBtnSurveyTargets.forEach((el) => el.classList.remove('disabled'))
  }

  //  Return data step 2
  formData() {
    let profile_attributes = {}

    let email          = this.userProfileField('email')
    let password       = this.userProfileField('password')
    let lastName       = this.userProfileField('last_name')
    let firstName      = this.userProfileField('first_name')
    let companyName    = this.userProfileField('company_name')
    let provinceId     = this.userProfileField('province_id')
    let departmentName = this.userProfileField('department_name')
    let position       = this.userProfileField('position')
    let telephone      = this.userProfileField('telephone')

    Object.assign(profile_attributes, lastName, firstName, companyName, provinceId, departmentName, position, telephone)

    if(password) {
      return {profile_attributes: profile_attributes, email: email, password: password}
    } else {
      return {profile_attributes: profile_attributes, email: email}
    }
  }

  userProfileField(fieldName) {
    if($(`input#user_profile_attributes_${fieldName}`).length > 0) {
      let obj = {[fieldName]: $(`input#user_profile_attributes_${fieldName}`).val()}
      return obj
    } else if($(`select#user_profile_attributes_${fieldName}`).length > 0) {
      let obj = {[fieldName]: $(`select#user_profile_attributes_${fieldName}`).val()}
      return obj
    } else if($(`input#user_${fieldName}`).length > 0) {
        return $(`input#user_${fieldName}`).val()
    }
  }

  //  Return data step 3
  surveyData() {
    let question_attributes = {}
    let genreIds = {}

    let industryId = this.surveyField('industry_id')
    let companySizeId = this.surveyField('company_size_id')
    let companyAddress = this.surveyField('company_address')
    let jobTitleId = this.surveyField('job_title_id')
    let jobCategoryId = this.surveyField('job_category_id')

    if(this.genreArray(this.selectTagTargets.filter(checkbox => checkbox.checked)).genre_ids.length > 0) {
      genreIds = this.genreArray(this.selectTagTargets.filter(checkbox => checkbox.checked))
    }

    let obj = Object.assign(question_attributes, industryId, companySizeId, companyAddress, jobTitleId, jobCategoryId, genreIds)

    return obj
  }

  surveyField(fieldName) {
    if($(`select#forms_${fieldName}`).length > 0) {
      let obj = {[fieldName]: $(`select#forms_${fieldName}`).val()}
      return obj
    } else if ($(`input#forms_${fieldName}`).length > 0) {
      let obj = {[fieldName]: $(`input#forms_${fieldName}`).val()}
      return obj
    }
  }

  genreArray(array) {
    let arr = []
    array.forEach(function(el) {
      arr.push(el.value)
    })

    let obj = {genre_ids: arr}

    return obj
  }

  dataSubmit(el) {
    let url = el.target.dataset.url
    let obj = { form: this.formData(), survey: this.surveyData(), bookmark: this.eventContentBookmarksValue }

    Rails.ajax({
      url:  url,
      data: jQuery.param({ data: obj }),
      type: 'POST',
      error: (e) => {
        const eventSlug = this.currentActiveContent.dataset.event_slug

        location.href = `/events/${eventSlug}/update_information?event_registration_error=true`
      },
      success: (e) => {
        document.cookie = `complete_user_id=${e.data.complete_user_id}; max-age=600;`
        location.href = e.data.transition_path
      }
    })

    el.target.classList.add('no-pointer-events')
  }

  checkEmailTaken(el) {
    let email = $(el.target).val()
    let eventSlug = el.target.dataset.slug
    let url = Routes.check_email_taken_event_user_event_registrations_path({event_slug: eventSlug, email: email})

    Rails.ajax({
      url: url,
      type: 'GET',
      error: (e) => {
      },
      success: (e) => {
        if (e.data) {
          super.displayError(this.emailFieldTarget, e.data)
          this.inputErrors[this.emailFieldTarget.id] = true
          this._updateInvalidInputCount()
          this.allowSubmit(false)
        }
      }
    })
  }

  next() {
    // current = 0, 1, 2
    let current = parseInt($('.radial-progress.active')[0].dataset.index)

    $(`.step .radial-progress[data-index="${current + 1}"]`).trigger("click")

    // 次へボタン表示切り替え
    if (current + 1 == 1) {
      document.querySelector('.js-next-step-button-0').classList.add('d-none');
      document.querySelector('.js-next-step-button-1').classList.remove('d-none');
    }
  }

  previous() {
    // current = 0, 1, 2
    let current = parseInt($('.radial-progress.active')[0].dataset.index)

    $(`.step .radial-progress[data-index="${current - 1}"]`).trigger("click")

    // 次へボタン表示切り替え
    if (current - 1 == 0) {
      document.querySelector('.js-next-step-button-0').classList.remove('d-none');
      document.querySelector('.js-next-step-button-1').classList.add('d-none');
    } else if (current - 1 == 1) {
      document.querySelector('.js-next-step-button-0').classList.add('d-none');
      document.querySelector('.js-next-step-button-1').classList.remove('d-none');
    }
  }

  select(el) {
    el.target.dataset.checked = ''

    this.disableOptions()

    // 選択されたジャンルのidをdata属性にセットする
    let selected_genres = []

    Array.prototype.forEach.call(this.selectTagTargets.filter(checkbox => checkbox.checked), function(element) {
      selected_genres.push(element.value)
    })

    const select_job_category = document.querySelector('#forms_job_category_id')

    select_job_category.setAttribute('data-selected-genres', selected_genres)
  }

  disableOptions() {
    // 選択していないタグを全て無効化する(最大選択数に達していたら)
    this.uncheckTags.forEach(el => $(el).prop('disabled', this.reachLimitTag))
  }

  get uncheckTags() {
    return this.selectTagTargets.filter(checkbox => !checkbox.checked)
  }

  get reachLimitTag() {
    return this.tagListTarget.dataset.limit == this.allSelected
  }

  get selectedTags() {
    return this.selectTagTargets.filter(checkbox => checkbox.dataset.checked == 'true')
  }

  get allSelected() {
    return this.selectTagTargets.filter(checkbox => checkbox.checked).length
  }
}
