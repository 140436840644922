// Import libraries from webpacker libraries
import videojs from 'video.js'
import _ from 'lodash'

// Import custom libraries
// import { SimpleAjaxForm } from './simple_ajax_form'

import { Controller } from "stimulus"
export class VideoPlayer extends Controller {
  static targets = [ 'player',  'streamPath', 'poster' ];

  connect() {
    this.watchedDurations = []

    this.playerId      = 'js-video-player'
    this.playerModalId = 'js-video-player-modal'
    this.modalId       = 'js-modal-video'

    if (this.hasPlayerTarget) {
      this.player = this.initPlayer
    }

    this.customHeightRelatedVideo()
  }

  customHeightRelatedVideo() {
    if($("#video-related").position() == undefined) return
    if($("#detail-left").position() == undefined) return

    let detailLeftTop =  $("#detail-left").position().top
    let detailLeftHeight = $("#detail-left").height()
    let detailLeftBottom = detailLeftTop + detailLeftHeight

    let videoRelatedTop = $("#video-related").position().top
    let haveDocument = $("#detail-left")[0].dataset.document

    if(haveDocument == "true") {
      // 190px : document__list height
      $("#video-related").css('max-height', `${detailLeftBottom - videoRelatedTop + 190}px`)
    } else {
      $("#video-related").css('max-height', `${detailLeftBottom - videoRelatedTop}px`)
    }
  }

  get initPlayer() {
    let player = videojs(this.playerId, this.videoOptions);

    player.src(this.streamPath)

    if(player.activePlugins_ == undefined) {
      player.hlsQualitySelector()
    }

    player.poster(this.poster)

    return player
  }

  get videoOptions() {
    return {
      type: 'application/x-mpegURL',
      controls: true,
      autoplay: false,
      aspectRatio: '16:9',
      playbackRates: [0.5, 1, 1.5, 2],
      preload: 'auto',
      controlBar: {
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
        volumePanel: {
          inline: false
        }
      }
    }
  }

  initPlayerModal() {
    let _this = this

    let playerModal = videojs(_this.playerModalId, _this.videoOptions);
    playerModal.src(_this.streamPath)
    if(playerModal.activePlugins_ == undefined) {
      playerModal.hlsQualitySelector()
    }

    let Button = videojs.getComponent('Button');
    let PopupButton = videojs.extend(Button, {
      constructor() {
        Button.apply(this, arguments);
        this.el_.innerHTML = '<span class="material-icons vjs-popup-button">crop_16_9</span>'
      },
      controlText(_, el) { el.title = "Popup" },
      handleClick() {
        $('#'+ _this.modalId).modal('show');
        _this.sync2Player(_this.player, playerModal);

        if(_this.player.isFullscreen()) {
          _this.player.exitFullscreen();
        }
      }
    })

    videojs.registerComponent('PopupButton', PopupButton);
    _this.player.getChild('controlBar').addChild('PopupButton', {}, 14);

    $('#' + this.modalId + ' .js-btn-close').click(function() {
      _this.sync2Player(playerModal, _this.player);
    })
  }

  sync2Player(playerSrc, playerDes) {
    playerDes.muted(playerSrc.muted());
    playerDes.volume(playerSrc.volume());
    playerDes.currentTime(playerSrc.currentTime());
    playerDes.playbackRate(playerSrc.playbackRate());

    let isPaused = playerSrc.paused();
    playerSrc.pause();

    if (!isPaused){
      playerDes.play();
    }
  }

  dispose() {
    videojs(this.playerId).dispose()
  }

  get streamPath() {
    return this.streamPathTarget.dataset.stream_path
  }

  get poster() {
    return this.posterTarget.dataset.poster
  }
}
