import { Controller } from "stimulus"

export default class extends Controller {

  showDrawerScreen(e) {
    this.drawerController.showScreen(e)

    let $contentList = $('#survey-list').find('.content-list')
    $contentList.addClass('no-pointer-events')

    if (!$(e.currentTarget).hasClass('button-right')) {
      $(e.currentTarget).parent().addClass('current')
    }

    e.preventDefault()
  }

  unpublishedData(e){
    this.listSurveyController.changeData(e)
    e.preventDefault()
  }

  get drawerController() {
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--drawer')
  }

  get listSurveyController(){
    return this.application.controllers.find(controller => controller.identifier === 'events--contents--questionnaires--list-survey')
  }
}
